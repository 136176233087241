import styled from "styled-components";
import ReactInputMask from "react-input-mask";

export const Header = styled.div`
  width: 100%;
  padding: 1rem;
  margin-bottom: 2rem;

  border-radius: 0.6rem;
  background-color: #fff;
  box-shadow: 0px 12.78px 9.37px -1.71px #e6eeff;

  .divider {
    margin: 0.5rem 0;
    width: 100%;
    height: 0.0625rem;

    background-color: #3058a4;
  }
`;

export const TopFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  p {
    color: #3058a4;
    font-weight: 600;
    font-size: 1.125rem;
  }
`;

export const Bottom = styled.div`
  p {
    color: #4f4f4f;
    font-size: 1.125rem;
  }
`;

export const NavContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  justify-content: space-between;
  grid-gap: 2rem;

  margin-bottom: 1rem;

  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-gap: 0.5rem;
  }
`;

export const NavCard = styled.nav`
  width: 100%;
  height: 110px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 9px;
  background-color: #fff;
  border: 0.85px solid #f5f5f5;
  box-shadow: 0px 12.78px 9.37px -1.71px #e6eeff;

  cursor: pointer;

  .number-transition {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
  }

  .number-transition.hidden {
    opacity: 0;
  }

  &:hover {
    transform: scale(1.1);
    transition: ease 0.4s;
  }

  h4 {
    width: 100%;

    color: #3058a4;
    font-size: 1.1rem;
    font-weight: 600;

    text-align: center;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    p {
      color: #4f4f4f;

      font-size: 2rem;
      font-weight: 600;
    }
  }
`;

export const BannersGetStarted = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  margin-bottom: 1rem;

  h2 {
    color: #3058a4;
    font-size: 1rem;
    margin-top: 1rem;
    font-weight: 600;
  }
`;

export const BannersOurFeatures = styled(BannersGetStarted)`
  margin-top: 1rem;
  margin-bottom: 2rem;

  .functionalities {
    display: flex;

    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #3058a4; /* Cor do thumb */
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      height: 1px;
      border-radius: 4px;
      background-color: #eeeeee; /* Cor do track */
    }

    .functionality-card {
      width: 225px;

      div {
        width: 225px;
      }
    }

    @media (min-width: 768px) {
      overflow: hidden;

      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

      .functionality-card {
        width: 100%;

        div {
          width: 100%;
        }
      }
    }
  }
`;

export const Banners = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-gap: 3rem;
`;

export const BannerCard = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  cursor: pointer;

  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0px 12.8px 9.4px -2px #e6eeff;

  img {
    width: 100%;
    height: 170px;
  }

  div {
    width: 100%;
    padding: 0.8rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    p {
      color: #4f4f4f;
      font-size: 0.8rem;
    }
  }

  &:hover {
    transform: scale(1.1);
    transition: ease 0.4s;
  }
`;

export const StyledInputMask = styled(ReactInputMask)`
  width: 100%;
  height: 50px;
  padding: 15px 10px;

  border: ${({ errors }) =>
    errors ? "2px solid #fb6b03" : "2px solid #3058a4"};

  font-size: 1rem;
`;

export const ContentContact = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);

  position: absolute;
  top: 0;
  left: 0;

  z-index: 10;

  .adjustment-modal {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentContactContainer = styled.div`
  width: 450px;

  border-radius: 10px;
  background-color: #fff;
  z-index: 10;
`;

export const ContentContactHeader = styled.div`
  width: 100%;
  height: 3.75rem;
  padding: 1rem;

  border-radius: 0.625rem 0.625rem 0 0;
  background-color: #3058a4;

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    gap: 0.6rem;

    h2 {
      color: #fff;
      font-size: 1rem;
    }
  }

  .close {
    width: 22px;
    height: 22px;
    cursor: pointer;
    border-radius: 50%;
    border: 1.5px solid #fff;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentForm = styled.form`
  padding: 1rem;

  h4 {
    margin-bottom: 0.5rem;

    color: #3058a4;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
  }

  label {
    font-size: 1.125rem;
    color: #3058a4;
  }
  input {
    margin-bottom: 1rem;
  }
  button {
    width: 100%;
    height: 50px;
    margin: 1rem auto 0 auto;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    border-radius: 4px;
    background: linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%);

    &:hover {
      opacity: 0.7;
    }
  }
`;
