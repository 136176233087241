import { useState, useContext, createContext } from "react";

import { apiCredits } from "../../../services/api";

const TutorialsContextClient = createContext({});

export const useTutorialsClient = () => {
  const context = useContext(TutorialsContextClient);

  if (!context) {
    throw new Error(
      "useTutorialsClient must be used within a TutorialsProviderClient"
    );
  }

  return context;
};

export const TutorialsProviderClient = ({ children }) => {
  const [tutorials, setTutorials] = useState([]);

  const loadTutorials = async () => {
    const response = await apiCredits.get("/tutoriais/buscar-todos");

    const tutorials = response.data;

    localStorage.setItem(
      "@PlayDrop:tutoriais",
      JSON.stringify(tutorials.response)
    );

    const storedData = localStorage.getItem("@PlayDrop:tutoriais");

    if (storedData) {
      setTutorials(JSON.parse(storedData));
    }
  };

  return (
    <TutorialsContextClient.Provider value={{ tutorials, loadTutorials }}>
      {children}
    </TutorialsContextClient.Provider>
  );
};
