import styled from "styled-components";

export const SuppliersContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  font-family: "Poppins";
  font-style: normal;

  .headers {
    display: flex;
    align-items: center;

    width: 100%;
    margin-bottom: 0.5rem;

    li {
      font-weight: 600;
      font-size: 1rem;
      color: #4f4f4f;
    }

    .headers__capa {
      width: 15%;
    }
    .headers__nome {
      width: 20%;
    }
    .headers__status {
      width: 10%;
    }
    .headers__categoria {
      width: 15%;
    }
    .headers__data {
      width: 15%;
    }
    .headers__price {
      width: 12.5%;
    }
    .headers__acoes {
      width: 12.5%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .main:nth-child(odd) {
      background-color: #ffffff;
    }

    div {
      display: flex;
      align-items: center;

      margin-bottom: 0.5rem;

      .content__capa {
        width: 15%;

        img {
          width: 80%;
        }
        video {
          width: 80%;
        }
      }
      .content__nome {
        width: 20%;
      }
      .content__status {
        width: 10%;
      }
      .orange {
        color: #fff;
        text-align: center;
        background-color: #f60b;
        border-radius: 4px;
      }
      .content__categoria {
        width: 15%;
      }
      .content__data {
        width: 15%;
      }
      .content__price {
        width: 12.5%;
      }
      .content__acoes {
        width: 12.5%;
        display: flex;

        .btn-add-vendors {
          padding: 8px;

          color: #fff;
          border-radius: 10px;
          background-color: #3058a4;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    overflow-x: scroll;

    .headers {
      width: 768px;

      .headers__capa {
        width: 110px;
      }
      .headers__nome {
        width: 140px;
      }
      .headers__status {
        width: 70px;
      }
      .headers__categoria {
        width: 110px;
      }
      .headers__data {
        width: 100px;
      }
      .headers__price {
        width: 110px;
      }
      .headers__acoes {
        width: 100px;
      }
    }

    .content {
      width: 768px;

      div {
        .content__capa {
          width: 110px;
        }
        .content__nome {
          width: 140px;
        }
        .headers__status {
          width: 70px;
        }
        .content__categoria {
          width: 110px;
        }
        .content__data {
          width: 100px;
        }
        .content__price {
          width: 110px;
        }
        .content__acoes {
          width: 100px;
        }
      }
    }
  }
`;
