import {
  Text,
  Grid,
  Button,
  Center,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { FaArrowDown } from "react-icons/fa";
import { useWebsitesClient } from "../../../contexts/Websites/WebsitesContextClient";
import { ModalBlockUser } from "../Products/ModalBlockUser";

export const WebsiteCard = ({
  site,
  productId,
  onClose,
  filteredCredits,
  discountCoinWallet,
  planId,
}) => {
  const { importProductToShopify } = useWebsitesClient();

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  return (
    <Grid
      p="4"
      bg="#fff"
      display="flex"
      flexDir="column"
      border="1px solid #eee"
      borderRadius="10px"
      boxShadow="0 12px 9px -1.7px #e6eeff"
    >
      <Heading
        fontFamily="Poppins"
        fontSize="1.5rem"
        fontWeight="600"
        lineHeight="2.375rem"
        color="#4f4f4f"
        mb="2"
      >
        {site.nomesite}
      </Heading>
      <Grid mb="4">
        <Text
          fontFamily="Poppins"
          fontSize="1rem"
          fontWeight="400"
          lineHeight="1rem"
          color="#4f4f4f"
        >
          Site
        </Text>
        <Text fontFamily="Poppins" fontSize="1rem" color="#3058A4">
          {site.dominio}
        </Text>
      </Grid>
      <Grid mb="4">
        <Text
          fontFamily="Poppins"
          fontSize="1rem"
          fontWeight="400"
          lineHeight="1rem"
          color="#4f4f4f"
        >
          ID
        </Text>
        <Text
          fontFamily="Poppins"
          fontSize="1.125rem"
          fontWeight="400"
          lineHeight="1rem"
          color="#3058A4"
        >
          {site.id_site}
        </Text>
      </Grid>
      <Button
        w="100%"
        h="42px"
        borderRadius="10px"
        bg="#03AC00"
        color="#ffffff"
        _hover={{ opacity: 0.8 }}
        alignSelf="end"
        leftIcon={
          <Center w="1rem" h="1rem" borderRadius="50%" border="2px solid #fff">
            <FaArrowDown fill="#fff" size="0.8rem" />
          </Center>
        }
        onClick={() => {
          if (planId !== 4) {
            importProductToShopify(productId, site.id_site);
          } else if (planId === 4 && filteredCredits.creditos > 0) {
            importProductToShopify(productId, site.id_site);
            discountCoinWallet(filteredCredits.id, 5, productId);
            onClose();
          } else if (planId === 4 && filteredCredits.creditos <= 0) {
            onOpenModal();
          }
        }}
      >
        Importar
      </Button>

      <ModalBlockUser
        isOpen={isOpenModal}
        onClose={onCloseModal}
        title="Ops, infelizmente, você não possui créditos suficientes para visualizar esta funcionalidade"
        text="Altere agora o seu plano para conquistar imediatamente acesso ILIMITADO."
      />
    </Grid>
  );
};
