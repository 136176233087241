import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import { ModalUpdateConfig } from "./ModalUpdateConfig";
import { ModalUpdateProfile } from "./ModalUpdateProfile";
import { Text, Grid, Button, useDisclosure } from "@chakra-ui/react";

import { FaUser } from "react-icons/fa";

export const ModalChange = ({ isOpen, onClose, userData }) => {
  const {
    isOpen: isOpenProfile,
    onOpen: onOpenProfile,
    onClose: onCloseProfile,
  } = useDisclosure();

  const {
    isOpen: isOpenConfig,
    onOpen: onOpenConfig,
    onClose: onCloseConfig,
  } = useDisclosure();

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={FaUser} color="#fff" />
            <ModalHeader.Title title="Atualização" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <Text
            mb="6"
            color="#3058A4"
            fontWeight="600"
            fontSize="1.5rem"
            textAlign="center"
          >
            Abaixo escolha que ação deseja fazer
          </Text>
          <Grid gap="6">
            <Button
              w="100%"
              h="50px"
              bg="#3058A4"
              color="#ffffff"
              onClick={() => onOpenConfig()}
              _hover={{
                bgColor: "#ffffff",
                color: "#3058A4",
                border: "2px solid #3058A4",
              }}
            >
              Alterar configurações da conta
            </Button>
            <Button
              mb="4"
              w="100%"
              h="50px"
              bg="#3058A4"
              color="#ffffff"
              onClick={() => onOpenProfile()}
              _hover={{
                bgColor: "#ffffff",
                color: "#3058A4",
                border: "2px solid #3058A4",
              }}
            >
              Alterar informações de perfil do usuário
            </Button>
          </Grid>
        </Modal.Body>
      </Modal.Content>
      <ModalUpdateProfile
        userData={userData}
        isOpen={isOpenProfile}
        onClose={onCloseProfile}
      />
      <ModalUpdateConfig
        isOpen={isOpenConfig}
        onClose={onCloseConfig}
        userId={userData.id}
        planId={userData.id_planos}
      />
    </Modal.Root>
  );
};
