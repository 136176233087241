import styled from "styled-components";

export const WithoutPlanContent = styled.div`
  width: 100%;
  height: 100%;

  background: linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%);

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 10px;

  div {
    padding: 0.4rem 1rem;

    border: 2px solid #fff;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 50px;
    }

    h4 {
      color: #fff;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  h5 {
    margin-top: 2rem;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 500;
  }

  button {
    width: 200px;
    height: 50px;
    margin-top: 2rem;

    border: 2px solid #fff;
    border-radius: 10px;
    background-color: #fff;

    color: #4f4f4f;
    font-size: 1.25rem;
    font-weight: 500;

    &:hover {
      opacity: 0.8;
    }
  }
`;
