import {
  Flex,
  FormLabel,
  InputGroup,
  FormControl,
  InputLeftElement,
  FormErrorMessage,
  Input as ChakraInput,
} from "@chakra-ui/react";

import { useState, useEffect, useCallback, forwardRef } from "react";

const inputVariation = {
  error: "red.500",
  default: "#3058A4",
  focus: "purple.800",
  filled: "green.500",
};

const InputBase = (
  { label, error = null, icon: Icon, heightSchema = false, ...rest },
  ref
) => {
  const [value, setValue] = useState("");
  const [variation, setVariation] = useState("default");

  useEffect(() => {
    if (error) {
      return setVariation("error");
    }
  }, [error]);

  const handleInputFocus = useCallback(() => {
    if (!error) {
      return setVariation("focus");
    }
  }, [error]);

  const handleInputBlur = useCallback(() => {
    if (value.length > 1 && !error) {
      return setVariation("filled");
    }
  }, [error, value]);

  return (
    <FormControl isInvalid={!!error}>
      <Flex p="0.25rem 0.25rem 0.25rem 0" alignItems="center">
        {!!label && (
          <FormLabel
            m="0 0.25rem 0 0"
            fontFamily="Poppins"
            color={inputVariation[variation]}
          >
            {label}
          </FormLabel>
        )}
        {!!error && (
          <FormErrorMessage
            m="0"
            fontFamily="Poppins"
            color={inputVariation[variation]}
          >
            - {error.message}
          </FormErrorMessage>
        )}
      </Flex>
      <InputGroup display="flex" alignItems="center">
        {Icon && (
          <InputLeftElement mt="0.2rem" color={inputVariation[variation]}>
            <Icon size="1.5rem" />
          </InputLeftElement>
        )}
        <ChakraInput
          {...rest}
          h="50px"
          bg="grey.50"
          ref={ref}
          size="base"
          _focus={{
            bgColor: "grey.100",
          }}
          variant="outline"
          fontFamily="Poppins"
          borderWidth="2px"
          onFocus={handleInputFocus}
          onBlurCapture={handleInputBlur}
          _hover={{ bgColor: "gray.100" }}
          color={inputVariation[variation]}
          _placeholder={{ color: "#4f4f4f" }}
          borderColor={inputVariation[variation]}
          onChangeCapture={(event) => setValue(event.currentTarget.value)}
        />
      </InputGroup>
    </FormControl>
  );
};

export const Input = forwardRef(InputBase);
