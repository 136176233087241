import { useState } from "react";

import { AddColumn } from "./styles";

import { Button, Input, useDisclosure } from "@chakra-ui/react";
import { useProductsClient } from "../../contexts/Products/ProductsContextClient";
import { useAuth } from "../../contexts/Auth";
import { ModalBlockUser } from "../../components/Modal/Products/ModalBlockUser";

export const CrudProductContent = ({
  onClose,
  esteira_id,
  product,
  crudOption,
  filteredCreditsConveyor,
  discountCoinWallet,
}) => {
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");

  const { addNewProduct, updateProductConveyor } = useProductsClient();

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const { data } = useAuth();

  const signed = data && Object.values(data).length > 0;

  const isBasicPlan = signed && data.id_planos === 4;

  const handleCrudProduct = () => {
    if (crudOption === "add") {
      if (isBasicPlan) {
        if (filteredCreditsConveyor.creditos > 0) {
          discountCoinWallet(5, "adicionar_esteira", productName);

          addNewProduct(productName, esteira_id, setLoading, onClose);
        } else {
          onOpenModal();
        }
      } else {
        addNewProduct(productName, esteira_id, setLoading, onClose);
      }
    } else {
      updateProductConveyor(productName, product, setLoading, onClose);
    }
  };

  return (
    <AddColumn>
      <Input
        w="100%"
        h="50px"
        border="2px solid #3058a4"
        onChange={(ev) => setProductName(ev.target.value)}
        placeholder="Digite aqui"
        defaultValue={crudOption === "update" ? product.content : ""}
      />

      <Button isLoading={loading} onClick={() => handleCrudProduct()}>
        {crudOption === "add" ? "Adicionar produto" : "Atualizar produto"}
      </Button>

      <ModalBlockUser
        isOpen={isOpenModal}
        onClose={onCloseModal}
        title="Ops, infelizmente, você não possui créditos suficientes para visualizar esta funcionalidade"
        text="Altere agora o seu plano para conquistar imediatamente acesso ILIMITADO."
      />
    </AddColumn>
  );
};
