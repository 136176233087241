import { useState } from "react";
import {
  Box,
  Button,
  Image,
  Progress,
  VStack,
  useToast,
} from "@chakra-ui/react";

import { useAuth } from "../../contexts/Auth";
import { Input } from "../Reusable/Input";
import { FormAuth, TermsContent } from "./styles";
import { FaEye, FaEyeSlash, FaLock, FaUser } from "react-icons/fa";

import { GoBack } from "../Reusable/GoBack";
import goBackImg from "../../assets/icons/goBack.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from "../../schemas/schemas";
import { MdLockOutline, MdOutlineMail } from "react-icons/md";
import { apiCredits } from "../../services/api";

const StepOne = ({ register, errors, loading, handleContinue }) => (
  <>
    <Input
      type="text"
      label="Nome"
      icon={FaUser}
      error={errors.nome}
      {...register("nome")}
      placeholder="Digite o seu nome"
    />

    <Button
      w="100%"
      h="50px"
      m="1rem auto 0 auto"
      bg="linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%)"
      type="button"
      color="#fff"
      border="none"
      onClick={() => handleContinue()}
      fontSize="1.25rem"
      isLoading={loading}
      fontWeight="500"
      borderRadius="4px"
      _hover={{ opacity: 0.8 }}
    >
      Continuar
    </Button>
  </>
);

const StepTwo = ({
  register,
  errors,
  goBack,
  loading,
  showError,
  watch,
  handleContinue,
}) => {
  return (
    <>
      {errors.nome && (
        <Box w="100%" display="flex" justifyContent="flex-start">
          <Image
            w="1rem"
            src={goBackImg}
            alt="Imagem de seta para retornar para página anterior"
            color="#FB6B03"
            cursor="pointer"
            onClick={goBack}
          />
        </Box>
      )}

      <p className="error">{errors.nome && errors.nome?.message}</p>
      {showError !== "" && <p className="error">{showError}</p>}

      <Input
        type="email"
        label="E-mail"
        icon={MdOutlineMail}
        error={errors.email}
        {...register("email")}
        placeholder="Digite o seu e-mail"
        value={watch("email") || ""}
      />

      <Button
        w="100%"
        h="50px"
        m="1rem auto 0 auto"
        bg="linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%)"
        type="button"
        color="#fff"
        border="none"
        onClick={() => handleContinue()}
        fontSize="1.25rem"
        isLoading={loading}
        fontWeight="500"
        borderRadius="4px"
        _hover={{ opacity: 0.8 }}
      >
        Continuar
      </Button>
    </>
  );
};

const StepThree = ({ register, errors, goBack, loading }) => {
  return (
    <>
      {(errors.email || errors.nome) && (
        <Box w="100%" display="flex" justifyContent="flex-start">
          <Image
            w="1rem"
            src={goBackImg}
            alt="Imagem de seta para retornar para página anterior"
            color="#FB6B03"
            cursor="pointer"
            onClick={goBack}
          />
        </Box>
      )}

      <p className="error">{errors.email && errors.email?.message}</p>

      <Input
        type="password"
        label="Senha"
        icon={MdLockOutline}
        error={errors.senha}
        {...register("senha")}
      />

      <TermsContent>
        <div>
          <input type="checkbox" {...register("acceptTerms")} />
          <p>
            Li e concordo com os
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.playdrop.com.br/termos-de-uso"
            >
              termos de uso
            </a>
            e com a
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.playdrop.com.br/politica"
            >
              política de privacidade
            </a>
            do site.
          </p>
        </div>
        {errors.acceptTerms && <span>{errors.acceptTerms?.message}</span>}
      </TermsContent>

      <Button
        w="100%"
        h="50px"
        m="1rem auto 0 auto"
        bg="linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%)"
        type="submit"
        color="#fff"
        border="none"
        fontSize="1.25rem"
        isLoading={loading}
        fontWeight="500"
        borderRadius="4px"
        _hover={{ opacity: 0.8 }}
      >
        Criar Conta
      </Button>
    </>
  );
};

export const SignUpForm = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState("");

  const toast = useToast();
  const { signUp } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerSchema),
  });

  const handleSignUp = (data) => {
    setLoading(true);

    signUp(data)
      .then(() => {
        setLoading(false);
        toast({
          title: "Sucesso!",
          description: "Cadastro criado com sucesso!",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        reset();
      })
      .catch(() => {
        toast({
          title: "Opss...",
          description: "Algo deu errado...",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  const handleContinue = async () => {
    const newEmail = watch("email");

    if (step === 1) {
      if (watch("nome") !== "") {
        setLoading(true);

        setTimeout(() => {
          setStep((prevStep) => prevStep + 1);

          setLoading(false);
        }, 1000);
      }
    }

    if (step === 2) {
      setLoading(true);

      if (newEmail !== "") {
        try {
          await apiCredits
            .post(
              "/login/user",
              { email: newEmail },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((res) => {
              const { response } = res.data;
              if (response.message) {
                setLoading(false);

                setShowError("O e-mail informado já existe");
              } else {
                setLoading(false);

                setShowError("");

                setStep((prevStep) => prevStep + 1);
              }
            });
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      } else {
        setLoading(false);

        setShowError("Por favor, digite um e-mail válido");
      }
    }
  };

  return (
    <FormAuth onSubmit={handleSubmit(handleSignUp)}>
      <Progress value={(step / 3) * 100} size="xs" colorScheme="blue" mb="4" />

      <h2>
        Seja bem vindo(a) <span className="subtitle">Comece por aqui!</span>
      </h2>

      <VStack spacing="4">
        {step === 1 && (
          <StepOne
            register={register}
            errors={errors}
            loading={loading}
            handleContinue={handleContinue}
          />
        )}

        {step === 2 && (
          <StepTwo
            register={register}
            errors={errors}
            goBack={() => setStep((prevStep) => prevStep - 1)}
            showError={showError}
            watch={watch}
            loading={loading}
            handleContinue={handleContinue}
          />
        )}

        {step === 3 && (
          <StepThree
            register={register}
            errors={errors}
            goBack={() => setStep((prevStep) => prevStep - 1)}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
            loading={loading}
          />
        )}
      </VStack>
    </FormAuth>
  );
};
