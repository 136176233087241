// Components
import {
  VStack,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from "@chakra-ui/react";
import { DrawerMobileOptions } from "./DrawerMobileOptions";

// Icons
import { FaFire } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import { MdLogout } from "react-icons/md";
import { LuLayoutGrid } from "react-icons/lu";
import { BsCurrencyDollar, BsGrid } from "react-icons/bs";
import { BiCalculator, BiCube, BiStoreAlt } from "react-icons/bi";

// Hooks
import { useAuth } from "../../contexts/Auth";
import { useNavigate } from "react-router-dom";

export const DashboardDrawer = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const { signOut } = useAuth();

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="left" onEsc={onClose}>
      <DrawerOverlay />
      <DrawerContent
        w="100%"
        h="560px"
        ml="2rem"
        mt="9.5rem"
        maxW="300px"
        bg="#3058A4"
        borderRadius="10px"
      >
        <DrawerBody bg="#3058A4" borderRadius="10px" w="265px">
          <VStack spacing="4" w="100%" pt="8">
            <DrawerMobileOptions
              textname="Dashboard"
              onClick={() => {
                navigate("/dashboard/menu-inicial");
                onClose();
              }}
            >
              <BsGrid fill="#ffffff" size="1.5rem" />
            </DrawerMobileOptions>
            <DrawerMobileOptions
              textname="Construtor de Lojas"
              onClick={() => {
                navigate("/dashboard/construtor-de-lojas");
                onClose();
              }}
            >
              <TbWorld color="#ffffff" size="24px" />
            </DrawerMobileOptions>

            {/* <DrawerMobileOptions
              textname="Criativos"
              onClick={() => {
                navigate("/dashboard/midias-sociais");
                onClose();
              }}
            >
              <PiCirclesThreePlusBold fill="#ffffff" size="1.5rem" />
            </DrawerMobileOptions> */}

            <DrawerMobileOptions
              textname="Produtos em Alta"
              onClick={() => {
                navigate("/dashboard/produtos-em-alta");
                onClose();
              }}
            >
              <FaFire fill="#ffffff" size="24px" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Produtos"
              onClick={() => {
                navigate("/dashboard/fornecedores");
                onClose();
              }}
            >
              <BiStoreAlt fill="#ffffff" size="24px" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Gerador de Copy"
              onClick={() => {
                navigate("/dashboard/copy");
                onClose();
              }}
            >
              <BiStoreAlt fill="#ffffff" size="24px" />
            </DrawerMobileOptions>
            <DrawerMobileOptions
              textname="Gerador de Nomes"
              onClick={() => {
                navigate("/dashboard/gerador-de-nomes");
                onClose();
              }}
            >
              <TbWorld fill="#ffffff" size="24px" />
            </DrawerMobileOptions>
            <DrawerMobileOptions
              textname="Players Academy"
              onClick={() => {
                navigate("/dashboard/tutoriais");
                onClose();
              }}
            >
              <BiCube fill="#ffffff" size="1.5rem" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Precificador"
              onClick={() => {
                navigate("/dashboard/calculadora");
                onClose();
              }}
            >
              <BiCalculator fill="#ffffff" size="1.5rem" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Esteira de produtos"
              onClick={() => {
                navigate("/dashboard/esteira-de-produtos");
                onClose();
              }}
            >
              <BiStoreAlt fill="#ffffff" size="1.5rem" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Aplicativos"
              onClick={() => {
                navigate("/dashboard/aplicativos");
                onClose();
              }}
            >
              <LuLayoutGrid color="#ffffff" size="1.5rem" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Planos"
              onClick={() => {
                navigate("/dashboard/planos");
                onClose();
              }}
            >
              <BsCurrencyDollar color="#ffffff" size="1.5rem" />
            </DrawerMobileOptions>
          </VStack>
          <Button
            pl="1"
            mt="10"
            outline="0"
            color="#ffffff"
            borderWidth="0"
            bg="transparent"
            fontWeight="400"
            borderColor="transparent"
            onClick={() => signOut()}
            leftIcon={<MdLogout fill="#ffffff" size="1.25rem" />}
          >
            Sair
          </Button>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
