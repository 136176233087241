import * as PC from "./styles";

import { Title } from "../../components/Reusable/Title";
import { Container } from "../../components/Reusable/Container";
import { ModalCrudColumn } from "../../components/Modal/Products/ModalCrudColumn";
import { DraggableColumn } from "./DraggableColumn";
import { SkeletonConveyor } from "../../components/Reusable/Skeletons/SkeletonConveyor";

import { FaPlus } from "react-icons/fa6";
import { BiStoreAlt } from "react-icons/bi";

import { useDisclosure } from "@chakra-ui/react";
import { useProductsClient } from "../../contexts/Products/ProductsContextClient";
import { useEffect, useState } from "react";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useCredits } from "../../contexts/Credits";
import { FiArrowUpRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";

export const ProductConveyor = () => {
  const [loading, setLoading] = useState(false);

  const { credits, discountCoinWallet } = useCredits();

  const navigate = useNavigate();

  const { data } = useAuth();

  const signed = data && Object.values(data).length > 0;

  const isBasicPlan = signed && data.id_planos === 4;

  const {
    productsConveyor,
    setProductsConveyor,
    updatePositionConveyor,
    loadProductsConveyor,
  } = useProductsClient();

  const filteredCreditsConveyor =
    credits.length > 0 &&
    credits?.find((item) => item.category === "adicionar_esteira");

  useEffect(() => {
    setLoading(true);

    loadProductsConveyor().then((_) => setLoading(false));

    // eslint-disable-next-line
  }, []);

  const {
    isOpen: isOpenAddColumn,
    onOpen: onOpenAddColumn,
    onClose: onCloseAddColumn,
  } = useDisclosure();

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);

      destItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });

      updatePositionConveyor(destItems, destColumn.id, removed.id);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      copiedItems.forEach((item, index) => (item.position = index + 1));

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });

      updatePositionConveyor(copiedItems);
    }
  };

  return (
    <Container>
      <Title
        headingName="Esteira de Produtos"
        caption="Aqui você pode colocar e armazenar a sua esteira de produtos desejados."
      >
        <BiStoreAlt size="2rem" fill="#3058A9" />
      </Title>

      <hr />

      {isBasicPlan && (
        <PC.InfoCredits>
          {filteredCreditsConveyor !== undefined &&
          filteredCreditsConveyor.creditos === 0 ? (
            <>
              <p>Opps, saldo para adicionar produtos acabou...</p>
              <div onClick={() => navigate("/dashboard/planos")}>
                <FiArrowUpRight size="1.5rem" color="#3058a4" />
              </div>
            </>
          ) : (
            <p>
              Você pode adicionar
              <span>
                {filteredCreditsConveyor && filteredCreditsConveyor.creditos}
              </span>
              produtos a esteira
            </p>
          )}
        </PC.InfoCredits>
      )}

      <PC.Content>
        {loading ? (
          <SkeletonConveyor quantityParam={3} />
        ) : (
          <DragDropContext
            onDragEnd={(result) =>
              onDragEnd(result, productsConveyor, setProductsConveyor)
            }
          >
            {productsConveyor &&
              Object.values(productsConveyor).length > 0 &&
              Object.entries(productsConveyor).map(([columnId, column]) => {
                return (
                  <Droppable key={columnId} droppableId={columnId}>
                    {(provided) => {
                      return (
                        <DraggableColumn
                          column={column}
                          provided={provided}
                          quantityColumns={
                            Object.entries(productsConveyor).length
                          }
                          filteredCreditsConveyor={filteredCreditsConveyor}
                          discountCoinWallet={discountCoinWallet}
                        />
                      );
                    }}
                  </Droppable>
                );
              })}
          </DragDropContext>
        )}

        {!loading && (
          <div className="add-column">
            <button onClick={() => onOpenAddColumn()}>
              Adicionar coluna <FaPlus size="1.5rem" />
            </button>
          </div>
        )}
      </PC.Content>

      <ModalCrudColumn
        isOpen={isOpenAddColumn}
        onClose={onCloseAddColumn}
        crudOption="add"
      />
    </Container>
  );
};
