import { Modal } from "../index";
import { Input } from "../../Reusable/Input";
import { ModalHeader } from "../ModalHeader";
import {
  Button,
  VStack,
  Select,
  Heading,
  useDisclosure,
  Box,
} from "@chakra-ui/react";

import { yupResolver } from "@hookform/resolvers/yup";
import { productSchema, productUpdateSchema } from "../../../schemas/schemas";

import { BiStoreAlt } from "react-icons/bi";
import { MdArrowDropDown } from "react-icons/md";

import { useForm } from "react-hook-form";
import { useProductsAdmin } from "../../../contexts/Products/ProductsContextAdmin";
import { useCategoriesClient } from "../../../contexts/Categories/CategoriesContextClient";
import { useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles.css";
import { UpdateImageAdmin } from "./UpdateImageAdmin";
import { formatDateIso } from "../../../utils/formatDate";

export const ModalCrudProduct = ({
  isOpen,
  onClose,
  verbType,
  product = {},
}) => {
  const { categoryData } = useCategoriesClient();
  const { addNewProduct, updateProduct } = useProductsAdmin();

  const {
    isOpen: isOpenUpdateImage,
    onOpen: onOpenUpdateImage,
    onClose: onCloseUpdateImage,
  } = useDisclosure();

  const [userInfo, setuserInfo] = useState({
    title: "",
  });

  const onChangeValue = (e) => {
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  let editorState = EditorState.createEmpty();
  const [description, setDescription] = useState(editorState);
  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };

  const date = new Date();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      verbType === "update" ? productUpdateSchema : productSchema
    ),
  });

  const handleCrudProduct = (data) => {
    const productData = {
      ...data,
      descricao: userInfo.description.value,
      linkcompra:
        data.linkcompra !== "" ? data.linkcompra : "Sem fornecedor nacional",
      link_facebook:
        data.link_facebook !== ""
          ? data.link_facebook
          : "Sem fornecedor internacional",
    };

    if (verbType === "create") {
      addNewProduct(productData);
    } else {
      updateProduct(productData, product.id_fornecedores);
    }
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BiStoreAlt} color="#fff" />
            <ModalHeader.Title
              title={
                verbType === "create"
                  ? "Fornecedores - Cadastrar Produto"
                  : "Fornecedores - Editar Produto"
              }
            />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <Heading mt="4" color="#3058A5" fontSize="1.4rem" textAlign="center">
            {verbType === "create"
              ? "Digite os dados para o cadastro do produto"
              : "Digite os dados para edição do produto"}
          </Heading>

          <VStack
            as="form"
            mt="2rem"
            spacing="4"
            onSubmit={handleSubmit(handleCrudProduct)}
          >
            {verbType !== "update" && (
              <Input
                type="file"
                {...register("imagem")}
                error={errors.imagem}
              />
            )}

            <Box
              w="100%"
              h="45px"
              bg="#3058a4"
              color="#fff"
              fontSize="1.125rem"
              fontWeight="500"
              textAlign="center"
              display="flex"
              alignItems="center"
              borderRadius="10px"
              justifyContent="center"
              _hover={{ opacity: 0.8 }}
              onClick={() => onOpenUpdateImage()}
            >
              Atualizar Imagem
            </Box>

            <Input
              label="Título"
              error={errors.produto}
              {...register("produto")}
              defaultValue={verbType === "update" ? product.produto : ""}
            />

            <Input
              type="date"
              label="Data"
              error={errors.data}
              {...register("data")}
              defaultValue={
                product && product.data ? product.data : formatDateIso(date)
              }
            />

            <Input
              type="text"
              label="Link fornecedor nacional"
              error={errors.linkcompra}
              {...register("linkcompra")}
              defaultValue={verbType === "update" ? product.linkcompra : ""}
            />

            <Input
              type="text"
              label="Link fornecedor internacional"
              error={errors.link_facebook}
              {...register("link_facebook")}
              defaultValue={verbType === "update" ? product.link_facebook : ""}
            />

            <Input
              type="text"
              label="Link concorrente"
              error={errors.link_concorrente}
              {...register("link_concorrente")}
              defaultValue={
                verbType === "update" ? product.link_concorrente : ""
              }
            />

            <Select
              h="60px"
              outline="0"
              iconSize="1rem"
              cursor="pointer"
              color="#505050"
              borderRadius="6px"
              fontSize="1.125rem"
              fontFamily="Poppins"
              iconColor="#3058A4"
              placeholder="Categoria"
              {...register("categoria")}
              border="1px solid #505050"
              _hover={{ borderColor: "#3058A4" }}
              icon={<MdArrowDropDown size="1.5rem" />}
              defaultValue={verbType === "update" ? product.categoria : ""}
            >
              {categoryData.map((category) => (
                <option key={category.id} value={category.tipo_produto}>
                  {category.tipo_produto}
                </option>
              ))}
            </Select>

            <Input
              type="number"
              label="Preço"
              error={errors.preco}
              {...register("preco")}
              defaultValue={verbType === "update" ? product.preco : ""}
            />

            <Select
              h="60px"
              outline="0"
              iconSize="1rem"
              cursor="pointer"
              color="#505050"
              borderRadius="6px"
              fontSize="1.125rem"
              fontFamily="Poppins"
              iconColor="#3058A4"
              {...register("status")}
              border="1px solid #505050"
              placeholder="Status do produto"
              _hover={{ borderColor: "#3058A4" }}
              icon={<MdArrowDropDown size="1.5rem" />}
              defaultValue={verbType === "update" ? product.status : ""}
            >
              <option value="Disponível">Disponível</option>
              <option value="Pendente">Pendente</option>
            </Select>

            <label className="label-description">Descrição</label>

            <Editor
              editorState={description}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
            />

            <textarea
              style={{ display: "none" }}
              disabled
              ref={(val) => (userInfo.description = val)}
              value={draftToHtml(convertToRaw(description.getCurrentContent()))}
            />

            <Button
              h="50px"
              w="200px"
              mt="1rem"
              mb="1rem"
              bg="#3058A4"
              type="submit"
              color="#ffffff"
              fontFamily="Poppins"
              _hover={{ opacity: 0.7 }}
            >
              {verbType === "update" ? "Confirmar edição" : "Cadastrar produto"}
            </Button>
          </VStack>
        </Modal.Body>
      </Modal.Content>

      <UpdateImageAdmin
        isOpen={isOpenUpdateImage}
        onClose={onCloseUpdateImage}
        productId={product.id_fornecedores}
      />
    </Modal.Root>
  );
};
