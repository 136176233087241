import { MdLockOutline } from "react-icons/md";
import { Input } from "../Reusable/Input";
import { FormAuth } from "./styles";
import { Button } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { recoverySchema } from "../../schemas/schemas";
import imageUnlock from "../../assets/icons/unlock.png";

export const CreateNewPassForm = () => {
  const { token } = useParams();
  const { registerNewPassword } = useAuth();

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(recoverySchema),
  });

  const handleRecovery = (data) => {
    registerNewPassword(data, token);
  };

  return (
    <FormAuth onSubmit={handleSubmit(handleRecovery)}>
      <div className="recovery-title">
        <img
          src={imageUnlock}
          alt="icone-desbloqueio"
          className="icon-unlock"
        />
        <p>
          Para sua segurança crie um senha incluindo informações como Letras
          <b> maiusculas, símbolos e números</b>.
        </p>
      </div>

      <Input
        label="Senha"
        type="password"
        icon={MdLockOutline}
        error={errors.senha}
        {...register("senha")}
      />
      <Input
        type="password"
        icon={MdLockOutline}
        label="Confirmar senha"
        error={errors.confirma_senha}
        {...register("confirma_senha")}
      />
      <Button
        w="100%"
        h="60px"
        type="submit"
        border="none"
        fontWeight="500"
        color="#ffffff"
        lineHeight="1rem"
        borderRadius="4px"
        fontSize="1.25rem"
        textAlign="center"
        fontFamily="Poppins"
        _hover={{ opacity: 0.8 }}
        m={["1rem auto", "1rem auto", "1rem 0"]}
        bg="linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%)"
      >
        Confirmar senha
      </Button>
    </FormAuth>
  );
};
