import { Input } from "../../Reusable/Input";
import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import { Textarea, VStack, Button } from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { useBacklogs } from "../../../contexts/Backlogs";

import { yupResolver } from "@hookform/resolvers/yup";
import { backlogSchema } from "../../../schemas/schemas";

import { BsBell } from "react-icons/bs";

export const ModalNotifyBacklog = ({ isOpen, onClose, userId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(backlogSchema),
  });

  const { backlogUpdate } = useBacklogs();

  const handleNewBacklog = (data) => {
    backlogUpdate(userId, data);
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="37.5rem" height="33.4rem">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BsBell} color="#fff" />
            <ModalHeader.Title title="Enviar Notificação" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <VStack
            mt="2rem"
            as="form"
            spacing="4"
            onSubmit={handleSubmit(handleNewBacklog)}
          >
            <Input
              type="text"
              label="Título da pendência"
              error={errors.titulo_pendencia}
              {...register("titulo_pendencia")}
            />
            <Input
              type="text"
              label="Status da pendência"
              error={errors.status_pendencias}
              {...register("status_pendencias")}
            />
            <Textarea
              type="text"
              error={errors.pendencias}
              {...register("pendencias")}
              placeholder="Descrição da Notificação"
            />
            <Button
              h="50px"
              w="220px"
              mt="1rem"
              mb="1rem"
              bg="#3058A4"
              type="submit"
              color="#ffffff"
              fontFamily="Poppins"
              _hover={{ opacity: 0.7 }}
            >
              Enviar notificação
            </Button>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
