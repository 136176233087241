import { Modal } from "../index";
import { Input } from "../../Reusable/Input";
import { ModalHeader } from "../ModalHeader";
import { VStack, Button, Select, Textarea } from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { useMediasAdmin } from "../../../contexts/SocialMedia/MediasContextAdmin";
import { useCategoriesClient } from "../../../contexts/Categories/CategoriesContextClient";

import { yupResolver } from "@hookform/resolvers/yup";
import { newMediaSchema } from "../../../schemas/schemas";

import { MdArrowDropDown } from "react-icons/md";
import { PiCirclesThreePlusBold } from "react-icons/pi";

export const ModalCrudSocialMedia = ({
  isOpen,
  onClose,
  verbType,
  mediaData = {},
}) => {
  const { categoryData } = useCategoriesClient();
  const { editMedia, addNewMedia } = useMediasAdmin();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(newMediaSchema),
  });

  const handleCreateOrUpdateMedia = (data) => {
    if (verbType === "create") {
      addNewMedia(data);
      reset();
    } else {
      editMedia(data, mediaData.id);
      onClose();
    }
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={PiCirclesThreePlusBold} color="#fff" />
            <ModalHeader.Title
              title={
                verbType === "create"
                  ? "Criativos - Cadastro"
                  : "Criativos - Edição"
              }
            />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <VStack
            mt="2rem"
            as="form"
            spacing="2"
            onSubmit={handleSubmit(handleCreateOrUpdateMedia)}
          >
            <Input type="file" {...register("imagem")} error={errors.imagem} />
            <Input
              label="Título"
              error={errors.titulo}
              {...register("titulo")}
              defaultValue={verbType === "update" ? mediaData.titulo : ""}
            />
            <Input
              type="date"
              label="Data"
              error={errors.data}
              {...register("data")}
              defaultValue={verbType === "update" ? mediaData.data : ""}
            />
            <Input
              label="Url"
              error={errors.url}
              {...register("url")}
              defaultValue={verbType === "update" ? mediaData.url : ""}
            />
            <Select
              h="60px"
              outline="0"
              iconSize="1rem"
              cursor="pointer"
              color="#505050"
              borderRadius="6px"
              fontSize="1.125rem"
              fontFamily="Poppins"
              iconColor="#3058A4"
              placeholder="Selecione"
              {...register("categoria")}
              border="1px solid #505050"
              _hover={{ borderColor: "#3058A4" }}
              icon={<MdArrowDropDown size="1.5rem" />}
              defaultValue={verbType === "update" ? mediaData.categoria : ""}
            >
              {categoryData.map((category) => (
                <option key={category.id} value={category.tipo_produto}>
                  {category.tipo_produto}
                </option>
              ))}
            </Select>
            <Textarea
              {...register("descricao")}
              placeholder="Descrição da mídia"
              defaultValue={verbType === "update" ? mediaData.descricao : ""}
            />
            <Button
              h="50px"
              w="200px"
              mt="1rem"
              mb="1rem"
              bg="#3058A4"
              type="submit"
              color="#ffffff"
              fontFamily="Poppins"
              _hover={{ opacity: 0.7 }}
            >
              Cadastrar
            </Button>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
