import { useAuth } from "../../../contexts/Auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";

import { Card } from "./styles";
import { ModalUpdatePlan } from "../../../components/Modal/Tutorials/ModalUpdatePlan";

export const TutorialCard = ({ tutorial, category }) => {
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const srcTutorials = "https://services.playdrop.com.br/api/";

  const { data } = useAuth();

  const signed = data && Object.values(data).length > 0;

  const scrollToTop = () => {
    return window.scrollTo(0, 0);
  };

  const {
    isOpen: isOpenModalUpdatePlan,
    onOpen: onOpenModalUpdatePlan,
    onClose: onCloseModalUpdatePlan,
  } = useDisclosure();

  const checkAccess = (fase) => {
    if (!signed) return false;
    const plan = data.id_planos;

    if (fase === 1) return true;
    if ((fase === 2 || fase === 3) && (plan === 5 || plan === 9)) return true;
    if ((fase === 4 || fase === 5) && plan === 9) return true;

    return false;
  };

  const handleWatchClick = () => {
    const fase = parseInt(category.match(/Fase (\d+)/)[1], 10);
    if (checkAccess(fase)) {
      navigate(`/dashboard/tutoriais/${tutorial.id}`);
      scrollToTop();
    } else {
      onOpenModalUpdatePlan();
    }
  };

  return (
    <Card
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <img
        src={`${srcTutorials}/${tutorial.imagem_capa}`}
        alt={tutorial.titulo}
      />

      {show && (
        <div className="hovered">
          <button onClick={handleWatchClick}>Ver tutoriais</button>
        </div>
      )}

      <ModalUpdatePlan
        isOpen={isOpenModalUpdatePlan}
        onClose={onCloseModalUpdatePlan}
      />
    </Card>
  );
};
