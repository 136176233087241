import { ProductsProviderAdmin } from "./ProductsContextAdmin";
import { ProductsProviderClient } from "./ProductsContextClient";

export const ProductsProvider = ({ children }) => {
  return (
    <ProductsProviderClient>
      <ProductsProviderAdmin>{children}</ProductsProviderAdmin>
    </ProductsProviderClient>
  );
};
