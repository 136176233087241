import * as PC from "./styles";

import { useNavigate } from "react-router-dom";

import { AiFillLike } from "react-icons/ai";
import { FaComment, FaShareAlt } from "react-icons/fa";
import { currencyFormat } from "../../utils/currencyFormat";
import { useProductsClient } from "../../contexts/Products/ProductsContextClient";

import { formatDate } from "../../utils/formatDate";
import { useDisclosure } from "@chakra-ui/react";
import { useAuth } from "../../contexts/Auth";
import { useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { ModalBlockUser } from "../../components/Modal/Products/ModalBlockUser";

export const ProductCard = ({
  product,
  filteredCredits,
  filteredCreditsConveyor,
  discountCoinWallet,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showDetailsConveyor, setShowDetailsConveyor] = useState(false);

  const navigate = useNavigate();

  const scrollToTop = () => {
    return window.scrollTo(0, 0);
  };

  const { data } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const signed = data && Object.values(data).length > 0;

  const isBasicPlan = signed && data.id_planos === 4;

  const srcProduct =
    "https://services.playdrop.com.br/api/uploads/imgs/produtos";

  const { addExistingProductToTheConveyor } = useProductsClient();

  const linkProduct = `https://app.playdrop.com.br/dashboard/fornecedores/${product.id_fornecedores}`;

  const isVideo = (filename) => {
    const videoExtensions = ["mp4", "avi", "mov", "mkv"]; // Adicione outras extensões de vídeo se necessário
    const extension = filename.split(".").pop();
    return videoExtensions.includes(extension.toLowerCase());
  };

  const handleLinkClick = () => {
    const url = `/dashboard/fornecedores/${product.id_fornecedores}`;
    window.open(url, "_blank");
  };

  return (
    <PC.Container>
      <figure
        onClick={() => {
          if (isBasicPlan) {
            if (filteredCredits.dados.includes(product.id_fornecedores)) {
              handleLinkClick();
              scrollToTop();
            } else if (filteredCredits.creditos > 0) {
              discountCoinWallet(
                filteredCredits.id,
                5,
                product.id_fornecedores
              );
              handleLinkClick();
              scrollToTop();
            } else {
              onOpen();
            }
          } else {
            handleLinkClick();
            scrollToTop();
          }
        }}
      >
        {isVideo(product.imagem) ? (
          <video controls muted>
            <source
              src={`${srcProduct}/${product.imagem}`}
              type={`video/${product.imagem.split(".").pop()}`}
            />
            Seu navegador não suporta o elemento de vídeo.
          </video>
        ) : (
          <img alt={product.produto} src={`${srcProduct}/${product.imagem}`} />
        )}
      </figure>

      <h4>{product.produto}</h4>

      <div className="price">
        <p>
          Publicado em: <span>{formatDate(product.data)}</span>
        </p>
        <p>
          Custo: <span>{currencyFormat(product.preco)}</span>
        </p>
      </div>

      <div className="statistics">
        <div className="statistics__content">
          <AiFillLike size="1.5rem" fill="#3058a4" />
          <span>
            {product.TotalReactions !== null ? product.TotalReactions : 0}
          </span>
        </div>
        <div className="statistics__content">
          <FaComment size="1.5rem" fill="#3058a4" />
          <span>
            {product.TotalComments !== null ? product.TotalComments : 0}
          </span>
        </div>
        <div className="statistics__content">
          <FaShareAlt size="1.5rem" fill="#3058a4" />
          <span>{product.TotalShares !== null ? product.TotalShares : 0}</span>
        </div>
      </div>

      <div className="buttons">
        <button
          onMouseEnter={() => setShowDetails(true)}
          onMouseLeave={() => setShowDetails(false)}
          onClick={() => {
            if (isBasicPlan) {
              if (filteredCredits.dados.includes(product.id_fornecedores)) {
                handleLinkClick();
                scrollToTop();
              } else if (filteredCredits.creditos > 0) {
                discountCoinWallet(
                  filteredCredits.id,
                  5,
                  product.id_fornecedores
                );
                handleLinkClick();
                scrollToTop();
              } else {
                onOpen();
              }
            } else {
              handleLinkClick();
              scrollToTop();
            }
          }}
        >
          Detalhes
        </button>
        <button
          className="orange"
          onMouseEnter={() => setShowDetailsConveyor(true)}
          onMouseLeave={() => setShowDetailsConveyor(false)}
          onClick={() => {
            if (isBasicPlan) {
              if (filteredCreditsConveyor.creditos > 0) {
                discountCoinWallet(
                  filteredCredits.id,
                  5,
                  product.id_fornecedores
                );
                addExistingProductToTheConveyor(
                  product.produto,
                  product.imagem,
                  linkProduct
                );
              } else {
                onOpen();
              }
            } else {
              addExistingProductToTheConveyor(
                product.produto,
                product.imagem,
                linkProduct
              );
            }
          }}
        >
          Adicionar a esteira
        </button>
      </div>

      {showDetails && isBasicPlan ? (
        <div className="info-credits">
          {filteredCredits !== undefined && filteredCredits.creditos === 0 ? (
            <>
              <p>Opps, saldo para visualizar acabou...</p>
              <div onClick={() => navigate("/dashboard/planos")}>
                <FiArrowUpRight size="1.5rem" color="#fff" />
              </div>
            </>
          ) : (
            <p>
              Você pode visualizar os detalhes de{" "}
              <span>{filteredCredits && filteredCredits.creditos}</span>{" "}
              produtos
            </p>
          )}
        </div>
      ) : null}

      {showDetailsConveyor && isBasicPlan ? (
        <div className="info-credits">
          {filteredCreditsConveyor.creditos === 0 ? (
            <>
              <p>Opps, saldo para esteira acabou...</p>
              <div onClick={() => navigate("/dashboard/planos")}>
                <FiArrowUpRight size="1.5rem" color="#3058a4" />
              </div>
            </>
          ) : (
            <p>
              Você pode adicionar
              <span>
                {filteredCreditsConveyor && filteredCreditsConveyor.creditos}
              </span>
              produtos a esteira
            </p>
          )}
        </div>
      ) : null}

      <ModalBlockUser
        isOpen={isOpen}
        onClose={onClose}
        title="Ops, infelizmente o seu plano não contempla a funcionalidade"
        text="Altere agora o seu plano para conquistar imediatamente acesso ILIMITADO."
      />
    </PC.Container>
  );
};
