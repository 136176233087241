import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  max-width: 1260px;
  margin-top: 6.25rem;
  font-family: "Poppins";
  border-radius: 10px;
  background-color: #f6f8fe;

  hr {
    width: 100%;
    height: 1px;
    margin-bottom: 1rem;
    background-color: #d6dcef;
  }

  h4 {
    color: #3058a4;
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  abbr {
    text-decoration: none;
    font-variant: none;
  }

  @media (max-width: 768px) {
    background-color: #ffffff;

    margin-top: 1rem;
  }

  @media (max-width: 1048px) {
    margin: 2.5rem auto 0 auto;
  }
`;
