import { WebsitesProviderAdmin } from "./WebsitesContextAdmin";
import { WebsitesProviderClient } from "./WebsitesContextClient";

export const WebSitesProvider = ({ children }) => {
  return (
    <WebsitesProviderClient>
      <WebsitesProviderAdmin>{children}</WebsitesProviderAdmin>
    </WebsitesProviderClient>
  );
};
