import { apiAuth } from "../../services/api";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useContext, useState, createContext, useEffect } from "react";

const AuthContext = createContext({});

// Criação do Hook useAuth
export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("@PlayDrop:user")) || {}
  );
  const [userId, setUserId] = useState(null);

  const toast = useToast();
  const navigate = useNavigate();

  // Realiza login na sessão
  const signIn = async (dataUser) => {
    const response = await apiAuth.post("/login", dataUser, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (response.data.status === "success") {
      const user = response.data.data;

      setData(user);

      localStorage.setItem("@PlayDrop:user", JSON.stringify(user));

      setUserId(user.id);

      navigate("/dashboard/menu-inicial");
    } else {
      setData({});
      toast({
        title: "Opss...",
        description: "Usuário ou senha inválidos!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const loadUser = async (userId) => {
    try {
      const response = await apiAuth.get(`/listausuario?id=${Number(userId)}`);
      const user = response.data.data;

      setData(user);
      localStorage.setItem("@PlayDrop:user", JSON.stringify(user));
    } catch (error) {
      console.error("Error loading user data", error);
    }
  };

  useEffect(() => {
    if (userId !== null) {
      loadUser(userId);
    }
  }, [userId]);

  // Realiza cadastro de um novo usuário
  const signUp = async (data) => {
    const body = { ...data, usuario: data.nome, aceite: "aceito" };

    await apiAuth
      .post("/cadperfil", body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((_) => {
        signIn({ email: data.email, senha: data.senha });
      });
  };

  // Desloga da sessão
  const signOut = () => {
    localStorage.clear();
    setData({});
    navigate("/", { replace: true });
  };

  // Alteração de senha na edição de perfil
  const updatePassword = (passwordData) => {
    const body = { ...passwordData, id: data.id };

    apiAuth
      .post(`/editarsenha?id=${data.id}`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          toast({
            title: "Sucesso",
            description: "Sua senha foi alterada!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
        } else if (res.data.message === "Senha incorreta") {
          toast({
            title: "Opss...",
            description: "Sua senha atual não está correta!",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((_) => {
        toast({
          title: "Opss...",
          description: "Algo deu errado!",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
      });
  };

  // Atualiza informações do perfil do usuário
  const updateProfile = (userData, setLoading, planId, onClose) => {
    setLoading(true);

    const validContact = userData.contato && userData.contato.length === 16;

    const body = {
      ...userData,
      id: data.id,
      id_login: data.id,
      id_planos: planId === 0 ? 10 : planId,
    };

    if (!validContact) {
      toast({
        title: "Erro",
        description: "Digite o número de telefone completo no formato correto.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      apiAuth
        .post(
          `/editarperfil?id=${data.id}`,
          { data: JSON.stringify(body) },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((_) => {
          loadUser(data.id);

          setLoading(false);

          onClose();

          navigate("/dashboard/planos");

          toast({
            title: "Sucesso",
            description: "Perfil atualizado com sucesso",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        })
        .catch((_) => {
          setLoading(false);
          toast({
            title: "Opss...",
            description: "Algo deu errado!",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  const updateProfileAdmin = (userData, userId) => {
    const body = {
      ...userData,
      id: userId,
      id_login: userId,
    };
    apiAuth
      .post(`/editarperfil?=${userId}`, body)
      .then((_) => {
        toast({
          title: "Sucesso",
          description: "Dados do usuário atualizados!",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
      })
      .catch((_) => {
        toast({
          title: "Opss...",
          description: "Algo deu errado!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const registerNewPassword = async (data, token) => {
    await apiAuth
      .post(
        `/atualisasenha?token=${token}`,
        { senha: data.senha, token: token },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(() => {
        toast({
          title: "Sucesso",
          description: "Sua senha foi adicionada com suceso!",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
        navigate("/", { replace: true });
      });
  };

  return (
    <AuthContext.Provider
      value={{
        signed: !!data,
        data,
        signIn,
        signUp,
        signOut,
        loadUser,
        updateProfile,
        updatePassword,
        updateProfileAdmin,
        registerNewPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
