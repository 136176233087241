import { useState, useContext, createContext } from "react";

import { apiAuth } from "../../../services/api";

const MediasContextClient = createContext({});

export const useMediasClient = () => {
  const context = useContext(MediasContextClient);

  if (!context) {
    throw new Error(
      "useMediasClient must be used within a MediasProviderClient"
    );
  }

  return context;
};

export const MediasProviderClient = ({ children }) => {
  const [socialData, setSocialData] = useState([]);
  const [filteredSocialData, setFilteredSocialData] = useState([]);

  // Lista as mídias existentes no BD
  const loadMedias = async () => {
    const response = await apiAuth.get("/listamidias");

    const medias = response.data;

    localStorage.setItem("@PlayDrop:midiassociais", JSON.stringify(medias));

    const storedData = localStorage.getItem("@PlayDrop:midiassociais");

    if (storedData) {
      setSocialData(JSON.parse(storedData));
    }
  };

  // Pesquisa de Mídias Sociais por categoria
  const searchByCategory = (categoryName) => {
    if (categoryName !== "") {
      const normalize = categoryName
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const filteredCategory = socialData.filter((item) =>
        item.categoria
          .toLocaleLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(normalize)
      );
      setFilteredSocialData([...filteredCategory]);
    } else {
      setFilteredSocialData([]);
      setSocialData([...socialData]);
    }
  };

  return (
    <MediasContextClient.Provider
      value={{ socialData, loadMedias, filteredSocialData, searchByCategory }}
    >
      {children}
    </MediasContextClient.Provider>
  );
};
