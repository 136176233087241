import { AiOutlineCheck } from "react-icons/ai";
import { ModalNotifyBacklog } from "../../../components/Modal/Notifications/ModalNotifyBacklog";
import { Center, Flex, Text, useDisclosure } from "@chakra-ui/react";

export const BacklogCard = ({ item }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const date = new Date(item.data_pendencia);

  const options = { day: "2-digit", month: "2-digit", year: "numeric" };

  const formattedDate = date.toLocaleDateString("pt-BR", options);

  return (
    <>
      <Flex
        gap="2"
        mt="2"
        w={["820px", "820px", "100%"]}
        borderBottom="1.5px solid #d4d0d0"
      >
        <Text w="15%" textAlign="center">
          {item.nome}
        </Text>
        <Text w="35%" textAlign="center">
          {item.email}
        </Text>
        <Text w="25%" textAlign="center">
          {item.pendencias}
        </Text>
        <Text w="15%" textAlign="center">
          {formattedDate}
        </Text>
        <Flex w="10%" alignItems="center" justifyContent="center">
          <Center
            w="40px"
            h="40px"
            cursor="Pointer"
            borderRadius="4px"
            onClick={() => onOpen()}
            _hover={{ bgColor: "#d4d0d0" }}
          >
            <AiOutlineCheck fill="#3058A5" size="1.5rem" />
          </Center>
        </Flex>
      </Flex>
      <ModalNotifyBacklog isOpen={isOpen} onClose={onClose} userId={item.id} />
    </>
  );
};
