import { useState, useContext, createContext, useMemo } from "react";

import { apiAuth } from "../../../services/api";

const TemplatesContextClient = createContext({});

export const useTemplatesClient = () => {
  const context = useContext(TemplatesContextClient);

  if (!context) {
    throw new Error(
      "useTemplatesClient must be used within a TemplateProviderClient"
    );
  }

  return context;
};

export const TemplatesProviderClient = ({ children }) => {
  const [themes, setThemes] = useState([]);

  const loadAllTemplates = async () => {
    const response = await apiAuth.get("/vitrinetemplates");

    const templates = response.data.data;

    localStorage.setItem("@PlayDrop:Temas", JSON.stringify(templates));

    const storedData = localStorage.getItem("@PlayDrop:Temas");

    if (storedData) {
      setThemes(JSON.parse(storedData));
    }
  };

  useMemo(() => {
    loadAllTemplates();

    // eslint-disable-next-line
  }, []);

  return (
    <TemplatesContextClient.Provider value={{ themes }}>
      {children}
    </TemplatesContextClient.Provider>
  );
};
