// Components
import {
  Text,
  Flex,
  Grid,
  Button,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { Title } from "../../components/Reusable/Title";
import { Container } from "../../components/Reusable/Container";
import { CardOptions } from "./CardOptions";
import {
  CancelAccount,
  HeaderContent,
  PlanRenewal,
  WebsitesContent,
} from "./styles";
import { formatDate } from "../../utils/formatDate";
import { WebsiteCard } from "./WebsiteCard";
import { currencyFormat } from "../../utils/currencyFormat";
import { HelpCardConfig } from "../../components/Reusable/HelpCardConfig";
import { ModalUpdateProfile } from "../../components/Modal/Users/Client/ModalUpdateProfile";
import { ModalToUnsubscribe } from "../../components/Modal/Plans/ModalToUnsubscribe";
import { ModalUpdatePasswordClient } from "../../components/Modal/Users/ModalUpdatePasswordClient";

// Icons
import { BsHeadset } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";
import { AiFillCreditCard } from "react-icons/ai";
import { FaLock, FaRegPaperPlane, FaUser } from "react-icons/fa";

// Hooks
import { useAuth } from "../../contexts/Auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePlansClient } from "../../contexts/Plans/PlansContextClient";
import { useWebsitesClient } from "../../contexts/Websites/WebsitesContextClient";

export const Config = () => {
  const navigate = useNavigate();

  const { data } = useAuth();
  const { plans } = usePlansClient();

  const signed = data && Object.values(data).length > 0;

  const plan = plans?.find((item) => item.id === data.id_planos);

  const {
    isOpen: isOpenUnsubscribe,
    onOpen: onOpenUnsubcribe,
    onClose: onCloseUnsubscribe,
  } = useDisclosure();

  const {
    isOpen: isOpenUpdatePass,
    onOpen: onOpenUpdatePass,
    onClose: onCloseUpdatePass,
  } = useDisclosure();

  const {
    isOpen: isOpenUpdateProfile,
    onOpen: onOpenUpdateProfile,
    onClose: onCloseUpdateProfile,
  } = useDisclosure();

  const { websitesUser, loadWebsitesUser } = useWebsitesClient();

  useEffect(() => {
    if (signed) {
      loadWebsitesUser();
    }

    // eslint-disable-next-line
  }, [signed]);

  return (
    <Container>
      <Title
        headingName="Configurações"
        caption="Acompanhe o status da sua assinatura, gerencie o seu plano e formas de pagamento"
      >
        <IoMdSettings fill="#3058A5" size="28px" cursor="pointer" />
      </Title>

      <hr />

      <HeaderContent>
        <div className="card-plan">
          <h4>Plano atual</h4>

          <div className="car-plan__content">
            {plan !== undefined ? (
              <>
                <h3>{plan.plano}</h3>

                <div>
                  <p>{currencyFormat(plan.mensalidades)}</p>
                  <p className="divider">/</p>
                  <p className="month">
                    {data.time_plan > 0 && data.time_plan === 30
                      ? "mês"
                      : data.time_plan === 90
                      ? "trimestre"
                      : data.time_plan === 365
                      ? "ano"
                      : "gratuito"}
                  </p>
                </div>
              </>
            ) : null}

            <Button
              mt="6"
              h="45.52px"
              w="190.95px"
              color="#eeeeee"
              borderRadius="11px"
              _hover={{ opacity: 0.7 }}
              border="1px solid #eeeeee"
              boxShadow="0px 16.1599px 11.8506px -2.15465px #E6EEFF"
              onClick={() => navigate("/dashboard/planos")}
              bg="linear-gradient(89.97deg, #FB8A03 0.44%, #FFB052 99.97%)"
            >
              {plan !== undefined ? "Alterar Plano" : "Ativar Plano"}
            </Button>
          </div>
        </div>
        <div className="more-options">
          <h4>Mais opções</h4>

          <div className="more-options__content">
            <CardOptions
              title="Alterar cartão de crédito"
              text="Para alterar o seu cartão de crédito, clique!"
            >
              <AiFillCreditCard size="2rem" color="#3058A5" />
            </CardOptions>
            <CardOptions
              title="Perfil"
              text="Clique para editar dados de perfil"
              onClick={() => onOpenUpdateProfile()}
            >
              <FaUser size="2rem" color="#3058A4" />
            </CardOptions>
            <CardOptions
              title="Alterar senha"
              onClick={() => onOpenUpdatePass()}
              text="Caso queira atualizar sua senha, clique!"
            >
              <FaLock size="1.5rem" color="#3058A4" />
            </CardOptions>
          </div>
        </div>
      </HeaderContent>

      <PlanRenewal>
        <h4>Renovação do plano</h4>

        {plan !== undefined && plan.plano === "Plano Basic" ? (
          <p>Seu plano é o {plan.plano} e não tem renovação</p>
        ) : (
          <p className="exists-plan">
            Seu plano se renovará no dia:{" "}
            <span> {formatDate(data.plano_data_venc)}.</span>
          </p>
        )}
      </PlanRenewal>

      <hr />

      <WebsitesContent>
        <h4>
          {data.perfil === "admin" ? "Lojas ativas" : "Suas lojas ativas"}
        </h4>

        {websitesUser?.length > 0 ? (
          websitesUser?.map((website) => (
            <WebsiteCard key={website.id_site} website={website} />
          ))
        ) : (
          <p className="without-website">
            Quando você tiver uma loja ativa aparecerá aqui.
          </p>
        )}
      </WebsitesContent>

      <hr />

      <Grid>
        <Heading color="#3058A4" fontSize="1.25rem">
          Ainda tem dúvidas?
        </Heading>
        <Text mt="4" mb="8" fontSize="1rem" color="#4F4F4F">
          Acesse os links abaixo para sanar as dúvidas que tiver.
        </Text>
        <Flex
          mb="8"
          gap="6"
          alignItems="center"
          flexDir={["column", "column", "row"]}
        >
          <HelpCardConfig
            bigText="Falar com o suporte"
            littleText="Seg a sexta das 8h às 17h"
          >
            <BsHeadset fill="#FB8A03" size="2.2rem" />
          </HelpCardConfig>
          <HelpCardConfig bigText="Informe um erro" littleText="Abrir chamado">
            <FaRegPaperPlane fill="#FB8A03" size="2.2rem" />
          </HelpCardConfig>
        </Flex>
      </Grid>

      <hr />

      {data.id_planos !== 4 && (
        <CancelAccount>
          <div>
            <p>Desativar minha conta</p>
            <span>
              O cancelamento da sua conta é um processo que não pode ser
              desfeito, seus dados serão excluidos da nossa base.
            </span>
          </div>
          <Button
            h="48px"
            w={["100%", "280px", "280px"]}
            bg="linear-gradient(89.97deg, #ff0000 0.44%, #ff5555 99.97%)"
            color="#fff"
            onClick={() => onOpenUnsubcribe()}
            fontSize="1.125rem"
            fontWeight="600"
            lineHeight="1.5rem"
            _hover={{ opacity: 0.8 }}
          >
            Cancelar assinatura
          </Button>
        </CancelAccount>
      )}

      <ModalToUnsubscribe
        isOpen={isOpenUnsubscribe}
        onClose={onCloseUnsubscribe}
      />
      <ModalUpdatePasswordClient
        isOpen={isOpenUpdatePass}
        onClose={onCloseUpdatePass}
      />
      <ModalUpdateProfile
        isOpen={isOpenUpdateProfile}
        onClose={onCloseUpdateProfile}
      />
    </Container>
  );
};
