import { HStack, Skeleton, Stack } from "@chakra-ui/react";

export const SkeletonInfoDetailsProduct = ({ width }) => {
  return (
    <Stack width={width}>
      <Skeleton height="220px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <HStack>
        <Skeleton width="24px" height="20px" />
        <Skeleton width="100%" height="20px" />
      </HStack>
      <HStack>
        <Skeleton width="24px" height="20px" />
        <Skeleton width="100%" height="20px" />
      </HStack>
      <HStack>
        <Skeleton width="24px" height="20px" />
        <Skeleton width="100%" height="20px" />
      </HStack>
      <HStack>
        <Skeleton width="24px" height="20px" />
        <Skeleton width="100%" height="20px" />
      </HStack>
      <HStack>
        <Skeleton width="24px" height="20px" />
        <Skeleton width="100%" height="20px" />
      </HStack>
      <Skeleton height="40px" />
    </Stack>
  );
};
