import * as PI from "../Products/styles";

import { saveAs } from "file-saver";
import { useState } from "react";
import { useProductsClient } from "../../contexts/Products/ProductsContextClient";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Link as LinkChakra, useDisclosure } from "@chakra-ui/react";

import { GoBack } from "../../components/Reusable/GoBack";
import { PiTarget } from "react-icons/pi";
import { IoIosPerson } from "react-icons/io";
import { BiCalculator, BiImport, BiStoreAlt } from "react-icons/bi";
import { MdCopyright, MdOutlineAnnouncement } from "react-icons/md";
import {
  FaDownload,
  FaPeopleArrows,
  FaExclamationTriangle,
} from "react-icons/fa";

import { ModalPersona } from "../../components/Modal/Products/ModalPersona";
import { ModalImportProduct } from "../../components/Modal/Products/ModalImportProduct";
import { ModalTargetAudience } from "../../components/Modal/Products/ModalTargetAudience";
import { TrendingProductCard } from "./TrendingProductCard";

export const TrendingProductInfo = () => {
  const [isActive, setIsActive] = useState("description");

  const navigate = useNavigate();

  const products = JSON.parse(localStorage.getItem("@PlayDrop:Produtos")) || [];

  const { id } = useParams();

  const {
    isOpen: isOpenModalPersona,
    onOpen: onOpenModalPersona,
    onClose: onCloseModalPersona,
  } = useDisclosure();

  const {
    isOpen: isOpenModalTargetAudience,
    onOpen: onOpenModalTargetAudience,
    onClose: onCloseModalTargetAudience,
  } = useDisclosure();

  const {
    isOpen: isOpenModalImportProduct,
    onOpen: onOpenModalImportProduct,
    onClose: onCloseModalImportProduct,
  } = useDisclosure();

  const { addExistingProductToTheConveyor } = useProductsClient();

  const srcProduct =
    "https://services.playdrop.com.br/api/uploads/imgs/produtos";

  const product = products.find((item) => item.id_fornecedores === Number(id));
  const productsWithSameCategory = products.filter(
    (item) => item.categoria === product.categoria
  );

  const downloadImage = (urlImage) => {
    saveAs(srcProduct, urlImage);
  };

  const linkProduct = `https://app.playdrop.com.br/dashboard/fornecedores/${product.id_fornecedores}`;

  const isVideo = (filename) => {
    const videoExtensions = ["mp4", "avi", "mov", "mkv"]; // Adicione outras extensões de vídeo se necessário
    const extension = filename.split(".").pop();
    return videoExtensions.includes(extension.toLowerCase());
  };

  return (
    <PI.InfoContainer>
      <div className="go-back">
        <GoBack onClick={() => navigate("/dashboard/fornecedores")} />
      </div>

      <PI.InfoContent>
        <figure>
          {isVideo(product.imagem) ? (
            <video controls muted>
              <source
                src={`${srcProduct}/${product.imagem}`}
                type={`video/${product.imagem.split(".").pop()}`}
              />
              Seu navegador não suporta o elemento de vídeo.
            </video>
          ) : (
            <img
              alt={product.produto}
              src={`${srcProduct}/${product.imagem}`}
            />
          )}

          <div onClick={() => downloadImage(`${product.imagem}`)}>
            <FaDownload size="1.5rem" />
          </div>
        </figure>

        <div className="info-product">
          <div>
            <p className="text-title">Nicho</p>
            <p className="text-name">{product.categoria}</p>
          </div>

          <p className="text-name gray">{product.produto}</p>

          <div>
            <p className="text-title blue big">Preço de Custo</p>
            <p className="text-price">
              {product.preco.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
              })}
            </p>
          </div>

          <div className="information">
            <div>
              <FaExclamationTriangle size="1.5rem" fill="#fff" />
            </div>
            <p className="text-title white">
              O preço pode sofrer alteração, fique atento a página do fornecedor
            </p>
          </div>
        </div>

        <div className="cards-and-more">
          <div className="cards">
            <div
              className="content"
              onClick={() => onOpenModalTargetAudience()}
            >
              <div className="name">
                <span>
                  <PiTarget color="#3058a4" size="1.5rem" />
                </span>
                <p>Público Alvo</p>
              </div>
            </div>

            <div className="content">
              <LinkChakra
                rel="noreferrer"
                cursor="pointer"
                target="_blank"
                href={product.link_facebook}
              >
                <div className="name">
                  <span>
                    <MdOutlineAnnouncement color="#3058a4" size="1.5rem" />
                  </span>
                  <p>Anúncios</p>
                </div>
              </LinkChakra>
            </div>

            <div className="content">
              <LinkChakra
                cursor="pointer"
                target="_blank"
                rel="noreferrer"
                href={product.link_concorrente}
              >
                <div className="name">
                  <span>
                    <FaPeopleArrows color="#3058a4" size="1.5rem" />
                  </span>
                  <p>Concorrente</p>
                </div>
              </LinkChakra>
            </div>

            <div className="content">
              <Link to={`/dashboard/copy/${product.produto}`}>
                <div className="name">
                  <span>
                    <MdCopyright color="#3058a4" size="1.5rem" />
                  </span>
                  <p>Copy</p>
                </div>
              </Link>
            </div>

            <div className="content">
              <div className="name" onClick={() => onOpenModalPersona()}>
                <span>
                  <IoIosPerson color="#3058a4" size="1.5rem" />
                </span>
                <p>Persona</p>
              </div>
            </div>

            <div className="content">
              <Link
                to={`/dashboard/calculadora/${product.produto}/${Number(
                  product.preco
                )}`}
              >
                <div className="name">
                  <span>
                    <BiCalculator color="#3058a4" size="1.5rem" />
                  </span>
                  <p>Precificador</p>
                </div>
              </Link>
            </div>

            <div
              className="content"
              onClick={() =>
                addExistingProductToTheConveyor(
                  product.produto,
                  product.imagem,
                  linkProduct
                )
              }
            >
              <div className="name">
                <span>
                  <BiStoreAlt size="1.5rem" fill="#3058A9" />
                </span>
                <p>Por na Esteira</p>
              </div>
            </div>

            <div className="content" onClick={() => onOpenModalImportProduct()}>
              <div className="name">
                <span>
                  <BiImport color="#3058a4" size="1.5rem" />
                </span>
                <p>Imp. para loja</p>
              </div>
            </div>
          </div>
        </div>
      </PI.InfoContent>

      <ul className="menu-info">
        <li
          className={`${isActive === "description" ? "description" : ""}`}
          onClick={() => setIsActive("description")}
        >
          Descrição
        </li>

        <li
          className={`${isActive === "related-products" ? "related" : ""}`}
          onClick={() => setIsActive("related-products")}
        >
          Produtos relacionados
        </li>
        <li
          className={`${isActive === "suppliers" ? "suppliers" : ""}`}
          onClick={() => setIsActive("suppliers")}
        >
          Fornecedores
        </li>
      </ul>

      <div className="info-mais-produtos">
        <h4>
          {isActive === "description"
            ? "Descrição"
            : isActive === "related-products"
            ? "Relacionados"
            : isActive === "suppliers"
            ? "Fornecedores"
            : null}
        </h4>

        {isActive === "description" ? (
          <div
            className="post__description"
            dangerouslySetInnerHTML={{ __html: product.descricao }}
          />
        ) : isActive === "related-products" ? (
          <PI.Grid>
            {productsWithSameCategory.length > 0
              ? productsWithSameCategory.map((product) => (
                  <TrendingProductCard
                    key={product.id_fornecedores}
                    product={product}
                  />
                ))
              : null}
          </PI.Grid>
        ) : isActive === "suppliers" ? (
          <div>Fornecedores</div>
        ) : null}
      </div>

      <ModalPersona
        isOpen={isOpenModalPersona}
        onClose={onCloseModalPersona}
        name={product.produto}
      />

      <ModalTargetAudience
        isOpen={isOpenModalTargetAudience}
        onClose={onCloseModalTargetAudience}
        productName={product.produto}
      />

      <ModalImportProduct
        isOpen={isOpenModalImportProduct}
        onClose={onCloseModalImportProduct}
        productId={product.id_fornecedores}
      />
    </PI.InfoContainer>
  );
};
