import styled from "styled-components";

export const Container = styled.button`
  width: 100%;
  height: 50px;
  margin: 1rem auto 0 auto;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 1.25rem;
  font-weight: 600;
  border-radius: 4px;
  background: linear-gradient(89.97deg, #3058a5 0.44%, #2964d3 99.97%);

  &:hover {
    opacity: 0.7;
  }

  @media (min-width: 768px) {
    width: ${(props) => (props.formSchema ? "100%" : "240px")};
  }
`;
