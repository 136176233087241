import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import { ContentConfigStore } from "../../../pages/StoreBuilder/ContentConfigStore";

import { TbWorld } from "react-icons/tb";

export const ModalConfigStore = ({
  isOpen,
  onClose,
  websitesUser,
  maxSitesAllowed,
}) => {
  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="38rem" h="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={TbWorld} color="#fff" />
            <ModalHeader.Title title="Aqui você inicia a criação do seu negócio" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <ContentConfigStore
            onClose={onClose}
            websitesUser={websitesUser}
            maxSitesAllowed={maxSitesAllowed}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
