// Components
import {
  VStack,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from "@chakra-ui/react";
import { DrawerMobileOptions } from "./DrawerMobileOptions";

// Hooks
import { useAuth } from "../../contexts/Auth";
import { useNavigate } from "react-router-dom";

// Icons
import { FaUser } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import { MdLogout } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { RiLayout3Line } from "react-icons/ri";
import { PiCirclesThreePlusBold } from "react-icons/pi";
import { BsCurrencyDollar, BsGrid } from "react-icons/bs";
import { BiCategory, BiCube, BiStoreAlt } from "react-icons/bi";

export const DrawerAdmPanelMobile = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="left" onEsc={onClose}>
      <DrawerOverlay />
      <DrawerContent
        w="100%"
        ml="2rem"
        mt="9.5rem"
        maxW="300px"
        maxH="600px"
        bg="#3058A4"
        borderRadius="10px"
      >
        <DrawerBody bg="#3058A4" borderRadius="10px">
          <VStack spacing="4" w="100%" pt="8">
            <DrawerMobileOptions
              textname="Dashboard"
              onClick={() => {
                navigate("/dashboard/menu-inicial");
                onClose();
              }}
            >
              <BsGrid fill="#ffffff" size="1.5rem" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Lojas"
              onClick={() => {
                navigate("/dashboard/sites-admin");
                onClose();
              }}
            >
              <TbWorld color="#ffffff" size="24px" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Temas"
              onClick={() => {
                navigate("/dashboard/templates-admin");
                onClose();
              }}
            >
              <RiLayout3Line fill="#ffffff" size="24px" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Criativos"
              onClick={() => {
                navigate("/dashboard/midias-admin");
                onClose();
              }}
            >
              <PiCirclesThreePlusBold fill="#ffffff" size="1.5rem" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Produtos"
              onClick={() => {
                navigate("/dashboard/fornecedores-admin");
                onClose();
              }}
            >
              <BiStoreAlt fill="#ffffff" size="24px" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Fornecedores"
              onClick={() => {
                navigate("/dashboard/fornecedores-manuais");
                onClose();
              }}
            >
              <BiStoreAlt fill="#ffffff" size="24px" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Tutoriais"
              onClick={() => {
                navigate("/dashboard/tutoriais-admin");
                onClose();
              }}
            >
              <BiCube fill="#ffffff" size="1.5rem" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Categorias"
              onClick={() => {
                navigate("/dashboard/categorias");
                onClose();
              }}
            >
              <BiCategory fill="#ffffff" size="1.5rem" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Usuários"
              onClick={() => {
                navigate("/dashboard/usuarios");
                onClose();
              }}
            >
              <FaUser fill="#ffffff" size="1.5rem" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Pendências"
              onClick={() => {
                navigate("/dashboard/pendencias");
                onClose();
              }}
            >
              <IoMdSettings fill="#ffffff" size="1.5rem" />
            </DrawerMobileOptions>

            <DrawerMobileOptions
              textname="Planos"
              onClick={() => {
                navigate("/dashboard/planos-admin");
                onClose();
              }}
            >
              <BsCurrencyDollar fill="#ffffff" size="1.5rem" />
            </DrawerMobileOptions>
          </VStack>
          <Button
            pl="1"
            mt="10"
            color="#ffffff"
            bg="transparent"
            fontWeight="400"
            borderWidth="0"
            borderColor="transparent"
            outline="0"
            onClick={() => signOut()}
            leftIcon={<MdLogout fill="#ffffff" size="1.25rem" />}
          >
            Sair
          </Button>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
