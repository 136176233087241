import styled from "styled-components";

const DrawerLogoutContainer = styled.div`
  width: 20rem;
  padding: 1rem;

  z-index: 1;

  position: absolute;
  top: 5.2rem;
  right: 1rem;

  border-radius: 8px;
  background-color: #fff;

  box-shadow: 0px 4px 8px 2px #3058a4;
`;

const DrawerLogoutHeader = styled.div`
  padding: 1rem 0;
  margin-bottom: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-color: #3058a4;
  border-bottom-width: 0.125rem;
`;

const DrawerLogoutHeaderName = styled.p`
  color: #404040;
  font-weight: 500;
  font-size: 1.25rem;
`;

const DrawerLogoutHeaderClose = styled.button`
  cursor: pointer;
`;

const DrawerLogoutContent = styled.div`
  .message {
    color: #4f4f4f;
    font-weight: 500;

    margin: 1rem 0 0 0;
  }
`;

const DrawerLogoutContentProfile = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    div {
      box-shadow: 0px 5px 8px -2.0137px #3058a1;
    }

    p {
      color: #3058a4;
    }
  }

  div {
    width: 3rem;
    height: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 10px 11.0753px -2.0137px #3058a1;
  }

  p {
    color: #363636;
    font-weight: 500;
    font-size: 1.2rem;
  }
`;

const DrawerLogoutContentButtons = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const DL = {
  Container: DrawerLogoutContainer,
  Header: {
    Container: DrawerLogoutHeader,
    Name: DrawerLogoutHeaderName,
    Close: DrawerLogoutHeaderClose,
  },
  Content: {
    Container: DrawerLogoutContent,
    Profile: DrawerLogoutContentProfile,
    Buttons: DrawerLogoutContentButtons,
  },
};
