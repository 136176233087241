import { useToast } from "@chakra-ui/react";
import { ModalContentTheme } from "./styles";
import { useAuth } from "../../contexts/Auth";
import { useNavigate } from "react-router-dom";
import { FaShopLock } from "react-icons/fa6";
import { useWebsitesClient } from "../../contexts/Websites/WebsitesContextClient";
import { useEffect } from "react";
import { useState } from "react";

import { apiAuth } from "../../services/api";
import { useBacklogs } from "../../contexts/Backlogs";

const typeTheme = {
  25: "https://services.playdrop.com.br/api/theme/theme-generic.zip",
  23: "https://services.playdrop.com.br/api/theme/theme-male.zip",
  26: "https://services.playdrop.com.br/api/theme/theme-female.zip",
  27: "https://services.playdrop.com.br/api/theme/theme-infantil.zip",
  28: "https://services.playdrop.com.br/api/theme/theme-generic2.zip",
};

export const ModalDownloadTheme = ({ themeName, themeId, descricao }) => {
  const [selectedStore, setSelectedStore] = useState(null);

  const { data } = useAuth();
  const { backlogUpdateAdmin } = useBacklogs();
  const { websitesUser, loadWebsitesUser } = useWebsitesClient();

  const signed = data && Object.values(data).length > 0;

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    loadWebsitesUser();

    // eslint-disable-next-line
  }, []);

  const selectedStoreChange = (event) => {
    const selectedSite = websitesUser.find(
      (store) => store.nomesite === event.target.id
    );
    setSelectedStore(selectedSite);
  };

  const handleSendThemeToShopify = async (themeId) => {
    const backLogObject = {
      data_pendencia: new Date(),
      titulo_pendencia: `Criação de tema ${themeName} para ${data.usuario}`,
      status_pendencias: "pendente",
      pendencias: "Criação de novo tema",
    };

    if (selectedStore === null) {
      toast({
        title: "Opss...",
        description: "Você precisa selecionar uma loja para enviar o tema!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      try {
        await apiAuth
          .post(
            "/SHOPIFY-send-theme",
            {
              id_login: data.id,
              id_site: selectedStore.id_site,
              file: typeTheme[themeId],
              name: themeName,
              role: "main",
            },
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((_) => {
            toast({
              title: "Sucesso",
              description: "O tema escolhido foi enviado para a sua loja!",
              status: "success",
              duration: 3500,
              isClosable: true,
            });

            backlogUpdateAdmin(backLogObject);
          });
      } catch (error) {
        toast({
          title: "Opss...",
          description: "Algo deu errado ao enviar tema!",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
      }
    }
  };

  return (
    <ModalContentTheme>
      {signed && data.id_planos === 4 && descricao.includes("Nichado") ? (
        <div className="free-plan-content">
          <div>
            <FaShopLock fill="#fb6b03" size="3rem" />
            <h4>Adquirir o {themeName} somente a partir do Plano Gold</h4>
          </div>

          <p>Aproveite os melhores temas adquirindo um de nossos planos</p>

          <button
            className="button-block-content"
            onClick={() => navigate("/dashboard/planos")}
          >
            Conhecer nossos planos
          </button>
        </div>
      ) : (
        <>
          <h4>Selecione a sua loja abaixo para instalar o template</h4>

          <ul className="websites-content">
            {websitesUser &&
              websitesUser.map((store) => (
                <li key={store.id_site}>
                  <input
                    type="radio"
                    id={store.nomesite}
                    name="store"
                    onChange={selectedStoreChange}
                  />
                  <label htmlFor={store.nomesite}>{store.nomesite}</label>
                </li>
              ))}
          </ul>

          <button
            className="send-theme"
            onClick={() => handleSendThemeToShopify(themeId)}
          >
            Enviar tema para Shopify
          </button>

          {/* <Progress
            w="100%"
            size="md"
            colorScheme="orange"
            isIndeterminate
            transition="ease-in 0.4s"
          /> */}
        </>
      )}
    </ModalContentTheme>
  );
};
