import styled, { css } from "styled-components";

export const Content = styled.div`
  width: 100%;

  overflow-x: scroll;
  overflow-y: hidden;

  display: flex;
  gap: 2rem;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border: 0;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #3059a4;
    border-radius: 24px;
  }

  .addRecommendedConveyor {
    p {
      width: 100%;
      max-width: 400px;
    }
  }

  .article {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    position: relative;

    background-color: #fff;

    padding: 0.8rem;

    .flex-title {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        margin: 0;
      }

      .options {
        &:hover {
          cursor: pointer;
          opacity: 0.9;
        }
      }
    }

    ul {
      width: 20.275rem;
      margin-top: 1rem;

      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .flex-add-product {
        width: 250px;
        height: 50px;
        margin: 1rem auto 0 auto;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        border-radius: 10px;

        p {
          font-size: 1.125rem;
          font-weight: 500;
          color: #fb6b03;
        }

        svg {
          color: #fb6b03;
        }

        &:hover {
          cursor: pointer;
          transition: ease-in 0.2s;

          background-color: #fb6b03;

          svg {
            transition: ease-in 0.2s;
            color: #fff;
          }
          p {
            transition: ease-in 0.2s;
            color: #fff;
          }
        }
      }
    }
  }

  .add-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
      width: 20.275rem;
      height: 3.4rem;

      color: #fb6b03;
      border: 2px solid #fb6b03;
      font-size: 1.125rem;
      font-weight: 500;
      border-radius: 10px;
      background-color: #fff;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      svg {
        color: #fb6b03;
      }

      &:hover {
        transition: ease-in 0.2s;

        background-color: #fb6b03;

        color: #fff;

        svg {
          transition: ease-in 0.2s;
          color: #fff;
        }
      }
    }
  }
`;

export const Card = styled.li`
  height: 60px;
  padding: 0.5rem;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0px 8px 5px -2px #e6eeff;
  border-radius: 10px;
  background-color: #fff;

  p {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;

    font-size: 1rem;
    font-weight: 500;
    color: #4f4f4f;
  }

  div {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    svg {
      color: #3058a4;

      &:hover {
        opacity: 0.8;
      }
    }

    .options {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        color: #3058a4;
      }
    }
  }

  ${(props) =>
    props.isDragging &&
    css`
      border: 1.5px dashed rgba(0, 0, 0, 0.2);
      background: transparent;
      border-radius: 0;

      p {
        opacity: 0;
      }
    `}
`;

export const Details = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;

  display: flex;
  gap: 1rem;

  .image-ok {
    position: relative;

    img {
      width: 100%;
      height: 220px;

      background-color: #e6eeff;
    }

    span {
      width: 3rem;
      height: 2.5rem;

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 0;
      right: 0;

      cursor: pointer;

      border-radius: 4px 0 4px 4px;
      background-color: #fff;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .left-items-title {
    width: 100%;
    max-width: 260px;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .content-image {
      width: 100%;

      .carregar_foto {
        width: 100%;
        padding: 10px;

        color: #fff;
        border-radius: 10px;
        background-color: #3058a4;

        display: inline-block;

        font-size: 16px;
        text-align: center;
        font-weight: 600;

        cursor: pointer;
      }
      input[type="file"] {
        width: 100%;
        opacity: 0;
      }

      .send-image {
        width: 100%;
        padding: 10px;
        margin-top: 0.5rem;

        color: #fff;
        border-radius: 10px;
        background-color: #3058a4;

        font-weight: 500;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  div {
    h4 {
      color: #4f4f4f;
      font-size: 1.3rem;
      font-weight: 500;
    }

    .link-flex {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      svg {
        color: #3058a4;

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }

    .link {
      color: #3058a4;
      font-size: 0.9rem;
      font-weight: 500;
    }

    .date {
      color: #4f4f4f;
      font-size: 1.1rem;

      span {
        font-size: 1.125rem;
        font-weight: 500;
      }
    }
  }

  .divider {
    width: 2px;
    background-color: #e6eeff;
  }

  .links {
    margin-top: 1rem;

    display: flex;
    flex-direction: column;
    gap: 0.9rem;

    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;

      padding: 0.7rem;
      box-shadow: 0px 8px 5px -2px #3058a4a1;
      border-radius: 10px;
      background-color: #fff;

      svg {
        color: #4f4f4f;
      }

      p {
        font-size: 1.125rem;
        font-weight: 500;
        color: #4f4f4f;
      }

      &:hover {
        cursor: pointer;

        transition: ease-in 0.2s;

        svg,
        p {
          color: #3058a4;
        }
      }
    }

    .trash {
      height: 45px;
      margin-top: 1rem;

      display: flex;
      align-items: center;
      justify-content: center;

      box-shadow: none;
      border-radius: 10px;
      background: linear-gradient(89.97deg, #ff0000 0.44%, #ff5555 99.97%);

      svg,
      p {
        color: #fff;
      }

      &:hover {
        opacity: 0.8;

        svg,
        p {
          color: #fff;
        }
      }
    }
  }

  .infos-product {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    .flex-content-inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 1rem;

      .publico-alvo {
        width: 100%;
      }

      .flex-input {
        position: relative;
      }
    }

    .margin {
      margin-bottom: 0.8rem;
    }

    label {
      color: #3058a4;
      font-size: 1.25rem;
      font-size: 600;
    }

    .container-notes {
      .editorClassName {
        border: 1.5px solid #e6eeff;
      }

      .notas {
        width: 100%;
        padding: 1rem;
        margin-top: 1rem;

        box-shadow: 0px 8px 5px -2px #3058a4a1;
      }
    }

    .grid-suppliers {
      .more-supplier {
        width: 200px;
        height: 50px;
        margin-top: 1rem;

        color: #fff;
        border-radius: 10px;
        background-color: #3058a4;

        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .flex-buttons {
        display: flex;
        align-items: center;
        gap: 0.8rem;
      }

      .flex-supplier {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 1rem;

        .info-price {
          margin-top: 6px;
          background-color: #fff;

          .flex-icon {
            width: 200px;
            height: 50px;
            margin-top: 1rem;

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;

            border-radius: 10px;
            background-color: #3058a4;

            cursor: pointer;

            p {
              color: #fff;
              font-size: 1.125rem;
            }

            &:hover {
              opacity: 0.8;
            }
          }

          .flex-content {
            display: flex;
            align-items: center;
            gap: 1rem;
          }

          div {
            width: 100%;

            label {
              color: #4f4f4f;
              font-size: 1rem;
              font-weight: 500;
            }

            .flex-input {
              position: relative;
            }
          }
        }
      }
    }

    .mt-1 {
      margin-top: 1rem;
    }

    .button {
      width: 100%;

      display: flex;
      justify-content: flex-end;

      button {
        width: 250px;
        height: 50px;

        color: #fff;
        font-size: 1.25rem;
        border-radius: 10px;
        background-color: #fb6b03;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`;

export const AddColumn = styled.div`
  width: 100%;
  height: 200px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;

  button {
    width: 100%;
    height: 50px;

    color: #3058a4;
    border: 2px solid #3058a4;
    font-size: 1.125rem;
    font-weight: 500;
    border-radius: 10px;
  }
`;

export const Options = styled.div`
  width: 280px;
  padding: 10px;

  display: flex;
  flex-direction: column;

  position: relative;

  border-radius: 10px;
  background-color: #3058a4;

  position: absolute;
  top: 0;
  right: 0;

  .delete {
    width: 100%;
    padding: 10px;
    margin-top: 1.5rem;

    display: flex;
    align-items: center;
    gap: 1rem;

    border-radius: 10px;
    background-color: #fff;

    cursor: pointer;

    svg {
      color: #3058a4;
    }
    p {
      color: #3058a4;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;

    cursor: pointer;
  }
`;

export const BlockContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    h4 {
      color: #3058a4;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }

  p {
    color: #4f4f4f;
    text-align: center;
    font-weight: 500;
  }

  .button-block-content {
    width: 350px;
    height: 50px;

    color: #fff;
    font-size: 1.25rem;
    background: linear-gradient(89.97deg, #fb8a03 0.44%, #ffb052 99.97%);
    border-radius: 10px;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const InfoCredits = styled.div`
  width: 100%;
  max-width: 500px;
  height: 2.5rem;
  padding: 0.2rem 1rem;
  margin-bottom: 2rem;

  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0px 8px 5px -1px #e6eeff;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #3058a4;
    font-size: 1rem;

    span {
      margin: 0 4px;
    }
  }

  div {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Checklist = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;

  .checklist {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

export const ChecklistItem = styled.div`
  padding: 10px;
  font-size: 18px;
  cursor: pointer;

  box-shadow: 0px 8px 5px -1px #e6eeff;
  border-radius: 10px;
  background-color: #fff;

  text-decoration: ${(props) => (props.checked ? "line-through" : "none")};

  display: flex;
  align-items: center;
  gap: 10px;

  .icons {
    width: 2.5rem;
    height: 2.5rem;

    border-radius: 4px;
    border: 2px solid #e6eeff;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
