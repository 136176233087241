import { Link as LinkChakra, Text } from "@chakra-ui/react";
import { Input } from "../../components/Reusable/Input";
import { StoreConnectionContainer } from "./styles";

import { GoBack } from "../../components/Reusable/GoBack";
import { useState } from "react";

export const StoreConnectionContent = ({
  setStep,
  storeData,
  setStoreData,
}) => {
  const [error, setError] = useState(false);

  return (
    <StoreConnectionContainer>
      <div className="go-back">
        <GoBack onClick={() => setStep("first_step")} />
      </div>

      <form>
        <Input
          label="Domínio principal da sua loja na Shopify"
          placeholder="Ex: minhaloja.myshopify.com"
          defaultValue={`https://${storeData.domain}`}
          onChange={(ev) =>
            setStoreData({ ...storeData, domain: ev.target.value })
          }
        />

        <div className="third-step">
          <h4>Veja abaixo como criar a sua conta na Shopify</h4>

          <iframe
            allowFullScreen
            title="Como criar sua conta"
            src="https://iframe.mediadelivery.net/embed/299009/433c8cf3-5cb9-49e5-a1ec-1f364f81aa70?autoplay=true&loop=false&muted=false&preload=true&responsive=true"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          />

          <LinkChakra
            rel="noreferrer"
            href="https://www.shopify.com/br/avaliacao-gratuita?irclickid=Su1xz1znJxyKWIzRdpw9j13PUkC09AU3qSLMTA0&irgwc=1&partner=3198336&affpt=excluded&utm_channel=affiliates&utm_source=3198336-impact&utm_medium=cpa"
            target="_blank"
          >
            <button>Criar conta na Shopify</button>
          </LinkChakra>

          <button
            className="next"
            type="button"
            onClick={() => {
              if (storeData.domain === "") {
                setError(true);
              } else {
                setError(false);
                setStep("third_step");
              }
            }}
          >
            Prosseguir
          </button>

          {error && storeData.domain === "" ? (
            <Text color="#fb6b03" fontWeight="500" textAlign="center">
              O campo "Domínio" precisa ser preenchido corretamente para
              prosseguir.
            </Text>
          ) : null}
        </div>
      </form>
    </StoreConnectionContainer>
  );
};
