import { Box, Flex, Text } from "@chakra-ui/react";

export const DrawerDeskOptions = ({
  children,
  bg,
  hover,
  onClick,
  color,
  textName,
  pathnames,
  verifyField,
}) => {
  return (
    <Flex
      pl="8"
      bg={bg}
      w="100%"
      h="50px"
      _hover={hover}
      cursor="pointer"
      onClick={onClick}
      position="relative"
      alignItems="center"
      justifyContent="flex-start"
      borderRadius="0 0.5rem 0.5rem 0"
    >
      {children}
      <Flex alignItems="center" gap="4">
        <Text ml="2" color={color}>
          {textName}
        </Text>
        {verifyField && textName === "Perfil" ? (
          <Box w="12px" h="12px" borderRadius="50%" bg="#BF4635" />
        ) : null}
      </Flex>
      {pathnames ? (
        <Box
          w="10px"
          h="50px"
          bg="#26B1FF"
          position="absolute"
          borderRadius="0 0.5rem 0.5rem 0"
          top="0"
          right="0"
        />
      ) : (
        <></>
      )}
    </Flex>
  );
};
