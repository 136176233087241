import { ModalCrudTutorial } from "../../../components/Modal/Tutorials/ModalCrudTutorial";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  useDisclosure,
} from "@chakra-ui/react";

import { ClassCard } from "./ClassCard";
import { ClassesContent } from "./styles";
import { ModalCrudClasses } from "../../../components/Modal/Tutorials/ModalCrudClasses";
import { useTutorialsAdmin } from "../../../contexts/Tutorials/TutorialsContextAdmin";

export const TutorialsCardAdmin = ({ tutorial }) => {
  const { removeTutorial } = useTutorialsAdmin();

  const {
    isOpen: isOpenAddClass,
    onOpen: onOpenAddClass,
    onClose: onCloseAddClass,
  } = useDisclosure();

  const {
    isOpen: isOpenEditTutorial,
    onOpen: onOpenEditTutorial,
    onClose: onCloseEditTutorial,
  } = useDisclosure();

  const srcTutorials = "https://services.playdrop.com.br/api/";

  return (
    <Grid
      p="4"
      w="100%"
      bg="#fff"
      borderRadius="10px"
      border="0.85px solid #f5f5f5"
      boxShadow="0px 12.78px 9.37px -1.71px #e6eeff"
    >
      <Flex alignItems="center" justifyContent="center">
        <Image
          w="25%"
          h="100%"
          src={`${srcTutorials}/${tutorial.imagem_capa}`}
          alt={tutorial.descricao}
        />

        <Grid w="75%" p="0 1rem" display="flex" flexDir="column" gap="0.4rem">
          <Heading as="h4" fontSize="1.125rem" fontWeight="500" color="#3058a4">
            {tutorial.categoria}
          </Heading>

          <Button
            p="4"
            bg="#e6eeff"
            color="#4f4f4f"
            _hover={{ opacity: 0.7 }}
            onClick={() => onOpenEditTutorial()}
            fontSize={["0.9rem", "0.9rem", "1rem"]}
          >
            Editar tutorial
          </Button>

          <Button
            p="4"
            bg="#e60"
            color="#fff"
            _hover={{ opacity: 0.7 }}
            onClick={() => removeTutorial(tutorial.id)}
            fontSize={["0.9rem", "0.9rem", "1rem"]}
          >
            Remover tutorial
          </Button>
        </Grid>
      </Flex>

      <Box w="100%" h="2px" m="1rem 0" bg="#e6eeff" />

      <ClassesContent>
        {tutorial.aulas?.map((data) => (
          <ClassCard key={data.id} data={data} tutorialId={tutorial.id} />
        ))}

        <div className="button">
          <button onClick={() => onOpenAddClass()}>Cadastrar nova aula</button>
        </div>
      </ClassesContent>

      <ModalCrudClasses
        isOpen={isOpenAddClass}
        onClose={onCloseAddClass}
        verbType="create"
        tutorialId={tutorial.id}
      />

      <ModalCrudTutorial
        isOpen={isOpenEditTutorial}
        onClose={onCloseEditTutorial}
        verbType="update"
        tutorial={tutorial}
      />
    </Grid>
  );
};
