import { Center, Image, Text } from "@chakra-ui/react";

import goBackImg from "../../assets/icons/goBack.svg";

export const GoBack = ({ onClick }) => {
  return (
    <Center alignItems="center" cursor="pointer" onClick={onClick}>
      <Image
        w="1rem"
        src={goBackImg}
        color="#FB6B03"
        alt="Imagem de seta para retornar para página anterior"
      />
      <Text
        ml="2"
        fontSize="1rem"
        fontWeight="500"
        color="#FB6B03"
        fontFamily="Poppins"
      >
        Voltar
      </Text>
    </Center>
  );
};
