import * as yup from "yup";

const validateUpperCase = "^(?=.*[A-Z])";
const validateLowerCase = "^(?=.*[a-z])";
const validateNumbers = "(?=.*[0-9])";
const validateSpecialCharacter = "^(?=.*[!#@$%&])";
const validateCharacter = "^.{8,}$";

export const registerSchema = yup.object().shape({
  nome: yup.string().required("Por favor, digite o seu nome"),
  email: yup
    .string()
    .required("Por favor, digite o seu e-mail")
    .email("E-mail inválido"),
  senha: yup
    .string()
    .required("Campo obrigatório!")
    .matches(
      validateCharacter,
      "Senha deve conter o mínimo de oito caracteres"
    ),
  acceptTerms: yup
    .bool()
    .oneOf([true], "É necessário concordar com os termos antes de continuar"),
});

export const signInSchema = yup.object().shape({
  email: yup
    .string()
    .required("Por favor, digite o seu e-mail")
    .email("E-mail inválido"),
  senha: yup.string().required("Por favor, digite a sua senha"),
});

export const updatePassSchema = yup.object().shape({
  senha: yup.string().required("Senha obrigatória"),
  novasenha: yup
    .string()
    .required("Campo obrigatório!")
    .matches(validateUpperCase, "Senha deve conter 1 letra maiuscula")
    .matches(validateLowerCase, "Senha deve conter 1 letra minuscula")
    .matches(validateNumbers, "Senha deve conter 1 numero")
    .matches(
      validateSpecialCharacter,
      "Senha deve conter um caractere especial"
    )
    .matches(validateCharacter, "Senha deve conter oito caracteres"),
  confirmasenha: yup
    .string()
    .required("Confirmação de senha obrigatória")
    .oneOf([yup.ref("novasenha")], "Senhas não conferem"),
});

export const registerDomainSchema = yup.object().shape({
  nomesite: yup.string().required("Campo obrigatório"),
});

export const recoverySchema = yup.object().shape({
  senha: yup
    .string()
    .required("Campo obrigatório!")
    .matches(validateUpperCase, "Senha deve conter 1 letra maiuscula")
    .matches(validateLowerCase, "Senha deve conter 1 letra minuscula")
    .matches(validateNumbers, "Senha deve conter 1 numero")
    .matches(
      validateSpecialCharacter,
      "Senha deve conter um caractere especial"
    )
    .matches(validateCharacter, "Senha deve conter oito caracteres"),
  confirma_senha: yup
    .string()
    .required("Confirmação de senha obrigatória")
    .oneOf([yup.ref("senha")], "Senhas não conferem"),
});

export const updateProfileYup = yup.object().shape({
  nome: yup.string().required("Por favor, digite o seu nome"),
  email: yup
    .string()
    .required("Por favor, digite o seu e-mail")
    .email("E-mail inválido"),
  contato: yup.string().required("Campo obrigatório"),
});

export const newMediaSchema = yup.object().shape({
  titulo: yup.string().required("Campo obrigatório"),
  categoria: yup.string().required("Campo obrigatório"),
  data: yup.string().required("Campo obrigatório"),
  descricao: yup.string().required("Campo obrigatório"),
  imagem: yup.mixed().required("Uma imagem é requerida"),
  url: yup.string().required("Campo obrigatório"),
});

export const newTemplateSchema = yup.object().shape({
  nome_template: yup.string().required("Campo obrigatório"),
  data_publicacao: yup.string().required("Campo obrigatório"),
  descricao: yup.string().required("Campo obrigatório"),
  capa_template: yup.mixed().required("Uma imagem é requerida"),
  url_download: yup.string().required("Campo obrigatório"),
  url_demo: yup.string().required("Campo obrigatório"),
});

export const categorySchema = yup.object().shape({
  tipo_produto: yup.string().required("Campo obrigatório"),
});

export const plansSchema = yup.object().shape({
  plano: yup.string().required("Campo obrigatório"),
  mensalidades: yup.number().required("Campo obrigatório"),
  ativos: yup.string().required("Campo obrigatório"),
  inativos: yup.string(),
});

export const notificationSchema = yup.object().shape({
  titulo_notificacoes: yup.string().required("Campo obrigatório"),
  data_notificacoes: yup.string().required("Campo obrigatório"),
  texto_notificacoes: yup.string(),
});

export const backlogSchema = yup.object().shape({
  titulo_pendencia: yup.string().required("Campo obrigatório"),
  pendencias: yup.string().required("Campo obrigatório"),
  status_pendencias: yup.string().required("Campo obrigatório"),
});

export const productSchema = yup.object().shape({
  produto: yup.string().required("Obrigatório"),
  categoria: yup.string().required("Obrigatório"),
  preco: yup.number().required("Obrigatório"),
  data: yup.string().required("Obrigatório"),
  status: yup.string().required("Obrigatório"),
  imagem: yup.mixed().required("Uma imagem é requerida"),
  linkcompra: yup.string(),
  link_facebook: yup.string(),
  link_concorrente: yup.string().required("Obrigatório"),
});

export const productUpdateSchema = yup.object().shape({
  produto: yup.string().required("Obrigatório"),
  categoria: yup.string().required("Obrigatório"),
  preco: yup.number().required("Obrigatório"),
  data: yup.string().required("Obrigatório"),
  status: yup.string().required("Obrigatório"),
  linkcompra: yup.string(),
  link_facebook: yup.string(),
  link_concorrente: yup.string().required("Obrigatório"),
});

export const tutorialSchema = yup.object().shape({
  data_publicacao: yup.string().required("Campo obrigatório"),
  descricao: yup.string().required("Campo obrigatório"),
  categoria: yup.string().required("Campo obrigatório"),
  video: yup.string(),
  imagem_capa: yup.mixed().required("Uma imagem é requerida"),
});

export const classSchema = yup.object().shape({
  titulo: yup.string().required("Campo obrigatório"),
  video: yup.string().required("Campo obrigatório"),
});

export const messagesSchema = yup.object().shape({
  titulo: yup.string().required("Campo obrigatório"),
  data: yup.string().required("Campo obrigatório"),
  status: yup.string().required("Campo obrigatório"),
  descricao: yup.string().required("Campo obrigatório"),
  env_usuarios: yup.string().required("Campo obrigatório"),
});

export const copyGeneratorSchema = yup.object().shape({
  style: yup.string().required("Obrigatório"),
  additional_note: yup.string().required("Obrigatório"),
  copy_size: yup.string().required("Obrigatório"),
});
