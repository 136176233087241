import { ModalHeader } from "@chakra-ui/react";

export const ModalHeaderRoot = ({ children }) => {
  return (
    <ModalHeader
      p="6"
      w="100%"
      h="3.75rem"
      bg="#3058A5"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderRadius="0.625rem 0.625rem 0 0"
    >
      {children}
    </ModalHeader>
  );
};
