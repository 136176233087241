import { createContext, useContext } from "react";
import { useTutorialsClient } from "../TutorialsContextClient";
import { apiAuth, apiCredits } from "../../../services/api";
import { useToast } from "@chakra-ui/react";

const TutorialsContextAdmin = createContext({});

export const useTutorialsAdmin = () => {
  const context = useContext(TutorialsContextAdmin);

  if (!context) {
    throw new Error(
      "useTutorialsAdmin must be used within a TutorialsProviderAdmin"
    );
  }

  return context;
};

export const TutorialsProviderAdmin = ({ children }) => {
  const toast = useToast();

  const { loadTutorials } = useTutorialsClient();

  const addNewTutorial = async (data) => {
    const body = {
      ...data,
      imagem_capa: data.imagem_capa[0],
      video: "Não necessário",
      titulo: "Não necessário",
    };

    try {
      await apiAuth
        .post("/cadastrotutorial", body, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((_) => {
          toast({
            title: "Sucesso",
            description: "Tutorial criado com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadTutorials();
        });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao cadastrar tutorial!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const editTutorial = async (tutorialId, updatedData) => {
    const body = {
      ...updatedData,
      id: tutorialId,
      imagem_capa: updatedData.imagem_capa[0],
    };

    try {
      await apiAuth
        .post(`/editartutorial?id=${tutorialId}`, body, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          toast({
            title: "Sucesso",
            description: "Edição realizada com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          console.log(res);
          loadTutorials();
        });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao editar tutorial!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const removeTutorial = async (tutorialId) => {
    try {
      await apiAuth.get(`/deletartutorial?id=${tutorialId}`).then((_) => {
        toast({
          title: "Sucesso",
          description: "Tutorial excluído com sucesso!",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
        loadTutorials();
      });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao remover tutorial!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const addNewClass = async (newClass, tutorialId) => {
    const body = { ...newClass, tutorial_id: tutorialId };

    try {
      await apiCredits
        .post("/tutoriais/salvar", body, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((_) => {
          toast({
            title: "Sucesso",
            description: "Aula criada com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });

          loadTutorials();
        });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao cadastrar nova aula!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const updateClass = async (newClass, tutorialId, classId) => {
    const body = { ...newClass, tutorial_id: tutorialId, id: classId };

    try {
      await apiCredits
        .post("/tutoriais/atualizar", body, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((_) => {
          toast({
            title: "Sucesso",
            description: "Aula atualizada com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });

          loadTutorials();
        });
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro",
        description: "Algo deu errado ao atualizar nova aula!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const deleteClass = async (classId) => {
    try {
      await apiCredits.get(`/tutoriais/excluir/${classId}`).then((_) => {
        toast({
          title: "Sucesso",
          description: "Aula deletada com sucesso!",
          status: "success",
          duration: 2500,
          isClosable: true,
        });

        loadTutorials();
      });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao deletar aula!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  return (
    <TutorialsContextAdmin.Provider
      value={{
        addNewTutorial,
        editTutorial,
        removeTutorial,
        addNewClass,
        updateClass,
        deleteClass,
      }}
    >
      {children}
    </TutorialsContextAdmin.Provider>
  );
};
