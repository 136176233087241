import styled from "styled-components";

import background from "../../assets/decorativos/background.png";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;

  background: url(${background}) no-repeat center;
  background-size: cover;
  filter: blur(10px);

  z-index: -1;
  position: absolute;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 500px;
  margin: 1rem auto;
  padding: 1.8rem 1rem 0 1rem;

  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  .img-qr {
    margin-top: 1.25rem;
    width: 150px;
  }
`;
