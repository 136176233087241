import styled, { keyframes } from "styled-components";

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-50px);
  }
  to{
    opacity: 1;
    transform: translateX(0px);
  }
`;

const appear = keyframes`
  from{
    opacity: 0;
    transform: translateZ();
  }
  to{
    opacity: 1;
    transform: translateZ();
  }
`;

export const FormAuth = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 1rem;

  width: 100%;

  animation: ${appear} 1.2s;

  .error {
    width: 100%;

    font-size: 1rem;
    font-weight: 500;
    color: #fb6b03;
  }

  h2 {
    font-family: "Poppins";
    font-size: 1.5rem;
    font-weight: 400;

    text-align: center;
    margin-top: 1rem;

    color: #3058a4;

    display: flex;
    flex-direction: column;
    align-items: center;

    .subtitle {
      color: #4f4f4f;
      font-size: 1.3rem;
      font-weight: 500;
    }
  }

  a {
    color: #3058a4;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
    text-decoration: underline;
    margin: 1rem 0;
    padding: 0 0.4rem 0 0.4rem;
  }

  span {
    font-size: 1rem;
    color: #fb6b03;
    font-weight: 500;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 0.8rem;
      margin-bottom: 1rem;
    }
  }

  p {
    font-size: 1rem;
    color: #4f4f4f;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
  }

  .recovery-title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 1.25rem 0;

    img {
      width: 40px;
      height: 40px;
    }

    p {
      font-size: 0.8rem;
    }
  }
`;

export const TermsContent = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 1rem;

  div {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.5rem;

    input {
      width: 1.6rem;
      height: 1.6rem;

      cursor: pointer;
    }

    p {
      color: #505050;
      margin-left: 0.5rem;

      a {
        text-decoration: none;

        &:hover {
          color: #fb6b03;
        }
      }
    }
  }
`;
