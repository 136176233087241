import sihi from "../../assets/icons/sihi.webp";
import shopify from "../../assets/icons/shopify.webp";
import yampi from "../../assets/icons/yampi.png";
import appmax from "../../assets/icons/appmax.png";
import mercadopago from "../../assets/icons/mercadopago.png";
import bkreviews from "../../assets/icons/bkreviews.png";
import alisaveplus from "../../assets/icons/alisaveplus.png";
import dSers from "../../assets/icons/dsers.webp";

export const categories = ["Parceiros Oficiais", "Aplicativos Recomendados"];

export const appData = [
  {
    id: 1,
    img: sihi,
    title: "SIHI",
    category: ["Parceiros Oficiais"],
    description: "Fornecedor de Produtos",
    link: "https://app.sihidropshipping.com/RetailerLogin/Register/MTI4OTA%3d",
  },
  {
    id: 2,
    img: shopify,
    title: "Shopify",
    category: ["Parceiros Oficiais"],
    description: "Criador de Lojas",
    link: "https://www.shopify.com/br/avaliacao-gratuita?irclickid=Su1xz1znJxyKWIzRdpw9j13PUkC09AU3qSLMTA0&irgwc=1&partner=3198336&affpt=excluded&utm_channel=affiliates&utm_source=3198336-impact&utm_medium=cpa",
  },
  {
    id: 3,
    img: dSers,
    title: "DSers",
    category: ["Parceiros Oficiais"],
    description: "Gerenciador de Lojas",
    link: "https://accounts.dsers.com/accounts/register",
  },
  {
    id: 4,
    img: yampi,
    title: "Yampi",
    category: ["Parceiros Oficiais"],
    description: "Checkout Transparente",
    link: "",
  },
  {
    id: 5,
    img: appmax,
    title: "AppMax",
    category: ["Aplicativos Recomendados"],
    description: "Gateway de Pagamento",
    link: "https://admin.appmax.com.br/auth/onboarding/make",
  },
  {
    id: 6,
    img: mercadopago,
    title: "Mercado Pago",
    category: ["Aplicativos Recomendados"],
    description: "Gateway de Pagamento",
    link: "https://www.mercadopago.com.br/hub/registration/fiscal-splitter?contextual=company&hsCtaTracking=6a0c88a1-b0d9-4668-8d49-833638e7918d%7Cebe04041-a14f-40d6-8280-3d7fea6b50d2",
  },
  {
    id: 7,
    img: bkreviews,
    title: "Bk Reviews",
    category: ["Aplicativos Recomendados"],
    description: "Avaliações/Depoimentos",
    link: "",
  },
  {
    id: 8,
    img: alisaveplus,
    title: "AliSave Plus",
    category: ["Aplicativos Recomendados"],
    description: "Imagem de Produtos AliexPress",
    link: "https://chromewebstore.google.com/detail/alisave-plus-download-ali/ppfoibjbcchcfifclhebkhnigneiceje",
  },
];
