import { useState, useEffect } from "react";

import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import { Box, Flex, Grid, Image, Link, Spinner, Text } from "@chakra-ui/react";

import { TbWorld } from "react-icons/tb";

import imgWorld from "../../../assets/icons/worldNoResults.svg";

import { apiRegistroBr } from "../../../services/api";

export const ModalPurchaseDomain = ({ isOpen, onClose, domainName }) => {
  const [loading, setLoading] = useState(false);
  const [respDomain, setRespDomain] = useState("");

  const handleSearchDomain = (domain) => {
    if (domain) {
      apiRegistroBr
        .get(`/${domain}`)
        .then((_) => setRespDomain("Yes"))
        .catch((_) => setRespDomain("No"));
    }
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(true);

      setTimeout(() => {
        handleSearchDomain(domainName);

        setLoading(false);
      }, 2000);
    }
  }, [domainName, isOpen]);

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" h="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={TbWorld} color="#fff" />
            <ModalHeader.Title title="Verificar disponibilidade de domínio" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          {loading ? (
            <Flex
              w="100%"
              h="300px"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner color="#3058a4" />
            </Flex>
          ) : (
            <Grid>
              {respDomain === "" && (
                <Box display="flex" flexDir="column" alignItems="center" mt="6">
                  <Image
                    src={imgWorld}
                    alt="Ícone do mundo com botão de pesquisa"
                  />
                  <Text
                    mt="2"
                    mb="10"
                    color="#BABABA"
                    fontSize="1.25rem"
                    textAlign="center"
                    fontFamily="Poppins"
                  >
                    Nenhum resultado
                  </Text>
                </Box>
              )}
              {respDomain === "No" && (
                <Grid
                  mt="6"
                  display="flex"
                  flexDir="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text
                    color="#686868"
                    textAlign="center"
                    fontFamily="Poppins"
                    fontSize={["1.25rem", "1.25rem", "1.5rem"]}
                  >
                    {domainName}
                  </Text>
                  <Box
                    mt="4"
                    mb="6"
                    display="flex"
                    flexWrap="wrap"
                    fontWeight="700"
                    color="#4E4E4E"
                    fontSize="1.25rem"
                    alignItems="center"
                    fontFamily="Poppins"
                    justifyContent="center"
                  >
                    Domínio
                    <Text
                      mr="2"
                      ml="2"
                      fontWeight="700"
                      color="#00C814"
                      fontSize="1.25rem"
                      fontFamily="Poppins"
                    >
                      disponível
                    </Text>
                    para registro.
                  </Box>
                  <Link
                    mt="6"
                    mb="10"
                    h="60px"
                    w="233px"
                    display="flex"
                    color="#ffffff"
                    target="_blank"
                    rel="noreferrer"
                    borderRadius="0"
                    fontSize="1.25rem"
                    alignItems="center"
                    fontFamily="Poppins"
                    justifyContent="center"
                    _hover={{ opacity: 0.8 }}
                    bg="linear-gradient(89.97deg, #3058A4 0.44%, #2964D3 99.97%)"
                    href={`https://www.registro.br/busca-dominio/?fqdn=${domainName}`}
                  >
                    Registrar
                  </Link>
                </Grid>
              )}
              {respDomain === "Yes" && (
                <Grid alignItems="center" justifyContent="center" mt="6">
                  <Text
                    color="#686868"
                    fontSize="1.5rem"
                    textAlign="center"
                    fontFamily="Poppins"
                  >
                    {domainName}
                  </Text>
                  <Box
                    mt="4"
                    display="flex"
                    flexWrap="wrap"
                    fontWeight="700"
                    color="#4E4E4E"
                    fontSize="1.25rem"
                    alignItems="center"
                    fontFamily="Poppins"
                    justifyContent="center"
                  >
                    Domínio
                    <Text
                      mr="2"
                      ml="2"
                      fontWeight="700"
                      color="#FF3D00"
                      fontSize="1.25rem"
                      fontFamily="Poppins"
                    >
                      não disponível
                    </Text>
                    para registro.
                  </Box>
                  <Text
                    mt="6"
                    mb="10"
                    fontSize="1rem"
                    fontWeight="400"
                    color="#686868"
                    textAlign="center"
                    fontFamily="Poppins"
                  >
                    Já registrado
                  </Text>
                </Grid>
              )}
            </Grid>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
