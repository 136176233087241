import { Center, Flex, Text, Grid } from "@chakra-ui/react";

export const HelpCardConfig = ({ bigText, littleText, children }) => {
  return (
    <Flex
      p="6"
      gap="4"
      w="280px"
      bg="#ffffff"
      display="flex"
      alignItems="center"
      borderRadius="10px"
      justifyContent="center"
      boxShadow="0px 15.1px 11.0753px -2.0137px #e6eeff"
    >
      <Center>{children}</Center>
      <Grid>
        <Text fontSize="1rem" color="#3058A5" fontWeight="600">
          {bigText}
        </Text>
        <Text fontSize="0.75rem" color="#3058A5">
          {littleText}
        </Text>
      </Grid>
    </Flex>
  );
};
