import { Center, Flex, Heading, Text } from "@chakra-ui/react";

export const CardOptions = ({ title, text, onClick, children }) => {
  return (
    <Flex
      gap="0.6rem"
      cursor="pointer"
      alignItems="center"
      _hover={{
        bgColor: "#e6eeff",
        borderRadius: "0.5rem",
        transition: "ease-in 0.3s",
      }}
      onClick={onClick}
    >
      <Center w="45px" h="45px" bg="#e6eeff" borderRadius="6px">
        {children}
      </Center>
      <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
        <Heading
          as="h5"
          fontWeight="600"
          color="#3058A5"
          fontSize={["0.9rem", "1rem", "1.125rem"]}
        >
          {title}
        </Heading>
        <Text color="#4d4d4d" fontSize={["0.8rem", "0.9rem", "1rem"]}>
          {text}
        </Text>
      </Flex>
    </Flex>
  );
};
