import { apiAuth } from "../../../services/api";
import { useToast } from "@chakra-ui/react";
import { usePlansClient } from "../PlansContextClient";
import { createContext, useCallback, useContext, useState } from "react";
import { useAuth } from "../../Auth";

const PlansContextAdmin = createContext({});

export const usePlansAdmin = () => {
  const context = useContext(PlansContextAdmin);

  if (!context) {
    throw new Error("usePlansAdmin must be used within a PlansProviderAdmin");
  }

  return context;
};

export const PlansProviderAdmin = ({ children }) => {
  const [linkInvoice, setLinkInvoice] = useState("");

  const { data } = useAuth();
  const { loadPlans } = usePlansClient();

  const toast = useToast();

  const addNewPlan = async (data) => {
    try {
      await apiAuth
        .post("/cadastrodeplanos", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((_) => {
          toast({
            title: "Sucesso",
            description: "Plano cadastrado com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadPlans();
        });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao cadastrar plano!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const updatePlanAdmin = async (data, planId) => {
    const body = { ...data, id: planId };

    try {
      await apiAuth
        .post(`/editarplanos?id=${planId}`, body, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          toast({
            title: "Sucesso",
            description: "Plano editado com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          console.log(res);
          loadPlans();
        });
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro",
        description: "Algo deu errado ao editar plano!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const deletePlan = async (planId) => {
    try {
      await apiAuth.get(`/deletarplanos?id=${planId}`).then((_) => {
        toast({
          title: "Sucesso",
          description: "Plano excluído com sucesso!",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
        loadPlans();
      });
    } catch (_) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao excluir plano!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const getLinkInvoice = async () => {
    try {
      const response = await apiAuth.get(
        `/lista-linkfatura?id_login=${data.id}`
      );
      setLinkInvoice(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const sendingInvoice = async (userId, link_fatura) => {
    try {
      await apiAuth
        .post("/atualiza-fatura", {
          link_fatura: link_fatura,
          id: userId,
        })
        .then((res) => {
          console.log(res);
          toast({
            title: "Sucesso",
            description: "Envio de fatura realizado!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadPlans();
        });
    } catch (_) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao enviar fatura!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const sendingReceipt = async (userId, link_comprovante) => {
    try {
      await apiAuth
        .post("/atualiza_comprovante", {
          link_comprovante: link_comprovante,
          id: userId,
        })
        .then((res) => {
          console.log(res);
          toast({
            title: "Sucesso",
            description: "Comprovante enviado!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadPlans();
        });
    } catch (_) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao enviar comprovante!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const updateStatusSite = async (userId, status_site) => {
    try {
      await apiAuth
        .post("/atualiza_status_site", {
          status_site: status_site,
          id: userId,
        })
        .then((res) => {
          console.log(res);
          toast({
            title: "Sucesso",
            description: "Status atualizado!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadPlans();
        });
    } catch (_) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao atualizar status!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const updateDueDate = async (planId, userId, dueDate) => {
    const brDate = dueDate.split("-").reverse().join("/");

    await apiAuth
      .post("/update-datavenc", {
        id: planId,
        data_venc: brDate,
        id_login: userId,
      })
      .then(() => {
        toast({
          title: "Sucesso",
          description: "Data de vencimento atualizada!",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
      })
      .catch((_) =>
        toast({
          title: "Erro",
          description: "Algo deu errado atualizar data de vencimento!",
          status: "error",
          duration: 2500,
          isClosable: true,
        })
      );
  };

  const updateStatusPlan = async (planId, userId, status) => {
    const body = { id: planId, id_login: userId, status: status };

    await apiAuth
      .post("/update-status-plano-admin", body)
      .then((res) => {
        toast({
          title: "Sucesso",
          description: "Status do plano atualizado!",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
      })
      .catch((_) =>
        toast({
          title: "Erro",
          description: "Algo deu errado atualizar status do plano!",
          status: "error",
          duration: 2500,
          isClosable: true,
        })
      );
  };

  return (
    <PlansContextAdmin.Provider
      value={{
        addNewPlan,
        linkInvoice,
        updatePlanAdmin,
        deletePlan,
        sendingInvoice,
        sendingReceipt,
        getLinkInvoice,
        updateStatusSite,
        updateDueDate,
        updateStatusPlan,
      }}
    >
      {children}
    </PlansContextAdmin.Provider>
  );
};
