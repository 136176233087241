export const MenuOption = ({
  icon: Icon,
  iconSize,
  onFunction = () => {},
  text,
  className,
}) => {
  return (
    <div className={className} onClick={onFunction}>
      <Icon size={iconSize} />
      <p>{text}</p>
    </div>
  );
};
