import { BiStoreAlt } from "react-icons/bi";
import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import { ModalImportProductContent } from "../../../pages/Products/ModalImportProductContent";

export const ModalImportProduct = ({ isOpen, onClose, productId, planId }) => {
  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="46rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BiStoreAlt} color="#fff" />
            <ModalHeader.Title title="Importar para Shopify" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>

        <Modal.Body>
          <ModalImportProductContent
            onClose={onClose}
            productId={productId}
            planId={planId}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
