import { apiAuth } from "../../../services/api";
import { useState, useContext, createContext, useEffect } from "react";

const PLANS_CACHE_KEY = "@PlayDrop:Planos";

const fetchPlans = async () => {
  const response = await apiAuth.get("/planos");

  const plans = response.data;

  return plans;
};

const updateCache = (data) => {
  localStorage.setItem(PLANS_CACHE_KEY, JSON.stringify(data));
};

const getCachedPlans = () => {
  const cachedData = localStorage.getItem(PLANS_CACHE_KEY);

  return cachedData ? JSON.parse(cachedData) : null;
};

const PlansContextClient = createContext({});

export const usePlansClient = () => {
  const context = useContext(PlansContextClient);

  if (!context) {
    throw new Error("usePlansClient must be used within a PlansProviderClient");
  }

  return context;
};

export const PlansProviderClient = ({ children }) => {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const cachedPlans = getCachedPlans();

    if (cachedPlans) {
      setPlans(cachedPlans);
    } else {
      fetchPlans().then((data) => {
        setPlans(data);
        updateCache(data);
      });
    }

    // Configurar verificação periódica (polling)
    const intervalId = setInterval(() => {
      fetchPlans().then((data) => {
        setPlans(data);
        updateCache(data);
      });
    }, 20000);

    return () => clearInterval(intervalId); // Limpar intervalo ao desmontar
  }, []);

  return (
    <PlansContextClient.Provider
      value={{
        plans,
      }}
    >
      {children}
    </PlansContextClient.Provider>
  );
};
