import { useAuth } from "../contexts/Auth";
import { AppRoutes } from "./app.routes";

export const MainRoutes = () => {
  const { data, signed } = useAuth();

  const isProfileAdmin = signed && data.perfil === "admin";

  return <AppRoutes isProfileAdmin={isProfileAdmin} />;
};
