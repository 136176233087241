import { useBacklogs } from "../../../contexts/Backlogs";
import {
  Box,
  Flex,
  Grid,
  Text,
  Title,
  Container,
  IoMdSettings,
} from "./controler";
import { BacklogCard } from "./BacklogCard";
import { useEffect } from "react";

export const BacklogPageAdmin = () => {
  const { backlogData, loadBacklogs } = useBacklogs();

  useEffect(() => {
    loadBacklogs();

    // eslint-disable-next-line
  }, []);

  const filteredBacklogs = backlogData?.filter(
    (item) => item.status_pendencias === "pendente"
  );

  return (
    <Container>
      <Title headingName="Pendências">
        <IoMdSettings fill="#3058A5" size="1.75rem" />
      </Title>
      <Box h="2px" mt="8" mb="8" w="100%" bg="#d4d0d0" />
      <Grid overflowX="auto">
        <Flex
          gap="2"
          w={["820px", "820px", "100%"]}
          borderBottom="1.5px solid #d4d0d0"
        >
          <Text w="15%" textAlign="center">
            Nome
          </Text>
          <Text w="35%" textAlign="center">
            E-mail
          </Text>
          <Text w="25%" textAlign="center">
            Solicitação
          </Text>
          <Text w="15%" textAlign="center">
            Data
          </Text>
          <Text w="10%" textAlign="center">
            Ações
          </Text>
        </Flex>
        {filteredBacklogs?.map((item) => (
          <BacklogCard key={item.id} item={item} />
        ))}
      </Grid>
    </Container>
  );
};
