import styled from "styled-components";

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

export const Config = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  .flex-items {
    display: flex;
    align-items: center;

    gap: 1rem;
  }

  .container {
    width: 100%;
    padding: 0.7rem;

    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    box-shadow: 0px 5px 4px -2px #3058a4;
    border-radius: 0.625rem;
    background-color: #fff;

    .container__title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 0.5rem;

      position: relative;

      div {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        p {
          color: #4f4f4f;
          font-weight: 500;
        }
      }

      .doubt {
        width: 2rem;
        height: 2rem;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 10px;
        background-color: #e6eeff;

        font-size: 1.25rem;
        font-weight: 500;

        &:hover {
          cursor: pointer;

          opacity: 0.8;
        }
      }

      span {
        width: 300px;
        padding: 0.625rem;

        position: absolute;
        top: -4.5rem;
        right: 0;

        box-shadow: 0px 5px 4px -2px #3058a4;
        border-radius: 0.625rem;
        background-color: #fff;
      }

      .marketing {
        top: -7.5rem;
      }
    }

    .container__input {
      width: 100%;
      height: 3.125rem;
      padding-left: 0.6rem;

      position: relative;

      display: flex;
      align-items: center;

      border-radius: 0.625rem;
      background-color: #e6eeff;

      .divider {
        width: 2px;
        height: 2.25rem;
        margin-left: 1rem;

        background-color: #4f4f4fa5;
      }
    }
  }

  .fixed-costs {
    position: relative;

    width: 100%;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    box-shadow: 0px 5px 4px -2px #3058a4;
    border-radius: 0.625rem;
    background-color: #fff;

    h4 {
      color: #4f4f4f;
      margin-bottom: 0;
    }

    .doubt {
      width: 2rem;
      height: 2rem;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 10px;
      background-color: #e6eeff;

      font-size: 1.25rem;
      font-weight: 500;

      &:hover {
        cursor: pointer;

        opacity: 0.8;
      }
    }

    span {
      width: 300px;
      padding: 0.625rem;

      position: absolute;
      top: -3.5rem;
      right: 0;
      z-index: 10;

      box-shadow: 0px 5px 4px -2px #3058a4;
      border-radius: 0.625rem;
      background-color: #fff;
    }

    .freight {
      top: 3.5rem;
    }
    .chechout {
      top: 5rem;
    }
    .gateway {
      top: 9rem;
    }
    .tax {
      top: 13rem;
    }
    .taxation {
      top: 14rem;
    }

    .flex-items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      .title {
        display: flex;
        align-items: center;
        gap: 0.4rem;
      }

      .no-opacity {
        opacity: 1;
      }

      .opacity {
        opacity: 0.7;
      }

      .input {
        width: 180px;
        height: 3.125rem;
        padding-left: 0.6rem;

        position: relative;

        display: flex;
        align-items: center;

        border-radius: 0.625rem;
        background-color: #e6eeff;

        .divider {
          width: 2px;
          height: 2.25rem;
          margin-left: 1rem;

          background-color: #4f4f4fa5;
        }
      }
    }
  }

  @media (min-width: 768px) {
    width: 365px;
  }
`;

export const Result = styled.div`
  width: 100%;
  margin-top: 1rem;

  display: flex;
  flex-direction: column;

  overflow-y: hidden;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border: 0;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #3059a4;
    border-radius: 24px;
  }

  .productNameContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .productName {
      color: #3058a4;
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
  }

  .flex-items-container {
    width: 550px;
    padding: 1rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    box-shadow: 0px 5px 4px -2px #3058a4;
    border-radius: 10px;
    background-color: #fff;

    .divider {
      width: 3px;
      height: 100%;

      background-color: #e6eeff;
    }

    .item {
      width: 100%;

      .content-doubt {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        .doubt {
          width: 2rem;
          height: 2rem;

          display: flex;
          align-items: center;
          justify-content: center;

          border-radius: 10px;
          background-color: #e6eeff;

          font-size: 1.25rem;
          font-weight: 500;

          &:hover {
            cursor: pointer;

            opacity: 0.8;
          }
        }

        span {
          width: 300px;
          padding: 0.625rem;

          position: absolute;
          top: -7.5rem;
          right: 0;
          z-index: 10;

          box-shadow: 0px 5px 4px -2px #3058a4;
          border-radius: 0.625rem;
          background-color: #fff;
        }
      }

      p {
        color: #4f4f4f;
        font-size: 1.125rem;
        font-weight: 500;

        text-align: center;
      }

      .price {
        width: 200px;
        height: 45px;
        margin: 1rem auto;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 10px;
        background-color: #008000;

        p {
          color: #fff;
          font-size: 1.25rem;
          font-weight: 500;
        }
      }
    }
  }

  .content-result {
    width: 815px;
    margin-bottom: 1rem;

    border: 1.5px solid #e6ddff;
    border-radius: 0.625rem;
    background-color: #fff;

    .content-result__title {
      width: 815px;
      height: 3.125rem;

      display: flex;
      align-items: center;

      border-radius: 0.625rem 0.625rem 0 0;
      background-color: #e6eeff;

      position: relative;

      li {
        width: 110px;

        font-size: 0.8rem;
        font-weight: 500;
        text-align: center;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        .doubt {
          width: 1.5rem;
          height: 1.5rem;

          display: flex;
          align-items: center;
          justify-content: center;

          border-radius: 10px;
          background-color: #fff;

          font-size: 1.25rem;
          font-weight: 500;

          &:hover {
            cursor: pointer;

            opacity: 0.8;
          }
        }

        span {
          width: 300px;
          padding: 0.625rem;

          position: absolute;
          top: 3rem;
          left: 0;
          z-index: 10;

          box-shadow: 0px 5px 4px -2px #3058a4;
          border-radius: 0.625rem;
          background-color: #fff;
        }

        .finalPrice {
          left: 2rem;
        }
        .fixedCost {
          left: 8rem;
        }
        .profit {
          left: auto;
          right: 8rem;
        }
        .maxCpa {
          left: auto;
          right: 0;
        }
        .profitMargin {
          left: auto;
          right: 0;
        }
        .marketingCost {
          left: 12rem;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;

      li {
        width: 815px;
        height: 3.125rem;

        background-color: #fff;

        display: flex;
        align-items: center;
        justify-content: space-around;

        p {
          width: 110px;

          font-size: 0.8rem;
          text-align: center;
          font-weight: 500;
        }

        .green {
          color: #fff;
          border-radius: 0.2rem;
          background-color: #008000;
        }

        .red {
          color: #fff;
          border-radius: 0.2rem;
          background-color: #f00000;
        }

        .green-color {
          color: #008000;
        }

        .red-color {
          color: #f00000;
        }

        &:nth-child(even) {
          background-color: #e1e1e1;
        }
      }
    }
  }

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;
