import { TutorialDetails } from "../../../pages/ClientSide/Tutorials/TutorialDetails";
import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";

import { BiCube } from "react-icons/bi";

export const ModalTutorialDetails = ({
  isOpen,
  onClose,
  classes,
  classTutorial,
}) => {
  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="80rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BiCube} color="#fff" />
            <ModalHeader.Title title="Detalhes" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <TutorialDetails classes={classes} classTutorial={classTutorial} />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
