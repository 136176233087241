import * as PI from "./styles";

import sihiImg from "../../assets/icons/sihi.webp";

import { useState } from "react";
import { useProductsClient } from "../../contexts/Products/ProductsContextClient";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Link as LinkChakra, useDisclosure } from "@chakra-ui/react";

import { GoBack } from "../../components/Reusable/GoBack";
import { PiTarget } from "react-icons/pi";
import { IoIosPerson } from "react-icons/io";
import { BiCalculator, BiImport, BiStoreAlt } from "react-icons/bi";
import { MdCopyright, MdOutlineAnnouncement } from "react-icons/md";
import { FaPeopleArrows, FaExclamationTriangle, FaCrown } from "react-icons/fa";

import { ProductCard } from "./ProductCard";
import { ModalPersona } from "../../components/Modal/Products/ModalPersona";
import { ModalImportProduct } from "../../components/Modal/Products/ModalImportProduct";
import { ModalTargetAudience } from "../../components/Modal/Products/ModalTargetAudience";
import { SuppliersCard } from "./SuppliersCard";
import { GiBrazil, GiWorld } from "react-icons/gi";
import { useAuth } from "../../contexts/Auth";
import { ModalBlockUser } from "../../components/Modal/Products/ModalBlockUser";

export const ProductInfo = () => {
  const [isActive, setIsActive] = useState("description");
  const [isSuppliers, setIsSupliers] = useState("international");

  const products = JSON.parse(localStorage.getItem("@PlayDrop:Produtos")) || [];

  const navigate = useNavigate();

  const { id } = useParams();
  const { data } = useAuth();

  const {
    isOpen: isOpenModalPersona,
    onOpen: onOpenModalPersona,
    onClose: onCloseModalPersona,
  } = useDisclosure();

  const {
    isOpen: isOpenModalTargetAudience,
    onOpen: onOpenModalTargetAudience,
    onClose: onCloseModalTargetAudience,
  } = useDisclosure();

  const {
    isOpen: isOpenModalImportProduct,
    onOpen: onOpenModalImportProduct,
    onClose: onCloseModalImportProduct,
  } = useDisclosure();

  const {
    isOpen: isOpenModalBlockUser,
    onOpen: onOpenModalBlockUser,
    onClose: onCloseModalBlockUser,
  } = useDisclosure();

  const { addExistingProductToTheConveyor } = useProductsClient();

  const srcProduct =
    "https://services.playdrop.com.br/api/uploads/imgs/produtos";

  // const srcOwner = "https://services.playdrop.com.br/api/uploads/imgs/owner";

  const product = products?.find((item) => item.id_fornecedores === Number(id));
  const productsWithSameCategory = products.filter(
    (item) => item.categoria === product.categoria
  );

  const linkProduct = `https://app.playdrop.com.br/dashboard/fornecedores/${product.id_fornecedores}`;

  const isVideo = (filename) => {
    const videoExtensions = ["mp4", "avi", "mov", "mkv"]; // Adicione outras extensões de vídeo se necessário
    const extension = filename.split(".").pop();
    return videoExtensions.includes(extension.toLowerCase());
  };

  return (
    <PI.InfoContainer>
      <div className="go-back">
        <GoBack onClick={() => navigate("/dashboard/fornecedores")} />
      </div>

      <PI.InfoContent>
        <figure>
          {isVideo(product.imagem) ? (
            <video controls muted>
              <source
                src={`${srcProduct}/${product.imagem}`}
                type={`video/${product.imagem.split(".").pop()}`}
              />
              Seu navegador não suporta o elemento de vídeo.
            </video>
          ) : (
            <img
              alt={product.produto}
              src={`${srcProduct}/${product.imagem}`}
            />
          )}
        </figure>

        <div className="info-product">
          <div>
            <p className="text-title">Nicho</p>
            <p className="text-name">{product.categoria}</p>
          </div>

          <p className="text-name gray">{product.produto}</p>

          <div>
            <p className="text-title blue big">Preço de Custo</p>
            <p className="text-price">
              {product.preco.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
              })}
            </p>
          </div>

          <div className="information">
            <div>
              <FaExclamationTriangle size="1.5rem" fill="#fff" />
            </div>
            <p className="text-title white">
              O preço pode sofrer alteração, fique atento a página do fornecedor
            </p>
          </div>
        </div>

        <div className="cards-and-more">
          <div className="cards">
            <div
              className="content"
              onClick={() => {
                onOpenModalTargetAudience();
              }}
            >
              <div className="name">
                <span>
                  <PiTarget color="#3058a4" size="1.5rem" />
                </span>
                <p>Público Alvo</p>
              </div>
            </div>

            <div className="content">
              <LinkChakra
                rel="noreferrer"
                cursor="pointer"
                target="_blank"
                href={`https://www.tiktok.com/search?q=${product.produto}`}
              >
                <div className="name">
                  <span>
                    <MdOutlineAnnouncement color="#3058a4" size="1.5rem" />
                  </span>
                  <p>Anúncios</p>
                </div>
              </LinkChakra>
            </div>

            <div className="content">
              <LinkChakra
                cursor="pointer"
                target="_blank"
                rel="noreferrer"
                href={product.link_concorrente}
              >
                <div className="name">
                  <span>
                    <FaPeopleArrows color="#3058a4" size="1.5rem" />
                  </span>
                  <p>Concorrente</p>
                </div>
              </LinkChakra>
            </div>

            <div className="content">
              <Link to={`/dashboard/copy/${product.produto}`}>
                <div className="name">
                  <span>
                    <MdCopyright color="#3058a4" size="1.5rem" />
                  </span>
                  <p>Copy</p>
                </div>
              </Link>
            </div>

            <div className="content">
              <div
                className="name"
                onClick={() => {
                  onOpenModalPersona();
                }}
              >
                <span>
                  <IoIosPerson color="#3058a4" size="1.5rem" />
                </span>
                <p>Persona</p>
              </div>
            </div>

            <div className="content">
              <Link
                to={`/dashboard/calculadora/${product.produto}/${Number(
                  product.preco
                )}`}
              >
                <div className="name">
                  <span>
                    <BiCalculator color="#3058a4" size="1.5rem" />
                  </span>
                  <p>Precificador</p>
                </div>
              </Link>
            </div>

            <div
              className="content"
              onClick={() =>
                addExistingProductToTheConveyor(
                  product.produto,
                  product.imagem,
                  linkProduct
                )
              }
            >
              <div className="name">
                <span>
                  <BiStoreAlt size="1.5rem" fill="#3058A9" />
                </span>
                <p>Por na Esteira</p>
              </div>
            </div>

            <div className="content" onClick={() => onOpenModalImportProduct()}>
              <div className="name">
                <span>
                  <BiImport color="#3058a4" size="1.5rem" />
                </span>
                <p>Imp. para loja</p>
              </div>
            </div>
          </div>
        </div>
      </PI.InfoContent>

      <ul className="menu-info">
        <li
          className={`${isActive === "description" ? "description" : ""}`}
          onClick={() => setIsActive("description")}
        >
          Descrição
        </li>

        <li
          className={`${isActive === "related-products" ? "related" : ""}`}
          onClick={() => setIsActive("related-products")}
        >
          Produtos relacionados
        </li>
        <li
          className={`${isActive === "suppliers" ? "suppliers" : ""}`}
          onClick={() => setIsActive("suppliers")}
        >
          Fornecedores
        </li>
      </ul>

      <div className="info-mais-produtos">
        <h4>
          {isActive === "related-products"
            ? "Relacionados"
            : isActive === "suppliers"
            ? "Fornecedores"
            : null}
        </h4>

        {
          // isActive === "description" ? (
          //   <div
          //     className="post__description"
          //     dangerouslySetInnerHTML={{ __html: product.descricao }}
          //   />
          isActive === "related-products" ? (
            <PI.Grid>
              {productsWithSameCategory.length > 0
                ? productsWithSameCategory.map((product) => (
                    <ProductCard
                      key={product.id_fornecedores}
                      product={product}
                    />
                  ))
                : null}
            </PI.Grid>
          ) : isActive === "suppliers" ? (
            <div className="suppliers">
              <div className="suppliers-container">
                <div
                  className="suppliers-container__menu"
                  onClick={() => setIsSupliers("international")}
                >
                  <div>
                    <GiWorld size="2rem" color="#3058a4" />
                  </div>
                  <p>Internacionais</p>
                </div>
                <a
                  rel="noreferrer"
                  href="https://app.sihidropshipping.com/RetailerLogin/Register/MTI4OTA%3d"
                  target="_blank"
                >
                  <div className="suppliers-container__menu">
                    <div>
                      <img src={sihiImg} alt="Ícone Sihi" />
                    </div>
                    <p className="small">SIHI</p>
                  </div>
                </a>

                {data.id_planos !== 4 ? (
                  <a
                    rel="noreferrer"
                    href={`https://qksource.com/list-detail.html?searchImg=1&token=b476d77e-ae53-409b-ad8c-2f9d5b10040c&imgSrc=${srcProduct}/${product.imagem}`}
                    target="_blank"
                  >
                    <div className="suppliers-container__menu relative">
                      <div>
                        <GiWorld size="2rem" color="#3058a4" />
                      </div>

                      <span>
                        <FaCrown size="1.8rem" fill="#fb6b03" />
                      </span>

                      <p className="small">Mais Fornecedores</p>
                    </div>
                  </a>
                ) : (
                  <div className="suppliers-container__menu relative">
                    <div>
                      <GiWorld size="2rem" color="#3058a4" />
                    </div>

                    <span>
                      <FaCrown size="1.8rem" fill="#fb6b03" />
                    </span>

                    <p className="small">Mais Fornecedores</p>
                  </div>
                )}

                <div
                  className="suppliers-container__menu relative"
                  onClick={() => setIsSupliers("brazilian")}
                >
                  <div>
                    <GiBrazil size="2rem" color="#3058a4" />
                  </div>

                  <span>
                    <FaCrown size="1.8rem" fill="#fb6b03" />
                  </span>

                  <p>Brasileiros</p>
                </div>
              </div>

              <SuppliersCard
                productAdId={product.adId}
                isSuppliers={isSuppliers}
                image={`${srcProduct}/${product.imagem}`}
                video={product.imagem}
                productName={product.produto}
                supplierLink={product.linkcompra}
                supplierInternationalLink={product.link_facebook}
              />
            </div>
          ) : null
        }
      </div>

      <ModalPersona
        isOpen={isOpenModalPersona}
        onClose={onCloseModalPersona}
        name={product.produto}
      />

      <ModalTargetAudience
        isOpen={isOpenModalTargetAudience}
        onClose={onCloseModalTargetAudience}
        productName={product.produto}
      />

      <ModalImportProduct
        isOpen={isOpenModalImportProduct}
        onClose={onCloseModalImportProduct}
        productId={product.id_fornecedores}
        planId={data.id_planos}
      />

      <ModalBlockUser
        isOpen={isOpenModalBlockUser}
        onClose={onCloseModalBlockUser}
        title="Ops, infelizmente o seu plano não contempla a funcionalidade"
        text="Altere agora o seu plano para conquistar imediatamente acesso ILIMITADO."
      />
    </PI.InfoContainer>
  );
};
