export const formatDate = (dateString) => {
  let date = new Date(dateString);

  let day = String(date.getDate()).padStart(2, "0");
  let month = String(date.getMonth() + 1).padStart(2, "0"); // Os meses são indexados a partir de 0
  let year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const formatDateIso = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Meses são 0-11, então adicione 1
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
