import { CategoriesProviderAdmin } from "./CategoriesContextAdmin";
import { CategoriesProviderClient } from "./CategoriesContextClient";

export const CategoriesProvider = ({ children }) => {
  return (
    <CategoriesProviderClient>
      <CategoriesProviderAdmin>{children}</CategoriesProviderAdmin>
    </CategoriesProviderClient>
  );
};
