import { TutorialsProviderAdmin } from "./TutorialsContextAdmin";
import { TutorialsProviderClient } from "./TutorialsContextClient";

export const TutorialsProvider = ({ children }) => {
  return (
    <TutorialsProviderClient>
      <TutorialsProviderAdmin>{children}</TutorialsProviderAdmin>
    </TutorialsProviderClient>
  );
};
