import { Card } from "./styles";
import { ModalOpenTheme } from "../../components/Modal/Templates/ModalOpenTheme";
import { ModalAddTemplate } from "../../components/Modal/Templates/ModalAddTemplate";
import { Button, useDisclosure } from "@chakra-ui/react";
import { FaCrown } from "react-icons/fa6";
import { useAuth } from "../../contexts/Auth";

export const TemplateCard = ({ templateData }) => {
  const { id_template, capa_template, nome_template, url_demo, descricao } =
    templateData;

  const { data } = useAuth();

  const srcTemplate =
    "https://services.playdrop.com.br/api/uploads/imgs/capas-sites";

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenModalOpenTheme,
    onOpen: onOpenModalOpenTheme,
    onClose: onCloseModalOpenTheme,
  } = useDisclosure();

  return (
    <Card>
      {descricao.includes("Nichado") && data.id_planos === 4 && (
        <div className="crown">
          <FaCrown size="3rem" color="#fb6b03" />
        </div>
      )}

      <img alt={nome_template} src={`${srcTemplate}/${capa_template}`} />

      <div className="content">
        <h4>{nome_template}</h4>

        <div>
          <Button
            w="50%"
            h="40px"
            bg="linear-gradient(89.97deg, #3058a5 0.44%, #2964d3 99.97%)"
            color="#fff"
            border="2px solid #3058A4"
            fontWeight="600"
            borderRadius="6px"
            onClick={() => onOpenModalOpenTheme()}
            _hover={{
              opacity: 0.8,
              transition: "ease-in 0.2s",
            }}
          >
            Ver loja ao vivo
          </Button>
          <Button
            w="50%"
            h="40px"
            bg="#fb6b03"
            color="#fff"
            borderRadius="6px"
            border="2px solid #fb6b03"
            _hover={{
              opacity: 0.8,
              transition: "ease-in 0.2s",
            }}
            onClick={() => onOpen()}
          >
            Baixar tema
          </Button>
        </div>
      </div>

      <ModalAddTemplate
        isOpen={isOpen}
        onClose={onClose}
        themeName={nome_template}
        themeId={id_template}
        descricao={descricao}
      />
      <ModalOpenTheme
        isOpen={isOpenModalOpenTheme}
        onClose={onCloseModalOpenTheme}
        theme_link={url_demo}
      />
    </Card>
  );
};
