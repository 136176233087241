import styled from "styled-components";

export const Content = styled.div`
  width: 100%;

  .info-credits {
    width: 100%;
    max-width: 350px;
    height: 2.5rem;
    padding: 0.2rem 1rem;
    margin-bottom: 1rem;

    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0px 8px 5px -1px #e6eeff;

    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #3058a4;
      font-size: 1rem;

      span {
        margin: 0 4px;
      }
    }

    div {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  h4 {
    margin-top: 1.5rem;
  }

  .copy-container {
    width: 100%;
    height: 100%;
    padding: 1rem;
    margin-bottom: 1rem;

    border: 2px solid #ddd;
    border-radius: 0.5rem;

    p {
      color: #4f4f4f;
    }

    .is-loading {
      width: 100%;
      text-align: center;

      color: #3058a4;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Flex = styled.div`
  width: 100%;

  display: flex;
  gap: 1rem;

  .blue {
    background: linear-gradient(89.97deg, #3058a5 0.44%, #2964d3 99.97%);
  }

  button {
    width: 100%;
    height: 40px;

    cursor: pointer;

    color: #fff;
    border-radius: 0.5rem;
    background: linear-gradient(89.97deg, #fb8a03 0.44%, #fd8f09 99.97%);

    :hover {
      opacity: 0.8;
      transition: ease-in 0.2s;
    }
  }

  .grid-select {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
      color: #3058a4;
      font-weight: 500;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
