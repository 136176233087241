import { useState } from "react";
import { useProductsClient } from "../contexts/Products/ProductsContextClient";

export const useFilter = () => {
  const [filteredProducts, setFilteredProducts] = useState([]);

  const { products, setProducts } = useProductsClient();

  const searchByName = (productName) => {
    if (productName !== "") {
      const normalize = productName
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const filteredItems = products.filter((item) =>
        item.produto
          .toLocaleLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(normalize)
      );
      if (filteredItems.length > 0) {
        setFilteredProducts([...filteredItems]);
      } else {
        setFilteredProducts([]);
      }
    } else {
      setFilteredProducts([]);
      setProducts([...products]);
    }
  };

  const searchByCategory = (categoryName) => {
    if (categoryName !== "") {
      const normalize = categoryName
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      if (normalize !== "todos") {
        const filteredItems = products.filter((item) =>
          item.categoria
            .toLocaleLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(normalize)
        );
        if (filteredItems.length > 0) {
          setFilteredProducts([...filteredItems]);
        } else {
          setFilteredProducts([]);
        }
      } else {
        setFilteredProducts([...products]);
      }
    } else {
      setFilteredProducts([]);
      setProducts([...products]);
    }
  };

  const searchByCriteria = (likes, comments, shares) => {
    const filteredItems = products.filter(
      (item) =>
        item.TotalReactions >= likes &&
        item.TotalComments >= comments &&
        item.TotalShares >= shares
    );
    if (filteredItems.length > 0) {
      setFilteredProducts([...filteredItems]);
    } else {
      setFilteredProducts([]);
    }
  };

  const searchByDate = (startDate, endDate) => {
    const setToMidnight = (date) => {
      const d = new Date(date);
      d.setHours(0, 0, 0, 0);
      return d;
    };

    const filteredItems = products.filter((product) => {
      const productDate = setToMidnight(new Date(product.data));
      const start = setToMidnight(startDate);
      const end = setToMidnight(endDate);

      return productDate >= start && productDate <= end;
    });

    if (filteredItems.length > 0) {
      setFilteredProducts([...filteredItems]);
    } else {
      setFilteredProducts([]);
    }
  };

  const cleanFilters = () => {
    setFilteredProducts([]);
    setProducts([...products]);
  };

  return {
    searchByName,
    searchByDate,
    searchByCategory,
    searchByCriteria,
    cleanFilters,
    filteredProducts,
  };
};
