import { apiAuth } from "../../../services/api";
import { useAuth } from "../../Auth";
import {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";

const CategoriesContextClient = createContext({});

export const useCategoriesClient = () => {
  const context = useContext(CategoriesContextClient);

  if (!context) {
    throw new Error(
      "useCategoriesClient must be used within an CategoriesProviderClient"
    );
  }

  return context;
};

export const CategoriesProviderClient = ({ children }) => {
  const [categoryData, setCategoryData] = useState([]);

  const { data } = useAuth();

  const signed = data && Object.values(data).length > 1;

  const loadCategories = useCallback(async () => {
    const response = await apiAuth.get("/listacategorias_produtos");
    const categories = response.data.data;

    localStorage.setItem("@PlayDrop:categorias", JSON.stringify(categories));

    const storedData = localStorage.getItem("@PlayDrop:categorias");

    if (storedData) {
      setCategoryData(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    signed && loadCategories();

    // eslint-disable-next-line
  }, [signed]);

  return (
    <CategoriesContextClient.Provider value={{ categoryData, loadCategories }}>
      {children}
    </CategoriesContextClient.Provider>
  );
};
