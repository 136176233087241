import styled from "styled-components";
import ReactInputMask from "react-input-mask";

export const StyledInputMask = styled(ReactInputMask)`
  width: 100%;
  height: 50px;
  padding: 15px 10px;

  border: ${({ errors }) =>
    errors ? "2px solid #fb6b03" : "2px solid #3058a4"};

  font-size: 1rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .username {
    width: 100%;
    margin-top: -2rem;

    display: flex;
    align-items: center;
    gap: 1rem;

    .first-letter {
      width: 5rem;
      height: 5rem;
      padding: 0.5rem;

      box-shadow: 0px 5px 10px -2px #3058a4;
      border-radius: 50%;
      background-color: #fff;

      display: flex;
      align-items: center;
      justify-content: center;

      .first-letter__content {
        p {
          color: #4f4f4f;
          font-size: 2rem;
          font-weight: 700;
        }
      }
    }

    h4 {
      margin-top: 1rem;

      color: #4f4f4f;
      font-size: 1.5rem;
    }
  }

  .to-config {
    width: 100%;
    margin-top: 1rem;

    font-size: 1.25rem;
    font-weight: 500;

    text-align: center;

    &:hover {
      color: #3058a4;
      transition: ease-in 0.2s;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;

    .username {
      margin-top: -1rem;
    }
    .to-config {
      margin-top: 0;
      text-align: end;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h4 {
    margin: 0;

    color: #4f4f4f;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .update-profile {
    width: 100%;
    padding: 1.25rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    box-shadow: 0px 5px 10px -1px #3058a1a6;
    border-radius: 10px;
    background-color: #fff;

    position: relative;

    .flex {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .info-plan {
    width: 100%;
    padding: 1.25rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    box-shadow: 0px 5px 10px -1px #3058a1a6;
    border-radius: 10px;
    background-color: #fff;

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      .plan-exists {
        color: #3058a4;
        font-size: 1.5rem;
        font-weight: 500;
      }

      .without-plan {
        color: #4f4f4f;
        font-size: 1.1rem;
      }

      .choice {
        color: #4f4f4f;
        font-weight: 500;

        &:hover {
          color: #3058a4;
          transition: ease-in 0.2s;
        }
      }
    }
  }
`;

export const UpdateProfileForm = styled.form`
  width: 100%;
  padding: 1.25rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  border-radius: 10px;
  background-color: #fff;

  position: relative;

  label {
    margin: 0;

    color: #3058a4;
    font-size: 1.125rem;
    font-weight: 500;
  }

  h4 {
    color: #3058a4;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
  }
`;
