import { useAuth } from "../../contexts/Auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Input } from "@chakra-ui/react";
import { Button } from "../../components/Button";
import { Spinner } from "../../components/Spinner";
import { FeedbackCard } from "../../components/Modal/Users/Client/FeedbackCard";
import { StyledInputMask, UpdateProfileForm } from "./styles";
import { updateProfileYup } from "../../schemas/schemas";

export const UpdateProfileContent = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const { data, updateProfile } = useAuth();

  const signed = data && Object.values(data).length > 0;

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(updateProfileYup),
  });

  useEffect(() => {
    if (signed) {
      setValue("nome", data.nome);
      setValue("contato", data.contato);
      setValue("email", data.email);
    }
  }, [signed, data, setValue]);

  const handleUpdateUser = (userData) => {
    updateProfile(userData, setLoading, data.id_planos, onClose);
  };

  return (
    <UpdateProfileForm onSubmit={handleSubmit(handleUpdateUser)}>
      {data.contato ? (
        <FeedbackCard feedback="positive" />
      ) : (
        <FeedbackCard feedback="negative" />
      )}

      <h4>Atualize seus dados de perfil</h4>

      <div>
        <label htmlFor="name">Nome</label>
        <Input
          w="100%"
          h="50px"
          id="nome"
          mt="0"
          color="#4f4f4f"
          borderWidth="2px"
          placeholder="Nome"
          borderRadius="2px"
          borderColor="#3058a4"
          {...register("nome")}
          isInvalid={!!errors.nome}
        />
      </div>

      <div>
        <label htmlFor="contato">Contato</label>
        <Controller
          control={control}
          name="contato"
          render={({ field }) => (
            <StyledInputMask
              {...field}
              id="contato"
              type="text"
              mask="(99) 9 9999-9999"
              maskChar=""
              isInvalid={!!errors.contato}
              placeholder="Contato"
              alwaysShowMask={false}
            />
          )}
        />
      </div>

      <div>
        <label htmlFor="email">E-mail</label>
        <Input
          w="100%"
          h="50px"
          id="email"
          color="#4f4f4f"
          borderWidth="2px"
          borderRadius="2px"
          placeholder="E-mail"
          borderColor="#3058a4"
          {...register("email")}
          isInvalid={!!errors.email}
        />
      </div>

      <Button type="submit">{loading ? <Spinner /> : "Salvar Dados"}</Button>
    </UpdateProfileForm>
  );
};
