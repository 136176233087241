import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiAuth } from "../../services/api";
import { useState } from "react";
import { useProductsClient } from "../../contexts/Products/ProductsContextClient";
import { Button, useToast } from "@chakra-ui/react";

export const ContentImageDetails = ({ setShowEditImage, productId }) => {
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const { productDetails, loadProductDetails } = useProductsClient();

  const imageSchema = yup.object().shape({
    foto: yup.mixed().required("Uma imagem é requerida"),
  });

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(imageSchema),
  });

  const toast = useToast();

  const handleAddNewImage = async (data) => {
    setLoading(true);

    try {
      await apiAuth
        .post(
          `/atualizar-img-produto?produto_id=${productDetails.produto_id}`,
          { foto: data.foto[0] },
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((_) => {
          loadProductDetails(productId);

          toast({
            title: "Sucesso",
            description: "Imagem atualizada com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });

          setLoading(false);
          setShowEditImage(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    let file = e.target.files[0];
    if (!file) return;

    // Verifica o tamanho do arquivo
    const fileSize = file.size / 1024; // Tamanho em KB
    if (fileSize > 2048) {
      alert("O arquivo é muito grande. Máximo permitido é 2MB.");
      return;
    }

    setIsUploading(fileSize > 700);
    if (fileSize > 700) {
      // Simula um progresso de upload
      for (let i = 1; i <= 100; i++) {
        setTimeout(() => setUploadProgress(i), 10 * i);
      }
    }

    let reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
      setIsUploading(false);
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="content-image">
      <form onSubmit={handleSubmit(handleAddNewImage)}>
        <label className="carregar_foto" htmlFor="carregarFoto">
          Carregar foto
        </label>
        <input
          type="file"
          id="carregarFoto"
          {...register("foto")}
          onChange={handleImageChange}
        />
        {isUploading && <div>Carregando... {uploadProgress}%</div>}
        {imagePreviewUrl && (
          <>
            <img
              src={imagePreviewUrl}
              alt="Preview"
              style={{
                width: "100%",
                height: "220px",
                marginTop: "-24px",
              }}
            />
            <Button
              w="100%"
              p="10px"
              bg="#3058a4"
              mt="0.5rem"
              type="submit"
              color="#fff"
              fontWeight="500"
              borderRadius="10px"
              _hover={{ opacity: 0.8 }}
              isLoading={loading}
            >
              Salvar Imagem
            </Button>
          </>
        )}
      </form>
    </div>
  );
};
