import { Input } from "../Reusable/Input";
import { FormAuth } from "./styles";

import imageUnlock from "../../assets/icons/unlock.png";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { apiAuth } from "../../services/api";
import { MdOutlineMail } from "react-icons/md";
import { useForm } from "react-hook-form";

export const RecoverForm = () => {
  const recoverySchema = yup.object().shape({
    email: yup
      .string()
      .required("Por favor, digite o seu e-mail")
      .email("Formato de e-mail inválido"),
  });

  const toast = useToast();
  const navigate = useNavigate();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(recoverySchema),
  });

  const handleRecovery = async (data) => {
    const email = data.email;

    await apiAuth
      .post(
        "/recuperasenha",
        { email: email },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((_) => {
        toast({
          title: "Sucesso...",
          description:
            "Solicitação de senha realizada com sucesso, verifique caixa de entrada do seu e-mail para o próximo passo!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((_) => {
        toast({
          title: "Opss...",
          description: "Algo deu errado!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });

    reset();
  };

  return (
    <FormAuth onSubmit={handleSubmit(handleRecovery)}>
      <div className="recovery-title">
        <img
          src={imageUnlock}
          alt="Ícone de cadeado fechado"
          className="icon-unlock"
        />
        <p>
          Informe seu <b>e-mail utilizado na plataforma</b> para iniciarmos a
          criação da nova senha.
        </p>
      </div>
      <Input
        type="text"
        label="E-mail"
        error={errors.email}
        icon={MdOutlineMail}
        {...register("email")}
      />
      <Button
        w="100%"
        h="60px"
        type="submit"
        border="none"
        fontWeight="500"
        color="#ffffff"
        lineHeight="1rem"
        borderRadius="4px"
        fontSize="1.25rem"
        textAlign="center"
        fontFamily="Poppins"
        _hover={{ opacity: 0.8 }}
        m={["1rem auto", "1rem auto", "1rem 0"]}
        bg="linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%)"
      >
        Enviar nova senha
      </Button>
      <Text
        mt="4"
        mb="6"
        fontSize="1rem"
        cursor="pointer"
        fontWeight="500"
        textAlign="center"
        _hover={{ color: "#FB6B03" }}
        onClick={() => navigate("/", { replace: true })}
      >
        Retorne para Login
      </Text>
    </FormAuth>
  );
};
