import styled from "styled-components";

export const ClassesContent = styled.ul`
  width: 100%;
  height: 200px;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border: 0;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #3059a4;
    border-radius: 24px;
  }

  .button {
    width: 100%;

    button {
      width: 100%;
      height: 50px;

      color: #fff;
      border-radius: 10px;
      background-color: #fb6b03;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  li {
    width: 100%;
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    box-shadow: 0px 8px 5px -2px #3058a4;
    border-radius: 10px;
    background-color: #fff;

    .icons {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`;
