import { FaEdit, FaTimes } from "react-icons/fa";
import { ModalCrudCategory } from "../../../components/Modal/Categories/ModalCrudCategory";
import { useCategoriesAdmin } from "../../../contexts/Categories/CategoriesContextAdmin";
import { Center, useDisclosure } from "@chakra-ui/react";

export const CardCategory = ({ categoryData }) => {
  const { deleteCategory } = useCategoriesAdmin();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <div className="main">
        <li className="content__nome">{categoryData.tipo_produto}</li>
        <li className="content__acoes">
          <Center
            w="2rem"
            h="2rem"
            mr="6px"
            as="button"
            bg="#3058A4"
            borderRadius="6px"
            onClick={() => onOpen()}
          >
            <FaEdit fill="#ffffff" />
          </Center>
          <Center
            w="2rem"
            h="2rem"
            as="button"
            bg="#FB6B03"
            borderRadius="6px"
            onClick={() => deleteCategory(categoryData.id)}
          >
            <FaTimes fill="#ffffff" />
          </Center>
        </li>
      </div>
      <ModalCrudCategory
        isOpen={isOpen}
        onClose={onClose}
        verbType="update"
        category={categoryData}
      />
    </>
  );
};
