import { useState } from "react";
import { ContainerInput } from "./styles";

export const CustomSelect = ({ selections, setSelections }) => {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
      if (inputValue.trim() !== "") {
        setSelections([...selections, inputValue.trim()]);
        setInputValue("");
      }
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleRemoveSelection = (indexToRemove) => {
    setSelections(selections.filter((_, index) => index !== indexToRemove));
  };

  return (
    <ContainerInput>
      <label htmlFor="select-input">
        Digite palavras-chaves que não podem faltar sobre o produto
      </label>

      <div className="content-input">
        <input
          id="select-input"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Digite e clique em TAB ou ENTER"
        />
      </div>

      <ul className="selections">
        {selections.map((selection, index) => (
          <li key={index} onClick={() => handleRemoveSelection(index)}>
            {selection}
          </li>
        ))}
      </ul>
    </ContainerInput>
  );
};
