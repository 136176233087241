import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import { Input, VStack, Heading, Button } from "@chakra-ui/react";

import { useAuth } from "../../../contexts/Auth";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { updateProfileYup } from "../../../schemas/schemas";

import { FaUser } from "react-icons/fa";

export const ModalUpdateProfile = ({ isOpen, onClose, userData }) => {
  const { updateProfileAdmin } = useAuth();

  const {
    // formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(updateProfileYup),
  });

  const handleUpdateUser = (data) => {
    updateProfileAdmin(data, userData.id);
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={FaUser} color="#fff" />
            <ModalHeader.Title title="Edição de Perfil" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <Heading
            mt="4"
            mb="4"
            color="#3058A5"
            fontSize="1.5rem"
            textAlign="center"
          >
            Digite os dados para edição do usuário
          </Heading>
          <VStack
            as="form"
            spacing="4"
            onSubmit={handleSubmit(handleUpdateUser)}
          >
            <Input
              w="100%"
              h="50px"
              color="#4f4f4f"
              borderWidth="2px"
              borderRadius="2px"
              borderColor="#3058a4"
              {...register("usuario")}
              placeholder="Nome de usuário"
              defaultValue={userData.usuario}
            />
            <Input
              w="100%"
              h="50px"
              color="#4f4f4f"
              borderWidth="2px"
              borderRadius="2px"
              placeholder="E-mail"
              borderColor="#3058a4"
              {...register("email")}
              defaultValue={userData.email}
            />
            <Input
              w="100%"
              h="50px"
              color="#4f4f4f"
              borderWidth="2px"
              placeholder="Nome"
              borderRadius="2px"
              borderColor="#3058a4"
              {...register("nome")}
              defaultValue={userData.nome}
            />
            <Input
              w="100%"
              h="50px"
              color="#4f4f4f"
              borderWidth="2px"
              placeholder="Contato"
              borderRadius="2px"
              borderColor="#3058a4"
              {...register("contato")}
              defaultValue={userData.contato}
            />
            <Input
              w="100%"
              h="50px"
              color="#4f4f4f"
              placeholder="CEP"
              borderWidth="2px"
              borderRadius="2px"
              {...register("cep")}
              borderColor="#3058a4"
              defaultValue={userData.cep}
            />
            <Input
              w="100%"
              h="50px"
              color="#4f4f4f"
              borderWidth="2px"
              borderRadius="2px"
              {...register("rua")}
              borderColor="#3058a4"
              placeholder="Endereço"
              defaultValue={userData.rua}
            />
            <Input
              w="100%"
              h="50px"
              color="#4f4f4f"
              borderWidth="2px"
              borderRadius="2px"
              borderColor="#3058a4"
              placeholder="Número da casa"
              {...register("numero_residencia")}
              defaultValue={userData.numero_residencia}
            />
            <Input
              w="100%"
              h="50px"
              color="#4f4f4f"
              borderWidth="2px"
              borderRadius="2px"
              borderColor="#3058a4"
              placeholder="Complemento"
              {...register("complemento")}
              defaultValue={userData.complemento}
            />
            <Input
              w="100%"
              h="50px"
              color="#4f4f4f"
              borderWidth="2px"
              borderRadius="2px"
              placeholder="Bairro"
              borderColor="#3058a4"
              {...register("bairro")}
              defaultValue={userData.bairro}
            />
            <Input
              w="100%"
              h="50px"
              color="#4f4f4f"
              borderWidth="2px"
              borderRadius="2px"
              placeholder="Estado"
              borderColor="#3058a4"
              {...register("estado")}
              defaultValue={userData.estado}
            />
            <Input
              w="100%"
              h="50px"
              color="#4f4f4f"
              borderWidth="2px"
              borderRadius="2px"
              placeholder="Cidade"
              borderColor="#3058a4"
              {...register("cidade")}
              defaultValue={userData.cidade}
            />
            <Button
              w="100%"
              h="60px"
              type="submit"
              border="none"
              fontWeight="500"
              color="#ffffff"
              lineHeight="1rem"
              borderRadius="4px"
              fontSize="1.25rem"
              textAlign="center"
              fontFamily="Poppins"
              _hover={{ opacity: 0.8 }}
              m={["1rem auto", "1rem auto", "1rem 0"]}
              bg="linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%)"
            >
              Salvar dados
            </Button>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
