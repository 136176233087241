import { Flex, Skeleton } from "@chakra-ui/react";

export const SkeletonConveyor = ({ quantityParam }) => {
  const quantity = Array.from({ length: quantityParam });

  return (
    <Flex alignItems="center" gap="1.5rem">
      {quantity.map((_, index) => (
        <Skeleton key={index} h="16rem" w="20rem" />
      ))}
    </Flex>
  );
};
