import * as NG from "./styles";

import { v4 as uuidv4 } from "uuid";

import { Title } from "../../components/Reusable/Title";
import { Input } from "../../components/Reusable/Input";
import { Button, useDisclosure } from "@chakra-ui/react";
import { Container } from "../../components/Reusable/Container";
import { dataOptions } from "./dataOptions";
import { StoreNameCard } from "./StoreNameCard";

import { TbWorld } from "react-icons/tb";

import { useCopyGenerator } from "../../hooks/useCopyGenerator";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";
import { useCredits } from "../../contexts/Credits";
import { FiArrowUpRight } from "react-icons/fi";
import { ModalBlockUser } from "../../components/Modal/Products/ModalBlockUser";

export const NameGenerator = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [storeNames, setStoreNames] = useState([]);

  const { name } = useParams();
  const { data } = useAuth();
  const { credits, discountCoinWallet } = useCredits();

  const signed = data && Object.values(data).length > 0;

  const isBasicPlan = signed && data.id_planos === 4;

  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const filteredCreditsName =
    credits.length > 0 &&
    credits?.find((item) => item.category === "gerar_nomes");

  const [step, setStep] = useState("first-step");
  const [info, setInfo] = useState({
    storeName: name !== "" ? name : "",
    firstStep: [],
    segment: "",
    personality: "",
  });

  const { storedValues, generateResponse } = useCopyGenerator(setLoading);

  const handleCheckboxChange = (name) => {
    setInfo((prev) => {
      // Verifica se já existe na lista
      if (prev.firstStep.includes(name)) {
        // Remove o item do array se já existir
        return {
          ...prev,
          firstStep: prev.firstStep.filter((item) => item !== name),
        };
      } else {
        // Adiciona o item ao array se não existir
        return {
          ...prev,
          firstStep: [...prev.firstStep, name],
        };
      }
    });
  };

  const handleGenerateNames = () => {
    if (isBasicPlan) {
      const idName = uuidv4().slice(0, 5);

      if (filteredCreditsName.creditos <= 0) {
        onOpen();
      }

      if (filteredCreditsName.creditos > 0) {
        const firstStepOrganized = info.firstStep.join(", ");

        setLoading(true);

        const question = `Quero que me dê 20 ideias de nomes para eu colocar em minha empresa, 
    um ecommerce, o nome da loja não pode ser grande e não pode conter as seguintes palavras: 
    LOJA, SHOP, EXPRESS, ECOMMERCE, ONLINE. Eu quero o nome da loja da seguinte forma: que tenha a 
    palavra: ${info.storeName}; O nome deve 
    conter: ${firstStepOrganized}; Minha loja atuará no 
    segmento: ${info.segment}; ${
          info.personality !== ""
            ? `Minha loja tem a seguinte 
    personaldiade: ${info.personality}`
            : ""
        }; Quero que me retorne o resultado em formato de Array de String Javascript`;

        generateResponse(question)
          .then((_) => setLoading(false))
          .catch((_) => setLoading(false));
      }

      discountCoinWallet(filteredCreditsName.id, 5, idName, "nome");
    } else {
      const firstStepOrganized = info.firstStep.join(", ");

      setLoading(true);

      const question = `Quero que me dê 20 ideias de nomes para eu colocar em minha empresa, 
    um ecommerce, o nome da loja não pode ser grande e não pode conter as seguintes palavras: 
    LOJA, SHOP, EXPRESS, ECOMMERCE, ONLINE. Eu quero o nome da loja da seguinte forma: que tenha a 
    palavra: ${info.storeName}; O nome deve 
    conter: ${firstStepOrganized}; Minha loja atuará no 
    segmento: ${info.segment}; ${
        info.personality !== ""
          ? `Minha loja tem a seguinte 
    personaldiade: ${info.personality}`
          : ""
      }; Quero que me retorne o resultado em formato de Array de String Javascript`;

      generateResponse(question)
        .then((_) => setLoading(false))
        .catch((_) => setLoading(false));
    }
  };

  useEffect(() => {
    let lines = storedValues.answer && storedValues.answer.trim().split("\n");

    let mapNames =
      lines !== undefined &&
      lines
        .map((line) => {
          let match = line.match(/\d+\.\s*(.+)/);
          return match ? match[1] : "";
        })
        .filter((name) => name);

    setStoreNames(mapNames);
  }, [storedValues]);

  return (
    <Container>
      <Title
        headingName="Gerador de Nomes de Loja"
        caption="O gerador de nomes é uma ferramenta para facilitar a criação do nome e identidade visual de sua loja."
      >
        <TbWorld color="#3058a4" size="1.6rem" />
      </Title>

      <hr />

      <NG.Content>
        {isBasicPlan && (
          <div className="info-credits">
            {filteredCreditsName !== undefined &&
            filteredCreditsName.creditos === 0 ? (
              <>
                <p>Opps, saldo para gerar nomes acabou...</p>
                <div onClick={() => navigate("/dashboard/planos")}>
                  <FiArrowUpRight size="1.5rem" color="#3058a4" />
                </div>
              </>
            ) : (
              <p>
                Você pode gerar nomes
                <span>
                  {filteredCreditsName && filteredCreditsName.creditos}
                </span>
                vezes
              </p>
            )}
          </div>
        )}

        {step === "first-step" ? (
          <NG.FormContent>
            <Input
              label="Digite a palavra que não pode faltar na sua loja"
              placeholder="Escreva aqui..."
              onChange={(ev) =>
                setInfo({ ...info, storeName: ev.target.value })
              }
              defaultValue={name !== "" ? name : ""}
            />

            {error === "empty-field" && (
              <span className="error">O campo acima não pode ser vazio</span>
            )}

            <Button
              w="240px"
              h="50px"
              m="1rem auto 0 auto"
              bg="linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%)"
              color="#fff"
              display="block"
              _hover={{ opacity: 0.8 }}
              onClick={() => {
                if (info.storeName !== "") {
                  setStep("second-step");
                  setError("");
                } else {
                  setError("empty-field");
                }
              }}
            >
              Gerar Nome
            </Button>
          </NG.FormContent>
        ) : null}

        {step === "second-step" ||
        step === "third-step" ||
        step === "fourth-step" ? (
          <NG.FormContent>
            <div className="flex-content">
              <Button
                w="240px"
                h="50px"
                m="1rem auto 0 auto"
                bg={
                  step === "second-step"
                    ? "linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%)"
                    : "#fff"
                }
                color={step === "second-step" ? "#fff" : "#3058a4"}
                border={step !== "second-step" && "2px solid #3058a4"}
                _hover={{ opacity: 0.8 }}
                onClick={() => setStep("second-step")}
              >
                Estilos de nome
              </Button>
              <Button
                w="240px"
                h="50px"
                m="1rem auto 0 auto"
                bg={
                  step === "third-step"
                    ? "linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%)"
                    : "#fff"
                }
                color={step === "third-step" ? "#fff" : "#3058a4"}
                border={step !== "third-step" && "2px solid #3058a4"}
                _hover={{ opacity: 0.8 }}
                onClick={() => setStep("third-step")}
              >
                Palavras-Chave
              </Button>
              <Button
                w="240px"
                h="50px"
                m="1rem auto 0 auto"
                bg={
                  step === "fourth-step"
                    ? "linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%)"
                    : "#fff"
                }
                color={step === "fourth-step" ? "#fff" : "#3058a4"}
                border={step !== "fourth-step" && "2px solid #3058a4"}
                _hover={{ opacity: 0.8 }}
              >
                Nomes Gerados
              </Button>
            </div>
          </NG.FormContent>
        ) : null}

        {step === "second-step" ? (
          <NG.FormContent>
            <div className="content-options">
              {dataOptions.map((item) => (
                <label htmlFor={item.title} key={item.title}>
                  <input
                    type="checkbox"
                    id={item.title}
                    value={item.title}
                    onChange={() => handleCheckboxChange(item.title)}
                    checked={info.firstStep.includes(item.title)}
                  />
                  <span>
                    <p className="title">{item.title}</p>
                    <p>{item.subtitle}</p>
                  </span>
                </label>
              ))}
            </div>

            {error === "empty-field-second" && (
              <span className="error">
                É importante selecionar ao menos 1 campo
              </span>
            )}

            <Button
              w="240px"
              h="50px"
              m="1rem auto 0 auto"
              bg="linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%)"
              color="#fff"
              display="block"
              _hover={{ opacity: 0.8 }}
              onClick={() => {
                if (info.firstStep.length > 0) {
                  setStep("third-step");
                  setError("");
                } else {
                  setError("empty-field-second");
                }
              }}
            >
              Próximo
            </Button>
          </NG.FormContent>
        ) : null}

        {step === "third-step" && <hr />}

        {step === "third-step" ? (
          <NG.FormContent>
            <div className="third-step-content">
              <Input
                label="Adicione quaisquer palavras-chave que possam estar relacionada a sua ideia de negócio"
                placeholder="Ex: Loja de Dropshipping"
                onChange={(ev) =>
                  setInfo({ ...info, segment: ev.target.value })
                }
              />

              <Input
                label="Agora adicione uma breve descrição de uma frase sobre a sua empresa ou produto"
                placeholder="Descrição da empresa (opcional)"
                onChange={(ev) =>
                  setInfo({ ...info, personality: ev.target.value })
                }
              />

              <Button
                w="240px"
                h="50px"
                m="1rem auto 0 auto"
                bg="linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%)"
                color="#fff"
                display="block"
                _hover={{ opacity: 0.8 }}
                isLoading={loading}
                onClick={() => {
                  setTimeout(() => {
                    setStep("fourth-step");
                    handleGenerateNames();
                  }, 2000);
                }}
              >
                Gerar nomes
              </Button>
            </div>
          </NG.FormContent>
        ) : null}

        {step === "fourth-step" ? (
          <div className="result-names">
            {storeNames.length > 0 ? (
              storeNames.map((name, index) => (
                <StoreNameCard key={index} name={name} />
              ))
            ) : (
              <div className="loading">
                <p>Gerando nomes...</p>
              </div>
            )}
          </div>
        ) : null}
      </NG.Content>

      <ModalBlockUser
        isOpen={isOpen}
        onClose={onClose}
        title="Ops, infelizmente, você não possui créditos suficientes para visualizar esta funcionalidade"
        text="Altere agora o seu plano para conquistar imediatamente acesso ILIMITADO."
      />
    </Container>
  );
};
