import { apiAuth } from "../../../services/api";
import { useAuth } from "../../Auth";
import { useToast } from "@chakra-ui/react";
import { createContext, useContext, useEffect, useState } from "react";

const WebsitesContextClient = createContext({});

export const useWebsitesClient = () => {
  const context = useContext(WebsitesContextClient);

  if (!context) {
    throw new Error(
      "useWebsitesClient must be used within a WebsitesProviderClient"
    );
  }

  return context;
};

export const WebsitesProviderClient = ({ children }) => {
  const [websitesUser, setWebsitesUser] = useState([]);

  const toast = useToast();

  const { data } = useAuth();

  const loadWebsitesUser = async () => {
    const response = await apiAuth.get(`/listarsites?id=${data.id}`);

    if (!response.data.erro) {
      const userStores = response.data.data;

      localStorage.setItem("@PlayDrop:websites", JSON.stringify(userStores));

      const storedData = localStorage.getItem("@PlayDrop:websites");

      if (storedData) {
        setWebsitesUser(JSON.parse(storedData));
      }
    } else {
      setWebsitesUser([]);
    }
  };

  useEffect(() => {
    loadWebsitesUser();

    // eslint-disable-next-line
  }, []);

  // Criação de um novo site (Client Side)
  const createWebsite = async (siteData) => {
    const currentDate = new Date();
    // const newDate = new Date();
    // const dueDate = new Date(newDate.setDate(newDate.getDate() + 30));

    // Informações padrão
    const body = {
      nomesite: siteData.storeName,
      dominio: siteData.domain,
      site_id: data.id,
      id_login: data.id,
      categoria: "padrão",
      data_inicio: currentDate.toLocaleDateString(),
      modalidade: "Loja Virtual",
      status_site: "progress",
      token_shopify: siteData.token,
    };

    // Requisição
    try {
      await apiAuth
        .post("/cadastrosites", body, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((_) => {
          loadWebsitesUser();

          toast({
            title: "Sucesso",
            description: "Sua loja foi criada com sucesso!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((_) => {
          toast({
            title: "Opss...",
            description: "Algo deu errado ao criar site!",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const importProductToShopify = async (productId, storeId, edit = false) => {
    const body = {
      id_fornecedores: productId,
      id_site: storeId,
    };

    if (!edit) {
      await apiAuth
        .post("/exportar-para-shopify", body, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          toast({
            title: "Sucesso",
            description: "Produto importado com sucesso para sua loja",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((error) => {
          toast({
            title: "Opss...",
            description:
              "Algo deu errado, por gentileza contate o administrador!",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    } else {
      await apiAuth
        .post(
          "/exportar-para-shopify",
          { ...body, edit: true },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((res) => {
          console.log(res);

          toast({
            title: "Sucesso",
            description: "Produto atualizado com sucesso para Shopify",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((error) => {
          toast({
            title: "Opss...",
            description:
              "Algo deu errado, por gentileza contate o administrador!",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <WebsitesContextClient.Provider
      value={{
        websitesUser,
        createWebsite,
        loadWebsitesUser,
        importProductToShopify,
      }}
    >
      {children}
    </WebsitesContextClient.Provider>
  );
};
