import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import { ProductContentConveyor } from "../../../pages/ProductConveyor/ProductContentConveyor";

import { BiStoreAlt } from "react-icons/bi";

export const ModalConveyorDetails = ({ isOpen, onClose, product }) => {
  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="80rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BiStoreAlt} color="#fff" />
            <ModalHeader.Title title="Detalhes do produto" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>

        <Modal.Body>
          <ProductContentConveyor
            product={product}
            isOpen={isOpen}
            onClose={onClose}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
