import "./styles.css";

import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";

import { BiCube } from "react-icons/bi";
import { FaShopLock } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export const ModalUpdatePlan = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="450px" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BiCube} color="#fff" />
            <ModalHeader.Title title="Assistir aula" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <div className="free-plan-content">
            <div>
              <FaShopLock fill="#fb6b03" size="3rem" />
              <h4>Faça um upgrade para assistir a essa aula</h4>
            </div>

            <p>
              Oops, para assistir a esse conteúdo exclusivo, você precisa fazer
              um Upgrade
            </p>

            <button
              className="button-block-content"
              onClick={() => navigate("/dashboard/planos")}
            >
              Atualizar o meu plano
            </button>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
