import * as yup from "yup";

import { Button, Grid, Heading, Input, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useProductsAdmin } from "../../../contexts/Products/ProductsContextAdmin";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiAuth } from "../../../services/api";
import { ModalHeader } from "../ModalHeader";
import { Modal } from "../index";
import { BiStoreAlt } from "react-icons/bi";

export const UpdateImageAdmin = ({ isOpen, onClose, productId }) => {
  const [loading, setLoading] = useState(false);

  const { loadAllProducts } = useProductsAdmin();

  const toast = useToast();

  const imageSchema = yup.object().shape({
    imagem: yup.mixed().required("Uma imagem é requerida"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(imageSchema),
  });

  const handleUpdateImage = async (data) => {
    setLoading(true);

    try {
      await apiAuth
        .post(
          `/atualizar-img-fornecedores?id_fornecedores=${productId}`,
          { foto: data.imagem[0] },
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((_) => {
          loadAllProducts();

          toast({
            title: "Sucesso",
            description: "Imagem atualizada com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });

          setLoading(false);

          onClose();
        });
    } catch (error) {
      console.log(error);

      toast({
        title: "Erro",
        description: "Algo deu errado ao atualizar imagem!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });

      setLoading(false);
    }
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BiStoreAlt} color="#fff" />
            <ModalHeader.Title title="Atualizar imagem" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <Heading mt="4" color="#3058A5" fontSize="1.4rem" textAlign="center">
            Atualizar imagem do produto
          </Heading>

          <Grid as="form" mt="2rem" onSubmit={handleSubmit(handleUpdateImage)}>
            <Input type="file" {...register("imagem")} />

            <Button
              h="50px"
              w="200px"
              mt="1rem"
              mb="1rem"
              bg="#3058A4"
              type="submit"
              color="#ffffff"
              fontFamily="Poppins"
              isLoading={loading}
              _hover={{ opacity: 0.7 }}
            >
              Atualizar imagem
            </Button>
          </Grid>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
