import styled from "styled-components";

export const Container = styled.header`
  background-color: #fff;

  width: 100%;
  height: 6.25rem;
  margin: 0 auto 3rem auto;
  padding: 1.5rem 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .plan-basic {
    width: 300px;
    height: 100%;
    padding: 10px;

    display: flex;
    align-items: center;
    gap: 1rem;

    box-shadow: 0 12px 9px -1.7px #e6eeff;
    border-radius: 10px;
    background-color: #fff;

    cursor: pointer;

    span {
      width: 100%;
      color: #fb8a03;
      font-size: 1rem;
      text-align: center;
      font-weight: 500;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  @media (min-width: 1048px) {
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
  }
`;

export const ContentMobile = styled.div`
  position: relative;

  @media (max-width: 1048px) {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const MenuIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #3058a4;
  border-radius: 0.5rem;

  @media (min-width: 1048px) {
    display: none;
  }
`;

export const ContentLogo = styled.div``;

export const ContentIcons = styled.nav`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  .faq {
    p {
      display: none;
    }

    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.2rem;

      p {
        display: flex;
        color: #3058a4;
        font-size: 1.2rem;
      }

      &:hover {
        svg {
          fill: #fb6b03;
        }
        p {
          color: #fb6b03;
          text-decoration: underline;
        }
      }
    }
  }

  .drawer-icon {
    display: none;

    @media (min-width: 1048px) {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 3rem;
      height: 3rem;

      border-radius: 50%;
      background-color: #fff;

      cursor: pointer;

      box-shadow: 0px 10px 11.0753px -2.0137px #3058a1;

      &:hover {
        box-shadow: 0px 5px 11.0753px -2.0137px #fb6b03;
      }
    }
  }
`;

export const Icon = styled.div`
  cursor: pointer;

  .credits-content {
    width: 90px;
    padding: 0.2rem 0.3rem;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;

    cursor: pointer;

    background-color: #fff;

    .credits {
      color: #daa520;
      font-size: 1.5rem;
      font-weight: 500;
    }

    &:hover {
      opacity: 0.8;
      svg {
        fill: #daa520;
      }
    }
  }

  &:hover {
    svg {
      fill: #fb6b03;
    }
  }
`;

export const LinkContent = styled.div`
  width: 100%;
  border: 1px solid #3058a5;
  padding: 1rem;
  text-align: center;
  margin: 1rem 0 2rem 0;

  &:hover {
    background-color: #3058a5;
    cursor: pointer;

    a {
      color: #fff;
    }
  }
  a {
    color: #3058a4;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 14px;

    transition: ease-in 0.4s;
  }

  @media (min-width: 768px) {
    width: 310px;
    font-size: 1rem;
  }
`;

export const DrawerCoinContent = styled.div`
  width: 300px;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  box-shadow: 0px 10px 11.0753px -2.0137px #3058a4;
  border-radius: 10px;
  background-color: #fff;

  position: absolute;
  top: 2.5rem;
  left: -13rem;
  z-index: 10;

  h4 {
    color: #3058a4;
    font-size: 1.125rem;
    font-weight: 500;
  }
`;
