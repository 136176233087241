import {
  Button,
  Flex,
  Grid,
  Heading,
  Text,
  useToast,
  Spinner,
} from "@chakra-ui/react";

import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";

import { BiStoreAlt } from "react-icons/bi";
import { useCopyGenerator } from "../../../hooks/useCopyGenerator";
import { useEffect, useState } from "react";

export const ModalPersona = ({ isOpen, onClose, name }) => {
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const { storedValues, generateResponse } = useCopyGenerator(setLoading);

  const handleGenerateNewPersona = (productName) => {
    const question = `Me informe qual a melhor persona, tipo de cliente ideal, para eu vender o produto: ${productName}`;

    generateResponse(question);
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (isOpen) {
      setLoading(true);

      setTimeout(() => {
        handleGenerateNewPersona(name);

        setLoading(false);
      }, 2000);
    }

    // eslint-disable-next-line
  }, [name, isOpen]);

  const copyText = (text) => {
    navigator.clipboard.writeText(text);

    toast({
      title: "Copiado",
      description: "Copiado para área de transferência!",
      status: "success",
      duration: 2500,
      isClosable: true,
    });
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BiStoreAlt} color="#fff" />
            <ModalHeader.Title title="Persona" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <Grid h="500px">
            <Heading
              textAlign="center"
              fontSize="1.25rem"
              color="#3058a4"
              fontWeight="500"
            >
              Bem vindo(a) ao gerador de Persona
            </Heading>

            <Grid w="100%">
              {loading ? (
                <Grid
                  w="100%"
                  h="100%"
                  display="flex"
                  flexDir="column"
                  alignItems="center"
                  gap="1.5rem"
                >
                  <Heading
                    as="h4"
                    color="#3058a4"
                    fontSize="1.25rem"
                    textAlign="center"
                    fontWeight="500"
                  >
                    {loading
                      ? "🤯 Gerando uma persona especial para você 🤯"
                      : "Persona Gerada:"}
                  </Heading>

                  <Spinner color="#3058a4" size="xl" />
                </Grid>
              ) : (
                <Grid
                  w="100%"
                  h="100%"
                  display="flex"
                  flexDir="column"
                  alignItems="center"
                  gap="1.5rem"
                >
                  <Heading
                    as="h4"
                    color="#3058a4"
                    fontSize="1.25rem"
                    textAlign="center"
                    fontWeight="500"
                  >
                    Persona Gerada:
                  </Heading>
                </Grid>
              )}

              {!loading && Object.values(storedValues).length > 0 ? (
                <Grid>
                  <Grid
                    w="100%"
                    h="300px"
                    mb="1rem"
                    pr="1rem"
                    textAlign="justify"
                    overflowX="hidden"
                    overflowY="scroll"
                  >
                    <Text color="#4f4f4f">{storedValues.answer}</Text>
                  </Grid>

                  <Flex
                    w="100%"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Button
                      bg="#3058a4"
                      color="#fff"
                      _hover={{ opacity: 0.8 }}
                      onClick={() => copyText(storedValues.answer)}
                    >
                      Copiar Texto
                    </Button>
                    <Button
                      bg="#3058a4"
                      color="#fff"
                      isLoading={loading}
                      _hover={{ opacity: 0.8 }}
                      onClick={() => handleGenerateNewPersona(name)}
                    >
                      Gerar nova persona
                    </Button>
                  </Flex>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
