import { useState } from "react";

import axios from "axios";

export const useCopyGenerator = (setLoading) => {
  const [storedValues, setStoredValues] = useState({});

  const client = axios.create({
    baseURL: "https://api.openai.com/v1",
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      "Content-Type": "application/json",
    },
  });

  const generateResponse = async (question) => {
    const params = {
      model: "gpt-3.5-turbo-instruct",
      prompt: question,
      max_tokens: 450,
      temperature: 0.5,
    };

    try {
      const response = await client.post("/completions", params);

      if (response.data.choices) {
        setStoredValues({
          question: question,
          answer: response.data.choices[0].text,
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return {
    storedValues,
    generateResponse,
  };
};
