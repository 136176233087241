import { Link } from "react-router-dom";

import { SignInForm } from "../../components/Forms/SignInForm";
import { LinkContent } from "../../components/Header/styles";
import { PlaydropLogo } from "../../components/Reusable/PlayDropLogo";
import { Container, Content } from "./styles";

import qrDash from "../../assets/icons/qr_sidebar.png";

export const Login = () => {
  return (
    <Container>
      <Content>
        <PlaydropLogo colorLogo="#3058A4" heightLogo="46px" widthLogo="176px" />

        <SignInForm />

        <LinkContent>
          <Link to="/cadastro">É novo por aqui? Crie a sua conta!</Link>
        </LinkContent>

        <img src={qrDash} alt="Imagem qr code para footer" />
      </Content>
    </Container>
  );
};
