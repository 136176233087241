import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import { FaPlay } from "react-icons/fa";
import { ModalTutorialDetails } from "../../../components/Modal/Tutorials/ModalTutorialDetails";

export const ClassesCard = ({ classes, classTutorial }) => {
  const {
    isOpen: isOpenTutorialDetails,
    onOpen: onOpenTutorialDetails,
    onClose: onCloseTutorialDetails,
  } = useDisclosure();

  return (
    <Flex
      w="100%"
      p="10px"
      bg="#fff"
      gap="1rem"
      cursor="pointer"
      boxShadow="0px 8px 12px -1.7px #e6eeff"
      alignItems="center"
      borderRadius="10px"
      _hover={{ opacity: 0.8 }}
      onClick={() => onOpenTutorialDetails()}
    >
      <Flex
        w="40px"
        h="40px"
        border="2px solid #fb6b03"
        alignItems="center"
        borderRadius="50%"
        justifyContent="center"
      >
        <FaPlay size="1.5rem" color="#fb6b03" />
      </Flex>

      <Text color="#3058a4" fontSize="1.25rem" fontWeight="500">
        {classTutorial.titulo}
      </Text>

      <ModalTutorialDetails
        isOpen={isOpenTutorialDetails}
        onClose={onCloseTutorialDetails}
        classes={classes}
        classTutorial={classTutorial}
      />
    </Flex>
  );
};
