import { useState, useContext, useCallback, createContext } from "react";

import { apiAuth } from "../../services/api";
import { useToast } from "@chakra-ui/react";

const MessagesContext = createContext({});

export const useMessages = () => {
  const context = useContext(MessagesContext);

  if (!context) {
    throw new Error("useMessages must be used within a MessagesProvider");
  }

  return context;
};

export const MessagesProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const toast = useToast();

  const loadMessages = useCallback(async () => {
    const response = await apiAuth.get("/listarmensagens");

    const messages = response.data;

    localStorage.setItem("@PlayDrop:mensagens", JSON.stringify(messages));

    const storedData = localStorage.getItem("@PlayDrop:mensagens");

    if (storedData) {
      setMessages(JSON.parse(storedData));
    }
  }, []);

  const addNewMessage = async (data) => {
    const body = { ...data, mensagens_id: "4" };

    try {
      await apiAuth
        .post("/cadmensagens", body, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((_) => {
          loadMessages();
          toast({
            title: "Sucesso",
            description: "Menssagem criada com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
        })
        .catch((_) => {
          toast({
            title: "Erro",
            description: "Algo deu errado ao cadastrar menssagem!",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const editMessage = async (data, messageId) => {
    const body = { ...data, id: messageId };
    try {
      await apiAuth
        .post("/atualizamensagem", body, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((_) => {
          loadMessages();
          toast({
            title: "Sucesso",
            description: "Menssagem atualizada com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
        })
        .catch((_) => {
          toast({
            title: "Erro",
            description: "Algo deu errado ao atualizar menssagem!",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMessage = async (messageId) => {
    try {
      await apiAuth
        .get(`/deletamensagem?id=${messageId}`)
        .then((_) => {
          toast({
            title: "Sucesso",
            description: "Menssagem excluída com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadMessages();
        })
        .catch((_) => {
          toast({
            title: "Erro",
            description: "Algo deu errado ao excluir menssagem!",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MessagesContext.Provider
      value={{ messages, addNewMessage, editMessage, deleteMessage }}
    >
      {children}
    </MessagesContext.Provider>
  );
};
