import { useMediasAdmin } from "../../../contexts/SocialMedia/MediasContextAdmin";
import { FaEdit, FaTimes } from "react-icons/fa";
import { ModalCrudSocialMedia } from "../../../components/Modal/SocialMedia/ModalCrudSocialMedia";
import { Center, useDisclosure } from "@chakra-ui/react";

export const MediaCard = ({ mediaData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { deleteMedia } = useMediasAdmin();
  const srcSocialMedia =
    "https://services.playdrop.com.br/api/uploads/imgs/midias";

  return (
    <>
      <div className="main">
        <li className="content__capa">
          <img
            src={`${srcSocialMedia}/${mediaData.imagem}`}
            alt={mediaData.titulo}
          />
        </li>
        <li className="content__nome">{mediaData.titulo}</li>
        <li className="content__categoria">{mediaData.categoria}</li>
        <li className="content__data">{mediaData.data}</li>
        <li className="content__exibicao">Sim</li>
        <li className="content__acoes">
          <Center
            w="2rem"
            h="2rem"
            mr="6px"
            as="button"
            bg="#3058A4"
            borderRadius="6px"
            onClick={() => onOpen()}
          >
            <FaEdit fill="#ffffff" />
          </Center>
          <Center
            w="2rem"
            h="2rem"
            as="button"
            bg="#FB6B03"
            borderRadius="6px"
            onClick={() => deleteMedia(mediaData.id)}
          >
            <FaTimes fill="#ffffff" />
          </Center>
        </li>
      </div>
      <ModalCrudSocialMedia
        isOpen={isOpen}
        onClose={onClose}
        verbType="update"
        mediaData={mediaData}
      />
    </>
  );
};
