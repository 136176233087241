import { Modal, ModalOverlay } from "@chakra-ui/react";

export const ModalRoot = ({
  isOpen,
  onClose,
  children,
  blockScrollOnMount = false,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={blockScrollOnMount}
    >
      <ModalOverlay />
      {children}
    </Modal>
  );
};
