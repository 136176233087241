import { useEffect } from "react";
import { TargetAudienceContent } from "./styles";
import { useCopyGenerator } from "../../hooks/useCopyGenerator";
import { Spinner, useToast } from "@chakra-ui/react";
import { useState } from "react";

export const ModalTargetContent = ({ productName, isOpen }) => {
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const { storedValues, generateResponse } = useCopyGenerator(setLoading);

  const handleGenerateTargetPublic = (product) => {
    setLoading(true);

    const question = `Qual o público-alvo para o produto ${product}? me diga apenas o gênero, idade e tipos de interesses.`;

    generateResponse(question)
      .then((_) => setLoading(false))
      .catch((_) => setLoading(false));
  };

  useEffect(() => {
    if (isOpen) {
      handleGenerateTargetPublic(productName);
    }

    // eslint-disable-next-line
  }, [isOpen, productName]);

  const copyText = (text) => {
    navigator.clipboard.writeText(text);

    toast({
      title: "Copiado",
      description: "Copiado para área de transferência!",
      status: "success",
      duration: 2500,
      isClosable: true,
    });
  };

  return (
    <TargetAudienceContent>
      <h4>Gerando público alvo para o produto: {productName}</h4>

      {loading ? (
        <div className="spinner">
          <Spinner size="xl" color="#3058a4" />
        </div>
      ) : (
        <div className="answer">
          <p>{storedValues.answer}</p>

          <button onClick={() => copyText(storedValues.answer)}>
            Copiar texto
          </button>
        </div>
      )}
    </TargetAudienceContent>
  );
};
