import { Users } from "./styles";
import { FaEdit } from "react-icons/fa";
import { ModalChange } from "../../../components/Modal/Users/ModalChange";
import { Center, useDisclosure } from "@chakra-ui/react";
import { formatDate } from "../../../utils/formatDate";

export const UserContent = ({ dataUser }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Users className="content main" planId={dataUser.id_planos}>
        <li className="content__nome">{dataUser.nome}</li>
        <li className="content__email">{dataUser.email}</li>
        <li className="content__plano">
          {dataUser.id_planos === 4
            ? "Plano Basic"
            : dataUser.id_planos === 5
            ? "Plano Gold"
            : "Plano Premium"}
        </li>
        <li className="content__status">
          {dataUser.plano_status ? dataUser.plano_status : "pendente"}
        </li>
        <li className="content__venc">
          {formatDate(dataUser.plano_data_venc).includes("NaN")
            ? "pendente"
            : formatDate(dataUser.plano_data_venc)}
        </li>
        <li className="content__pagamento">Não</li>
        <li className="content__acoes">
          <Center
            as="button"
            onClick={() => onOpen()}
            _hover={{ opacity: 0.7 }}
          >
            <FaEdit fill="#3058a4" size="1.5rem" />
          </Center>
        </li>
      </Users>
      <ModalChange isOpen={isOpen} onClose={onClose} userData={dataUser} />
    </>
  );
};
