import { Draggable } from "react-beautiful-dnd";
import { CiMenuKebab } from "react-icons/ci";
import { ProductConveyorCard } from "./ProductConveyorCard";
import { FaArrowRightLong } from "react-icons/fa6";
import { useDisclosure } from "@chakra-ui/react";
import { OptionsConveyor } from "./OptionsConveyor";
import { useState } from "react";
import { ModalCrudProductConveyor } from "../../components/Modal/Products/ModalCrudProductConveyor";

export const DraggableColumn = ({
  column,
  provided,
  quantityColumns,
  filteredCreditsConveyor,
  discountCoinWallet,
}) => {
  const [showOption, setShowOption] = useState(false);

  const {
    isOpen: isOpenAddProduct,
    onOpen: onOpenAddProduct,
    onClose: onCloseAddProduct,
  } = useDisclosure();

  return (
    <div
      className="article"
      ref={provided.innerRef}
      {...provided.droppableProps}
    >
      <div className="flex-title">
        <h4>{column.name}</h4>
        <span className="options" onClick={() => setShowOption(true)}>
          <CiMenuKebab color="#3058a4" size="1.5rem" />
        </span>
      </div>

      {showOption ? (
        <OptionsConveyor
          esteira_id={column.id}
          nameColumn={column.name}
          onClose={() => setShowOption(false)}
          quantityColumns={quantityColumns}
        />
      ) : null}

      <ul>
        {provided.placeholder}

        {column &&
          column.items.map((product, index) => {
            return (
              <Draggable
                key={product.id}
                index={index}
                draggableId={`${product.id}`}
              >
                {(provided) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ProductConveyorCard key={index} product={product} />
                    </div>
                  );
                }}
              </Draggable>
            );
          })}

        <div className="flex-add-product" onClick={() => onOpenAddProduct()}>
          <FaArrowRightLong size="1.5rem" />
          <p>Adicionar produto</p>
        </div>
      </ul>

      <ModalCrudProductConveyor
        isOpen={isOpenAddProduct}
        onClose={onCloseAddProduct}
        esteira_id={column.id}
        crudOption="add"
        filteredCreditsConveyor={filteredCreditsConveyor}
        discountCoinWallet={discountCoinWallet}
      />
    </div>
  );
};
