import { Title } from "../../../components/Reusable/Title";
import { MainPage } from "../../../components/Reusable/Pagination/MainPage";
import { Container } from "../../../components/Reusable/Container";
import { TemplateCard } from "./TemplateCard";
import { RiLayout3Line } from "react-icons/ri";
import { SearchContent } from "../SocialMediaAdmin/styles";
import { TemplateContent } from "./styles";
import { MdArrowDropDown } from "react-icons/md";
import { useMemo, useState } from "react";
import { useTemplatesClient } from "../../../contexts/Templates/TemplatesContextClient";
import {
  Button,
  Grid,
  Heading,
  Input,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { ModalCrudTemplate } from "../../../components/Modal/Templates/ModalCrudTemplate";

let PageSize = 8;

export const MyTemplatesAdmin = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { themes } = useTemplatesClient();

  const currentTableTemplatesAdmin = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (themes !== undefined) {
      return themes.slice(firstPageIndex, lastPageIndex);
    }

    return [];
  }, [currentPage, themes]);

  return (
    <Container>
      <Title
        headingName="Temas - Painel Administrativo"
        caption="Selecione qual template deseja importar para sua loja e poderá
              personalizá-lo de forma simples e prática, otimizando o seu tempo."
      >
        <RiLayout3Line fill="#3058A4" size="1.5rem" />
      </Title>
      <Button
        h="50px"
        w="250px"
        mb="1rem"
        bg="#3058A4"
        color="#ffffff"
        fontFamily="Poppins"
        onClick={() => onOpen()}
        _hover={{ opacity: 0.7 }}
      >
        Publicar novo template
      </Button>
      <SearchContent>
        <div>
          <p>Show</p>
          <Select
            w="5rem"
            h="2rem"
            outline="0"
            color="#505050"
            iconSize="1rem"
            cursor="pointer"
            borderRadius="6px"
            iconColor="#3058A4"
            fontFamily="Poppins"
            fontSize="1.125rem"
            border="1px solid #505050"
            icon={<MdArrowDropDown />}
            _hover={{ borderColor: "#3058A4" }}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </Select>
          <p>entries</p>
        </div>
        <div>
          <p>Search:</p>
          <Input w="270px" color="#505050" />
        </div>
      </SearchContent>
      <TemplateContent>
        {currentTableTemplatesAdmin.length < 1 ? (
          <Grid
            gap="4"
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <Heading
              w="100%"
              color="#3058a4"
              fontSize="2rem"
              textAlign="center"
            >
              Não há templates cadastrados
            </Heading>
            <Button
              h="50px"
              w="250px"
              mb="1rem"
              bg="#3058A4"
              color="#ffffff"
              fontFamily="Poppins"
              onClick={() => onOpen()}
              _hover={{ opacity: 0.7 }}
            >
              Cadastrar novo template
            </Button>
          </Grid>
        ) : (
          <>
            <ul className="headers">
              <li className="headers__capa">Capa</li>
              <li className="headers__nome">Nome</li>
              <li className="headers__data">Data de postagem</li>
              <li className="headers__exibicao">Status de exibição</li>
              <li className="headers__acoes">Ações</li>
            </ul>
            <ul className="content">
              {currentTableTemplatesAdmin.map((template) => {
                return (
                  <TemplateCard
                    key={template.id_template}
                    template={template}
                  />
                );
              })}
            </ul>
          </>
        )}
      </TemplateContent>
      <MainPage
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={themes.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <ModalCrudTemplate isOpen={isOpen} onClose={onClose} verbType="create" />
    </Container>
  );
};
