import { useDisclosure } from "@chakra-ui/react";
import { FaEdit, FaTimes } from "react-icons/fa";
import { ModalCrudClasses } from "../../../components/Modal/Tutorials/ModalCrudClasses";
import { useTutorialsAdmin } from "../../../contexts/Tutorials/TutorialsContextAdmin";

export const ClassCard = ({ data, tutorialId }) => {
  const { deleteClass } = useTutorialsAdmin();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <li>
      <p>{data.titulo}</p>

      <div className="icons">
        <span onClick={() => onOpen()}>
          <FaEdit size="1.5rem" color="#3058a4" />
        </span>
        <span onClick={() => deleteClass(data.id)}>
          <FaTimes size="1.5rem" color="#fb6b03" />
        </span>
      </div>

      <ModalCrudClasses
        isOpen={isOpen}
        onClose={onClose}
        currentClass={data}
        tutorialId={tutorialId}
        classId={data.id}
        verbType="update"
      />
    </li>
  );
};
