import * as P from "../Products/styles";

import { Title } from "../../components/Reusable/Title";
import { MainPage } from "../../components/Reusable/Pagination/MainPage";
import { Container } from "../../components/Reusable/Container";
import { TrendingProductCard } from "./TrendingProductCard";

import { useProductsClient } from "../../contexts/Products/ProductsContextClient";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";

import { FaFire } from "react-icons/fa";
import { useFilter } from "../../hooks/useFilter";

// Quantidade de itens por seção
let PageSize = 12;

export const TrendingProducts = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { pathname } = useLocation();

  const { cleanFilters } = useFilter();

  const isTrendingProducts = pathname === "/dashboard/produtos-em-alta";

  const { products } = useProductsClient();

  useEffect(() => {
    cleanFilters();

    // eslint-disable-next-line
  }, []);

  const filterTrendingProducts =
    products !== undefined &&
    products.filter((product) =>
      product.categoria.includes("Produtos em alta")
    );

  // Carrega a lista de produtos com base em sua paginação
  const currentTableSuppliers = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    if (filterTrendingProducts) {
      return filterTrendingProducts.slice(firstPageIndex, lastPageIndex);
    }

    return [];
  }, [currentPage, filterTrendingProducts]);

  return (
    <Container>
      {isTrendingProducts ? (
        <>
          <Title
            headingName="Produtos em Alta"
            caption="Veja abaixo alguns dos muitos produtos que foram a virada de chave
            financeira de muitos dropshippers. Produtos com fornecedores
            nacionais e internacionais, com frete grátis. Deixe a sua operação
            de dropshipping ainda mais poderosa com produtos vencedores."
          >
            <FaFire size="2rem" fill="#3058A9" />
          </Title>

          <hr />

          <P.Grid>
            {currentTableSuppliers.map((product) => {
              return (
                <TrendingProductCard
                  key={product.id_fornecedores}
                  product={product}
                />
              );
            })}
          </P.Grid>

          <MainPage
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={filterTrendingProducts.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
      ) : (
        <Outlet />
      )}
    </Container>
  );
};
