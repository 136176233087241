import { useForm } from "react-hook-form";
import { usePlansAdmin } from "../../../contexts/Plans/PlansContextAdmin";

import { Modal } from "../index";
import { Input } from "../../Reusable/Input";
import { ModalHeader } from "../ModalHeader";
import { Button, VStack, Heading, Textarea } from "@chakra-ui/react";

import { yupResolver } from "@hookform/resolvers/yup";
import { plansSchema } from "../../../schemas/schemas";

import { BsCurrencyDollar } from "react-icons/bs";

export const ModalCrudPlan = ({ isOpen, onClose, verbType, planData = {} }) => {
  const { id, plano, mensalidades, ativos, inativos } = planData;

  const { addNewPlan, updatePlanAdmin } = usePlansAdmin();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(plansSchema),
  });

  const handleAddNewPlan = (data) => {
    if (verbType === "create") {
      addNewPlan(data);
      console.log("entrou aqui");
    } else {
      updatePlanAdmin(data, id);
      console.log("entrou aqui edit");
    }
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BsCurrencyDollar} color="#fff" />
            <ModalHeader.Title title="Planos - Painel Administrativo" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <Heading color="#3058A5" fontSize="1.5rem" textAlign="center">
            Digite os dados para {verbType === "create" ? "criação" : "edição"}{" "}
            do plano
          </Heading>
          <VStack
            mt="2rem"
            as="form"
            spacing="4"
            onSubmit={handleSubmit(handleAddNewPlan)}
          >
            <Input
              error={errors.plano}
              label="Título do Plano"
              {...register("plano")}
              defaultValue={verbType !== "create" ? plano : ""}
            />
            <Input
              label="Mensalidade"
              error={errors.mensalidades}
              {...register("mensalidades")}
              defaultValue={verbType !== "create" ? mensalidades : ""}
            />
            <Textarea
              error={errors.ativos}
              {...register("ativos")}
              placeholder="Recursos do plano ativos"
              defaultValue={verbType !== "create" ? ativos : ""}
            />
            <Textarea
              error={errors.inativos}
              {...register("inativos")}
              placeholder="Recursos do plano inativos"
              defaultValue={verbType !== "create" ? inativos : ""}
            />
            <Button
              h="50px"
              w="200px"
              mt="1rem"
              mb="1rem"
              bg="#3058A4"
              type="submit"
              color="#ffffff"
              fontFamily="Poppins"
              _hover={{ opacity: 0.7 }}
            >
              {verbType === "create" ? "Cadastrar" : "Editar"}
            </Button>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
