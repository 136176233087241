import { Link } from "react-router-dom";

import { LinkContent } from "../../components/Header/styles";
import { Content, Container, BackgroundImage } from "./styles";

import { PlaydropLogo } from "../../components/Reusable/PlayDropLogo";

import qrDash from "../../assets/icons/qr_sidebar.png";

import { SignUpForm } from "../../components/Forms/SignUpForm";

export const Register = () => {
  return (
    <Container>
      <BackgroundImage />

      <Content>
        <PlaydropLogo colorLogo="#3058A4" heightLogo="46px" widthLogo="176px" />

        <SignUpForm />

        <LinkContent>
          <Link to="/">Já possui uma conta? Faça login</Link>
        </LinkContent>

        <img src={qrDash} alt="Imagem qr code para footer" />
      </Content>
    </Container>
  );
};
