import styled from "styled-components";

export const SearchContent = styled.div`
  display: flex;
  justify-content: space-between;

  font-family: "Poppins";
  font-style: normal;

  margin-bottom: 1rem;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: #4f4f4f;
      font-weight: 400;
      font-size: 1rem;

      margin-left: 0.3rem;
      margin-right: 0.3rem;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    gap: 1rem;
  }
`;

export const MediaContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  font-family: "Poppins";
  font-style: normal;

  .headers {
    display: flex;
    align-items: center;

    width: 100%;
    margin-bottom: 0.5rem;

    li {
      font-weight: 600;
      font-size: 1rem;
      color: #4f4f4f;
    }

    .headers__capa {
      width: 15%;
    }
    .headers__nome {
      width: 20%;
    }
    .headers__categoria {
      width: 15%;
    }
    .headers__data {
      width: 20%;
    }
    .headers__exibicao {
      width: 20%;
    }
    .headers__acoes {
      width: 10%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .main:nth-child(odd) {
      background-color: #ffffff;
    }

    div {
      display: flex;
      align-items: center;

      margin-bottom: 0.5rem;

      .content__capa {
        width: 15%;

        img {
          width: 80%;
        }
      }
      .content__nome {
        width: 20%;
      }
      .content__categoria {
        width: 15%;
      }
      .content__data {
        width: 20%;
      }
      .content__exibicao {
        width: 20%;
      }
      .content__acoes {
        width: 10%;
        display: flex;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    overflow-x: scroll;

    .headers {
      width: 768px;

      .headers__capa {
        width: 110px;
      }
      .headers__nome {
        width: 140px;
      }
      .headers__categoria {
        width: 110px;
      }
      .headers__data {
        width: 140px;
      }
      .headers__exibicao {
        width: 140px;
      }
      .headers__acoes {
        width: 80px;
      }
    }

    .content {
      width: 768px;

      div {
        .content__capa {
          width: 110px;
        }
        .content__nome {
          width: 140px;
        }
        .content__categoria {
          width: 110px;
        }
        .content__data {
          width: 140px;
        }
        .content__exibicao {
          width: 140px;
        }
        .content__acoes {
          width: 80px;
        }
      }
    }
  }
`;
