import { useToast } from "@chakra-ui/react";
import { createContext, useContext, useState } from "react";

import { apiCep } from "../../services/api";

const CepContext = createContext({});

export const useCep = () => {
  const context = useContext(CepContext);

  if (!context) {
    throw new Error("useAuth must be used within an CepProvider");
  }

  return context;
};

export const CepProvider = ({ children }) => {
  const [ceps, setCeps] = useState({});

  const toast = useToast();

  const handleSearch = async (cepNumber, setLoading) => {
    setLoading(true);
    if (cepNumber.length === 8) {
      await apiCep
        .get(`${cepNumber}`)
        .then((response) => {
          setCeps(response.data);
          setLoading(false);
        })
        .catch((_) => {
          toast({
            title: "Opss...",
            description: "O CEP digitado não foi encontrado",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
          setLoading(false);
        });
    } else {
      toast({
        title: "Opss...",
        description: "Cep inválido, são necessários 08 dígitos!",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <CepContext.Provider value={{ ceps, handleSearch }}>
      {children}
    </CepContext.Provider>
  );
};
