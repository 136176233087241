import styled from "styled-components";

export const HeaderContent = styled.div`
  margin-bottom: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  .card-plan {
    width: 100%;
    margin-bottom: 3rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      color: #3058a4;
      font-size: 1.25rem;
      font-weight: 600;
    }

    .car-plan__content {
      width: 100%;
      height: 100%;
      padding: 0.8rem;

      border: 1px solid #eee;
      box-shadow: 0px 12px 5px -2px #e6eeff;
      border-radius: 10px;
      background-color: #fff;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h3 {
        color: #4f4f4f;
        font-size: 1.5rem;
        font-weight: 600;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        p {
          color: #4f4f4f;
          font-size: 1.6rem;
          font-weight: 600;
        }
        .divider {
          font-size: 1rem;
          font-weight: 400;
        }
        .month {
          color: #4f4f4f;
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }
  }

  .more-options {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      color: #3058a4;
      font-size: 1.25rem;
      font-weight: 600;
    }

    .more-options__content {
      width: 100%;
      height: 100%;
      padding: 1rem;

      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;

      border: 1px solid #eee;
      box-shadow: 0px 12px 5px -2px #e6eeff;
      border-radius: 10px;
      background-color: #fff;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;

    .card-plan {
      width: 30%;
      margin-bottom: 0;

      align-items: flex-start;
    }
    .more-options {
      width: 70%;

      align-items: flex-start;
    }
  }
`;

export const PlanRenewal = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  display: flex;
  flex-direction: column;

  p {
    font-size: 1.125rem;
  }

  .exists-plan {
    color: #4f4f4f;
    font-size: 1.125rem;

    span {
      color: #3058a4;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }
`;

export const OpenInvoices = styled.div`
  margin-bottom: 2rem;

  display: flex;
  flex-direction: column;
`;

export const CancelAccount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5rem;

  div {
    display: flex;
    flex-direction: column;

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1rem;
      color: #3058a4;
      margin-bottom: 0.8rem;
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
      color: #4d4d4d;
      padding-right: 2rem;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
      align-items: center;
      span {
        padding-right: 0;
        padding-bottom: 2rem;
        text-align: center;
      }
    }
  }
`;

export const WebsitesContent = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  .without-website {
    color: #3058a4;
    font-size: 1rem;
    font-weight: 500;
  }
`;
