import { useForm } from "react-hook-form";
import { useCategoriesAdmin } from "../../../contexts/Categories/CategoriesContextAdmin";

import { Modal } from "../index";
import { Input } from "../../Reusable/Input";
import { ModalHeader } from "../ModalHeader";
import { VStack, Button, Heading } from "@chakra-ui/react";

import { yupResolver } from "@hookform/resolvers/yup";
import { categorySchema } from "../../../schemas/schemas";

import { BiCategory } from "react-icons/bi";

export const ModalCrudCategory = ({
  isOpen,
  onClose,
  verbType,
  category = {},
}) => {
  const { addNewCategory, editCategory } = useCategoriesAdmin();

  const { id, tipo_produto } = category;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(categorySchema),
  });

  const handleCrudCategory = (data) => {
    if (verbType === "create") {
      addNewCategory(data);
    } else {
      editCategory(data, id);
    }
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BiCategory} color="#fff" />
            <ModalHeader.Title title="Categorias - Painel Administrativo" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <Heading color="#3058A5" fontSize="1.5rem" textAlign="center">
            Digite o nome da categoria para{" "}
            {verbType === "create" ? "criação" : "edição"}
          </Heading>
          <VStack
            mt="2rem"
            as="form"
            spacing="4"
            onSubmit={handleSubmit(handleCrudCategory)}
          >
            <Input
              label="Título da Categoria"
              error={errors.tipo_produto}
              {...register("tipo_produto")}
              defaultValue={verbType !== "create" ? tipo_produto : ""}
            />
            <Button
              h="50px"
              w="200px"
              mt="1rem"
              mb="1rem"
              type="submit"
              bg="#3058A4"
              color="#ffffff"
              fontFamily="Poppins"
              _hover={{ opacity: 0.7 }}
            >
              {verbType === "create"
                ? "Confirmar cadastro"
                : "Confirmar edição"}
            </Button>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
