import { apiAuth } from "../../../services/api";
import { useToast } from "@chakra-ui/react";
import { createContext, useContext, useState } from "react";

const WebsitesContextAdmin = createContext({});

export const useWebsitesAdmin = () => {
  const context = useContext(WebsitesContextAdmin);

  if (!context) {
    throw new Error(
      "useWebsitesAdmin must be used within a WebsitesProviderAdmin"
    );
  }

  return context;
};

export const WebsitesProviderAdmin = ({ children }) => {
  const [websitesAdmin, setWebsitesAdmin] = useState([]);

  const toast = useToast();

  const loadWebsitesAdmin = async () => {
    const response = await apiAuth.get("/listarsites");

    const allWebsites = response.data.data;

    localStorage.setItem("@PlayDrop:websites", JSON.stringify(allWebsites));

    const storedData = localStorage.getItem("@PlayDrop:websites");

    if (storedData) {
      setWebsitesAdmin(JSON.parse(storedData));
    }
  };

  const searchWebsite = (username) => {
    username.toLocaleLowerCase();
    if (username === "") {
      loadWebsitesAdmin();
    } else {
      const filteredUsers = websitesAdmin.filter(
        (websiteUser) =>
          websiteUser.nome.toLocaleLowerCase().includes(username) ||
          websiteUser.email.toLocaleLowerCase().includes(username)
      );
      setWebsitesAdmin(filteredUsers);
    }
  };

  const updateSpecificWebsite = async (status, siteId, userId) => {
    const body = {
      id_site: siteId,
      id_login: userId,
      status_propagacao: status,
    };

    try {
      await apiAuth.post("/update-especifico-site", body).then((res) => {
        console.log(res);
        toast({
          title: "Sucesso",
          description: "Status atualizado para o cliente!",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro",
        description: "Algo deu errado ao atualizar status!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  return (
    <WebsitesContextAdmin.Provider
      value={{
        websitesAdmin,
        setWebsitesAdmin,
        searchWebsite,
        updateSpecificWebsite,
        loadWebsitesAdmin,
      }}
    >
      {children}
    </WebsitesContextAdmin.Provider>
  );
};
