// Components
import { Title } from "../../../components/Reusable/Title";
import { GoBack } from "../../../components/Reusable/GoBack";
import { ClassesCard } from "./ClassesCard";
import { Box, Grid, Flex, Spinner } from "@chakra-ui/react";

// Hooks
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Icons
import { BiCube } from "react-icons/bi";

export const TutorialsInfo = () => {
  const [loading, setLoading] = useState(false);
  const [smallView, setSmallView] = useState(false);

  const { id } = useParams();

  const tutorials =
    JSON.parse(localStorage.getItem("@PlayDrop:tutoriais")) || [];

  const tutorial = tutorials.find((item) => item.id === Number(id));

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setSmallView(true);
      } else {
        setSmallView(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return loading ? (
    <Flex
      w="100%"
      h="500px"
      color="#3058a4"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="xl" />
    </Flex>
  ) : (
    <>
      {!smallView && (
        <Flex mb="6" alignItems="center" justifyContent="flex-start">
          <GoBack onClick={() => navigate(-1)} />
        </Flex>
      )}
      <Flex>
        <Title headingName={tutorial.descricao}>
          <BiCube fill="#3058A4" size="1.5rem" />
        </Title>
      </Flex>

      <Box mt="1rem" mb="1rem" w="100%" h="1.5px" bg="#D6DCEF" />

      <Grid w="100%" display="flex" flexDir="column" gap="1rem">
        {tutorial.aulas?.map((data) => (
          <ClassesCard
            key={data.id}
            classes={tutorial.aulas}
            classTutorial={data}
          />
        ))}
      </Grid>
    </>
  );
};
