import { Title } from "../../../components/Reusable/Title";
import { BiCube } from "react-icons/bi";
import { MainPage } from "../../../components/Reusable/Pagination/MainPage";
import { Container } from "../../../components/Reusable/Container";
import { useEffect, useMemo, useState } from "react";
import { ModalCrudTutorial } from "../../../components/Modal/Tutorials/ModalCrudTutorial";
import { TutorialsCardAdmin } from "./TutorialsCardAdmin";
import { useTutorialsClient } from "../../../contexts/Tutorials/TutorialsContextClient";
import { Button, Grid, Heading, useDisclosure } from "@chakra-ui/react";

let PageSize = 9;

export const TutorialsAdmin = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    isOpen: isOpenAddTutorial,
    onOpen: onOpenAddTutorial,
    onClose: onCloseAddTutorial,
  } = useDisclosure();

  const { tutorials, loadTutorials } = useTutorialsClient();

  useEffect(() => {
    loadTutorials();

    // eslint-disable-next-line
  }, []);

  const currentTableTutorialsAdmin = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (tutorials !== undefined) {
      return tutorials.slice(firstPageIndex, lastPageIndex);
    }

    return [];
  }, [currentPage, tutorials]);

  return (
    <Container>
      <Title
        headingName="Tutoriais - Painel Administrativo"
        caption="Aprenda a como começar a vender e conquistar a sua liberdade financeira com os nossos passos a passos, caso ainda tenha dúvidas contate o nosso suporte."
      >
        <BiCube fill="#3058A4" size="1.5rem" />
      </Title>

      <hr />

      <Button
        mb="6"
        h="50px"
        w="250px"
        bg="#3058A4"
        color="#ffffff"
        fontFamily="Poppins"
        onClick={() => onOpenAddTutorial()}
        _hover={{ opacity: 0.7 }}
      >
        Publicar novo tutorial
      </Button>

      <Heading mb="4" fontSize="1.5rem" fontWeight="700" color="#3058A4">
        Tutoriais cadastrados
      </Heading>

      <Grid
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
        gridGap="1rem"
      >
        {currentTableTutorialsAdmin.map((tutorial) => {
          return <TutorialsCardAdmin key={tutorial.id} tutorial={tutorial} />;
        })}
      </Grid>

      <ModalCrudTutorial
        isOpen={isOpenAddTutorial}
        onClose={onCloseAddTutorial}
        verbType="create"
      />

      {tutorials !== undefined && (
        <MainPage
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={tutorials.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
    </Container>
  );
};
