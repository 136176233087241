import { Modal } from "../index";
import { Input } from "../../Reusable/Input";
import { ModalHeader } from "../ModalHeader";
import { Text, VStack, Button } from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { useTutorialsAdmin } from "../../../contexts/Tutorials/TutorialsContextAdmin";

import { yupResolver } from "@hookform/resolvers/yup";
import { classSchema } from "../../../schemas/schemas";

import { BiCube } from "react-icons/bi";
import { useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "./styles.css";

export const ModalCrudClasses = ({
  isOpen,
  onClose,
  verbType,
  currentClass,
  tutorialId,
  classId,
}) => {
  const [descriptionClass, setDescriptionClass] = useState({
    title: "",
  });

  const onChangeValue = (e) => {
    setDescriptionClass({
      ...descriptionClass,
      [e.target.name]: e.target.value,
    });
  };

  let editorState = EditorState.createEmpty();
  const [description, setDescription] = useState(editorState);
  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };

  const { addNewClass, updateClass } = useTutorialsAdmin();

  const {
    register,
    handleSubmit,
    reset,
    // formState: { errors },
  } = useForm({
    resolver: yupResolver(classSchema),
  });

  const onSubmitFunction = (data) => {
    const body = {
      ...data,
      descricao:
        descriptionClass.description && descriptionClass.description.value,
    };

    if (verbType === "create") {
      addNewClass(body, tutorialId);
      reset();
    } else {
      updateClass(body, tutorialId, classId);
    }
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BiCube} color="#fff" />
            <ModalHeader.Title
              title={
                verbType === "create" ? "Adicionar Nova Aula" : "Editar Aula"
              }
            />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <VStack
            as="form"
            spacing="4"
            onSubmit={handleSubmit(onSubmitFunction)}
          >
            <Input
              label="Título"
              type="text"
              {...register("titulo")}
              defaultValue={verbType === "update" ? currentClass.titulo : ""}
            />

            <label className="label-description">Descrição</label>

            <Editor
              editorState={description}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
            />

            <textarea
              style={{ display: "none" }}
              disabled
              ref={(val) => (descriptionClass.description = val)}
              value={draftToHtml(convertToRaw(description.getCurrentContent()))}
            />

            <Input
              label="Vídeo"
              {...register("video")}
              defaultValue={verbType === "update" ? currentClass.video : ""}
            />

            <Text>Digite todo o caminho do vídeo.</Text>
            <Text as="span">
              Ex.: https://www.<b>youtube/vimeo</b>
              .com/embed/MteKFlwcn-M?si=5wiUsIV6DJ4IfNIO
            </Text>

            <Button
              h="50px"
              w="200px"
              mt="1rem"
              mb="1rem"
              bg="#3058A4"
              type="submit"
              color="#ffffff"
              fontFamily="Poppins"
              _hover={{ opacity: 0.7 }}
            >
              Confirmar
            </Button>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
