import { Modal } from "../index";
import { Input } from "../../Reusable/Input";
import { ModalHeader } from "../ModalHeader";
import { Text, Button, VStack, Textarea } from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { useTemplatesAdmin } from "../../../contexts/Templates/TemplatesContextAdmin";

import { yupResolver } from "@hookform/resolvers/yup";
import { newTemplateSchema } from "../../../schemas/schemas";

import { RiLayout3Line } from "react-icons/ri";

export const ModalCrudTemplate = ({
  isOpen,
  onClose,
  verbType,
  templateData = {},
}) => {
  const {
    url_demo,
    descricao,
    id_template,
    url_download,
    nome_template,
    data_publicacao,
  } = templateData;

  const { addNewTemplate, editTemplate } = useTemplatesAdmin();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(newTemplateSchema),
  });

  const handleCrudTemplate = (data) => {
    if (verbType === "create") {
      addNewTemplate(data);
    } else {
      editTemplate(data, id_template);
    }
    reset();
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={RiLayout3Line} color="#fff" />
            <ModalHeader.Title title="Temas - Painel Administrativo" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <Text
            ml="2"
            color="#3058A5"
            fontSize="1.25rem"
            textAlign="center"
            fontFamily="Poppins"
          >
            Siga os passos abaixo para{" "}
            {verbType === "create" ? "criação" : "edição"} do template
          </Text>
          <VStack
            as="form"
            mt="2rem"
            spacing="2"
            onSubmit={handleSubmit(handleCrudTemplate)}
          >
            <Input
              type="file"
              error={errors.capa_template}
              {...register("capa_template")}
            />
            <Input
              label="Título"
              error={errors.nome_template}
              {...register("nome_template")}
              defaultValue={verbType !== "create" ? nome_template : ""}
            />
            <Input
              type="date"
              label="Data de Publicação"
              error={errors.data_publicacao}
              {...register("data_publicacao")}
              defaultValue={verbType !== "create" ? data_publicacao : ""}
            />
            <Input
              label="Link para download"
              error={errors.url_download}
              {...register("url_download")}
              placeholder="Link para download"
              defaultValue={verbType !== "create" ? url_download : ""}
            />
            <Input
              label="Link par demo"
              error={errors.url_demo}
              {...register("url_demo")}
              placeholder="Link para visualizar site"
              defaultValue={verbType !== "create" ? url_demo : ""}
            />
            <Textarea
              error={errors.descricao}
              {...register("descricao")}
              placeholder="Descrição da mídia"
              defaultValue={verbType !== "create" ? descricao : ""}
            />
            <Button
              h="50px"
              w="200px"
              mt="1rem"
              mb="1rem"
              type="submit"
              bg="#3058A4"
              color="#ffffff"
              fontFamily="Poppins"
              _hover={{ opacity: 0.7 }}
            >
              {verbType === "create" ? "Cadastrar" : "Editar"}
            </Button>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
