import { Modal } from "../index";
import { FaShopLock } from "react-icons/fa6";
import { ModalHeader } from "../ModalHeader";
import { ModalBlockContent } from "../../../pages/ProductConveyor/ModalBlockContent";

export const ModalBlockUser = ({ isOpen, onClose, title, text }) => {
  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="36rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={FaShopLock} color="#fff" />
            <ModalHeader.Title title="Atualizar meu plano" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>

        <Modal.Body>
          <ModalBlockContent title={title} text={text} />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
