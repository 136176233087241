import { Flex, Text } from "@chakra-ui/react";

export const DrawerMobileOptions = ({ children, onClick, textname }) => {
  return (
    <Flex
      w="100%"
      alignItems="center"
      justifyContent="flex-start"
      onClick={onClick}
      cursor="pointer"
    >
      {children}
      <Text ml="2" color="#ffffff">
        {textname}
      </Text>
    </Flex>
  );
};
