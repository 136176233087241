import { useAuth } from "../../contexts/Auth";

import { FaTimes, FaUser } from "react-icons/fa";

import { DL } from "./styles";
import { ModalUpdateProfile } from "../Modal/Users/Client/ModalUpdateProfile";
import { Button, useDisclosure } from "@chakra-ui/react";

export const DrawerLogout = ({ id = "drawer", onClose = () => {} }) => {
  const { data, signOut } = useAuth();

  const {
    isOpen: isOpenModalUpdateProfile,
    onOpen: onOpenModalUpdateProfile,
    onClose: onCloseModalUpdateProfile,
  } = useDisclosure();

  return (
    <>
      <DL.Container>
        <DL.Header.Container>
          <DL.Header.Name>Olá, {data.nome}</DL.Header.Name>

          <DL.Header.Close id={id} onClick={onClose}>
            <FaTimes color="#404040" size="1.25rem" />
          </DL.Header.Close>
        </DL.Header.Container>

        <DL.Content.Container>
          <DL.Content.Profile onClick={() => onOpenModalUpdateProfile()}>
            <div>
              <FaUser size="1.5rem" fill="#3058a4" />
            </div>

            <p>Gerenciar Perfil</p>
          </DL.Content.Profile>

          <p className="message">O que deseja fazer?</p>

          <DL.Content.Buttons>
            <Button
              mt="4"
              w="100%"
              h="40px"
              bg="#fff"
              color="#3058A4"
              borderRadius="10px"
              alignItems="center"
              justifyContent="center"
              border="1px solid #3058A4"
              _hover={{ color: "#fff", bgColor: "#3058a4" }}
              onClick={() => {
                onClose();
              }}
            >
              Permanecer
            </Button>

            <Button
              mt="4"
              w="100%"
              h="40px"
              bg="linear-gradient(89.97deg, #ff0000 0.44%, #ff5555 99.97%)"
              color="#ffffff"
              borderRadius="10px"
              alignItems="center"
              justifyContent="center"
              onClick={() => signOut()}
              _hover={{ opacity: 0.8 }}
            >
              Sair
            </Button>
          </DL.Content.Buttons>
        </DL.Content.Container>
      </DL.Container>

      <ModalUpdateProfile
        isOpen={isOpenModalUpdateProfile}
        onClose={onCloseModalUpdateProfile}
      />
    </>
  );
};
