import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CategoriesContent = styled.div`
  width: 100%;
  max-width: 1260px;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  overflow-y: hidden;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    cursor: pointer;
    width: 6px;
    height: 6px;
    border: 0;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    height: 6px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: #3059a4;
    border-radius: 24px;
    cursor: pointer;
  }

  .category-card {
    width: 110px;
    height: 110px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-shadow: 0px 8px 5px -1px #e6eeff;
    border-radius: 1rem;
    background-color: #fff;

    div {
      svg {
        color: #fb6b03;
      }
    }

    p {
      width: 110px;
      text-align: center;
      color: #3058a4;
      font-size: 1.1rem;
      font-weight: 500;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      transition: ease-in 0.2s;
    }
  }

  .selected {
    background-color: #fb6b03;

    div {
      svg {
        color: #fff;
      }
    }

    p {
      color: #fff;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;

  p {
    color: #3058a4;
    font-size: 1.25rem;
    font-weight: 500;
  }

  .content-search {
    width: 100%;

    display: flex;
    align-items: center;
    gap: 0.5rem;

    .search {
      position: relative;

      svg {
        position: absolute;
        top: 0.7rem;
        left: 0.5rem;

        z-index: 10;

        cursor: pointer;

        color: #3058a4;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .content-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    .filter {
      width: 9.4rem;
      height: 2.5rem;
      padding: 0.2rem 1rem;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.4rem;

      cursor: pointer;

      border-radius: 0.5rem;
      background-color: #fff;
      box-shadow: 0px 8px 5px -1px #e6eeff;

      p {
        font-size: 1rem;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .content-info {
      flex-direction: row;
    }
  }
`;

export const Grid = styled.ul`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15.625rem, 1fr));
  grid-gap: 1.5rem;
`;

export const Container = styled.li`
  width: 100%;
  height: 36rem;

  background-color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;

  figure {
    width: 100%;
    height: 16.875rem;

    background-color: #fff;

    position: relative;

    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
    video {
      width: 100%;
      height: 100%;
    }

    .fire-content {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;

      svg {
        color: #3058a4;
      }
    }
  }

  h4 {
    padding: 0.5rem;

    margin: 0.5rem 0;

    color: #4f4f4f;
    font-size: 1rem;
    font-weight: 600;

    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }
  }

  .price {
    padding: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    p {
      color: #4f4f4f;
      font-size: 0.7rem;

      span {
        color: #3058a4;
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }

  .statistics {
    padding: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    .statistics__content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.2rem;
    }
  }

  .buttons {
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    button {
      width: 100%;
      height: 2.8rem;

      color: #fff;
      font-size: 1rem;
      border-radius: 0.5rem;
      background: linear-gradient(89.97deg, #3058a5 0.44%, #2964d3 99.97%);

      &:hover {
        transition: ease-in 0.2s;
        opacity: 0.8;
      }
    }

    .orange {
      color: #fff;
      background: #fb6b03;
      line-height: 1rem;

      &:hover {
        transition: ease-in 0.2s;
        opacity: 0.8;
      }
    }
  }

  .info-credits {
    width: 100%;
    height: 80px;
    padding: 0.2rem 1rem;

    position: absolute;
    top: 0;
    z-index: 10;

    border-radius: 0.5rem;
    background-color: #3058a4;

    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #fff;
      font-size: 1rem;
      text-align: center;

      span {
        margin: 0 4px;
      }
    }

    div {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &:hover {
    transform: scale(1.1);
    transition: ease 0.4s;
  }
`;

export const Root = styled.div`
  width: 100%;
  padding: 1rem;

  display: flex;
  flex-direction: column;

  box-shadow: 0px 8px 5px -1px #e6eeff;
  border-radius: 10px;
  background-color: #fff;

  p {
    margin-bottom: 0;

    color: #4f4f4f;
    font-size: 1.125rem;
  }

  .select {
    width: 100px;
    height: 2.5rem;
    margin-bottom: 1rem;

    border: 2px solid #e6eeff;
    box-shadow: 0px 8px 5px -1px #e6eeff;
    border-radius: 20px;
    background-color: #fff;

    cursor: pointer;

    position: relative;

    .circle-one,
    .circle-two {
      width: 40px;
      height: 100%;

      border-radius: 50%;
    }

    .circle-one {
      position: absolute;
      top: 0;
      left: 0;

      background-color: #e6eeff;
    }
    .circle-two {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #3058a4;
    }
  }

  .content-filter-date {
    position: relative;

    display: flex;
    flex-direction: column;

    margin-bottom: 1rem;

    .btn-filter {
      width: 150px;
      height: 2.4rem;

      color: #fff;
      border: none;
      border-radius: 0 10px 10px 0;
      background-color: #3058a4;

      &:hover {
        opacity: 0.8;
      }
    }

    .inputs-filter-date {
      width: 240px;
      height: 2.5rem;

      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid #e6eeff;
      box-shadow: 0px 8px 5px -1px #e6eeff;
      border-radius: 6px 0 0 6px;
      background-color: #fff;

      input {
        width: 120px;
        height: 100%;
        padding-left: 1rem;

        border-radius: 10px;

        cursor: pointer;

        outline: none;
        border: none;
      }
    }
  }

  .flex-buttons-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    button {
      width: 300px;
      height: 2.5rem;
      margin: 1rem 0;

      color: #fff;
      font-size: 1.125rem;
      font-weight: 500;
      background: linear-gradient(89.97deg, #3058a5 0.44%, #2964d3 99.97%);
      border-radius: 0.625rem;

      &:hover {
        opacity: 0.8;
      }
    }

    .btn-clean {
      background: #fb6b03;
    }
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 0;

    color: #4f4f4f;
    font-size: 1.125rem;
    font-weight: 500;
  }
`;

export const FilterGrid = styled.ul`
  width: 100%;
  padding: 1rem;
  margin-top: 0;
  margin-bottom: 1rem;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;

  box-shadow: 0px 8px 5px -1px #e6eeff;
  border-radius: 10px;

  .container {
    h4 {
      font-size: 1rem;
      color: #3058a4;
      font-weight: 500;
      margin-bottom: 0.4rem;
    }

    .slider {
      width: 100%;
      outline: none;

      cursor: pointer;
    }

    .value_label {
      font-size: 1rem;
      font-weight: 500;
    }
  }
`;

export const InfoContainer = styled.div`
  .go-back {
    display: none;
  }

  .menu-info {
    width: 100%;
    margin-top: 2rem;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1rem;

    li {
      height: 50px;

      text-align: center;

      display: flex;
      align-items: center;
      justify-content: center;

      color: #3058a4;
      font-size: 0.9rem;
      font-weight: 600;

      border: 2px solid #3058a4;
      border-radius: 10px;
      background-color: #fff;

      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .adverts,
    .criatives,
    .related,
    .description,
    .suppliers {
      color: #fff;
      background-color: #3058a4;
    }
  }

  .info-mais-produtos {
    margin-top: 2rem;

    .post__description {
      width: 100%;
      padding: 1rem;

      box-shadow: 0px 8px 5px -1px #e6eeff;
      border-radius: 10px;
      background-color: #fff;
    }

    .target-audience {
      width: 100%;
      padding: 1rem;

      box-shadow: 0px 8px 5px -1px #e6eeff;
      border-radius: 10px;
      background-color: #fff;
    }

    .suppliers {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .suppliers-container {
        display: flex;
        align-items: center;
        gap: 1rem;

        .suppliers-container__menu {
          width: 150px;
          height: 80px;
          padding: 0.5rem 0;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          box-shadow: 0px 8px 5px -1px #e6eeff;
          border-radius: 10px;
          background-color: #fff;

          .small {
            font-size: 0.9rem;
          }

          img {
            width: 40px;
          }

          cursor: pointer;

          &:hover {
            opacity: 0.8;

            transform: scale(1.1);
            transition: ease-in 0.2s;
          }
        }

        .relative {
          position: relative;

          span {
            position: absolute;
            top: -0.8rem;
            right: 0.4rem;
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    .go-back {
      margin-bottom: 2rem;

      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;

export const InfoContent = styled.div`
  height: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;

  figure {
    width: 100%;
    height: 400px;

    position: relative;

    background-color: #fff;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    video {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    div {
      width: 50px;
      height: 50px;

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 0;
      right: 0;

      border-radius: 10px;
      background-color: #3058a4;

      cursor: pointer;

      svg {
        color: #fff;
      }

      &:hover {
        transform: scale(1.1);
        transition: ease 0.4s;

        opacity: 0.7;

        svg {
          transform: scale(1.1);
          transition: ease 0.4s;
        }
      }
    }
  }

  .information {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;

    padding: 0.5rem;

    background-color: #fb6b03;

    border: 2px solid #fb6b03;
    border-radius: 10px;
  }

  .info-product {
    width: 100%;
    padding-right: 3rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .owner {
      width: 100%;

      display: flex;
      align-items: center;
      gap: 1rem;

      figure {
        width: 60px;
        height: 60px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }

        video {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      h5 {
        color: #3058a4;
        font-size: 1.25rem;
        font-weight: 500;
      }
    }

    .text-title {
      font-size: 0.9rem;
      font-weight: 600;

      color: #fb6b03;

      text-align: center;
    }

    .big {
      font-size: 1.2rem;
    }

    .text-name {
      margin-bottom: 0.3rem;

      font-weight: 600;
      font-size: 1.5rem;
      color: #fb6b03;
      text-align: center;
    }

    .text-price {
      font-size: 2rem;
      font-weight: 600;
      color: #4f4f4f;
      text-align: center;
    }

    .gray {
      color: #4f4f4f;
    }

    .white {
      color: #fff;
    }

    .text-description {
      font-size: 1rem;
      color: #4f4f4f;
      text-align: center;
    }

    @media (min-width: 768px) {
      align-items: flex-start;

      .text-title,
      .text-name,
      .text-price,
      .text-description {
        text-align: left;
      }
    }
  }

  .cards-and-more {
    width: 100%;

    .cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
      align-items: flex-start;
      grid-gap: 1rem;

      .content {
        height: 45px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        cursor: pointer;

        padding: 0.5rem;
        border-radius: 6px;
        background-color: #fff;
        box-shadow: 0 2px 8px -2px #4f4f4f;

        &:hover {
          transform: scale(1.1);
          transition: ease 0.4s;
        }

        a {
          text-decoration: none;
        }

        .name {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          p {
            font-size: 1.125rem;
            font-weight: 500;
            color: #4f4f4f;
          }
        }
      }
    }
  }
`;

export const ImportProductContent = styled.div`
  width: 100%;
  height: 300px;

  h4 {
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 500;
    color: #3058a4;
  }

  .no-websites {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    h4 {
      text-align: center;
    }

    a {
      font-size: 1.125rem;
      font-weight: 500;
      color: #3058a4;
    }
  }

  div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1rem;
  }
`;

export const TargetAudienceContent = styled.div`
  width: 100%;

  h4 {
    color: #4f4f4f;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 1rem;
  }

  .spinner {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .answer {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
      width: 200px;
      height: 42px;
      margin: 0 auto;

      color: #fff;
      border-radius: 10px;
      background-color: #fb6b03;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
`;

export const SuppliersCardContent = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15.625rem, 1fr));
  grid-gap: 1.5rem;

  .is-loading {
    width: 100%;
    margin-top: 1rem;
    text-align: center;

    color: #3058a4;
    font-size: 1.25rem;
    font-weight: 500;
  }

  .free-plan {
    width: 100%;
    height: 300px;

    background: linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 10px;

    div {
      padding: 0.4rem 1rem;

      border: 2px solid #fff;
      border-radius: 10px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 50px;
      }

      h4 {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }

    h5 {
      margin-top: 2rem;
      color: #fff;
      font-size: 1.25rem;
      font-weight: 500;
    }

    button {
      width: 200px;
      height: 50px;
      margin-top: 2rem;

      border: 2px solid #fff;
      border-radius: 10px;
      background-color: #fff;

      color: #4f4f4f;
      font-size: 1.25rem;
      font-weight: 500;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  li {
    padding: 1rem;

    box-shadow: 0 5px 8px -2px #e6eeff;
    border-radius: 10px;
    background-color: #fff;

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .playdrop-logo {
      padding: 0.4rem 1rem;

      border: 2px solid #fff;
      border-radius: 10px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 50px;
      }

      h4 {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      margin-bottom: 10px;

      div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #e6eeff;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .brazilian-icon {
        width: 18%;
        height: 50px;
        background-color: #009739;
      }

      h4 {
        margin: 0;
        width: 88%;

        color: #3058a4;
        font-size: 1.1rem;
        font-weight: 500;
      }
    }

    img {
      width: 100%;
      margin: 0;
      border-radius: 10px;
    }

    a {
      p {
        width: 100%;
        height: 42px;
        margin-top: 10px;

        color: #fff;
        border-radius: 10px;
        background-color: #fb6b03;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1.125rem;
        font-weight: 500;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    &:hover {
      transition: ease-in 0.2s;
      transform: scale(1.1);
    }
  }

  .brazilian-product {
    width: 100%;
    max-width: 350px;
  }
`;
