import { Title } from "../../components/Reusable/Title";
import { Container } from "../../components/Reusable/Container";

import { Input } from "@chakra-ui/react";
import { Content } from "./styles";
import { AppCard } from "./AppCard";

import { LuLayoutGrid } from "react-icons/lu";

import { appData, categories } from "./appData";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";

export const Applications = () => {
  const [appName, setAppName] = useState("");
  const [filteredAppName, setFilteredAppName] = useState([]);

  const searchByName = (name) => {
    if (name !== "") {
      const normalize = name
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      const app = appData.filter((item) =>
        item.title
          .toLocaleLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(normalize)
      );

      setFilteredAppName([...app]);
    } else {
      setFilteredAppName([...appData]);
    }
  };

  return (
    <Container>
      <Title
        headingName="Aplicativos"
        caption="Aqui deixamos todas as nossas extensões e nossos parceiras que recomendamos para ajudar você a facilitar a sua operação em sua loja."
      >
        <LuLayoutGrid fill="#3058A5" size="1.5rem" />
      </Title>

      <hr />

      <Content>
        <div className="search">
          <FaSearch size="1.25rem" />
          <Input
            w="100%"
            h="2.5rem"
            pl="2rem"
            bg="#fff"
            maxW="28rem"
            boxShadow="0px 8px 5px -1px #e6eeff"
            _placeholder={{ fontSize: "0.8rem", color: "#3058a4" }}
            placeholder="Pesquise algum app..."
            onChange={(ev) => {
              setAppName(ev.target.value);
              searchByName(ev.target.value);
            }}
          />
        </div>

        <div className="apps">
          {categories.map((category) => (
            <div className="content" key={category}>
              <h4>{category}</h4>

              <div className="grid">
                {filteredAppName.length > 0
                  ? filteredAppName
                      .filter((item) => item.category.includes(category))
                      .map((data) => <AppCard key={data.id} data={data} />)
                  : appData
                      .filter((item) => item.category.includes(category))
                      .map((data) => <AppCard key={data.id} data={data} />)}
              </div>
            </div>
          ))}
        </div>
      </Content>
    </Container>
  );
};
