// Components
import { Box, Flex } from "@chakra-ui/react";
import { DrawerDeskOptions } from "./DrawerDeskOptions";

// Hooks
import { useBacklogs } from "../../contexts/Backlogs";
import { useLocation, useNavigate } from "react-router-dom";

// Icons
import { FaUser } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import { IoMdSettings } from "react-icons/io";
import { RiLayout3Line } from "react-icons/ri";
import { PiCirclesThreePlusBold } from "react-icons/pi";
import { BsCurrencyDollar, BsGrid } from "react-icons/bs";
import { BiCategory, BiCube, BiStoreAlt } from "react-icons/bi";

export const DrawerAdmPanelDesk = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { backlogData } = useBacklogs();

  const filteredBacklog = backlogData?.filter(
    (item) => item.status_pendencias === "pendente"
  );

  return (
    <Box
      w="100%"
      bg="#ffffff"
      gap="2"
      top="6.25rem"
      left="0"
      display="flex"
      flexDir="column"
      maxWidth="15.625rem"
      position="fixed"
      fontFamily="Poppins"
    >
      <DrawerDeskOptions
        textName="Dashboard"
        pathnames={location.pathname === "/dashboard/menu-inicial"}
        color={
          location.pathname === "/dashboard/menu-inicial"
            ? "#ffffff"
            : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/menu-inicial");
        }}
        bg={location.pathname === "/dashboard/menu-inicial" && "#3058A4"}
        hover={
          location.pathname !== "/dashboard/menu-inicial" && { bg: "#E0E0E0" }
        }
      >
        <BsGrid
          fill={
            location.pathname === "/dashboard/menu-inicial"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="1.5rem"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Lojas"
        pathnames={location.pathname === "/dashboard/sites-admin"}
        color={
          location.pathname === "/dashboard/sites-admin" ? "#ffffff" : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/sites-admin");
        }}
        bg={location.pathname === "/dashboard/sites-admin" ? "#3058A4" : ""}
        hover={
          location.pathname !== "/dashboard/sites-admin"
            ? { bg: "#E0E0E0" }
            : null
        }
      >
        <TbWorld
          color={
            location.pathname === "/dashboard/sites-admin"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Temas"
        color={
          location.pathname === "/dashboard/templates-admin" ||
          location.pathname === "/dashboard/templates-admin/novo-template"
            ? "#ffffff"
            : "#3058A4"
        }
        pathnames={
          location.pathname === "/dashboard/templates-admin" ||
          location.pathname === "/dashboard/templates-admin/novo-template"
        }
        onClick={() => {
          navigate("/dashboard/templates-admin");
        }}
        bg={
          location.pathname === "/dashboard/templates-admin" ||
          location.pathname === "/dashboard/templates-admin/novo-template"
            ? "#3058A4"
            : ""
        }
        hover={
          location.pathname !== "/dashboard/templates-admin" &&
          location.pathname !== "/dashboard/templates-admin/novo-template"
            ? { bg: "#E0E0E0" }
            : null
        }
      >
        <RiLayout3Line
          fill={
            location.pathname === "/dashboard/templates-admin" ||
            location.pathname === "/dashboard/templates-admin/novo-template"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Criativos"
        color={
          location.pathname === "/dashboard/midias-admin"
            ? "#ffffff"
            : "#3058A4"
        }
        pathnames={location.pathname === "/dashboard/midias-admin"}
        onClick={() => {
          navigate("/dashboard/midias-admin");
        }}
        bg={location.pathname === "/dashboard/midias-admin" ? "#3058A4" : ""}
        hover={
          location.pathname !== "/dashboard/midias-admin"
            ? { bg: "#E0E0E0" }
            : null
        }
      >
        <PiCirclesThreePlusBold
          fill={
            location.pathname === "/dashboard/midias-admin"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="1.5rem"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Produtos"
        color={
          location.pathname === "/dashboard/fornecedores-admin" ||
          location.pathname === "/dashboard/fornecedores-admin/novo-produto"
            ? "#ffffff"
            : "#3058A4"
        }
        pathnames={
          location.pathname === "/dashboard/fornecedores-admin" ||
          location.pathname === "/dashboard/fornecedores-admin/novo-produto"
        }
        onClick={() => {
          navigate("/dashboard/fornecedores-admin");
        }}
        bg={
          location.pathname === "/dashboard/fornecedores-admin" ||
          location.pathname === "/dashboard/fornecedores-admin/novo-produto"
            ? "#3058A4"
            : ""
        }
        hover={
          location.pathname !== "/dashboard/fornecedores-admin" &&
          location.pathname !== "/dashboard/fornecedores-admin/novo-produto"
            ? { bg: "#e0e0e0" }
            : null
        }
      >
        <BiStoreAlt
          fill={
            location.pathname === "/dashboard/fornecedores-admin" ||
            location.pathname === "/dashboard/fornecedores-admin/novo-produto"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Fornecedores"
        color={
          location.pathname === "/dashboard/fornecedores-manuais" ||
          location.pathname === "/dashboard/fornecedores-manuais/novo-produto"
            ? "#ffffff"
            : "#3058A4"
        }
        pathnames={location.pathname === "/dashboard/fornecedores-manuais"}
        onClick={() => {
          navigate("/dashboard/fornecedores-manuais");
        }}
        bg={
          location.pathname === "/dashboard/fornecedores-manuais"
            ? "#3058A4"
            : ""
        }
        hover={
          location.pathname !== "/dashboard/fornecedores-manuais"
            ? { bg: "#e0e0e0" }
            : null
        }
      >
        <BiStoreAlt
          fill={
            location.pathname === "/dashboard/fornecedores-manuais"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Tutoriais"
        pathnames={location.pathname === "/dashboard/tutoriais-admin"}
        color={
          location.pathname === "/dashboard/tutoriais-admin"
            ? "#ffffff"
            : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/tutoriais-admin");
        }}
        bg={location.pathname === "/dashboard/tutoriais-admin" && "#3058A4"}
        hover={
          location.pathname !== "/dashboard/tutoriais-admin" && {
            bg: "#E0E0E0",
          }
        }
      >
        <BiCube
          fill={
            location.pathname === "/dashboard/tutoriais-admin"
              ? "#E0E0E0"
              : "#3058A4"
          }
          size="1.5rem"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Categorias"
        pathnames={
          location.pathname === "/dashboard/categorias" ||
          location.pathname === "/dashboard/categorias/nova-categoria"
        }
        color={
          location.pathname === "/dashboard/categorias" ||
          location.pathname === "/dashboard/categorias/nova-categoria"
            ? "#ffffff"
            : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/categorias");
        }}
        bg={
          location.pathname === "/dashboard/categorias" ||
          location.pathname === "/dashboard/categorias/nova-categoria"
            ? "#3058A4"
            : ""
        }
        hover={
          location.pathname !== "/dashboard/categorias" &&
          location.pathname !== "/dashboard/categorias/nova-categoria"
            ? { bg: "#E0E0E0" }
            : null
        }
      >
        <BiCategory
          fill={
            location.pathname === "/dashboard/categorias" ||
            location.pathname === "/dashboard/categorias/nova-categoria"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="1.5rem"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Usuários"
        pathnames={location.pathname === "/dashboard/usuarios"}
        color={
          location.pathname === "/dashboard/usuarios" ? "#ffffff" : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/usuarios");
        }}
        bg={location.pathname === "/dashboard/usuarios" && "#3058A4"}
        hover={location.pathname !== "/dashboard/usuarios" && { bg: "#E0E0E0" }}
      >
        <FaUser
          fill={
            location.pathname === "/dashboard/usuarios" ? "#42BBFF" : "#3058A4"
          }
          size="1.5rem"
        />
      </DrawerDeskOptions>

      <Box position="relative">
        <DrawerDeskOptions
          textName="Pendências"
          pathnames={location.pathname === "/dashboard/pendencias"}
          color={
            location.pathname === "/dashboard/pendencias"
              ? "#ffffff"
              : "#3058A4"
          }
          onClick={() => {
            navigate("/dashboard/pendencias");
          }}
          bg={location.pathname === "/dashboard/pendencias" && "#3058A4"}
          hover={
            location.pathname !== "/dashboard/pendencias" && { bg: "#E0E0E0" }
          }
        >
          <IoMdSettings
            fill={
              location.pathname === "/dashboard/pendencias"
                ? "#42BBFF"
                : "#3058A4"
            }
            size="1.5rem"
          />
        </DrawerDeskOptions>
        {filteredBacklog && (
          <Flex
            top="0"
            w="40px"
            h="50px"
            bg="#FB6B03"
            right="0.6rem"
            color="#ffffff"
            fontWeight="bold"
            position="absolute"
            alignItems="center"
            fontSize="1.125rem"
            justifyContent="center"
          >
            {filteredBacklog?.length}
          </Flex>
        )}
      </Box>

      <DrawerDeskOptions
        textName="Planos"
        pathnames={location.pathname === "/dashboard/planos-admin"}
        color={
          location.pathname === "/dashboard/planos-admin"
            ? "#ffffff"
            : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/planos-admin");
        }}
        bg={location.pathname === "/dashboard/planos-admin" && "#3058A4"}
        hover={
          location.pathname !== "/dashboard/planos-admin" && { bg: "#E0E0E0" }
        }
      >
        <BsCurrencyDollar
          fill={
            location.pathname === "/dashboard/planos-admin"
              ? "#E0E0E0"
              : "#3058A4"
          }
          size="1.5rem"
        />
      </DrawerDeskOptions>
    </Box>
  );
};
