import { Box, Flex, Grid, Text } from "@chakra-ui/react";

export const MessageCard = ({ item }) => {
  const date = new Date(item.data_pendencia);

  const options = { day: "2-digit", month: "2-digit", year: "numeric" };

  const formattedDate = date.toLocaleDateString("pt-BR", options);

  return (
    <Grid
      p="6"
      mt="8"
      w="100%"
      borderRadius="6px"
      border="2px solid #56A754"
      boxShadow="0px 12.78px 9.37px -1.71px #e6eeff"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontWeight="500" color="#4f4f4f" fontSize="1.25rem">
          {item.titulo_pendencia}
        </Text>
        <Text fontWeight="500" color="#4f4f4f" fontSize="1.25rem">
          {formattedDate}
        </Text>
      </Flex>
      <Box mt="4" mb="4" w="100%" h="2px" bg="#56A754" />
      <Text fontWeight="500" color="#4f4f4f" fontSize="1.125rem">
        {item.pendencias}
      </Text>
    </Grid>
  );
};
