import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import { ModalTargetContent } from "../../../pages/Products/ModalTargetContent";

import { PiTarget } from "react-icons/pi";

export const ModalTargetAudience = ({ isOpen, onClose, productName }) => {
  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={PiTarget} color="#fff" />
            <ModalHeader.Title title="Público Alvo" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <ModalTargetContent productName={productName} isOpen={isOpen} />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
