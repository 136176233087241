import { useAuth } from "../contexts/Auth";
import { matchPath, Navigate, useLocation } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const { data } = useAuth();

  const signed = data && Object.values(data).length > 0;
  const location = useLocation();
  const isInLoginRoute = !!matchPath("/", location.pathname);
  const isInRegisterRoute = !!matchPath("/cadastro", location.pathname);

  if ((signed && isInLoginRoute) || (signed && isInRegisterRoute)) {
    // Se o usuário está logado e está na rota de login ou na rota de cadastro, redireciona para o dashboard
    return <Navigate to="/dashboard/menu-inicial" state={{ from: location }} />;
  }

  if (!signed && !isInLoginRoute) {
    // Se o usuário não está logado e está tentando acessar uma rota protegida, redireciona para a rota de login
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};
