import { Link, Spinner } from "@chakra-ui/react";
import { ThemeContent } from "./styles";
import { useEffect, useRef, useState } from "react";

export const OpenThemeContent = ({ themeLink, onClose }) => {
  const [loading, setLoading] = useState(false);
  const linkRef = useRef(null);

  useEffect(() => {
    setLoading(true);

    const timer = setTimeout(() => {
      if (linkRef.current) {
        linkRef.current.click();

        onClose();

        setLoading(false);
      }
    }, 3000);

    return () => {
      clearTimeout(timer);

      setLoading(false);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <ThemeContent>
      <h4>Use a senha abaixo para visualizar o Tema</h4>

      <div className="password">SENHA: 1</div>

      <Link
        w="100%"
        h="40px"
        bg="linear-gradient(89.97deg, #3058a5 0.44%, #2964d3 99.97%)"
        rel="noreferrer"
        ref={linkRef}
        href={themeLink}
        color="#fff"
        target="_blank"
        display="flex"
        fontWeight="600"
        alignItems="center"
        borderRadius="6px"
        justifyContent="center"
        _hover={{
          opacity: 0.8,
          transition: "ease-in 0.2s",
        }}
      >
        {loading ? (
          <Spinner
            size="lg"
            color="white"
            speed="0.65s"
            thickness="4px"
            emptyColor="gray.200"
          />
        ) : (
          "Ver loja ao vivo"
        )}
      </Link>
    </ThemeContent>
  );
};
