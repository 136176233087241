import { Title } from "../../components/Reusable/Title";
import { MainPage } from "../../components/Reusable/Pagination/MainPage";
import { Container } from "../../components/Reusable/Container";
import { VendorCard } from "./CardProduct";
import { BiStoreAlt } from "react-icons/bi";
import { SearchContent } from "../AdministrativePanel/SocialMediaAdmin/styles";
import { SuppliersContent } from "../AdministrativePanel/SuppliersAdmin/styles";
import { useProductsAdmin } from "../../contexts/Products/ProductsContextAdmin";
import { useMemo, useState } from "react";
import { Grid, Input, Heading } from "@chakra-ui/react";

let PageSize = 12;

export const RegisterVendors = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { products, loadAllProducts } = useProductsAdmin();

  useMemo(() => {
    loadAllProducts();

    // eslint-disable-next-line
  }, []);

  const currentTableSuppliersAdmin = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (products !== undefined) {
      return products.slice(firstPageIndex, lastPageIndex);
    }

    return [];
  }, [currentPage, products]);

  return (
    <Container>
      <Title
        headingName="Fornecedores Manuais"
        caption="Página para visualização de produtos disponíveis para adicionar fornecedores de forma manual"
      >
        <BiStoreAlt size="2rem" fill="#3058A9" />
      </Title>

      <SearchContent>
        <div>
          <p>Search:</p>
          <Input w="270px" color="#505050" />
        </div>
      </SearchContent>

      <SuppliersContent>
        {currentTableSuppliersAdmin.length < 1 ? (
          <Grid
            gap="4"
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <Heading
              w="100%"
              color="#3058a4"
              fontSize="2rem"
              textAlign="center"
            >
              Sem produtos cadastrados
            </Heading>
          </Grid>
        ) : (
          <>
            <ul className="headers">
              <li className="headers__capa">Capa</li>
              <li className="headers__nome">Nome</li>
              <li className="headers__status">Status</li>
              <li className="headers__categoria">Categoria</li>
              <li className="headers__data">Data de postagem</li>
              <li className="headers__price">Preço R$</li>
              <li className="headers__acoes">Ações</li>
            </ul>
            <ul className="content">
              {currentTableSuppliersAdmin
                .filter((product) => product.adId > 0)
                .map((product) => {
                  return (
                    <VendorCard
                      key={product.id_fornecedores}
                      product={product}
                    />
                  );
                })}
            </ul>
          </>
        )}
      </SuppliersContent>
      {products !== undefined && (
        <MainPage
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={products.filter((product) => product.adId > 0).length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
    </Container>
  );
};
