import { useState, useContext, useCallback, createContext } from "react";

import { apiAuth } from "../../services/api";

const UserContext = createContext({});

// Criação do Hook useAuth
export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within an UserProvider");
  }

  return context;
};

export const UserProvider = ({ children }) => {
  const [users, setUsers] = useState([]);

  // Lista de usuários no Painel Admin
  const loadUsers = useCallback(async () => {
    const response = await apiAuth.get("/listausuarios");

    const users = response.data.data;

    const invertData = users.slice().reverse();

    localStorage.setItem("@PlayDrop:usuarios", JSON.stringify(invertData));

    const storedData = localStorage.getItem("@PlayDrop:usuarios");

    if (storedData) {
      setUsers(JSON.parse(storedData));
    }
  }, []);

  // Pesquisa usuário específico
  const searchUser = (username) => {
    username.toLocaleLowerCase();
    if (username === "") {
      loadUsers();
    } else {
      const filteredUsers = users.filter(
        (user) =>
          user.nome.toLocaleLowerCase().includes(username) ||
          user.email.toLocaleLowerCase().includes(username)
      );
      setUsers(filteredUsers);
    }
  };

  return (
    <UserContext.Provider value={{ users, searchUser, loadUsers }}>
      {children}
    </UserContext.Provider>
  );
};
