import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import draftToHtml from "draftjs-to-html";
import { useState } from "react";

export const DraftEditor = ({ label, whatNote, noteData, setNoteData }) => {
  const onChangeValue = (e) => {
    setNoteData({
      ...noteData,
      [e.target.name]: e.target.value,
    });
  };

  let editorState = EditorState.createEmpty();
  const [description, setDescription] = useState(editorState);
  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };

  return (
    <div className="container-notes">
      <label>{label}</label>

      <Editor
        editorState={description}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
      />

      <textarea
        style={{ display: "none" }}
        disabled
        ref={(val) => (noteData.description = val)}
        value={draftToHtml(convertToRaw(description.getCurrentContent()))}
      />

      {whatNote && (
        <div className="notas" dangerouslySetInnerHTML={{ __html: whatNote }} />
      )}
    </div>
  );
};
