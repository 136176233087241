import { Center } from "@chakra-ui/react";
import { FaTimes } from "react-icons/fa";

export const ModalHeaderClose = ({ onClose }) => {
  return (
    <Center
      w="22px"
      h="22px"
      cursor="pointer"
      onClick={() => onClose()}
      borderRadius="50%"
      border="1px solid #ffffff"
    >
      <FaTimes fill="#ffffff" size="1rem" />
    </Center>
  );
};
