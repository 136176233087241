import { Title } from "../../../components/Reusable/Title";
import { useUser } from "../../../contexts/User";
import { MainPage } from "../../../components/Reusable/Pagination/MainPage";
import { Container } from "../../../components/Reusable/Container";
import { UserContent } from "./UserContent";
import { FaSearch, FaUser } from "react-icons/fa";
import { useEffect, useMemo, useState } from "react";
import { HeaderUsers, ContentUsers } from "./styles";
import { Center, Input, InputGroup, InputRightElement } from "@chakra-ui/react";

let PageSize = 8;

export const UsersAdmin = () => {
  const [username, setUsername] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { users, searchUser, loadUsers } = useUser();

  useEffect(() => {
    loadUsers();

    // eslint-disable-next-line
  }, []);

  const currentTableUsers = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (users !== undefined) {
      return users.slice(firstPageIndex, lastPageIndex);
    }
    return [];
  }, [currentPage, users]);

  return (
    <Container>
      <HeaderUsers>
        <Title headingName="Usuários - Painel Administrativo">
          <FaUser fill="#3058a4" size="1.5rem" />
        </Title>
        <InputGroup mt="4" w="270px">
          <Input
            color="#505050"
            placeholder="Pesquisar usuário"
            value={username}
            onChange={(ev) => {
              setUsername(ev.target.value);
              searchUser(ev.target.value);
            }}
          />
          <InputRightElement w="50px">
            <Center
              as="button"
              _hover={{ opacity: 0.7 }}
              onClick={() => searchUser(username)}
            >
              <FaSearch fill="#3058a4" size="1.5rem" />
            </Center>
          </InputRightElement>
        </InputGroup>
      </HeaderUsers>
      <ContentUsers>
        <div className="header">
          <li className="header__nome">Nome</li>
          <li className="header__email">E-mail</li>
          <li className="header__plano">Plano</li>
          <li className="header__status">Status</li>
          <li className="header__venc">D. Venc.</li>
          <li className="header__pagamento">Pgto</li>
          <li className="header__acoes">Ações</li>
        </div>
        {currentTableUsers.map((user) => (
          <UserContent key={user.id} dataUser={user} />
        ))}
      </ContentUsers>
      {users.length > 1 && (
        <MainPage
          pageSize={PageSize}
          currentPage={currentPage}
          totalCount={users.length}
          className="pagination-bar"
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
    </Container>
  );
};
