import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import { OpenThemeContent } from "../../../pages/StoreBuilder/OpenThemeContent";

import { FaArrowDown } from "react-icons/fa";

export const ModalOpenTheme = ({ isOpen, onClose, theme_link }) => {
  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="28rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={FaArrowDown} color="#fff" />
            <ModalHeader.Title title="Visualizar Tema" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <OpenThemeContent themeLink={theme_link} onClose={onClose} />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
