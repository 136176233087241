import * as P from "./styles";

// Components
import {
  Flex,
  Center,
  ListItem,
  UnorderedList,
  useToast,
  Link,
} from "@chakra-ui/react";

// Hooks
import { useAuth } from "../../contexts/Auth";

// Icons
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { currencyFormat } from "../../utils/currencyFormat";
import { useNavigate } from "react-router-dom";
import { apiAuth } from "../../services/api";

export const CardPlan = ({ plan }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const { data, loadUser } = useAuth();

  // Separação da lista de benefícios ativos
  const activeBenefits = plan.ativos.split(",");

  // Separação da lista de benefícios inativos / não inclusos
  const inativesBenefits = plan.inativos !== "" && plan.inativos.split(",");

  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  const updatePlan = async (timePlan, planId) => {
    const date = new Date();
    const days = timePlan === 1 ? 30 : 365;

    const dueDate = addDays(date, days);

    const body = {
      id: data.id,
      id_login: data.id,
      id_planos: Number(planId),
      key_planos: Number(planId),
      time_plan: timePlan === 1 ? 30 : 365,
      plano_status: "ativo",
      plano_sub_status: "aguardando_pagamento",
      plano_data_venc: dueDate.toISOString().slice(0, 10),
    };

    try {
      await apiAuth
        .post(
          `/editarperfil?id=${data.id}`,
          { data: JSON.stringify(body) },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((_) => {
          navigate("/dashboard/construtor-de-lojas");
          loadUser(data.id);
        });
    } catch (error) {
      toast({
        title: "Opss...",
        description: "Algo deu errado!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <P.CardPlanContent bgSchema={plan.id === 9}>
      {plan.id === 4 && (
        <div className="best-seller">Ideal para iniciantes</div>
      )}
      {plan.id === 5 && (
        <div className="best-seller">Ideal para começar a vender</div>
      )}
      {plan.id === 9 && (
        <div className="best-seller">Ideal para escalar sua empresa</div>
      )}

      <h4 className="title-plan">
        {plan.id === 4 ? "Mensal" : plan.id === 5 ? "Trimestral" : "Anual"}
      </h4>

      <UnorderedList ml="0" mb="6">
        <>
          {activeBenefits.map((benefits, index) => {
            return (
              <Flex key={index} alignItems="baseline">
                <Center>
                  <BsCheckLg size="1rem" fill="#03AC00" />
                </Center>
                <ListItem
                  ml="2"
                  fontWeight="400"
                  fontStyle="normal"
                  fontFamily="Poppins"
                  fontSize="1.2rem"
                  color={plan.id === 9 ? "#fff" : "#4f4f4f"}
                >
                  {benefits}
                </ListItem>
              </Flex>
            );
          })}
          {inativesBenefits &&
            inativesBenefits.map((benefits, index) => {
              return (
                <Flex key={index} alignItems="baseline">
                  <Center>
                    <BsXLg size="1rem" fill="#F44336" />
                  </Center>
                  <ListItem
                    fontSize="1.2rem"
                    color="#B0B0B0"
                    fontWeight="400"
                    fontFamily="Poppins"
                    fontStyle="normal"
                    ml="2"
                  >
                    {benefits}
                  </ListItem>
                </Flex>
              );
            })}
        </>
      </UnorderedList>

      <div className="price-gold">
        {/* <div className="old-price">
          <p>
            {plan.id === 4
              ? "R$ 59,99"
              : plan.id === 5
              ? "R$ 129,90"
              : "R$ 299,99"}
          </p>
          <div>
            {plan.id === 4 ? "55% off" : plan.id === 5 ? "65% off" : "50% off"}
          </div>
        </div> */}

        <div className="new-price">
          <span>A partir de:</span>
          <div>
            <p>{currencyFormat(plan.mensalidades)}</p>
            <span>/mês</span>
          </div>
        </div>
      </div>

      {/* {plan.id === 4 && (
        <div className="free-plan" position="relative">
          <div className="top" />
          <div className="back">Plano gratuito</div>
        </div>
      )} */}

      <Link
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        rel="noreferrer"
        target="_blank"
        href={
          plan.id === 4
            ? "https://pay.kirvano.com/4b05567b-e942-4cd4-8522-cb35a54df55b"
            : plan.id === 5
            ? "https://pay.kirvano.com/b1f4c8a2-d71a-4ff3-929c-1092b966d0ea"
            : "https://pay.kirvano.com/4d360586-2361-4f0b-aa90-3d953249a5e3"
        }
      >
        <P.StyledButton
          disabled={plan.id === data.id_planos ? true : false}
          activePlan={plan.id === data.id_planos ? true : false}
          onClick={() => {
            if (plan.id === 4) {
              updatePlan(1, plan.id);
            } else if (plan.id === 5) {
              updatePlan(3, plan.id);
            } else {
              updatePlan(12, plan.id);
            }
          }}
        >
          {plan.id === data.id_planos ? "Plano Ativo" : "Assinar"}
        </P.StyledButton>
      </Link>
    </P.CardPlanContent>
  );
};
