import { PlaydropLogo } from "../../components/Reusable/PlayDropLogo";
import { CreateNewPassForm } from "../../components/Forms/CreateNewPassForm";
import { Container, Content } from "./styles";

import qrDash from "../../assets/icons/qr_sidebar.png";

export const CreateNewPassword = () => {
  return (
    <Container>
      <Content>
        <PlaydropLogo colorLogo="#3058A4" heightLogo="46px" widthLogo="176px" />

        <CreateNewPassForm />

        <img className="img-qr" src={qrDash} alt="Imagem qr code para footer" />
      </Content>
    </Container>
  );
};
