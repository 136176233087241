import * as PC from "./styles";

import { Link, useNavigate } from "react-router-dom";

import { useProductsClient } from "../../contexts/Products/ProductsContextClient";
import { useState, useEffect } from "react";

import { MenuOption } from "./MenuOption";
import { SkeletonInfoDetailsProduct } from "../../components/Reusable/Skeletons/SkeletonInfoDetailsProduct";
import {
  Flex,
  Link as LinkChakra,
  Text,
  Input,
  Button,
  Select,
} from "@chakra-ui/react";

import { TbPhotoEdit } from "react-icons/tb";

import { MdCampaign } from "react-icons/md";
import { PiCirclesThreePlusBold } from "react-icons/pi";
import { BiCalculator, BiStoreAlt } from "react-icons/bi";
import { FaCheck, FaEdit, FaRegCopyright, FaTrash } from "react-icons/fa";

import { ContentImageDetails } from "./ContentImageDetails";

import { DraftEditor } from "./DraftEditor";

const options = [
  "O criativo mostra que o produto resolve uma dor",
  'O criativo tem um fator "UAU"',
  "O criativo prende a atenção nos 0-5 primeiros segundos",
  "O criativo desperta curiosidade",
];

export const ProductContentConveyor = ({ product, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [showLink, setShowLink] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showMoreSupplier, setShowMoreSupplier] = useState("");

  const [menuOption, setMenuOption] = useState("supplier");
  const [changeData, setChangeData] = useState({});
  const [showEditImage, setShowEditImage] = useState(false);

  const navigate = useNavigate();

  const [infoNotes, setInfoNotes] = useState({});
  const [copyOne, setCopyOne] = useState({});
  const [copyTwo, setCopyTwo] = useState({});
  const [campaignNotes, setCampaignNotes] = useState({});

  const [checkedItems, setCheckedItems] = useState([]);

  const {
    deteleProduct,
    productDetails,
    loadProductDetails,
    updateProductDetails,
  } = useProductsClient();

  useEffect(() => {
    setLoading(true);

    loadProductDetails(product.id)
      .then((_) => setLoading(false))
      .catch((_) => setLoading(false));

    // eslint-disable-next-line
  }, [product]);

  useEffect(() => {
    if (productDetails) {
      setChangeData(productDetails);
    }
  }, [productDetails]);

  const handleUpdateProduct = () => {
    updateProductDetails(
      {
        ...changeData,
        notas: infoNotes.description && infoNotes.description.value,
        copy_1: copyOne.description && copyOne.description.value,
        copy_2: copyTwo.description && copyTwo.description.value,
        notas_campanha:
          campaignNotes.description && campaignNotes.description.value,
        criativo_2: checkedItems.length > 0 ? checkedItems.join(", ") : "",
      },
      setLoadingButton
    );
  };

  const handleCheck = (option) => {
    setCheckedItems((prevState) => {
      if (prevState.includes(option)) {
        return prevState.filter((item) => item !== option);
      } else {
        return [...prevState, option];
      }
    });
  };

  const srcProduct =
    "https://services.playdrop.com.br/api/uploads/imgs/produtos";

  return (
    <PC.Details>
      {loading ? (
        <SkeletonInfoDetailsProduct width="250px" />
      ) : (
        <div className="left-items-title">
          {!showEditImage && productDetails.image ? (
            <div className="image-ok">
              <img
                src={`${srcProduct}/${productDetails.image}`}
                alt={productDetails.name}
              />
              <span onClick={() => setShowEditImage(true)}>
                <TbPhotoEdit size="1.6rem" color="#3058a4" />
              </span>
            </div>
          ) : (
            <ContentImageDetails
              setShowEditImage={setShowEditImage}
              productId={product.id}
            />
          )}

          <h4>{changeData && changeData.name}</h4>

          {productDetails.link && showLink ? (
            <div className="link-flex">
              <div>
                <FaEdit size="1.25rem" onClick={() => setShowLink(false)} />
              </div>
              <LinkChakra
                className="link"
                rel="noreferrer"
                href={`${productDetails.link}`}
                target="_blank"
              >
                {productDetails.link}
              </LinkChakra>
            </div>
          ) : null}
          {!productDetails.link || !showLink ? (
            <Input
              border="2px solid #3058a4"
              onChange={(ev) =>
                setChangeData({ ...changeData, link: ev.target.value })
              }
              placeholder="Inserir link do produto"
              defaultValue={changeData && changeData.link}
            />
          ) : null}

          <p className="date">
            Adicionado em: <span>{changeData && changeData.created}</span>
          </p>

          <div className="links">
            <MenuOption
              icon={BiStoreAlt}
              iconSize="1.5rem"
              text="Fornecedores"
              onFunction={() => setMenuOption("supplier")}
            />

            <MenuOption
              icon={PiCirclesThreePlusBold}
              iconSize="1.5rem"
              text="Criativos"
              onFunction={() => setMenuOption("criatives")}
            />

            <MenuOption
              icon={MdCampaign}
              iconSize="1.5rem"
              text="Campanha"
              onFunction={() => setMenuOption("campaign")}
            />

            <Link to={`/dashboard/copy/${changeData.name}`}>
              <MenuOption
                icon={FaRegCopyright}
                iconSize="1.5rem"
                text="Gerar copy com IA"
              />
            </Link>

            <MenuOption
              className="trash"
              icon={FaTrash}
              iconSize="1.5rem"
              text="Remover da esteira"
              onClick={() => {
                deteleProduct(product.id);
                onClose();
              }}
            />
          </div>
        </div>
      )}

      <div className="divider" />

      {menuOption === "supplier" ? (
        loading ? (
          <SkeletonInfoDetailsProduct width="100%" />
        ) : (
          <div className="infos-product">
            <h4>Informações de fornecedores</h4>

            <div className="grid-suppliers">
              <div className="flex-supplier">
                <div>
                  <label>Link fornecedor 1</label>
                  <Input
                    w="100%"
                    h="3.125rem"
                    placeholder="https://www.produtoexemplo.com.br/produtoexemplo"
                    defaultValue={changeData && changeData.link_fornecedor_1}
                    onChange={(ev) =>
                      setChangeData({
                        ...changeData,
                        link_fornecedor_1: ev.target.value,
                      })
                    }
                  />

                  <button
                    className="more-supplier"
                    onClick={() => setShowMoreSupplier("fornecedor2")}
                  >
                    Adicionar fornecedor
                  </button>
                </div>

                <div className="info-price">
                  <div className="flex-content">
                    <div>
                      <label>Preço de Custo</label>
                      <div className="flex-input">
                        <Flex
                          top="0.55rem"
                          left="2"
                          position="absolute"
                          alignItems="center"
                        >
                          <Text
                            fontSize="1.25rem"
                            color="#3058a4"
                            fontWeight="500"
                          >
                            R$
                          </Text>
                        </Flex>
                        <Input
                          h="3.125rem"
                          pl="2.1rem"
                          color="#3058a4"
                          fontWeight="500"
                          border="2px solid #3058a4"
                          onChange={(ev) =>
                            setChangeData({
                              ...changeData,
                              preco_custo_1: ev.target.value,
                            })
                          }
                          placeholder={
                            changeData.preco_custo_1
                              ? changeData.preco_custo_1
                              : "0,00"
                          }
                          defaultValue={changeData && changeData.preco_custo_1}
                        />
                      </div>
                    </div>

                    <div>
                      <label>Preço de Venda</label>
                      <div className="flex-input">
                        <Flex
                          top="0.55rem"
                          left="2"
                          position="absolute"
                          alignItems="center"
                        >
                          <Text
                            fontSize="1.25rem"
                            color="#3058a4"
                            fontWeight="500"
                          >
                            R$
                          </Text>
                        </Flex>
                        <Input
                          h="3.125rem"
                          pl="2.1rem"
                          color="#3058a4"
                          fontWeight="500"
                          border="2px solid #3058a4"
                          onChange={(ev) =>
                            setChangeData({
                              ...changeData,
                              preco_venda_1: ev.target.value,
                            })
                          }
                          placeholder={
                            changeData.preco_venda_1
                              ? changeData.preco_venda_1
                              : "0,00"
                          }
                          defaultValue={changeData && changeData.preco_venda_1}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="flex-icon"
                    onClick={() =>
                      navigate(
                        `/dashboard/calculadora/${changeData.name}/${Number(
                          changeData.preco_custo_1
                        )}`
                      )
                    }
                  >
                    <p>Precificar</p>
                    <BiCalculator size="1rem" color="#fff" />
                  </div>
                </div>
              </div>
            </div>

            {changeData.link_fornecedor_2 ||
            showMoreSupplier === "fornecedor2" ||
            showMoreSupplier === "fornecedor3" ? (
              <div className="grid-suppliers">
                <div className="flex-supplier">
                  <div>
                    <label>Link fornecedor 2</label>
                    <Input
                      w="100%"
                      h="3.125rem"
                      placeholder="https://www.produtoexemplo.com.br/produtoexemplo"
                      defaultValue={changeData && changeData.link_fornecedor_2}
                      onChange={(ev) =>
                        setChangeData({
                          ...changeData,
                          link_fornecedor_2: ev.target.value,
                        })
                      }
                    />

                    <div className="flex-buttons">
                      <button
                        className="more-supplier"
                        onClick={() => setShowMoreSupplier("fornecedor3")}
                      >
                        Adicionar fornecedor
                      </button>

                      <button
                        className="more-supplier"
                        onClick={() => setShowMoreSupplier("fornecedor1")}
                      >
                        Fechar
                      </button>
                    </div>
                  </div>

                  <div className="info-price">
                    <div className="flex-content">
                      <div>
                        <label>Preço de Custo</label>

                        <div className="flex-input">
                          <Flex
                            top="0.55rem"
                            left="2"
                            position="absolute"
                            alignItems="center"
                          >
                            <Text
                              fontSize="1.25rem"
                              color="#3058a4"
                              fontWeight="500"
                            >
                              R$
                            </Text>
                          </Flex>
                          <Input
                            h="3.125rem"
                            pl="2.1rem"
                            color="#3058a4"
                            fontWeight="500"
                            border="2px solid #3058a4"
                            onChange={(ev) =>
                              setChangeData({
                                ...changeData,
                                preco_custo_2: ev.target.value,
                              })
                            }
                            placeholder={
                              changeData.preco_custo_2
                                ? changeData.preco_custo_2
                                : "0,00"
                            }
                            defaultValue={
                              changeData && changeData.preco_custo_2
                            }
                          />
                        </div>
                      </div>

                      <div>
                        <label>Preço de Venda</label>
                        <div className="flex-input">
                          <Flex
                            top="0.55rem"
                            left="2"
                            position="absolute"
                            alignItems="center"
                          >
                            <Text
                              fontSize="1.25rem"
                              color="#3058a4"
                              fontWeight="500"
                            >
                              R$
                            </Text>
                          </Flex>
                          <Input
                            h="3.125rem"
                            pl="2.1rem"
                            color="#3058a4"
                            fontWeight="500"
                            border="2px solid #3058a4"
                            onChange={(ev) =>
                              setChangeData({
                                ...changeData,
                                preco_venda_2: ev.target.value,
                              })
                            }
                            placeholder={
                              changeData.preco_venda_2
                                ? changeData.preco_venda_2
                                : "0,00"
                            }
                            defaultValue={
                              changeData && changeData.preco_venda_2
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="flex-icon"
                      onClick={() =>
                        navigate(
                          `/dashboard/calculadora/${changeData.name}/${Number(
                            changeData.preco_custo_2
                          )}`
                        )
                      }
                    >
                      <p>Precificar</p>
                      <BiCalculator size="1rem" color="#fff" />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {changeData.link_fornecedor_3 ||
            showMoreSupplier === "fornecedor3" ? (
              <div className="grid-suppliers">
                <div className="flex-supplier">
                  <div>
                    <label>Link fornecedor 3</label>
                    <Input
                      w="100%"
                      h="3.125rem"
                      placeholder="https://www.produtoexemplo.com.br/produtoexemplo"
                      defaultValue={changeData && changeData.link_fornecedor_3}
                      onChange={(ev) =>
                        setChangeData({
                          ...changeData,
                          link_fornecedor_3: ev.target.value,
                        })
                      }
                    />

                    <button
                      className="more-supplier"
                      onClick={() => setShowMoreSupplier("fornecedor2")}
                    >
                      Fechar
                    </button>
                  </div>

                  <div className="info-price">
                    <div className="flex-content">
                      <div>
                        <label>Preço de Custo</label>
                        <div className="flex-input">
                          <Flex
                            top="0.55rem"
                            left="2"
                            position="absolute"
                            alignItems="center"
                          >
                            <Text
                              fontSize="1.25rem"
                              color="#3058a4"
                              fontWeight="500"
                            >
                              R$
                            </Text>
                          </Flex>
                          <Input
                            h="3.125rem"
                            pl="2.1rem"
                            color="#3058a4"
                            fontWeight="500"
                            border="2px solid #3058a4"
                            onChange={(ev) =>
                              setChangeData({
                                ...changeData,
                                preco_custo_3: ev.target.value,
                              })
                            }
                            placeholder={
                              changeData.preco_custo_3
                                ? changeData.preco_custo_3
                                : "0,00"
                            }
                            defaultValue={
                              changeData && changeData.preco_custo_3
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <label>Preço de Venda</label>
                        <div className="flex-input">
                          <Flex
                            top="0.55rem"
                            left="2"
                            position="absolute"
                            alignItems="center"
                          >
                            <Text
                              fontSize="1.25rem"
                              color="#3058a4"
                              fontWeight="500"
                            >
                              R$
                            </Text>
                          </Flex>
                          <Input
                            h="3.125rem"
                            pl="2.1rem"
                            color="#3058a4"
                            fontWeight="500"
                            border="2px solid #3058a4"
                            onChange={(ev) =>
                              setChangeData({
                                ...changeData,
                                preco_venda_3: ev.target.value,
                              })
                            }
                            placeholder={
                              changeData.preco_venda_3
                                ? changeData.preco_venda_3
                                : "0,00"
                            }
                            defaultValue={
                              changeData && changeData.preco_venda_3
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="flex-icon"
                      onClick={() =>
                        navigate(
                          `/dashboard/calculadora/${changeData.name}/${Number(
                            changeData.preco_custo_3
                          )}`
                        )
                      }
                    >
                      <p>Precificar</p>
                      <BiCalculator size="1rem" color="#fff" />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <DraftEditor
              label="Notas"
              whatNote={changeData.notas}
              noteData={infoNotes}
              setNoteData={setInfoNotes}
            />

            <div className="button">
              <Button
                type="button"
                isLoading={loadingButton}
                onClick={() => {
                  handleUpdateProduct();
                  setTimeout(() => {
                    setShowLink(true);
                  }, 1000);
                }}
              >
                Salvar alterações
              </Button>
            </div>
          </div>
        )
      ) : menuOption === "criatives" ? (
        <div className="infos-product">
          <h4>Criativos</h4>

          <div>
            <label>Headline</label>
            <Input
              w="100%"
              h="3.125rem"
              onChange={(ev) =>
                setChangeData({ ...changeData, criativo_1: ev.target.value })
              }
              placeholder="Headline do Criativo"
              defaultValue={changeData && changeData.criativo_1}
            />
          </div>

          <DraftEditor
            label="Copys do Criativo"
            whatNote={changeData.copy_1}
            noteData={copyOne}
            setNoteData={setCopyOne}
          />

          <DraftEditor
            label="Persona - Quem vai comprar de você?"
            whatNote={changeData.copy_2}
            noteData={copyTwo}
            setNoteData={setCopyTwo}
          />

          <PC.Checklist>
            <label>Checklist de Critérios</label>

            <div className="checklist">
              {options.map((option, index) => (
                <PC.ChecklistItem
                  key={index}
                  checked={checkedItems.includes(option)}
                  onClick={() => handleCheck(option)}
                >
                  <div className="icons">
                    {checkedItems.includes(option) ? (
                      <FaCheck size="1.5rem" color="#00ff00" />
                    ) : (
                      <FaCheck size="1.5rem" color="#e6eeff" />
                    )}
                  </div>
                  {option}
                </PC.ChecklistItem>
              ))}
            </div>
          </PC.Checklist>

          <div className="button">
            <Button
              type="button"
              isLoading={loadingButton}
              onClick={() => handleUpdateProduct()}
            >
              Salvar alterações
            </Button>
          </div>
        </div>
      ) : menuOption === "campaign" ? (
        <div className="infos-product">
          <h4>Campanhas</h4>

          <div className="flex-content-inputs">
            <div>
              <label>Nome Campanha</label>
              <Input
                w="100%"
                h="3.125rem"
                onChange={(ev) =>
                  setChangeData({ ...changeData, campanha: ev.target.value })
                }
                placeholder="Digite aqui o nome"
                defaultValue={changeData && changeData.campanha}
              />
            </div>

            <div>
              <label>Tipo Campanha</label>
              <Input
                w="100%"
                h="3.125rem"
                onChange={(ev) =>
                  setChangeData({
                    ...changeData,
                    tipo_campanha: ev.target.value,
                  })
                }
                placeholder="Digite aqui o tipo"
                defaultValue={changeData && changeData.tipo_campanha}
              />
            </div>

            <div>
              <label>QTD Criativos</label>
              <Input
                w="100%"
                h="3.125rem"
                onChange={(ev) =>
                  setChangeData({
                    ...changeData,
                    qtd_criativo: ev.target.value,
                  })
                }
                placeholder="Digite aqui a quantidade"
                defaultValue={changeData && changeData.qtd_criativo}
              />
            </div>
          </div>

          <div>
            <h4 className="margin">Público-alvo da campanha</h4>

            <div className="flex-content-inputs">
              <div className="publico-alvo">
                <label>Público Personalizado/Interesses</label>
                <Input
                  w="100%"
                  h="3.125rem"
                  onChange={(ev) =>
                    setChangeData({ ...changeData, interesse: ev.target.value })
                  }
                  placeholder="Interesse"
                  defaultValue={changeData && changeData.interesse}
                />
              </div>

              <div className="publico-alvo">
                <label>Idade</label>
                <Input
                  w="100%"
                  h="3.125rem"
                  onChange={(ev) =>
                    setChangeData({ ...changeData, idade: ev.target.value })
                  }
                  placeholder="Idade"
                  defaultValue={changeData && changeData.idade}
                />
              </div>

              <div className="publico-alvo">
                <label>Gênero</label>

                <Select
                  w="100%"
                  h="3.125rem"
                  onChange={(ev) =>
                    setChangeData({ ...changeData, genero: ev.target.value })
                  }
                  placeholder="Gênero"
                  defaultValue={changeData && changeData.genero}
                >
                  <option value="masculino">Masculino</option>
                  <option value="feminino">Feminino</option>
                  <option value="ambos">Ambos</option>
                </Select>
              </div>
            </div>
          </div>

          <div>
            <h4 className="margin">Investimento campanha</h4>

            <div className="flex-content-inputs">
              <div>
                <label>Face ADS</label>
                <div className="flex-input">
                  <Flex
                    top="0.6rem"
                    left="2"
                    position="absolute"
                    alignItems="center"
                  >
                    <Text fontSize="1.25rem" color="#3058a4" fontWeight="500">
                      R$
                    </Text>
                  </Flex>

                  <Input
                    w="100%"
                    h="3.125rem"
                    pl="2rem"
                    color="#3058a4"
                    border="2px solid #3058a4"
                    fontSize="1.125rem"
                    fontWeight="500"
                    onChange={(ev) =>
                      setChangeData({
                        ...changeData,
                        ads_face: ev.target.value,
                      })
                    }
                    defaultValue={changeData && changeData.ads_face}
                  />
                </div>
              </div>

              <div>
                <label>Google ADS</label>

                <div className="flex-input">
                  <Flex
                    top="0.6rem"
                    left="2"
                    position="absolute"
                    alignItems="center"
                  >
                    <Text fontSize="1.25rem" color="#3058a4" fontWeight="500">
                      R$
                    </Text>
                  </Flex>
                  <Input
                    w="100%"
                    h="3.125rem"
                    pl="2rem"
                    color="#3058a4"
                    border="2px solid #3058a4"
                    fontSize="1.125rem"
                    fontWeight="500"
                    onChange={(ev) =>
                      setChangeData({
                        ...changeData,
                        ads_google: ev.target.value,
                      })
                    }
                    defaultValue={changeData && changeData.ads_google}
                  />
                </div>
              </div>

              <div>
                <label>Outros</label>

                <div className="flex-input">
                  <Flex
                    top="0.6rem"
                    left="2"
                    position="absolute"
                    alignItems="center"
                  >
                    <Text fontSize="1.25rem" color="#3058a4" fontWeight="500">
                      R$
                    </Text>
                  </Flex>
                  <Input
                    w="100%"
                    h="3.125rem"
                    pl="2rem"
                    color="#3058a4"
                    border="2px solid #3058a4"
                    fontSize="1.125rem"
                    fontWeight="500"
                    onChange={(ev) =>
                      setChangeData({
                        ...changeData,
                        ads_outros: ev.target.value,
                      })
                    }
                    defaultValue={changeData && changeData.ads_outros}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <DraftEditor
              label="Observações diárias da Campanha"
              whatNote={changeData.notas_campanha}
              noteData={campaignNotes}
              setNoteData={setCampaignNotes}
            />

            <div className="button mt-1">
              <Button
                type="button"
                isLoading={loadingButton}
                onClick={() => handleUpdateProduct()}
              >
                Salvar alterações
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </PC.Details>
  );
};
