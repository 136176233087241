import * as L from "./styles";

import { Header } from "../Header";
import { Outlet } from "react-router-dom";
import { DesktopDrawer } from "../Drawer/DesktopDrawer";
import { DrawerAdmPanelDesk } from "../Drawer/DrawerAdmPanelDesk";

import { useAuth } from "../../contexts/Auth";
import { Center, Link } from "@chakra-ui/react";
import { FaWhatsapp } from "react-icons/fa";

export const Layout = () => {
  const { data } = useAuth();

  const signed = data && Object.values(data).length > 0;

  return (
    <L.Container>
      <L.Content>
        <Header />

        <L.Grid>
          <Outlet />
        </L.Grid>

        <L.Flex>
          <div className="support">
            <div></div>
          </div>
          {signed && data.perfil === "admin" ? (
            <DrawerAdmPanelDesk />
          ) : (
            <DesktopDrawer />
          )}
          <Outlet />
        </L.Flex>

        <Link
          href="https://wa.me/+5511952177459?text=Ol%C3%A1,%20preciso%20de%20uma%20ajuda"
          target="_blank"
          rel="noreferrer"
          position="fixed"
          bottom="5.5rem"
          right="1.8rem"
        >
          <Center cursor="pointer" p="0.3rem" bg="#fff" borderRadius="50%">
            <FaWhatsapp color="#3058a4" size="2.8rem" />
          </Center>
        </Link>
      </L.Content>
    </L.Container>
  );
};
