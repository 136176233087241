import { BiStoreAlt } from "react-icons/bi";
import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Grid,
  Heading,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { apiAuth } from "../../../services/api";

export const ModalMiningProducts = ({ isOpen, onClose }) => {
  const [token, setToken] = useState("");
  const [jsonInput, setJsonInput] = useState("");
  const [isValidJson, setIsValidJson] = useState(true);
  const [itemNames, setItemNames] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const toast = useToast();

  const handleChange = (e) => {
    setJsonInput(e.target.value);
    validateJson(e.target.value);
  };

  const handleCheckboxChange = (selected) => {
    setSelectedItems(selected);
  };

  const validateJson = (jsonString) => {
    try {
      const parsedJson = JSON.parse(jsonString);

      if (!!parsedJson) {
        const names = [parsedJson].map((item) => item.MainCreative.Title);

        setItemNames(names);
      } else {
        setItemNames([]);
      }
      setIsValidJson(true);
    } catch (e) {
      setIsValidJson(false);
      setItemNames([]);
    }
  };

  const copyToClipboard = async () => {
    const parsedJson = JSON.parse(jsonInput);
    const selectedJson = parsedJson.filter((item) =>
      selectedItems.includes(item.MainCreative.Title)
    );
    const selectedJsonString = JSON.stringify(selectedJson, null, 2);

    if (token !== "") {
      try {
        await apiAuth
          .post(
            "/data-save",
            { json: selectedJsonString, token: token },
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((_) => {
            toast({
              title: "Sucesso",
              description: "Fornecedores importados!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          });
      } catch (error) {
        toast({
          title: "Opss...",
          description: "Algo deu errado ao importar fornecedores!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="46rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BiStoreAlt} color="#fff" />
            <ModalHeader.Title title="Adicionar JSON" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>

        <Modal.Body>
          <Grid>
            <Heading
              mt="1rem"
              mb="2rem"
              color="#3058a4"
              fontSize="1.125rem"
              fontWeight="500"
            >
              Cole os dados em JSON que você copiou abaixo:
            </Heading>

            <Textarea
              h="250px"
              value={jsonInput}
              onChange={handleChange}
              placeholder="Cole o JSON aqui"
            />

            <Grid
              w="100%"
              m="2rem 0"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {isValidJson ? (
                <Box fontSize="1.5rem" color="green">
                  O JSON fornecido é válido!!
                </Box>
              ) : (
                <Box fontSize="1.5rem" color="red">
                  O JSON fornecido é inválido!!
                </Box>
              )}
            </Grid>

            {isValidJson && itemNames.length > 0 && (
              <Grid w="100%">
                <Heading mb="1rem" fontSize="1.125rem" color="#3058a4">
                  Nome dos produtos
                </Heading>

                <CheckboxGroup onChange={handleCheckboxChange}>
                  <Grid
                    display="grid"
                    gridGap="1rem"
                    gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
                  >
                    {itemNames.map((item) => (
                      <Checkbox key={item} value={item}>
                        <Box
                          p="10px"
                          bg="#3058a4"
                          color="#fff"
                          borderRadius="10px"
                        >
                          {item}
                        </Box>
                      </Checkbox>
                    ))}
                  </Grid>
                </CheckboxGroup>

                <Input
                  w="100%"
                  h="50px"
                  mt="1rem"
                  border="2px solid #3058a4"
                  onChange={(ev) => setToken(ev.target.value)}
                  placeholder="Cole aqui o Token vindo do Parceiro"
                  borderRadius="10px"
                />

                <Button onClick={copyToClipboard} mt="1rem" colorScheme="blue">
                  Importar Fornecedores
                </Button>
              </Grid>
            )}
          </Grid>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
