// Components
import { Title } from "../../../components/Reusable/Title";
import { MainPage } from "../../../components/Reusable/Pagination/MainPage";
import { Container } from "../../../components/Reusable/Container";
import { MediaCard } from "./MediaCard";
import { ModalCrudSocialMedia } from "../../../components/Modal/SocialMedia/ModalCrudSocialMedia";
import { SearchContent, MediaContent } from "./styles";
import {
  Grid,
  Input,
  Select,
  Button,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";

// Hooks
import { useNavigate } from "react-router-dom";
import { useMediasClient } from "../../../contexts/SocialMedia/MediasContextClient";
import { useMemo, useState } from "react";

// Icons
import { MdArrowDropDown } from "react-icons/md";
import { PiCirclesThreePlusBold } from "react-icons/pi";
import { useAuth } from "../../../contexts/Auth";

let PageSize = 6;

export const SocialMediaAdmin = () => {
  const navigate = useNavigate();

  const { data } = useAuth();
  const { socialData, loadMedias } = useMediasClient();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentPage, setCurrentPage] = useState(1);

  useMemo(() => {
    Object.values(data).length > 0 && loadMedias();
  }, [data]);

  const currentTableSocialMediaAdmin = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (socialData !== undefined) {
      return socialData.slice(firstPageIndex, lastPageIndex);
    }

    return [];
  }, [currentPage, socialData]);

  return (
    <Container>
      <Title
        headingName="Criativos - Painel Administrativo"
        caption="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam convallis turpis at varius tincidunt. Morbi aliquet efficitur purus, id condimentum leo blandit at."
      >
        <PiCirclesThreePlusBold fill="#3058A4" size="1.5rem" />
      </Title>
      <Button
        h="50px"
        w="250px"
        mb="1rem"
        bg="#3058A4"
        color="#ffffff"
        fontFamily="Poppins"
        _hover={{ opacity: 0.7 }}
        onClick={() => onOpen()}
      >
        Publicar nova mídia social
      </Button>
      <SearchContent>
        <div>
          <p>Show</p>
          <Select
            w="5rem"
            h="2rem"
            outline="0"
            color="#505050"
            iconSize="1rem"
            cursor="pointer"
            borderRadius="6px"
            iconColor="#3058A4"
            fontFamily="Poppins"
            fontSize="1.125rem"
            border="1px solid #505050"
            icon={<MdArrowDropDown />}
            _hover={{ borderColor: "#3058A4" }}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </Select>
          <p>entries</p>
        </div>
        <div>
          <p>Search:</p>
          <Input w="270px" color="#505050" />
        </div>
      </SearchContent>
      <MediaContent>
        {currentTableSocialMediaAdmin.length < 1 ? (
          <Grid
            gap="4"
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <Heading
              w="100%"
              color="#3058a4"
              fontSize="2rem"
              textAlign="center"
            >
              Não há mídia cadastrada
            </Heading>
            <Button
              h="50px"
              w="250px"
              mb="1rem"
              bg="#3058A4"
              color="#ffffff"
              fontFamily="Poppins"
              _hover={{ opacity: 0.7 }}
              onClick={() => navigate("/dashboard/midias-admin/nova-midia")}
            >
              Cadastrar nova mídia social
            </Button>
          </Grid>
        ) : (
          <>
            <ul className="headers">
              <li className="headers__capa">Capa</li>
              <li className="headers__nome">Nome</li>
              <li className="headers__categoria">Categoria</li>
              <li className="headers__data">Data de postagem</li>
              <li className="headers__exibicao">Status de exibição</li>
              <li className="headers__acoes">Ações</li>
            </ul>
            <ul className="content">
              {currentTableSocialMediaAdmin.map((media) => {
                return <MediaCard key={media.id} mediaData={media} />;
              })}
            </ul>
          </>
        )}
      </MediaContent>
      {socialData !== undefined && (
        <MainPage
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={socialData.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
      <ModalCrudSocialMedia
        isOpen={isOpen}
        onClose={onClose}
        verbType="create"
      />
    </Container>
  );
};
