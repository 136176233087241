import { useDisclosure } from "@chakra-ui/react";
import { ModalAddVendors } from "../../components/Modal/Products/ModalAddVendors";

export const VendorCard = ({ product }) => {
  const srcProduct =
    "https://services.playdrop.com.br/api/uploads/imgs/produtos";

  const isVideo = (filename) => {
    const videoExtensions = ["mp4", "avi", "mov", "mkv"]; // Adicione outras extensões de vídeo se necessário
    const extension = filename.split(".").pop();
    return videoExtensions.includes(extension.toLowerCase());
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <div className="main">
        <li className="content__capa">
          {isVideo(product.imagem) ? (
            <video controls muted>
              <source
                src={`${srcProduct}/${product.imagem}`}
                type={`video/${product.imagem.split(".").pop()}`}
              />
              Seu navegador não suporta o elemento de vídeo.
            </video>
          ) : (
            <img
              alt={product.produto}
              src={`${srcProduct}/${product.imagem}`}
            />
          )}
        </li>
        <li className="content__nome">{product.produto}</li>
        <li
          className={`content__status ${
            product.status === "Pendente" ? "orange" : ""
          }`}
        >
          {product.status}
        </li>
        <li className="content__categoria">{product.categoria}</li>
        <li className="content__data">{product.data}</li>
        <li className="content__price">
          {product.preco.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
          })}
        </li>
        <li className="content__acoes">
          <button className="btn-add-vendors" onClick={() => onOpen()}>
            Add fornecedor
          </button>
        </li>
      </div>

      <ModalAddVendors
        isOpen={isOpen}
        onClose={onClose}
        adId={product.adId}
        productName={product.produto}
      />
    </>
  );
};
