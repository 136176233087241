import * as C from "./styles";

import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { currencyFormat } from "../../utils/currencyFormat";

import { Title } from "../../components/Reusable/Title";
import { Container } from "../../components/Reusable/Container";
import { Flex, Input, Spinner, Text } from "@chakra-ui/react";

import { GoBack } from "../../components/Reusable/GoBack";
import { FiTruck } from "react-icons/fi";
import { CiDollar } from "react-icons/ci";
import { BiCalculator } from "react-icons/bi";
import { TbReceiptTax } from "react-icons/tb";
import { VscDebugRestart } from "react-icons/vsc";
import { LiaBullhornSolid } from "react-icons/lia";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { FaPercent, FaDollarSign, FaRegCreditCard } from "react-icons/fa";

export const Calculator = () => {
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showInfo, setShowInfo] = useState({
    costPrice: false,
    costMarketing: false,
    costIof: false,
    costFreight: false,
    costCheckout: false,
    costGateway: false,
    costTax: false,
    costTaxation: false,
    costMarkup: false,
    costFinalPrice: false,
    costFixed: false,
    profit: false,
    maxCPA: false,
    profitMargin: false,
    recommendedPrice: false,
    federalTaxInfo: false,
    costMarketingResult: false,
    profitMarginResult: false,
  });

  const navigate = useNavigate();

  const { productName, productPrice } = useParams();

  const [config, setConfig] = useState({
    markup: {
      oneAndSeventyFive: 1.75,
      two: 2,
      twoAndTwentyFive: 2.25,
      twoAndHalf: 2.5,
      twoAndSeventyFive: 2.75,
      three: 3,
    }, // X
    marketing: 20, // Custo de Marketing %
    iof: 0.38, // %
    freight: 0, // Frete R$
    checkout: 2.5, // %
    gateway: 5, // %
    cnpjTaxation: 0, // Tributação CNPJ %
    remittance: 0, // Remessa Conforme ICMS %
    federalTax: 0, // Imposto Federal %
  });

  const [result, setResult] = useState({
    finalPrice: {
      oneAndSeventyFive: 0,
      two: 0,
      twoAndTwentyFive: 0,
      twoAndHalf: 0,
      twoAndSeventyFive: 0,
      three: 0,
    }, // Preço final R$
    fixedCost: {
      oneAndSeventyFive: 0,
      two: 0,
      twoAndTwentyFive: 0,
      twoAndHalf: 0,
      twoAndSeventyFive: 0,
      three: 0,
    }, // Custo fixo R$
    marketingCost: {
      oneAndSeventyFive: 0,
      two: 0,
      twoAndTwentyFive: 0,
      twoAndHalf: 0,
      twoAndSeventyFive: 0,
      three: 0,
    }, // Custo de Marketing R$
    profit: {
      oneAndSeventyFive: 0,
      two: 0,
      twoAndTwentyFive: 0,
      twoAndHalf: 0,
      twoAndSeventyFive: 0,
      three: 0,
    }, // Lucro R$
    maxCPA: {
      oneAndSeventyFive: 0,
      two: 0,
      twoAndTwentyFive: 0,
      twoAndHalf: 0,
      twoAndSeventyFive: 0,
      three: 0,
    }, // CPA Máximo R$
    profitMargin: {
      oneAndSeventyFive: 0,
      two: 0,
      twoAndTwentyFive: 0,
      twoAndHalf: 0,
      twoAndSeventyFive: 0,
      three: 0,
    }, // Margem de lucro %
  });

  const calculatePrice = (price) => {
    setLoading(true);

    setTimeout(() => {
      // Preço final

      const finalPriceOneAndSeventyFive =
        Number(price) * config.markup.oneAndSeventyFive +
        Number(config.freight);

      const finalPriceTwo =
        Number(price) * config.markup.two + Number(config.freight);

      const finalPriceTwoAndTwentyFive =
        Number(price) * config.markup.twoAndTwentyFive + Number(config.freight);

      const finalPriceTwoAndHalf =
        Number(price) * config.markup.twoAndHalf + Number(config.freight);

      const finalPriceTwoAndSeventyFive =
        Number(price) * config.markup.twoAndSeventyFive +
        Number(config.freight);

      const finalPriceThree =
        Number(price) * config.markup.three + Number(config.freight);

      // Custo de Marketing - CPA Desejado -
      const markCalcOneAndSeventyFive =
        finalPriceOneAndSeventyFive * (config.marketing / 100);
      const markCalcTwo = finalPriceTwo * (config.marketing / 100);
      const markCalcTwoAndTwentyFive =
        finalPriceTwoAndTwentyFive * (config.marketing / 100);
      const markCalcTwoAndHalf =
        finalPriceTwoAndHalf * (config.marketing / 100);
      const markCalcTwoAndSeventyFive =
        finalPriceTwoAndSeventyFive * (config.marketing / 100);
      const markCalcThree = finalPriceThree * (config.marketing / 100);

      // Imposto
      const taxCalcOneAndSeventyFive =
        finalPriceOneAndSeventyFive * (config.cnpjTaxation / 100);
      const taxCalcTwo = finalPriceTwo * (config.cnpjTaxation / 100);
      const taxCalcTwoAndTwentyFive =
        finalPriceTwoAndTwentyFive * (config.cnpjTaxation / 100);
      const taxCalcTwoAndHalf =
        finalPriceTwoAndHalf * (config.cnpjTaxation / 100);
      const taxCalcTwoAndSeventyFive =
        finalPriceTwoAndSeventyFive * (config.cnpjTaxation / 100);
      const taxCalcThree = finalPriceThree * (config.cnpjTaxation / 100);

      // Imposto Federal
      const federalTaxCalc = Number(price) * (config.federalTax / 100);

      // IOF
      const iofCalcOneAndSeventyFive =
        finalPriceOneAndSeventyFive * (config.iof / 100);
      const iofCalcTwo = finalPriceTwo * (config.iof / 100);
      const iofCalcTwoAndTwentyFive =
        finalPriceTwoAndTwentyFive * (config.iof / 100);
      const iofCalcTwoAndHalf = finalPriceTwoAndHalf * (config.iof / 100);
      const iofCalcTwoAndSeventyFive =
        finalPriceTwoAndSeventyFive * (config.iof / 100);
      const iofCalcThree = finalPriceThree * (config.iof / 100);

      // Checkout
      const checkoutCalcOneAndSeventyFive =
        finalPriceOneAndSeventyFive * (config.checkout / 100);
      const checkoutCalcTwo = finalPriceTwo * (config.checkout / 100);
      const checkoutCalcTwoAndTwentyFive =
        finalPriceTwoAndTwentyFive * (config.checkout / 100);
      const checkoutCalcTwoAndHalf =
        finalPriceTwoAndHalf * (config.checkout / 100);
      const checkoutCalcTwoAndSeventyFive =
        finalPriceTwoAndSeventyFive * (config.checkout / 100);
      const checkoutCalcThree = finalPriceThree * (config.checkout / 100);

      // Gateway
      const gatewayCalcOneAndSeventyFive =
        finalPriceOneAndSeventyFive * (config.gateway / 100);
      const gatewayCalcTwo = finalPriceTwo * (config.gateway / 100);
      const gatewayCalcTwoAndTwentyFive =
        finalPriceTwoAndTwentyFive * (config.gateway / 100);
      const gatewayCalcTwoAndHalf =
        finalPriceTwoAndHalf * (config.gateway / 100);
      const gatewayCalcTwoAndSeventyFive =
        finalPriceTwoAndSeventyFive * (config.gateway / 100);
      const gatewayCalcThree = finalPriceThree * (config.gateway / 100);

      // Taxação
      const remittanceCalc = Number(price) * (config.remittance / 100);

      // Custo fixo

      const profitOneAndSeventyFive =
        finalPriceOneAndSeventyFive -
        Number(price) -
        markCalcOneAndSeventyFive -
        taxCalcOneAndSeventyFive -
        federalTaxCalc -
        iofCalcOneAndSeventyFive -
        checkoutCalcOneAndSeventyFive -
        gatewayCalcOneAndSeventyFive -
        remittanceCalc;

      const profitTwo =
        finalPriceTwo -
        Number(price) -
        markCalcTwo -
        taxCalcTwo -
        federalTaxCalc -
        iofCalcTwo -
        checkoutCalcTwo -
        gatewayCalcTwo -
        remittanceCalc;

      const profitTwoAndTwentyFive =
        finalPriceTwoAndTwentyFive -
        Number(price) -
        markCalcTwoAndTwentyFive -
        taxCalcTwoAndTwentyFive -
        federalTaxCalc -
        iofCalcTwoAndTwentyFive -
        checkoutCalcTwoAndTwentyFive -
        gatewayCalcTwoAndTwentyFive -
        remittanceCalc;

      const profitTwoAndHalf =
        finalPriceTwoAndHalf -
        Number(price) -
        markCalcTwoAndHalf -
        taxCalcTwoAndHalf -
        federalTaxCalc -
        iofCalcTwoAndHalf -
        checkoutCalcTwoAndHalf -
        gatewayCalcTwoAndHalf -
        remittanceCalc;

      const profitTwoAndSeventyFive =
        finalPriceTwoAndSeventyFive -
        Number(price) -
        markCalcTwoAndSeventyFive -
        taxCalcTwoAndSeventyFive -
        federalTaxCalc -
        iofCalcTwoAndSeventyFive -
        checkoutCalcTwoAndSeventyFive -
        gatewayCalcTwoAndSeventyFive -
        remittanceCalc;

      const profitThree =
        finalPriceThree -
        Number(price) -
        markCalcThree -
        taxCalcThree -
        federalTaxCalc -
        iofCalcThree -
        checkoutCalcThree -
        gatewayCalcThree -
        remittanceCalc;

      const fixedCostOneAndSeventyFive =
        finalPriceOneAndSeventyFive - profitOneAndSeventyFive;
      const fixedCostTwo = finalPriceTwo - profitTwo;
      const fixedCostTwoAndTwentyFive =
        finalPriceTwoAndTwentyFive - profitTwoAndTwentyFive;
      const fixedCostTwoAndHalf = finalPriceTwoAndHalf - profitTwoAndHalf;
      const fixedCostTwoAndSeventyFive =
        finalPriceTwoAndSeventyFive - profitTwoAndSeventyFive;
      const fixedCostThree = finalPriceThree - profitThree;

      const maxCPAOneAndSeventyFive =
        markCalcOneAndSeventyFive + profitOneAndSeventyFive;
      const maxCPATwo = markCalcTwo + profitTwo;
      const maxCPATwoAndTwentyFive =
        markCalcTwoAndTwentyFive + profitTwoAndTwentyFive;
      const maxCPATwoAndHalf = markCalcTwoAndHalf + profitTwoAndHalf;
      const maxCPATwoAndSeventyFive =
        markCalcTwoAndSeventyFive + profitTwoAndSeventyFive;
      const maxCPAThree = markCalcThree + profitThree;

      const profitMarginOneAndSeventyFive =
        (profitOneAndSeventyFive / finalPriceOneAndSeventyFive) * 100;
      const profitMarginTwo = (profitTwo / finalPriceTwo) * 100;
      const profitMarginTwoAndTwentyFive =
        (profitTwoAndTwentyFive / finalPriceTwoAndTwentyFive) * 100;
      const profitMarginTwoAndHalf =
        (profitTwoAndHalf / finalPriceTwoAndHalf) * 100;
      const profitMarginTwoAndSeventyFive =
        (profitTwoAndSeventyFive / finalPriceTwoAndSeventyFive) * 100;
      const profitMarginThree = (profitThree / finalPriceThree) * 100;

      setResult({
        ...result,
        finalPrice: {
          oneAndSeventyFive: finalPriceOneAndSeventyFive,
          two: finalPriceTwo,
          twoAndTwentyFive: finalPriceTwoAndTwentyFive,
          twoAndHalf: finalPriceTwoAndHalf,
          twoAndSeventyFive: finalPriceTwoAndSeventyFive,
          three: finalPriceThree,
        },
        fixedCost: {
          oneAndSeventyFive: fixedCostOneAndSeventyFive,
          two: fixedCostTwo,
          twoAndTwentyFive: fixedCostTwoAndTwentyFive,
          twoAndHalf: fixedCostTwoAndHalf,
          twoAndSeventyFive: fixedCostTwoAndSeventyFive,
          three: fixedCostThree,
        },
        marketingCost: {
          oneAndSeventyFive: markCalcOneAndSeventyFive,
          two: markCalcTwo,
          twoAndTwentyFive: markCalcTwoAndTwentyFive,
          twoAndHalf: markCalcTwoAndHalf,
          twoAndSeventyFive: markCalcTwoAndSeventyFive,
          three: markCalcThree,
        },
        profit: {
          oneAndSeventyFive: profitOneAndSeventyFive,
          two: profitTwo,
          twoAndTwentyFive: profitTwoAndTwentyFive,
          twoAndHalf: profitTwoAndHalf,
          twoAndSeventyFive: profitTwoAndSeventyFive,
          three: profitThree,
        },
        maxCPA: {
          oneAndSeventyFive: maxCPAOneAndSeventyFive,
          two: maxCPATwo,
          twoAndTwentyFive: maxCPATwoAndTwentyFive,
          twoAndHalf: maxCPATwoAndHalf,
          twoAndSeventyFive: maxCPATwoAndSeventyFive,
          three: maxCPAThree,
        },
        profitMargin: {
          oneAndSeventyFive: profitMarginOneAndSeventyFive.toFixed(2),
          two: profitMarginTwo.toFixed(2),
          twoAndTwentyFive: profitMarginTwoAndTwentyFive.toFixed(2),
          twoAndHalf: profitMarginTwoAndHalf.toFixed(2),
          twoAndSeventyFive: profitMarginTwoAndSeventyFive.toFixed(2),
          three: profitMarginThree.toFixed(2),
        },
      });

      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    if (productPrice) {
      setTimeout(() => {
        calculatePrice(productPrice);
      }, 500);
    }

    // eslint-disable-next-line
  }, [productPrice]);

  return (
    <Container>
      <Flex w="100%" mb="1rem" justifyContent="flex-start">
        <GoBack onClick={() => navigate(-1)} />
      </Flex>

      <Title
        headingName="Precificador"
        caption="Precifique o produto que você quiser, calcule suas margens de lucro e muito mais"
      >
        <BiCalculator fill="#3058A5" size="1.5rem" />
      </Title>

      <hr />

      <C.Content>
        <C.Config>
          <div className="container">
            <div className="container__title">
              <div>
                <CiDollar fill="#4f4f4f" size="1.25rem" />
                <p>Preço de Custo</p>
              </div>
              <div
                className="doubt"
                onMouseEnter={() =>
                  setShowInfo({ ...showInfo, costPrice: true })
                }
                onMouseLeave={() =>
                  setShowInfo({ ...showInfo, costPrice: false })
                }
              >
                ?
              </div>

              {showInfo.costPrice ? (
                <span>
                  Digite o preço que você irá pagar ao seu fornecedor pelo
                  produto.
                </span>
              ) : null}
            </div>
            <div className="container__input">
              <FaDollarSign size="1.5rem" fill="#3058a4" />
              <div className="divider" />
              <Input
                type="number"
                color="#3058a4"
                border="0"
                outline="none"
                onChange={(ev) => {
                  setPrice(ev.target.value);
                  calculatePrice(ev.target.value);
                }}
                fontSize="1.2rem"
                fontWeight="500"
                placeholder="Digite aqui"
                defaultValue={productPrice && productPrice}
                _hover={{ border: 0 }}
                _focus={{ border: 0 }}
                _placeholder={{ fontSize: "1.2rem", color: "#3058a4a8" }}
                _focusVisible={{ border: 0 }}
              />
            </div>
          </div>
          <div className="flex-items">
            <div className="container">
              <div className="container__title">
                <div>
                  <LiaBullhornSolid fill="#4f4f4f" size="1.25rem" />
                  <p>Marketing</p>
                </div>

                <div
                  className="doubt"
                  onMouseEnter={() =>
                    setShowInfo({ ...showInfo, costMarketing: true })
                  }
                  onMouseLeave={() =>
                    setShowInfo({ ...showInfo, costMarketing: false })
                  }
                >
                  ?
                </div>

                {showInfo.costMarketing ? (
                  <span className="marketing">
                    Digite a porcentagem que você imagina investir em marketing
                    para conseguir vender um produto.
                  </span>
                ) : null}
              </div>

              <div className="container__input">
                <FaPercent size="1.5rem" fill="#3058a4" />
                <div className="divider" />
                <Input
                  type="number"
                  color="#3058a4"
                  border="0"
                  outline="none"
                  fontSize="1.2rem"
                  fontWeight="500"
                  defaultValue={config.marketing}
                  onChange={(ev) => {
                    setConfig({
                      ...config,
                      marketing: Number(ev.target.value),
                    });
                  }}
                  _hover={{ border: 0 }}
                  _focus={{ border: 0 }}
                  _placeholder={{ fontSize: "1.2rem", color: "#3058a4a8" }}
                  _focusVisible={{ border: 0 }}
                />

                <Flex
                  top="0.7rem"
                  right="0.5rem"
                  cursor="pointer"
                  _hover={{ opacity: 0.7 }}
                  position="absolute"
                  onClick={() => calculatePrice(price)}
                  alignItems="center"
                  gap="4px"
                >
                  <Text color="#4f4f4f" fontSize="0.85rem" fontWeight="500">
                    Atualizar
                  </Text>
                  <VscDebugRestart size="1.5rem" color="#3058a4" />
                </Flex>
              </div>
            </div>
          </div>

          <div className="fixed-costs">
            <Flex alignItems="center" justifyContent="space-between" gap="1rem">
              <h4>Custos Fixos</h4>

              <Flex
                gap="0.2rem"
                cursor="pointer"
                _hover={{ opacity: 0.7 }}
                onClick={() => calculatePrice(price)}
                alignItems="center"
              >
                <Text color="#4f4f4f" fontSize="0.85rem" fontWeight="500">
                  Atualizar
                </Text>
                <VscDebugRestart size="1.5rem" color="#3058a4" />
              </Flex>
            </Flex>

            <div className="flex-items">
              <div className="title">
                <TbReceiptTax color="#4f4f4f" size="1.25rem" />
                <p>IOF</p>

                <div
                  className="doubt"
                  onMouseEnter={() =>
                    setShowInfo({ ...showInfo, costIof: true })
                  }
                  onMouseLeave={() =>
                    setShowInfo({ ...showInfo, costIof: false })
                  }
                >
                  ?
                </div>

                {showInfo.costIof ? (
                  <span>
                    Imposto sobre Operações Financeiras, é um imposto federal
                    pago por pessoas físicas e jurídicas.
                  </span>
                ) : null}
              </div>

              <div className="input">
                <FaPercent size="1.5rem" fill="#3058a4" />
                <div className="divider" />
                <Input
                  type="number"
                  color="#3058a4"
                  border="0"
                  outline="none"
                  fontSize="1.2rem"
                  fontWeight="500"
                  onChange={(ev) => {
                    setConfig({ ...config, iof: ev.target.value });
                  }}
                  defaultValue={config.iof}
                  _hover={{ border: 0 }}
                  _focus={{ border: 0 }}
                  _placeholder={{ fontSize: "1.2rem", color: "#3058a4a8" }}
                  _focusVisible={{ border: 0 }}
                />
              </div>
            </div>

            <div className="flex-items">
              <div className="title">
                <FiTruck color="#4f4f4f" size="1.25rem" />
                <p>Frete</p>

                <div
                  className="doubt"
                  onMouseEnter={() =>
                    setShowInfo({ ...showInfo, costFreight: true })
                  }
                  onMouseLeave={() =>
                    setShowInfo({ ...showInfo, costFreight: false })
                  }
                >
                  ?
                </div>

                {showInfo.costFreight ? (
                  <span className="freight">
                    Digite aqui o valor que irá pagar no frete
                  </span>
                ) : null}
              </div>

              <div className="input">
                <FaDollarSign size="1.5rem" fill="#3058a4" />
                <div className="divider" />
                <Input
                  type="number"
                  color="#3058a4"
                  border="0"
                  outline="none"
                  fontSize="1.2rem"
                  fontWeight="500"
                  onChange={(ev) => {
                    setConfig({ ...config, freight: ev.target.value });
                  }}
                  defaultValue={config.freight}
                  _hover={{ border: 0 }}
                  _focus={{ border: 0 }}
                  _placeholder={{ fontSize: "1.2rem", color: "#3058a4a8" }}
                  _focusVisible={{ border: 0 }}
                />
              </div>
            </div>

            <div className="flex-items">
              <div className="title">
                <MdOutlineShoppingCartCheckout color="#4f4f4f" size="1.25rem" />
                <p>Checkout</p>

                <div
                  className="doubt"
                  onMouseEnter={() =>
                    setShowInfo({ ...showInfo, costCheckout: true })
                  }
                  onMouseLeave={() =>
                    setShowInfo({ ...showInfo, costCheckout: false })
                  }
                >
                  ?
                </div>

                {showInfo.costCheckout ? (
                  <span className="chechout">
                    Digite aqui a porcentagem que o seu checkout cobrará de taxa
                    por produto vendido, <b> exemplo: Yampi 2,5%</b>
                  </span>
                ) : null}
              </div>

              <div className="input">
                <FaPercent size="1.5rem" fill="#3058a4" />
                <div className="divider" />
                <Input
                  type="number"
                  color="#3058a4"
                  border="0"
                  outline="none"
                  fontSize="1.2rem"
                  fontWeight="500"
                  onChange={(ev) => {
                    setConfig({ ...config, checkout: ev.target.value });
                  }}
                  defaultValue={config.checkout}
                  _hover={{ border: 0 }}
                  _focus={{ border: 0 }}
                  _placeholder={{ fontSize: "1.2rem", color: "#3058a4a8" }}
                  _focusVisible={{ border: 0 }}
                />
              </div>
            </div>

            <div className="flex-items">
              <div className="title">
                <FaRegCreditCard color="#4f4f4f" size="1.25rem" />
                <p>Gateway</p>

                <div
                  className="doubt"
                  onMouseEnter={() =>
                    setShowInfo({ ...showInfo, costGateway: true })
                  }
                  onMouseLeave={() =>
                    setShowInfo({ ...showInfo, costGateway: false })
                  }
                >
                  ?
                </div>

                {showInfo.costGateway ? (
                  <span className="gateway">
                    Digite aqui a porcentagem que o seu gateway cobrará de taxa
                    por produto vendido, <b> exemplo: AppMax 5%</b>
                  </span>
                ) : null}
              </div>

              <div className="input">
                <FaPercent size="1.5rem" fill="#3058a4" />
                <div className="divider" />
                <Input
                  type="number"
                  color="#3058a4"
                  border="0"
                  outline="none"
                  fontSize="1.2rem"
                  fontWeight="500"
                  onChange={(ev) => {
                    setConfig({ ...config, gateway: ev.target.value });
                  }}
                  defaultValue={config.gateway}
                  _hover={{ border: 0 }}
                  _focus={{ border: 0 }}
                  _placeholder={{ fontSize: "1.2rem", color: "#3058a4a8" }}
                  _focusVisible={{ border: 0 }}
                />
              </div>
            </div>

            <div className="flex-items">
              <div className="title">
                <TbReceiptTax color="#4f4f4f" size="1.25rem" />
                <p>I. Federal</p>

                <div
                  className="doubt"
                  onMouseEnter={() =>
                    setShowInfo({ ...showInfo, federalTaxInfo: true })
                  }
                  onMouseLeave={() =>
                    setShowInfo({ ...showInfo, federalTaxInfo: false })
                  }
                >
                  ?
                </div>

                {showInfo.federalTaxInfo ? (
                  <span className="tax">
                    <b>Imposto Federal</b>
                  </span>
                ) : null}
              </div>

              <div
                className={`input ${
                  config.federalTax <= 0 ? "opacity" : "no-opacity"
                }`}
              >
                <FaPercent size="1.5rem" fill="#3058a4" />
                <div className="divider" />
                <Input
                  type="number"
                  color="#3058a4"
                  border="0"
                  outline="none"
                  fontSize="1.2rem"
                  fontWeight="500"
                  onChange={(ev) => {
                    setConfig({ ...config, federalTax: ev.target.value });
                  }}
                  defaultValue={
                    config.federalTax === 0 ? "20" : config.federalTax
                  }
                  _hover={{ border: 0 }}
                  _focus={{ border: 0 }}
                  _placeholder={{ fontSize: "1.2rem", color: "#3058a4a8" }}
                  _focusVisible={{ border: 0 }}
                />
              </div>
            </div>

            <div className="flex-items">
              <div className="title">
                <TbReceiptTax color="#4f4f4f" size="1.25rem" />
                <p>ICMS</p>

                <div
                  className="doubt"
                  onMouseEnter={() =>
                    setShowInfo({ ...showInfo, costTaxation: true })
                  }
                  onMouseLeave={() =>
                    setShowInfo({ ...showInfo, costTaxation: false })
                  }
                >
                  ?
                </div>

                {showInfo.costTaxation ? (
                  <span className="taxation">
                    É cobrado o ICMS
                    <b>
                      {" "}
                      (Imposto sobre Circulação de Mercadorias e Serviços){" "}
                    </b>
                    sobre as importações de 17% para produtos de até US$50,00
                    dólares.
                  </span>
                ) : null}
              </div>

              <div
                className={`input ${
                  config.remittance <= 0 ? "opacity" : "no-opacity"
                }`}
              >
                <FaPercent size="1.5rem" fill="#3058a4" />
                <div className="divider" />
                <Input
                  type="number"
                  color="#3058a4"
                  border="0"
                  outline="none"
                  fontSize="1.2rem"
                  fontWeight="500"
                  onChange={(ev) => {
                    setConfig({ ...config, remittance: ev.target.value });
                  }}
                  defaultValue={
                    config.remittance === 0 ? "17" : config.remittance
                  }
                  _hover={{ border: 0 }}
                  _focus={{ border: 0 }}
                  _placeholder={{ fontSize: "1.2rem", color: "#3058a4a8" }}
                  _focusVisible={{ border: 0 }}
                />
              </div>
            </div>

            <div className="flex-items">
              <div className="title">
                <TbReceiptTax color="#4f4f4f" size="1.25rem" />
                <p>N. Fiscal</p>

                <div
                  className="doubt"
                  onMouseEnter={() =>
                    setShowInfo({ ...showInfo, costTax: true })
                  }
                  onMouseLeave={() =>
                    setShowInfo({ ...showInfo, costTax: false })
                  }
                >
                  ?
                </div>

                {showInfo.costTax ? (
                  <span className="tax">
                    Tributação CNPJ - Digite aqui a porcentagem que pagará de
                    imposto por emitir Nota Fiscal desse produto{" "}
                    <b> apenas para CNPJ</b>
                  </span>
                ) : null}
              </div>

              <div
                className={`input ${
                  config.cnpjTaxation <= 0 ? "opacity" : "no-opacity"
                }`}
              >
                <FaPercent size="1.5rem" fill="#3058a4" />
                <div className="divider" />
                <Input
                  type="number"
                  color="#3058a4"
                  border="0"
                  outline="none"
                  fontSize="1.2rem"
                  fontWeight="500"
                  onChange={(ev) => {
                    setConfig({ ...config, cnpjTaxation: ev.target.value });
                  }}
                  defaultValue={config.cnpjTaxation}
                  _hover={{ border: 0 }}
                  _focus={{ border: 0 }}
                  _placeholder={{ fontSize: "1.2rem", color: "#3058a4a8" }}
                  _focusVisible={{ border: 0 }}
                />
              </div>
            </div>
          </div>
        </C.Config>

        <C.Result>
          <div className={`${productName && "productNameContainer"}`}>
            <h4>
              {productName
                ? "Estimativa de preço para: "
                : "Estimativa de Preço"}
            </h4>
            {productName && <p className="productName">{productName}</p>}
          </div>

          <div className="content-result">
            {loading ? (
              <Flex
                w="100%"
                h="28rem"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner w="50px" h="50px" color="#3058a4" />
              </Flex>
            ) : (
              <>
                <ul className="content-result__title">
                  <li>
                    Markup
                    <div
                      className="doubt"
                      onMouseEnter={() =>
                        setShowInfo({ ...showInfo, costMarkup: true })
                      }
                      onMouseLeave={() =>
                        setShowInfo({ ...showInfo, costMarkup: false })
                      }
                    >
                      ?
                    </div>
                    {showInfo.costMarkup ? (
                      <span>
                        É a quantidade de vezes que o valor de custo do seu
                        produto foi multiplicado, até chegar no valor de venda
                        recomendado.
                        <b>
                          Exemplo: se custa 100, o markup for 3, será 300 o
                          preço de venda.
                        </b>
                      </span>
                    ) : null}
                  </li>
                  <li>
                    Preço final
                    <div
                      className="doubt"
                      onMouseEnter={() =>
                        setShowInfo({ ...showInfo, costFinalPrice: true })
                      }
                      onMouseLeave={() =>
                        setShowInfo({ ...showInfo, costFinalPrice: false })
                      }
                    >
                      ?
                    </div>
                    {showInfo.costFinalPrice ? (
                      <span className="finalPrice">
                        É o valor final que você deverá vender o seu produto na
                        sua loja.
                      </span>
                    ) : null}
                  </li>
                  <li>
                    Custo fixo
                    <div
                      className="doubt"
                      onMouseEnter={() =>
                        setShowInfo({ ...showInfo, costFixed: true })
                      }
                      onMouseLeave={() =>
                        setShowInfo({ ...showInfo, costFixed: false })
                      }
                    >
                      ?
                    </div>
                    {showInfo.costFixed ? (
                      <span className="fixedCost">
                        É o valor de custo total da sua operação para vender um
                        produto.
                      </span>
                    ) : null}
                  </li>
                  <li>
                    C. de Marketing
                    <div
                      className="doubt"
                      onMouseEnter={() =>
                        setShowInfo({ ...showInfo, costMarketingResult: true })
                      }
                      onMouseLeave={() =>
                        setShowInfo({ ...showInfo, costMarketingResult: false })
                      }
                    >
                      ?
                    </div>
                    {showInfo.costMarketingResult ? (
                      <span className="marketingCost">
                        É o valor de custo de marketing para vender um produto.
                      </span>
                    ) : null}
                  </li>
                  <li>
                    Lucro R$
                    <div
                      className="doubt"
                      onMouseEnter={() =>
                        setShowInfo({ ...showInfo, profit: true })
                      }
                      onMouseLeave={() =>
                        setShowInfo({ ...showInfo, profit: false })
                      }
                    >
                      ?
                    </div>
                    {showInfo.profit ? (
                      <span className="profit">
                        É o valor final de lucro que cairá livre, sem mais
                        gastos, na sua conta bancária.
                      </span>
                    ) : null}
                  </li>
                  <li>
                    Max CPA
                    <div
                      className="doubt"
                      onMouseEnter={() =>
                        setShowInfo({ ...showInfo, maxCPA: true })
                      }
                      onMouseLeave={() =>
                        setShowInfo({ ...showInfo, maxCPA: false })
                      }
                    >
                      ?
                    </div>
                    {showInfo.maxCPA ? (
                      <span className="maxCpa">
                        É o valor máximo que você pode gastar em marketing para
                        fazer um cliente comprar o seu produto, caso passe desse
                        valor, <b> tomará PREJUIZO</b>.
                      </span>
                    ) : null}
                  </li>

                  <li>
                    M. de lucro
                    <div
                      className="doubt"
                      onMouseEnter={() =>
                        setShowInfo({ ...showInfo, profitMargin: true })
                      }
                      onMouseLeave={() =>
                        setShowInfo({ ...showInfo, profitMargin: false })
                      }
                    >
                      ?
                    </div>
                    {showInfo.profitMargin ? (
                      <span className="profitMargin">
                        É o valor em porcentagem de quanto de lucro final terá
                        na venda do produto.
                        <b> Recomendado acima de 15% POSITIVO</b>.
                      </span>
                    ) : null}
                  </li>
                </ul>

                <ul className="content">
                  <li className="">
                    <p>{config.markup.oneAndSeventyFive}x</p>
                    <p>{currencyFormat(result.finalPrice.oneAndSeventyFive)}</p>
                    <p>{currencyFormat(result.fixedCost.oneAndSeventyFive)}</p>
                    <p>
                      {currencyFormat(result.marketingCost.oneAndSeventyFive)}
                    </p>
                    <p
                      className={
                        result.profit.oneAndSeventyFive < 0 ? "red" : "green"
                      }
                    >
                      {currencyFormat(result.profit.oneAndSeventyFive)}
                    </p>
                    <p>{currencyFormat(result.maxCPA.oneAndSeventyFive)}</p>
                    <p
                      className={
                        result.profitMargin.oneAndSeventyFive < 0
                          ? "red-color"
                          : "green-color"
                      }
                    >
                      {result.profitMargin.oneAndSeventyFive}%
                    </p>
                  </li>

                  <li className="">
                    <p>{config.markup.two}x</p>
                    <p>{currencyFormat(result.finalPrice.two)}</p>
                    <p>{currencyFormat(result.fixedCost.two)}</p>
                    <p>{currencyFormat(result.marketingCost.two)}</p>
                    <p className={result.profit.two < 0 ? "red" : "green"}>
                      {currencyFormat(result.profit.two)}
                    </p>
                    <p>{currencyFormat(result.maxCPA.two)}</p>
                    <p
                      className={
                        result.profitMargin.two < 0
                          ? "red-color"
                          : "green-color"
                      }
                    >
                      {result.profitMargin.two}%
                    </p>
                  </li>

                  <li className="">
                    <p>{config.markup.twoAndTwentyFive}x</p>
                    <p>{currencyFormat(result.finalPrice.twoAndTwentyFive)}</p>
                    <p>{currencyFormat(result.fixedCost.twoAndTwentyFive)}</p>
                    <p>
                      {currencyFormat(result.marketingCost.twoAndTwentyFive)}
                    </p>
                    <p
                      className={
                        result.profit.twoAndTwentyFive < 0 ? "red" : "green"
                      }
                    >
                      {currencyFormat(result.profit.twoAndTwentyFive)}
                    </p>
                    <p>{currencyFormat(result.maxCPA.twoAndTwentyFive)}</p>
                    <p
                      className={
                        result.profitMargin.twoAndTwentyFive < 0
                          ? "red-color"
                          : "green-color"
                      }
                    >
                      {result.profitMargin.twoAndTwentyFive}%
                    </p>
                  </li>

                  <li className="">
                    <p>{config.markup.twoAndHalf}x</p>
                    <p>{currencyFormat(result.finalPrice.twoAndHalf)}</p>
                    <p>{currencyFormat(result.fixedCost.twoAndHalf)}</p>
                    <p>{currencyFormat(result.marketingCost.twoAndHalf)}</p>
                    <p
                      className={result.profit.twoAndHalf < 0 ? "red" : "green"}
                    >
                      {currencyFormat(result.profit.twoAndHalf)}
                    </p>
                    <p>{currencyFormat(result.maxCPA.twoAndHalf)}</p>
                    <p
                      className={
                        result.profitMargin.twoAndHalf < 0
                          ? "red-color"
                          : "green-color"
                      }
                    >
                      {result.profitMargin.twoAndHalf}%
                    </p>
                  </li>

                  <li className="">
                    <p>{config.markup.twoAndSeventyFive}x</p>
                    <p>{currencyFormat(result.finalPrice.twoAndSeventyFive)}</p>
                    <p>{currencyFormat(result.fixedCost.twoAndSeventyFive)}</p>
                    <p>
                      {currencyFormat(result.marketingCost.twoAndSeventyFive)}
                    </p>
                    <p
                      className={
                        result.profit.twoAndSeventyFive < 0 ? "red" : "green"
                      }
                    >
                      {currencyFormat(result.profit.twoAndSeventyFive)}
                    </p>
                    <p>{currencyFormat(result.maxCPA.twoAndSeventyFive)}</p>
                    <p
                      className={
                        result.profitMargin.twoAndSeventyFive < 0
                          ? "red-color"
                          : "green-color"
                      }
                    >
                      {result.profitMargin.twoAndSeventyFive}%
                    </p>
                  </li>

                  <li className="">
                    <p>{config.markup.three}x</p>
                    <p>{currencyFormat(result.finalPrice.three)}</p>
                    <p>{currencyFormat(result.fixedCost.three)}</p>
                    <p>{currencyFormat(result.marketingCost.three)}</p>
                    <p className={result.profit.three < 0 ? "red" : "green"}>
                      {currencyFormat(result.profit.three)}
                    </p>
                    <p>{currencyFormat(result.maxCPA.three)}</p>
                    <p
                      className={
                        result.profitMargin.three < 0
                          ? "red-color"
                          : "green-color"
                      }
                    >
                      {result.profitMargin.three}%
                    </p>
                  </li>
                </ul>
              </>
            )}
          </div>

          <div className="flex-items-container">
            <div className="item">
              <div className="content-doubt">
                <p>Preço Recomendado</p>
                <div
                  className="doubt"
                  onMouseEnter={() =>
                    setShowInfo({ ...showInfo, recommendedPrice: true })
                  }
                  onMouseLeave={() =>
                    setShowInfo({ ...showInfo, recommendedPrice: false })
                  }
                >
                  ?
                </div>

                {showInfo.recommendedPrice ? (
                  <span className="recommended-price">
                    Recomendamos sempre o markup de 3x, para garantir
                    lucratividade nas vendas de seus produtos.
                  </span>
                ) : null}
              </div>

              <div className="price">
                <p>{currencyFormat(result.finalPrice.three)}</p>
              </div>
            </div>

            <div className="divider" />

            <div className="item">
              <div className="content-doubt">
                <p>Lucro</p>
                <div
                  className="doubt"
                  onMouseEnter={() =>
                    setShowInfo({ ...showInfo, profitMarginResult: true })
                  }
                  onMouseLeave={() =>
                    setShowInfo({ ...showInfo, profitMarginResult: false })
                  }
                >
                  ?
                </div>

                {showInfo.profitMarginResult ? (
                  <span className="profitMargin">
                    É o valor em porcentagem de quanto de lucro final terá na
                    venda do produto.
                    <b> Recomendado acima de 15% POSITIVO</b>.
                  </span>
                ) : null}
              </div>

              <div className="price">
                <p>{currencyFormat(result.profit.three)}</p>
              </div>
            </div>
          </div>
        </C.Result>
      </C.Content>
    </Container>
  );
};
