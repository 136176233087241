import { Modal } from "../index";
import { Input } from "../../Reusable/Input";
import { Text, Grid, Button } from "@chakra-ui/react";
import { ModalHeader } from "../ModalHeader";

import { useAuth } from "../../../contexts/Auth";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { updatePassSchema } from "../../../schemas/schemas";

import { FaLock } from "react-icons/fa";

export const ModalUpdatePasswordClient = ({ isOpen, onClose }) => {
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(updatePassSchema),
  });

  const { updatePassword } = useAuth();

  const handleNewPassword = (passwordData) => {
    updatePassword(passwordData);

    reset();
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={FaLock} color="#fff" />
            <ModalHeader.Title title="Alterar senha" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <Grid w="100%" mt="4" mb="6">
            <Text
              pb="4"
              fontWeight="600"
              color="#585858"
              fontSize="1.2rem"
              textAlign="center"
            >
              Pense na sua segurança e crie uma senha forte.
            </Text>
            <Text color="#4f4f4f" textAlign="center">
              Para sua segurança crie um senha incluindo informações como Letras
              <b> maiusculas, símbolos e números</b>.
            </Text>
          </Grid>
          <Grid w="100%" as="form" onSubmit={handleSubmit(handleNewPassword)}>
            <Input type="password" label="Senha Atual" {...register("senha")} />
            <Input
              type="password"
              label="Nova Senha"
              error={errors.novasenha}
              {...register("novasenha")}
            />
            <Input
              type="password"
              label="Confirmação de senha"
              error={errors.confirmasenha}
              {...register("confirmasenha")}
            />
            <Button
              w="100%"
              h="60px"
              type="submit"
              border="none"
              fontWeight="500"
              color="#ffffff"
              lineHeight="1rem"
              borderRadius="4px"
              fontSize="1.25rem"
              textAlign="center"
              fontFamily="Poppins"
              _hover={{ opacity: 0.8 }}
              m={["1rem auto", "1rem auto", "1rem 0"]}
              bg="linear-gradient(89.97deg, #3058a4 0.44%, #2978d3 99.97%)"
            >
              Cadastrar senha
            </Button>
          </Grid>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
