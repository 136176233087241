import { Link } from "@chakra-ui/react";
import { AppCardContent } from "./styles";

export const AppCard = ({ data }) => {
  return (
    <AppCardContent>
      <figure>
        <img src={data.img} alt={data.title} />
      </figure>

      <h4>{data.title}</h4>

      <p>{data.description}</p>

      <Link w="100%" href={data.link} rel="noreferrer" target="_blank">
        <button>Ver App</button>
      </Link>
    </AppCardContent>
  );
};
