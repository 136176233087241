import * as SB from "./styles";

import { Title } from "../../components/Reusable/Title";
import { TbWorld } from "react-icons/tb";
import { Container } from "../../components/Reusable/Container";
import { TemplateCard } from "./TemplateCard";
import { ModalConfigStore } from "../../components/Modal/Stores/ModalConfigStore";

import { useAuth } from "../../contexts/Auth";
import { useDisclosure } from "@chakra-ui/react";
import { useWebsitesClient } from "../../contexts/Websites/WebsitesContextClient";
import { useTemplatesClient } from "../../contexts/Templates/TemplatesContextClient";
import { useMemo, useState, useEffect } from "react";

const numberOfStoresAllowed = {
  free: 1,
  gold: 2,
  premium: 3,
};

export const StoreBuilder = () => {
  const [maxSitesAllowed, setMaxSitesAllowed] = useState(0);

  const { data } = useAuth();

  const signed = data && Object.values(data).length > 1;

  const { websitesUser, loadWebsitesUser } = useWebsitesClient();

  const {
    isOpen: isOpenConfigStore,
    onOpen: onOpenConfigStore,
    onClose: onCloseConfigStore,
  } = useDisclosure();

  const { themes } = useTemplatesClient();

  useEffect(() => {
    loadWebsitesUser();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    switch (data.id_planos) {
      case 4:
        setMaxSitesAllowed(numberOfStoresAllowed.free);
        break;
      case 5:
        setMaxSitesAllowed(numberOfStoresAllowed.gold);
        break;
      case 9:
        setMaxSitesAllowed(numberOfStoresAllowed.premium);
        break;
      default:
        break;
    }

    // eslint-disable-next-line
  }, [signed]);

  useEffect(() => {
    // Seleciona o botão e dispara o clique automaticamente quando o componente é montado
    const button = document.querySelector(".create-store");
    if (button && websitesUser.length < 1) {
      button.click();
    }
  }, [websitesUser]);

  // Filtro de categorias dentro da tabela de tutoriais - impedindo duplicidade
  const getCategories = useMemo(() => {
    const filteredCategories = themes?.reduce((categories, template) => {
      if (!categories.includes(template.descricao)) {
        categories.push(template.descricao);
      }
      return categories;
    }, []);

    if (filteredCategories.length > 0) {
      return filteredCategories;
    }

    return [];
  }, [themes]);

  return (
    <Container>
      <SB.Content>
        <div className="store-title">
          <Title
            headingName="Construtor de Lojas"
            caption="Aqui você irá conseguir criar a sua loja, basta escolher uma das opções abaixo para começar a vender."
          >
            <TbWorld color="#3058A4" size="30px" />
          </Title>

          <button className="create-store" onClick={() => onOpenConfigStore()}>
            Criar sua loja
          </button>
        </div>

        <hr />

        <div className="themes">
          {websitesUser.length > 0 ? (
            getCategories?.map((category) => (
              <div className="category" key={category}>
                <h4>{category}</h4>

                <SB.ContentOfThemes>
                  {themes
                    ?.filter((template) => template.descricao === category)
                    .map((template) => {
                      return (
                        <TemplateCard
                          key={template.id_template}
                          templateData={template}
                        />
                      );
                    })}
                </SB.ContentOfThemes>
              </div>
            ))
          ) : (
            <div>
              <h4 style={{ textAlign: "center" }}>
                Você ainda não possui uma loja cadastrada, crie a sua loja
                clicando no botão acima
              </h4>
            </div>
          )}
        </div>
      </SB.Content>

      <ModalConfigStore
        isOpen={isOpenConfigStore}
        onClose={onCloseConfigStore}
        websitesUser={websitesUser.length}
        maxSitesAllowed={maxSitesAllowed}
      />
    </Container>
  );
};
