import * as D from "./styles";

import { useNavigate } from "react-router-dom";

import { BsPlayCircle } from "react-icons/bs";

import introducaoImg from "../../assets/posts/como-usar.png";
import importTemasImg from "../../assets/posts/importar-temas.png";
import importLucrarMaisImg from "../../assets/posts/lucrar-mais.png";

export const BannersGetStarted = () => {
  const navigate = useNavigate();

  return (
    <D.BannersGetStarted>
      <h2>Primeiros passos</h2>

      <D.Banners>
        <D.BannerCard>
          <img
            src={introducaoImg}
            alt="Imagem ilustrativa de introdução a como utilizar a plataforma"
            onClick={() => navigate("/dashboard/tutoriais")}
          />

          <div>
            <p>
              Conheça a <b> plataforma</b>
            </p>
            <BsPlayCircle
              fill="#FB8A03"
              size="35px"
              onClick={() => navigate("/dashboard/tutoriais")}
            />
          </div>
        </D.BannerCard>

        <D.BannerCard>
          <img
            src={importTemasImg}
            alt="Imagem ilustrativa de importação de tema para a plataforma"
            onClick={() => navigate("/dashboard/tutoriais")}
          />

          <div>
            <p>
              Crie a <b> sua loja </b> em 03 passos
            </p>
            <BsPlayCircle
              fill="#FB8A03"
              size="35px"
              onClick={() => navigate("/dashboard/tutoriais")}
            />
          </div>
        </D.BannerCard>

        <D.BannerCard>
          <img
            src={importLucrarMaisImg}
            alt="Imagem ilustrativa de como lucrar mais com a plataforma"
            onClick={() => navigate("/dashboard/tutoriais")}
          />

          <div>
            <p>
              Aprenda a dominar o mercado de <b> Dropshipping</b>
            </p>
            <BsPlayCircle
              fill="#FB8A03"
              size="35px"
              onClick={() => navigate("/dashboard/tutoriais")}
            />
          </div>
        </D.BannerCard>
      </D.Banners>
    </D.BannersGetStarted>
  );
};
