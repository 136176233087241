import { useState, useContext, createContext, useEffect } from "react";

import { apiAuth, apiCredits } from "../../../services/api";
import { useAuth } from "../../Auth";
import { useToast } from "@chakra-ui/react";

const ProductsContextClient = createContext({});

export const useProductsClient = () => {
  const context = useContext(ProductsContextClient);

  if (!context) {
    throw new Error(
      "useProductsAdmin must be used within an ProductsProviderClient"
    );
  }

  return context;
};

export const ProductsProviderClient = ({ children }) => {
  const [vendors, setVendors] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectValue, setSelectValue] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productsConveyor, setProductsConveyor] = useState({});
  const [productDetails, setProductDetails] = useState({});

  const { data } = useAuth();

  const toast = useToast();

  const loadAllProducts = async () => {
    const response = await apiAuth.get("/listarfornecedores");

    const data = response.data.fornecedores.filter(
      (product) => product.status === "Disponível"
    );

    const invertData = data.slice().reverse();

    localStorage.setItem("@PlayDrop:Produtos", JSON.stringify(invertData));

    const storedData = localStorage.getItem("@PlayDrop:Produtos");

    if (storedData) {
      setProducts(JSON.parse(storedData));
    }
  };

  useEffect(() => {
    loadAllProducts();
  }, [data]);

  const loadProductsConveyor = async () => {
    const response = await apiAuth.get(`/listar-esteira?id_login=${data.id}`);

    const productsResponse = response.data;

    if (!productsResponse.message) {
      const orderColumns = Object.values(productsResponse).sort(
        (first, second) => first.id - second.id
      );

      // Reconstruindo o objeto original
      const newColumns = {};

      orderColumns.forEach((categoria) => {
        const categoryName = categoria.name;
        newColumns[categoryName] = { ...categoria };
      });

      localStorage.setItem("@Playdrop:esteira", JSON.stringify(newColumns));

      const storedData = localStorage.getItem("@Playdrop:esteira");

      if (storedData) {
        setProductsConveyor(JSON.parse(storedData));
      }
    }
  };

  useEffect(() => {
    loadProductsConveyor();

    // eslint-disable-next-line
  }, []);

  const updatePositionConveyor = async (newContent, esteira_id, produto_id) => {
    try {
      await apiAuth
        .post(
          "/atualizar-posicao-esteira",
          {
            esteira_id: esteira_id,
            produto_id: produto_id,
            position: JSON.stringify(newContent),
          },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((res) => console.log(res));
    } catch (error) {
      console.log(error);
    }
  };

  const updateColumn = async (esteira_id, columnName) => {
    try {
      await apiAuth
        .post(
          "/atualizar-item-esteira",
          {
            id: esteira_id,
            name: columnName,
          },
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((_) => {
          loadProductsConveyor();

          toast({
            title: "Sucesso",
            description: "Coluna atualizada",
            status: "success",
            duration: 1000,
            isClosable: true,
          });
        });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao atualizar coluna",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const deteleProduct = async (productId) => {
    try {
      await apiAuth
        .post(
          "/deletar-produto",
          { id: productId },
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((_) => {
          loadProductsConveyor();

          toast({
            title: "Sucesso",
            description: "Exlusão realizada",
            status: "success",
            duration: 1000,
            isClosable: true,
          });
        });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao excluir produto",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const deteleColumn = async (columnId) => {
    try {
      await apiAuth
        .post(
          "/deletar-item-esteira",
          { esteira_id: Number(columnId) },
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((_) => {
          loadProductsConveyor();
          toast({
            title: "Sucesso",
            description: "Exlusão realizada",
            status: "success",
            duration: 1000,
            isClosable: true,
          });
        });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao excluir produto",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const addNewProduct = async (
    productName,
    esteira_id,
    setLoading,
    onClose
  ) => {
    const formData = {
      produto_name: productName,
      esteira_id: esteira_id,
      id_login: data.id,
    };

    setLoading(true);

    try {
      await apiAuth
        .post("/cadastro-de-produto", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((_) => {
          loadProductsConveyor();

          setLoading(false);

          toast({
            title: "Sucesso",
            description: "Produto adicionado!",
            status: "success",
            duration: 1000,
            isClosable: true,
          });

          onClose();
        });
    } catch (error) {
      setLoading(false);

      toast({
        title: "Erro",
        description: "Algo deu errado ao cadastrar produto!",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const addExistingProductToTheConveyor = async (
    productName,
    image_url,
    link
  ) => {
    const formData = {
      produto_name: productName,
      image_url: image_url,
      link: link,
      id_login: data.id,
    };

    try {
      await apiAuth
        .post("/cadastro-de-produto", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          if (res.data.error === "true") {
            toast({
              title: "Erro",
              description: "Você não tem nenhuma coluna cadastrada na esteira!",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Sucesso",
              description: "Produto adicionado na esteira!",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
        });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao adicionar produto na esteira!",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const updateProductConveyor = async (
    productName,
    product,
    setLoading,
    onClose
  ) => {
    const newData = {
      position: product.position,
      produto_id: product.id,
      name: productName,
    };

    setLoading(true);

    try {
      await apiAuth
        .post(
          "/atualizar-produto",
          { produto_id: product.id, data: JSON.stringify(newData) },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((_) => {
          loadProductsConveyor();

          setLoading(false);

          toast({
            title: "Sucesso",
            description: "Produto atualizado!",
            status: "success",
            duration: 1000,
            isClosable: true,
          });

          onClose();
        });
    } catch (error) {
      setLoading(false);

      toast({
        title: "Erro",
        description: "Algo deu errado ao atualizar produto!",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const loadProductDetails = async (productId) => {
    const response = await apiAuth.get(`/detalhe-produto?id=${productId}`);

    const product = response.data;

    localStorage.setItem("@Playdrop:detalhes-produto", JSON.stringify(product));

    const storedData = localStorage.getItem("@Playdrop:detalhes-produto");

    if (storedData) {
      setProductDetails(JSON.parse(storedData));
    }
  };

  const searchByCategory = (categoryName) => {
    if (categoryName !== "") {
      const normalize = categoryName
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      console.log(categoryName);
      console.log(normalize);

      const filteredCategory = products.filter((item) =>
        item.categoria
          .toLocaleLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(normalize)
      );
      setFilteredProducts([...filteredCategory]);
    } else {
      setFilteredProducts([]);
      setProducts([...products]);
    }
  };

  const updateProductDetails = async (details, setLoading) => {
    setLoading(true);

    try {
      await apiAuth
        .post(
          "/atualizar-produto",
          { produto_id: details.produto_id, data: JSON.stringify(details) },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((_) => {
          // loadProductsConveyor();

          setLoading(false);

          toast({
            title: "Sucesso",
            description: "Produto atualizado!",
            status: "success",
            duration: 1000,
            isClosable: true,
          });
        });
    } catch (error) {
      setLoading(false);

      toast({
        title: "Erro",
        description: "Algo deu errado ao atualizar produto!",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const loadVendors = async (adId) => {
    const response = await apiCredits.get(`/vendor/get/${adId}`);

    const data = response.data.response.slice(0, 12);

    localStorage.setItem("@PlayDrop:Vendors", JSON.stringify(data));

    const storedData = localStorage.getItem("@PlayDrop:Vendors");

    if (storedData) {
      setVendors(JSON.parse(storedData));
    }
  };

  return (
    <ProductsContextClient.Provider
      value={{
        products,
        setProducts,
        selectValue,
        setSelectValue,
        searchByCategory,
        filteredProducts,
        setFilteredProducts,
        loadProductsConveyor,
        productsConveyor,
        setProductsConveyor,
        updatePositionConveyor,
        deteleProduct,
        deteleColumn,
        addNewProduct,
        updateProductConveyor,
        loadProductDetails,
        productDetails,
        updateProductDetails,
        addExistingProductToTheConveyor,
        updateColumn,
        loadVendors,
        vendors,
      }}
    >
      {children}
    </ProductsContextClient.Provider>
  );
};
