import styled from "styled-components";

export const CheckListContent = styled.ul`
  width: 100%;
  margin-bottom: 2rem;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 2rem;
`;

export const List = styled.li`
  padding: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;

  border: 2px solid ${(props) => (props.colorSchema ? "#02a724" : "#e6eeff")};
  border-radius: 10px;
  background-color: #fff;

  button {
    width: 100%;
    height: 50px;

    color: #fff;
    border-radius: 10px;
    background-color: #3058a4;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .content-icon {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h4 {
      font-size: 1.125rem;
      color: ${(props) => (props.colorSchema ? "#02a724" : "#8f8f8f")};
    }
  }

  .concluded {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    p {
      font-size: 1.125rem;
      font-weight: 500;
      color: #02a724;
    }
  }

  &:hover {
    transform: scale(1.1);
    transition: ease-in 0.2s;
  }
`;
