import styled from "styled-components";

export const CategoryContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  font-family: "Poppins";
  font-style: normal;

  .headers {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-bottom: 0.5rem;

    li {
      font-weight: 600;
      font-size: 1rem;
      color: #4f4f4f;
    }

    .headers__nome {
      width: 45%;
    }

    .headers__data {
      width: 35%;
    }

    .headers__acoes {
      width: 20%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .main:nth-child(odd) {
      background-color: #ffffff;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 0.5rem;
      height: 50px;
      padding-left: 0.5rem;

      .content__nome {
        width: 45%;
      }

      .content__data {
        width: 35%;
      }

      .content__acoes {
        width: 20%;
        display: flex;
      }
    }
  }
`;
