import { apiAuth, apiCredits } from "../../../services/api";
import { useToast } from "@chakra-ui/react";
import { createContext, useContext, useState } from "react";

const ProductsContextAdmin = createContext({});

export const useProductsAdmin = () => {
  const context = useContext(ProductsContextAdmin);

  if (!context) {
    throw new Error(
      "useProductsAdmin must be used within an ProductsProviderAdmin"
    );
  }

  return context;
};

export const ProductsProviderAdmin = ({ children }) => {
  const [products, setProducts] = useState([]);

  const toast = useToast();

  const loadAllProducts = async () => {
    const response = await apiAuth.get("/listarfornecedores");

    const data = response.data.fornecedores;

    const invertData = data.slice().reverse();

    localStorage.setItem("@PlayDrop:fornecedores", JSON.stringify(invertData));

    const storedData = localStorage.getItem("@PlayDrop:fornecedores");

    if (storedData) {
      setProducts(JSON.parse(storedData));
    }
  };

  const addNewProduct = async (data) => {
    const body = {
      ...data,
      imagem: data.imagem[0],
      clicks: 0,
      pessoas: 0,
    };

    try {
      await apiAuth
        .post("/cadastrofornecedores", body, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res);

          toast({
            title: "Sucesso",
            description: "Produto adicionado com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadAllProducts();
        });
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro",
        description: "Algo deu errado ao adicionar produto!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const updateProduct = async (data, productId) => {
    const body = {
      ...data,
      id_fornecedores: productId,
    };

    try {
      await apiAuth
        .post(
          "/updatefornecedores",
          { data: JSON.stringify(body) },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((res) => {
          console.log(res);

          toast({
            title: "Sucesso",
            description: "Produto adicionado com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadAllProducts();
        });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao adicionar produto!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const deleteProduct = async (productId) => {
    try {
      await apiAuth
        .get(`/deletarfornecedor?id_fornecedores=${productId}`)
        .then((_) => {
          toast({
            title: "Sucesso",
            description: "Produto excluído com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadAllProducts();
        });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao excluir produto!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const addNewVendors = async (token, adId, setLoading) => {
    setLoading(true);

    const body = {
      token: token,
      IDs: adId,
    };

    try {
      await apiCredits
        .post("/vendor/search", body, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((_) => {
          setLoading(false);
          toast({
            title: "Sucesso",
            description: "Fornecedores manuais cadastrados com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
        });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Erro",
        description: "Algo deu errado ao adicionar fornecedor!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  return (
    <ProductsContextAdmin.Provider
      value={{
        products,
        loadAllProducts,
        addNewProduct,
        updateProduct,
        deleteProduct,
        addNewVendors,
      }}
    >
      {children}
    </ProductsContextAdmin.Provider>
  );
};
