import { CepProvider } from "./Cep";
import { UserProvider } from "./User";
import { AuthProvider } from "./Auth";
import { PlansProvider } from "./Plans";
import { ChakraProvider } from "@chakra-ui/react";
import { MessagesProvider } from "./Messages";
import { ProductsProvider } from "./Products";
import { WebSitesProvider } from "./Websites";
import { BacklogsProvider } from "./Backlogs";
import { TemplatesProvider } from "./Templates";
import { TutorialsProvider } from "./Tutorials";
import { CategoriesProvider } from "./Categories";
import { SocialMediaProvider } from "./SocialMedia";
import { CreditsProvider } from "./Credits";

export const AppProvider = ({ children }) => {
  return (
    <AuthProvider>
      <UserProvider>
        <BacklogsProvider>
          <CepProvider>
            <ProductsProvider>
              <WebSitesProvider>
                <SocialMediaProvider>
                  <TemplatesProvider>
                    <CategoriesProvider>
                      <MessagesProvider>
                        <PlansProvider>
                          <TutorialsProvider>
                            <CreditsProvider>
                              <ChakraProvider>{children}</ChakraProvider>
                            </CreditsProvider>
                          </TutorialsProvider>
                        </PlansProvider>
                      </MessagesProvider>
                    </CategoriesProvider>
                  </TemplatesProvider>
                </SocialMediaProvider>
              </WebSitesProvider>
            </ProductsProvider>
          </CepProvider>
        </BacklogsProvider>
      </UserProvider>
    </AuthProvider>
  );
};
