import { Title } from "../../../components/Reusable/Title";
import { Container } from "../../../components/Reusable/Container";
import { PlanCardAdmin } from "./PlanCardAdmin";
import { ModalCrudPlan } from "../../../components/Modal/Plans/ModalCrudPlan";
import { usePlansClient } from "../../../contexts/Plans/PlansContextClient";
import { BsCurrencyDollar } from "react-icons/bs";
import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { useMemo } from "react";
import { useAuth } from "../../../contexts/Auth";

export const PlansAdmin = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data } = useAuth();
  const { plans, loadPlans } = usePlansClient();

  useMemo(() => {
    Object.values(data).length > 0 && loadPlans();
  }, [data, loadPlans]);

  return (
    <>
      <Container>
        <Title
          headingName="Planos - Painel Administrativo"
          caption="Área de administrador para controle dos planos na PlayDrop."
        >
          <BsCurrencyDollar fill="#3058A4" size="1.875rem" />
        </Title>
        <Button
          h="50px"
          w="200px"
          mt="1rem"
          mb="1rem"
          bg="#3058A4"
          color="#ffffff"
          fontFamily="Poppins"
          _hover={{ opacity: 0.7 }}
          onClick={() => onOpen()}
        >
          Adicionar novo plano
        </Button>
        <Flex
          alignItems="center"
          display="grid"
          gap="8"
          gridTemplateColumns="repeat(auto-fill, minmax(15rem, 1fr))"
        >
          {plans.map((plan) => {
            return <PlanCardAdmin key={plan.id} planData={plan} />;
          })}
        </Flex>
      </Container>
      <ModalCrudPlan isOpen={isOpen} onClose={onClose} verbType="create" />
    </>
  );
};
