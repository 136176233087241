import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import { Text, Grid, Button, Select, useToast } from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";
import { MdArrowDropDown } from "react-icons/md";
import { useState } from "react";
import { apiAuth } from "../../../services/api";
import { useUser } from "../../../contexts/User";

export const ModalUpdateConfig = ({ isOpen, onClose, userId }) => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(false);

  const [statusData, setStatusData] = useState({
    statusPlan: "",
    subStatusPlan: "",
  });
  const [planData, setPlanData] = useState({
    planId: 4,
    time_plan: 1,
  });

  const toast = useToast();

  const { loadUsers } = useUser();

  const handleUpdateStatusPlan = async (event, data) => {
    event.preventDefault();

    setLoadingStatus(true);

    const body = {
      id: userId,
      id_login: userId,
      plano_status: data.statusPlan,
      plano_sub_status: data.subStatusPlan,
    };

    try {
      await apiAuth
        .post(
          `/editarperfil?id=${userId}`,
          { data: JSON.stringify(body) },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((_) => {
          loadUsers();
          setLoadingStatus(false);
        });
    } catch (error) {
      toast({
        title: "Opss...",
        description: "Algo deu errado!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      setLoadingStatus(false);
    }
  };

  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  const handleUpdatePlan = async (event, data) => {
    event.preventDefault();

    setLoadingPlan(true);

    const date = new Date();
    const days =
      Number(data.time_plan) === 1
        ? 30
        : Number(data.time_plan) === 3
        ? 90
        : 365;

    const dueDate = addDays(date, days);

    const body = {
      id: userId,
      id_login: userId,
      id_planos: Number(data.planId),
      key_planos: Number(data.planId),
      time_plan:
        Number(data.time_plan) === 1
          ? 30
          : Number(data.time_plan) === 3
          ? 90
          : 365,
      plano_data_venc: dueDate.toISOString().slice(0, 10),
    };

    try {
      await apiAuth
        .post(
          `/editarperfil?id=${userId}`,
          { data: JSON.stringify(body) },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((_) => {
          loadUsers();
          setLoadingPlan(false);
        });
    } catch (error) {
      toast({
        title: "Opss...",
        description: "Algo deu errado!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      setLoadingPlan(false);
    }
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={FaUser} color="#fff" />
            <ModalHeader.Title title="Edição de configurações" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <Grid gap="6" mb="6" mt="6">
            <Grid
              w="100%"
              p="4"
              boxShadow="0 12px 9px -1.7px #e6eeff"
              borderRadius="10px"
            >
              <Grid>
                <Text
                  textAlign="left"
                  fontWeight="500"
                  color="#3058A5"
                  fontSize="1rem"
                >
                  Status do Plano
                </Text>
                <Select
                  h="50px"
                  outline="0"
                  color="#505050"
                  iconSize="1rem"
                  cursor="pointer"
                  borderRadius="6px"
                  iconColor="#3058A4"
                  fontSize="1.125rem"
                  border="2px solid #3058A4"
                  placeholder="Status do plano"
                  onChange={(ev) =>
                    setStatusData({
                      ...statusData,
                      statusPlan: ev.target.value,
                    })
                  }
                  _hover={{ borderColor: "#3058A4" }}
                  icon={<MdArrowDropDown size="1.5rem" />}
                >
                  <option value="ativo">Ativo</option>
                  <option value="pendente">Pendente</option>
                  <option value="inativo">Inativo</option>
                  <option value="cancelado">Cancelado</option>
                </Select>
              </Grid>

              <Grid>
                <Text
                  textAlign="left"
                  fontWeight="500"
                  color="#3058A5"
                  fontSize="1rem"
                >
                  Sub Status do Plano
                </Text>
                <Select
                  h="50px"
                  outline="0"
                  color="#505050"
                  iconSize="1rem"
                  cursor="pointer"
                  borderRadius="6px"
                  iconColor="#3058A4"
                  fontSize="1.125rem"
                  border="2px solid #3058A4"
                  placeholder="Sub status do plano"
                  onChange={(ev) =>
                    setStatusData({
                      ...statusData,
                      subStatusPlan: ev.target.value,
                    })
                  }
                  _hover={{ borderColor: "#3058A4" }}
                  icon={<MdArrowDropDown size="1.5rem" />}
                >
                  <option value="pago">Pago</option>
                  <option value="aguardando_pagamento">
                    Aguardando Pagamento
                  </option>
                  <option value="atraso_pagamento">Atraso Pagamento</option>
                </Select>
              </Grid>

              <Button
                mt="2"
                h="50px"
                bg="#ffffff"
                color="#3058A4"
                isLoading={loadingStatus}
                border="2px solid #3058A4"
                _hover={{ bgColor: "#3058A4", color: "#ffffff" }}
                onClick={(event) => handleUpdateStatusPlan(event, statusData)}
              >
                Atualizar
              </Button>
            </Grid>
          </Grid>

          <Grid
            w="100%"
            p="4"
            boxShadow="0 12px 9px -1.7px #e6eeff"
            borderRadius="10px"
          >
            <Grid>
              <Text
                textAlign="left"
                fontWeight="500"
                color="#3058A5"
                fontSize="1rem"
              >
                Validade do plano
              </Text>
              <Select
                h="50px"
                outline="0"
                color="#505050"
                iconSize="1rem"
                cursor="pointer"
                borderRadius="6px"
                iconColor="#3058A4"
                fontSize="1.125rem"
                border="2px solid #3058A4"
                placeholder="Validade do Plano"
                onChange={(ev) =>
                  setPlanData({
                    ...planData,
                    time_plan: ev.target.value,
                  })
                }
                _hover={{ borderColor: "#3058A4" }}
                icon={<MdArrowDropDown size="1.5rem" />}
              >
                <option value={1}>Mensal</option>
                <option value={3}>Trimestral</option>
                <option value={12}>Anual</option>
              </Select>
            </Grid>

            <Grid>
              <Text
                textAlign="left"
                fontWeight="500"
                color="#3058A5"
                fontSize="1rem"
              >
                Alterar Plano
              </Text>
              <Select
                h="50px"
                outline="0"
                color="#505050"
                iconSize="1rem"
                cursor="pointer"
                borderRadius="6px"
                iconColor="#3058A4"
                fontSize="1.125rem"
                border="2px solid #3058A4"
                placeholder="Plano"
                onChange={(ev) =>
                  setPlanData({
                    ...planData,
                    planId: ev.target.value,
                  })
                }
                _hover={{ borderColor: "#3058A4" }}
                icon={<MdArrowDropDown size="1.5rem" />}
              >
                <option value="4">Plano Basic</option>
                <option value="5">Plano Gold</option>
                <option value="9">Plano Premium</option>
              </Select>
            </Grid>

            <Button
              mt="2"
              h="50px"
              bg="#ffffff"
              color="#3058A4"
              isLoading={loadingPlan}
              border="2px solid #3058A4"
              _hover={{ bgColor: "#3058A4", color: "#ffffff" }}
              onClick={(event) => handleUpdatePlan(event, planData)}
            >
              Atualizar Plano
            </Button>
          </Grid>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
