import { Modal } from "../../index";
import { ModalHeader } from "../../ModalHeader";

import { FaUser } from "react-icons/fa";
import { UpdateProfileContent } from "../../../../pages/Profile/UpdateProfileContent";

export const ModalUpdateProfile = ({ isOpen, onClose }) => {
  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="500px" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={FaUser} color="#fff" />
            <ModalHeader.Title title="Atualizar dados de perfil" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <UpdateProfileContent onClose={onClose} />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
