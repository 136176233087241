import {
  Text,
  Flex,
  Button,
  Center,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";

import { useState } from "react";
import { ModalCrudPlan } from "../../../components/Modal/Plans/ModalCrudPlan";
import { usePlansAdmin } from "../../../contexts/Plans/PlansContextAdmin";
import { FaEdit, FaTimes } from "react-icons/fa";
import { currencyFormat } from "../../../utils/currencyFormat";

export const PlanCardAdmin = ({ planData }) => {
  const [showDescription, setShowDescription] = useState(false);

  const actives = planData.ativos.split(",");
  const inatives = planData.inativos.split(",");

  const { deletePlan } = usePlansAdmin();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        p="6"
        bg="#ffffff"
        flexDir="column"
        borderRadius="8.59px"
        border="0.85px solid #EEEEEE"
        boxShadow="0px 12.7792px 9.37143px -1.7039px #E6EEFF"
      >
        <Heading
          mb="6"
          color="#3058A4"
          fontWeight="600"
          fontSize="1.5rem"
          textAlign="center"
        >
          {planData.plano}
        </Heading>
        <Text
          mb="6"
          fontSize="2rem"
          color="#494949"
          fontWeight="600"
          textAlign="center"
          fontStyle="normal"
        >
          {currencyFormat(planData.mensalidades)}
        </Text>
        <Button
          bg="#3058A4"
          color="#ffffff"
          _hover={{ opacity: 0.7 }}
          onClick={() => setShowDescription(!showDescription)}
        >
          Mostrar benefícios
        </Button>
        <Flex mt="4" alignItems="center" justifyContent="space-around">
          <Center
            w="2.5rem"
            h="2.5rem"
            mr="6px"
            as="button"
            bg="#2212F4"
            borderRadius="6px"
            onClick={() => onOpen()}
          >
            <FaEdit size="1.5rem" fill="#ffffff" />
          </Center>
          <Center
            w="2.5rem"
            h="2.5rem"
            as="button"
            bg="#FB6B03"
            borderRadius="6px"
            onClick={() => deletePlan(planData.id)}
          >
            <FaTimes size="1.5rem" fill="#ffffff" />
          </Center>
        </Flex>
        {showDescription && (
          <>
            {actives.map((benefit) => (
              <Text
                key={benefit}
                ml="2"
                mb="2"
                mt="2"
                color="#4F4F4F"
                fontWeight="400"
                textAlign="center"
                fontStyle="normal"
                fontFamily="Poppins"
                fontSize={["1.2rem", "1.2rem", "0.9rem"]}
              >
                {benefit}
              </Text>
            ))}
            {inatives &&
              inatives.map((benefit) => (
                <Text
                  key={benefit}
                  ml="2"
                  mb="2"
                  mt="2"
                  color="#F44336"
                  fontWeight="400"
                  textAlign="center"
                  fontStyle="normal"
                  fontFamily="Poppins"
                  fontSize={["1.2rem", "1.2rem", "0.9rem"]}
                >
                  {benefit}
                </Text>
              ))}
          </>
        )}
      </Flex>
      <ModalCrudPlan
        isOpen={isOpen}
        onClose={onClose}
        verbType="update"
        planData={planData}
      />
    </>
  );
};
