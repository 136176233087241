import { useEffect, useState } from "react";
import * as C from "./styles";
import { useAuth } from "../../contexts/Auth";
import { useWebsitesClient } from "../../contexts/Websites/WebsitesContextClient";
import { useProductsClient } from "../../contexts/Products/ProductsContextClient";
import { FaCheck, FaDownload, FaUser } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import { LuLayoutDashboard } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useBacklogs } from "../../contexts/Backlogs";

export const ChecklistDashboard = () => {
  const { data } = useAuth();

  const { websitesUser } = useWebsitesClient();

  const { productsConveyor } = useProductsClient();

  const { backlogDataUser } = useBacklogs();

  const navigate = useNavigate();

  let productsConveyorQuantity = 0;

  for (const item in productsConveyor) {
    if (productsConveyor.hasOwnProperty(item)) {
      productsConveyorQuantity += productsConveyor[item].items.length;
    }
  }

  const searchThemeBacklog =
    backlogDataUser.length > 0 &&
    backlogDataUser?.filter(
      (item) => item.pendencias === "Criação de novo tema"
    );

  const getTasks = () => [
    {
      id: 1,
      text: "Criação de sua conta",
      navigate: "",
      icon: (
        <FaUser
          size="2rem"
          color={data.contato !== "" ? "#02a724" : "#8f8f8f"}
        />
      ),
      completed: data.contato !== "" ? true : false,
    },
    {
      id: 2,
      text: "Crie sua primeira loja",
      navigate: "/dashboard/construtor-de-lojas",
      icon: (
        <TbWorld
          size="2rem"
          color={websitesUser.length > 0 ? "#02a724" : "#8f8f8f"}
        />
      ),
      completed: websitesUser.length > 0 ? true : false,
    },
    {
      id: 3,
      text: "Importar produtos para esteira",
      navigate: "/dashboard/fornecedores",
      icon: (
        <FaDownload
          size="2rem"
          color={productsConveyorQuantity > 0 ? "#02a724" : "#8f8f8f"}
        />
      ),
      completed: productsConveyorQuantity > 0 ? true : false,
    },
    {
      id: 4,
      text: "Adicionar novo tema para sua loja",
      navigate: "/dashboard/construtor-de-lojas",
      icon: (
        <LuLayoutDashboard
          size="2rem"
          color={searchThemeBacklog.length > 0 ? "#02a724" : "#8f8f8f"}
        />
      ),
      completed: searchThemeBacklog.length > 0 ? true : false,
    },
  ];

  const [tasks, setTasks] = useState(getTasks());

  const countCompletedTasks = (tasks) => {
    return tasks.filter((task) => task.completed).length;
  };

  const completedCount = countCompletedTasks(tasks);
  const totalCount = tasks.length;

  const progressPercentage = (completedCount / totalCount) * 100;

  const progressBarStyle = {
    width: "300px",
    height: "0.5rem",
    backgroundColor: "#e0e0e0",
    borderRadius: "0.5rem",
    overflow: "hidden",
  };

  const progressStyle = {
    width: `${progressPercentage}%`,
    height: "100%",
    backgroundColor: "#02a724",
  };

  useEffect(() => {
    setTasks(getTasks());

    // eslint-disable-next-line
  }, [data, websitesUser, productsConveyorQuantity]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "1rem",
          marginTop: "2rem",
        }}
      >
        <p>
          {completedCount} de {totalCount}
        </p>
        <div style={progressBarStyle}>
          <div style={progressStyle}></div>
        </div>
      </div>

      <C.CheckListContent>
        {tasks.map((item) => (
          <C.List key={item.id} colorSchema={item.completed}>
            <div className="content-icon">
              <span>{item.icon}</span>
              <h4>{item.text}</h4>
            </div>

            {item.completed ? (
              <div className="concluded">
                <p>Concluído!</p>

                <FaCheck size="1.5rem" color="#02a724" />
              </div>
            ) : (
              <button onClick={() => navigate(item.navigate)}>
                Prosseguir
              </button>
            )}
          </C.List>
        ))}
      </C.CheckListContent>
    </>
  );
};
