import { FaEdit, FaTimes } from "react-icons/fa";
import { useTemplatesAdmin } from "../../../contexts/Templates/TemplatesContextAdmin";
import { ModalCrudTemplate } from "../../../components/Modal/Templates/ModalCrudTemplate";
import { Center, useDisclosure } from "@chakra-ui/react";

export const TemplateCard = ({ template }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { deleteTemplate } = useTemplatesAdmin();

  return (
    <>
      <div className="main">
        <li className="content__capa">
          <img
            src={`https://services.playdrop.com.br/api/uploads/imgs/capas-sites/${template.capa_template}`}
            alt={template.nome_template}
          />
        </li>
        <li className="content__nome">{template.nome_template}</li>
        <li className="content__data">{template.data_publicacao}</li>
        <li className="content__exibicao">Sim</li>
        <li className="content__acoes">
          <Center
            w="2rem"
            h="2rem"
            mr="6px"
            as="button"
            bg="#3058A4"
            borderRadius="6px"
            onClick={() => onOpen()}
          >
            <FaEdit fill="#ffffff" />
          </Center>
          <Center
            w="2rem"
            h="2rem"
            as="button"
            bg="#FB6B03"
            borderRadius="6px"
            onClick={() => deleteTemplate(template.id_template)}
          >
            <FaTimes fill="#ffffff" />
          </Center>
        </li>
      </div>
      <ModalCrudTemplate
        isOpen={isOpen}
        onClose={onClose}
        verbType="update"
        templateData={template}
      />
    </>
  );
};
