import { Modal } from "../index";
import { Input } from "../../Reusable/Input";
import { ModalHeader } from "../ModalHeader";
import { VStack, Button, Textarea } from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { useTutorialsAdmin } from "../../../contexts/Tutorials/TutorialsContextAdmin";

import { yupResolver } from "@hookform/resolvers/yup";
import { tutorialSchema } from "../../../schemas/schemas";

import { BiCube } from "react-icons/bi";

export const ModalCrudTutorial = ({ isOpen, onClose, verbType, tutorial }) => {
  const { addNewTutorial, editTutorial } = useTutorialsAdmin();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(tutorialSchema),
  });

  const onSubmitFunction = (data) => {
    if (verbType === "create") {
      addNewTutorial(data);
    } else {
      editTutorial(tutorial.id, data);
    }
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BiCube} color="#fff" />
            <ModalHeader.Title
              title={
                verbType === "create"
                  ? "Adicionar Novo Tutorial"
                  : "Editar Tutorial"
              }
            />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <VStack
            as="form"
            spacing="4"
            onSubmit={handleSubmit(onSubmitFunction)}
          >
            <Input
              type="file"
              label="Imagem de Capa"
              error={errors.imagem_capa}
              {...register("imagem_capa")}
            />
            <Input
              label="Data"
              type="date"
              {...register("data_publicacao")}
              defaultValue={
                verbType === "update" ? tutorial.data_publicacao : ""
              }
            />
            <Input
              label="Categoria"
              {...register("categoria")}
              defaultValue={verbType === "update" ? tutorial.categoria : ""}
            />

            <Textarea
              {...register("descricao")}
              placeholder="Digite a descrição do tutorial"
              defaultValue={verbType === "update" ? tutorial.descricao : ""}
            />

            <Button
              h="50px"
              w="200px"
              mt="1rem"
              mb="1rem"
              bg="#3058A4"
              type="submit"
              color="#ffffff"
              fontFamily="Poppins"
              _hover={{ opacity: 0.7 }}
            >
              Confirmar
            </Button>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
