import styled from "styled-components";

export const Content = styled.ul`
  width: 100%;

  h4 {
    margin-top: 2rem;
    margin-bottom: 0.8rem;

    color: #3058a4;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .skeleton {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1rem;
  }
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  .search {
    position: relative;

    span {
      position: absolute;
      top: 1.25rem;
      right: 1rem;

      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Card = styled.div`
  width: 300px;
  height: 350px;

  border: 1px solid #f5f5f5;
  border-radius: 10px;
  background-color: #fff;

  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .hovered {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;

    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5);

    cursor: pointer;

    button {
      width: 250px;
      height: 50px;

      font-size: 1.25rem;
      font-weight: 500;
      border-radius: 10px;
      background-color: #fff;

      &:hover {
        cursor: pointer;

        color: #fff;
        background-color: #3058a4;

        transition: ease-in 0.2s;
      }
    }
  }
`;

export const Details = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .video-content {
    width: 80%;

    .post__description {
      width: 100%;
      padding: 1rem;

      box-shadow: 0px 8px 5px -1px #e6eeff;
      border-radius: 10px;
      background-color: #fff;
    }

    h4 {
      font-size: 1.25rem;
      font-weight: 500;
      color: #3058a4;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    p {
      font-size: 1.125rem;
      color: #4f4f4f;
    }
  }

  .divider {
    width: 2px;
    height: 600px;
    margin: 0 1rem;

    background-color: #e6eeff;
  }

  .more-classes-content {
    width: 20%;

    .more-classes {
      width: 100%;
      height: 460px;
      padding: 10px;
      margin-bottom: 1rem;

      overflow-x: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        border: 0;
      }

      &::-webkit-scrollbar-track {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #3059a4;
        border-radius: 24px;
      }

      box-shadow: 0px 8px 5px -2px #e6eeff;
      border-radius: 10px;

      display: flex;
      flex-direction: column;
      gap: 1rem;

      li {
        width: 100%;
        height: 60px;
        padding-left: 0.5rem;

        display: flex;
        align-items: center;

        box-shadow: 0px 8px 5px -2px #3058a4;
        border-radius: 10px;
        background-color: #fff;

        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    button {
      width: 100%;
      height: 55px;

      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 10px;
      background-color: #fb6b03;

      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;
