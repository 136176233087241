import { Title } from "../../../components/Reusable/Title";
import { useEffect, useMemo } from "react";
import { TbWorld } from "react-icons/tb";
import { MainPage } from "../../../components/Reusable/Pagination/MainPage";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Container } from "../../../components/Reusable/Container";
import { WebsiteInfo } from "./WebsiteInfo";
import { HeaderUsers } from "../UsersAdmin/styles";
import { SitesContent } from "./styles";
import { useWebsitesAdmin } from "../../../contexts/Websites/WebsitesContextAdmin";
import { Center, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useUser } from "../../../contexts/User";

let PageSize = 10;

export const MySitesAdmin = () => {
  const { websitesAdmin, searchWebsite, loadWebsitesAdmin } =
    useWebsitesAdmin();

  const { users, loadUsers } = useUser();

  const [currentPage, setCurrentPage] = useState(1);
  const [username, setUsername] = useState("");

  useEffect(() => {
    loadWebsitesAdmin();
    loadUsers();

    // eslint-disable-next-line
  }, []);

  const filterWebsites = useMemo(() => {
    return websitesAdmin?.map((site) => {
      const user = users.find((user) => user.id === site.id_login);

      return {
        ...site,
        contato: user?.contato || "N/A",
        email: user?.email || "N/A",
        nome: user?.nome || "N/A",
      };
    });
  }, [websitesAdmin, users]);

  const currentTableWebsites = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return filterWebsites?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filterWebsites]);

  return (
    <Container>
      <HeaderUsers>
        <Title headingName="Lojas - Painel Administrativo">
          <TbWorld color="#3058A4" size="30px" />
        </Title>
        <InputGroup mt="4" w="270px">
          <Input
            color="#505050"
            placeholder="Pesquisar usuário"
            value={username}
            onChange={(ev) => {
              setUsername(ev.target.value);
              searchWebsite(ev.target.value);
            }}
          />
          <InputRightElement w="50px">
            <Center
              as="button"
              _hover={{ opacity: 0.7 }}
              onClick={() => searchWebsite(username)}
            >
              <FaSearch fill="#3058a4" size="1.5rem" />
            </Center>
          </InputRightElement>
        </InputGroup>
      </HeaderUsers>
      <SitesContent>
        <div className="header">
          <li className="header__nome">Usuário</li>
          <li className="header__email">Contato</li>
          <li className="header__sites">Site</li>
          <li className="header__tema">Tema</li>
          <li className="header__id">ID</li>
          <li className="header__acoes">Ações</li>
        </div>
        {currentTableWebsites.map((site) => {
          return <WebsiteInfo key={site.id_site} site={site} />;
        })}
      </SitesContent>
      {websitesAdmin.length > 1 && (
        <MainPage
          pageSize={PageSize}
          currentPage={currentPage}
          totalCount={websitesAdmin.length}
          className="pagination-bar"
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
    </Container>
  );
};
