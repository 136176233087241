import { Flex, Grid, Heading, Text } from "@chakra-ui/react";

export const Title = ({ children, headingName, caption }) => {
  return (
    <Grid>
      <Flex
        alignItems="center"
        justifyContent={["center", "center", "center", "flex-start"]}
      >
        {children}
        <Heading
          as="h2"
          fontWeight="600"
          color="#3058a4"
          ml="2"
          fontSize={["1.25rem", "1.5rem"]}
        >
          {headingName}
        </Heading>
      </Flex>
      {caption && (
        <Text
          mt="4"
          mb="8"
          color="#4F4F4F"
          fontWeight="400"
          fontSize="1rem"
          textAlign={["center", "center", "center", "left"]}
        >
          {caption}
        </Text>
      )}
    </Grid>
  );
};
