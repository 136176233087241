import * as P from "./styles";

// Components
import { Title } from "../../components/Reusable/Title";
import { CardPlan } from "./CardPlan";
import { Container } from "../../components/Reusable/Container";
import { Center, Flex, Text } from "@chakra-ui/react";

// Hooks
import { useAuth } from "../../contexts/Auth";
import { usePlansClient } from "../../contexts/Plans/PlansContextClient";

// Icons
import { BsCurrencyDollar } from "react-icons/bs";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export const Plans = () => {
  const { data } = useAuth();

  const { plans } = usePlansClient();

  return (
    <Container>
      {data.plano_sub_status && data.plano_sub_status === "atraso_pagamento" ? (
        <Flex
          w="100%"
          h="47px"
          pl="6"
          mb="6"
          bg="#FFDD86"
          borderRadius="10px"
          alignItems="center"
        >
          <Center>
            <HiOutlineExclamationCircle
              size="1.5rem"
              fontWeight="500"
              color="#BF4635"
            />
          </Center>
          <Text
            ml="4"
            fontSize="1rem"
            fontWeight="600"
            color="#BF4635"
            fontFamily="Poppins"
          >
            Informamos que o seu plano está em atraso, por favor entre em
            contato com o suporte para regularizar e voltar a vender e lucrar
          </Text>
        </Flex>
      ) : null}

      <Title
        headingName="Planos Disponíveis"
        caption="Agora você pode escolher qual plano está de acordo com a sua necessidade."
      >
        <BsCurrencyDollar fill="#3058A4" size="1.5rem" />
      </Title>

      <hr />

      <P.Content>
        <P.PlansContent>
          {plans?.map((plan) => {
            return <CardPlan key={plan.id} plan={plan} />;
          })}
        </P.PlansContent>
      </P.Content>
    </Container>
  );
};
