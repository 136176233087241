import styled from "styled-components";

export const Content = styled.div`
  width: 100%;

  .info-credits {
    width: 100%;
    max-width: 350px;
    height: 2.5rem;
    padding: 0.2rem 1rem;
    margin-bottom: 1rem;

    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0px 8px 5px -1px #e6eeff;

    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #3058a4;
      font-size: 1rem;

      span {
        margin: 0 4px;
      }
    }

    div {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .result-names {
    width: 100%;
    margin-top: 2rem;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1.5rem;

    .card-name {
      display: flex;
      flex-direction: column;
      gap: 10px;

      padding: 1rem;

      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0px 5px 4px -2px #e3e6fe;

      h4 {
        color: #4f4f4f;
        font-size: 1.5rem;
        margin-bottom: 0;
      }

      .domain {
        p {
          color: #4f4f4f;
          font-weight: 500;
        }
        span {
          color: #3058a4;
          font-size: 1rem;
          font-weight: 500;
        }
      }

      .verify-domain {
        display: flex;
        align-items: center;
        gap: 1rem;

        &:hover {
          cursor: pointer;

          svg {
            transform: scale(1.1);
            transition: ease-in 0.2s;
          }
        }
      }

      &:hover {
        transform: scale(1.1);
        transition: ease-in 0.2s;
      }
    }
  }

  .loading {
    width: 100%;
    height: 200px;

    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #3058a4;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
`;

export const FormContent = styled.form`
  width: 100%;

  .error {
    color: #f00000;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
  }

  .flex-content {
    width: 100%;
    margin-bottom: 1rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .content-options {
    width: 100%;
    padding: 1rem;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1rem;

    label {
      height: 7.5rem;
      padding: 1rem;

      box-shadow: 0px 5px 4px -2px #e3e6fe;
      border-radius: 10px;
      background-color: #fff;

      display: flex;
      align-items: baseline;
      gap: 1rem;

      cursor: pointer;

      input {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      span {
        .title {
          font-size: 1.25rem;
          font-weight: 500;
          color: #4f4f4f;
        }
        p {
          font-size: 1rem;
          font-weight: 500;
          color: #a1a1a1;
        }
      }
    }
  }

  .third-step-content {
    width: 100%;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;
