// Icons
import { TbWorld } from "react-icons/tb";
import { FaFire, FaRegCopyright } from "react-icons/fa";
import { BsCurrencyDollar, BsGrid } from "react-icons/bs";
import { BiCalculator, BiCube, BiStoreAlt } from "react-icons/bi";

// Components
import { Box } from "@chakra-ui/react";
import { DrawerDeskOptions } from "./DrawerDeskOptions";

// Hooks
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LuLayoutGrid } from "react-icons/lu";

export const DesktopDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  return (
    <Box
      w="100%"
      bg="#ffffff"
      gap="2"
      top="6.25rem"
      left="0"
      display="flex"
      flexDir="column"
      maxWidth="15.625rem"
      position="fixed"
      fontFamily="Poppins"
    >
      <DrawerDeskOptions
        textName="Dashboard"
        pathnames={location.pathname === "/dashboard/menu-inicial"}
        color={
          location.pathname === "/dashboard/menu-inicial"
            ? "#ffffff"
            : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/menu-inicial");
        }}
        bg={location.pathname === "/dashboard/menu-inicial" && "#3058A4"}
        hover={
          location.pathname !== "/dashboard/menu-inicial" && {
            bg: "#E0E0E0",
            transition: "ease-in 0.2s",
            transform: "scale(1.1)",
          }
        }
      >
        <BsGrid
          fill={
            location.pathname === "/dashboard/menu-inicial"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="1.5rem"
        />
      </DrawerDeskOptions>
      <DrawerDeskOptions
        textName="Construtor de Lojas"
        pathnames={location.pathname === "/dashboard/construtor-de-lojas"}
        color={
          location.pathname === "/dashboard/construtor-de-lojas"
            ? "#fff"
            : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/construtor-de-lojas");
        }}
        bg={
          location.pathname === "/dashboard/construtor-de-lojas"
            ? "#3058A4"
            : ""
        }
        hover={
          location.pathname !== "/dashboard/construtor-de-lojas"
            ? {
                bg: "#E0E0E0",
                transition: "ease-in 0.2s",
                transform: "scale(1.1)",
              }
            : null
        }
      >
        <TbWorld
          color={
            location.pathname === "/dashboard/construtor-de-lojas"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Produtos em Alta"
        color={
          location.pathname === "/dashboard/produtos-em-alta" ||
          location.pathname === `/dashboard/produtos-em-alta/${id}`
            ? "#ffffff"
            : "#3058A4"
        }
        pathnames={
          location.pathname === "/dashboard/produtos-em-alta" ||
          location.pathname === `/dashboard/produtos-em-alta/${id}`
        }
        onClick={() => {
          navigate("/dashboard/produtos-em-alta");
        }}
        bg={
          location.pathname === "/dashboard/produtos-em-alta" ||
          location.pathname === `/dashboard/produtos-em-alta/${id}`
            ? "#3058A4"
            : ""
        }
        hover={
          location.pathname !== "/dashboard/produtos-em-alta" &&
          location.pathname !== `/dashboard/produtos-em-alta/${id}`
            ? {
                bg: "#e0e0e0",
                transition: "ease-in 0.2s",
                transform: "scale(1.1)",
              }
            : null
        }
      >
        <FaFire
          fill={
            location.pathname === "/dashboard/produtos-em-alta" ||
            location.pathname === `/dashboard/produtos-em-alta/${id}`
              ? "#42BBFF"
              : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Produtos"
        color={
          location.pathname === "/dashboard/fornecedores" ||
          location.pathname === `/dashboard/fornecedores/${id}`
            ? "#ffffff"
            : "#3058A4"
        }
        pathnames={
          location.pathname === "/dashboard/fornecedores" ||
          location.pathname === `/dashboard/fornecedores/${id}`
        }
        onClick={() => {
          navigate("/dashboard/fornecedores");
        }}
        bg={
          location.pathname === "/dashboard/fornecedores" ||
          location.pathname === `/dashboard/fornecedores/${id}`
            ? "#3058A4"
            : ""
        }
        hover={
          location.pathname !== "/dashboard/fornecedores" &&
          location.pathname !== `/dashboard/fornecedores/${id}`
            ? {
                bg: "#e0e0e0",
                transition: "ease-in 0.2s",
                transform: "scale(1.1)",
              }
            : null
        }
      >
        <BiStoreAlt
          fill={
            location.pathname === "/dashboard/fornecedores" ||
            location.pathname === `/dashboard/fornecedores/${id}`
              ? "#42BBFF"
              : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Gerador de Copy"
        color={location.pathname === "/dashboard/copy" ? "#ffffff" : "#3058A4"}
        pathnames={location.pathname === "/dashboard/copy"}
        onClick={() => {
          navigate("/dashboard/copy");
        }}
        bg={location.pathname === "/dashboard/copy" ? "#3058A4" : ""}
        hover={
          location.pathname !== "/dashboard/copy"
            ? {
                bg: "#e0e0e0",
                transition: "ease-in 0.2s",
                transform: "scale(1.1)",
              }
            : null
        }
      >
        <FaRegCopyright
          fill={location.pathname === "/dashboard/copy" ? "#42BBFF" : "#3058A4"}
          size="24px"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Gerador de Nomes"
        color={
          location.pathname === "/dashboard/gerador-de-nomes"
            ? "#ffffff"
            : "#3058A4"
        }
        pathnames={location.pathname === "/dashboard/gerador-de-nomes"}
        onClick={() => {
          navigate("/dashboard/gerador-de-nomes");
        }}
        bg={
          location.pathname === "/dashboard/gerador-de-nomes" ? "#3058A4" : ""
        }
        hover={
          location.pathname !== "/dashboard/gerador-de-nomes"
            ? {
                bg: "#e0e0e0",
                transition: "ease-in 0.2s",
                transform: "scale(1.1)",
              }
            : null
        }
      >
        <TbWorld
          color={
            location.pathname === "/dashboard/gerador-de-nomes"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Players Academy"
        color={
          location.pathname === "/dashboard/tutoriais" ||
          location.pathname === `/dashboard/tutoriais/${id}`
            ? "#ffffff"
            : "#3058A4"
        }
        pathnames={
          location.pathname === "/dashboard/tutoriais" ||
          location.pathname === `/dashboard/tutoriais/${id}`
        }
        onClick={() => {
          navigate("/dashboard/tutoriais");
        }}
        bg={
          location.pathname === "/dashboard/tutoriais" ||
          location.pathname === `/dashboard/tutoriais/${id}`
            ? "#3058A4"
            : ""
        }
        hover={
          location.pathname !== "/dashboard/tutoriais" &&
          location.pathname !== `/dashboard/tutoriais/${id}`
            ? {
                bg: "#e0e0e0",
                transition: "ease-in 0.2s",
                transform: "scale(1.1)",
              }
            : null
        }
      >
        <BiCube
          fill={
            location.pathname === "/dashboard/tutoriais" ||
            location.pathname === `/dashboard/tutoriais/${id}`
              ? "#42BBFF"
              : "#3058A4"
          }
          size="1.5rem"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Precificador"
        color={
          location.pathname === "/dashboard/calculadora" ? "#ffffff" : "#3058A4"
        }
        pathnames={location.pathname === "/dashboard/calculadora"}
        onClick={() => {
          navigate("/dashboard/calculadora");
        }}
        bg={location.pathname === "/dashboard/calculadora" ? "#3058A4" : ""}
        hover={
          location.pathname !== "/dashboard/calculadora"
            ? {
                bg: "#e0e0e0",
                transition: "ease-in 0.2s",
                transform: "scale(1.1)",
              }
            : null
        }
      >
        <BiCalculator
          fill={
            location.pathname === "/dashboard/calculadora"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="1.5rem"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Esteira de Produtos"
        pathnames={location.pathname === "/dashboard/esteira-de-produtos"}
        color={
          location.pathname === "/dashboard/esteira-de-produtos"
            ? "#ffffff"
            : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/esteira-de-produtos");
        }}
        bg={location.pathname === "/dashboard/esteira-de-produtos" && "#3058A4"}
        hover={
          location.pathname !== "/dashboard/esteira-de-produtos" && {
            bg: "#E0E0E0",
            transition: "ease-in 0.2s",
            transform: "scale(1.1)",
          }
        }
      >
        <BiStoreAlt
          fill={
            location.pathname === "/dashboard/esteira-de-produtos"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Aplicativos"
        pathnames={location.pathname === "/dashboard/aplicativos"}
        color={
          location.pathname === "/dashboard/aplicativos" ? "#ffffff" : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/aplicativos");
        }}
        bg={location.pathname === "/dashboard/aplicativos" && "#3058A4"}
        hover={
          location.pathname !== "/dashboard/aplicativos" && {
            bg: "#E0E0E0",
            transition: "ease-in 0.2s",
            transform: "scale(1.1)",
          }
        }
      >
        <LuLayoutGrid
          color={
            location.pathname === "/dashboard/aplicativos"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Planos"
        pathnames={location.pathname === "/dashboard/planos"}
        color={
          location.pathname === "/dashboard/planos" ? "#ffffff" : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/planos");
        }}
        bg={location.pathname === "/dashboard/planos" && "#3058A4"}
        hover={
          location.pathname !== "/dashboard/planos" && {
            bg: "#E0E0E0",
            transition: "ease-in 0.2s",
            transform: "scale(1.1)",
          }
        }
      >
        <BsCurrencyDollar
          color={
            location.pathname === "/dashboard/planos" ? "#42BBFF" : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>
    </Box>
  );
};
