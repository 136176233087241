import * as T from "./styles";

import { useTutorialsClient } from "../../../contexts/Tutorials/TutorialsContextClient";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";

import { Input } from "../../../components/Reusable/Input";
import { Title } from "../../../components/Reusable/Title";
import { BiCube } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { Container } from "../../../components/Reusable/Container";
import { TutorialCard } from "./TutorialCard";
import { SkeletonTemplates } from "../../../components/Reusable/Skeletons/SkeletonTemplates";

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./styles.css";

export const Tutorials = () => {
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const { tutorials, loadTutorials } = useTutorialsClient();

  useEffect(() => {
    setLoading(true);

    loadTutorials()
      .then((_) => setLoading(false))
      .catch((_) => setLoading(false));

    // eslint-disable-next-line
  }, []);

  // Filtro de categorias dentro da tabela de tutoriais - impedindo duplicidade
  const getCategories = useMemo(() => {
    const filteredCategories = tutorials?.reduce((categories, tutorial) => {
      if (!categories.includes(tutorial.categoria)) {
        categories.push(tutorial.categoria);
      }
      return categories;
    }, []);

    if (filteredCategories.length > 0) {
      return filteredCategories;
    }

    return [];
  }, [tutorials]);

  return (
    <Container>
      {location.pathname === "/dashboard/tutoriais" ? (
        <>
          <Title
            headingName="Players"
            caption="Estamos empolgados em tê-lo(a) por aqui, prontos para embarcar em uma jornada rumo ao sucesso na construção de uma loja virtual. Queremos garantir que vocês tenham todas as ferramentas  necessárias para alcançar o sucesso, por isso, incentivamos vocês a  aproveitarem ao máximo cada aula."
          >
            <BiCube fill="#3058A4" size="1.5rem" />
          </Title>

          <hr />

          <T.Header>
            <div className="search">
              <Input
                height="2.5rem"
                border="2px solid #3058a4"
                _placeholder={{ fontSize: "0.8rem" }}
                placeholder="Pesquise algum tutorial..."
              />
              <span onClick={() => {}}>
                <FaSearch fill="#3058A5" size="1.5rem" />
              </span>
            </div>
          </T.Header>

          <hr />

          <T.Content>
            {loading ? (
              <div className="skeleton">
                <SkeletonTemplates quantityParam={8} />
              </div>
            ) : (
              getCategories?.map((category) => {
                return (
                  <div key={category}>
                    <h4>{category}</h4>

                    <Swiper
                      className="mySwiper" // install Swiper modules
                      modules={[Navigation, Pagination]}
                      slidesPerView={4}
                      spaceBetween={30}
                      navigation={true}
                      pagination={{ clickable: true }}
                    >
                      {tutorials
                        .filter((tutorial) => tutorial.categoria === category)
                        .map((tutorial) => {
                          return (
                            <SwiperSlide key={tutorial.id}>
                              <TutorialCard
                                tutorial={tutorial}
                                category={category}
                              />
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                  </div>
                );
              })
            )}
          </T.Content>
        </>
      ) : (
        <Outlet />
      )}
    </Container>
  );
};
