import { BsArrowRight } from "react-icons/bs";
import { useDisclosure } from "@chakra-ui/react";
import { ModalPurchaseDomain } from "../../components/Modal/NameGenerator/ModalPurchaseDomain";

export const StoreNameCard = ({ name }) => {
  const domain = name.toLowerCase().replace(/\s/g, "");

  const {
    isOpen: isOpenModalPurchaseDomain,
    onOpen: onOpenModalPurchaseDomain,
    onClose: onCloseModalPurchaseDomain,
  } = useDisclosure();
  return (
    <div className="card-name">
      <h4>{name}</h4>

      <div className="domain">
        <p>Domínio:</p>
        <span>{`www.${domain}.com.br`}</span>
      </div>

      <div
        className="verify-domain"
        onClick={() => onOpenModalPurchaseDomain()}
      >
        <p>Verificar domínio</p>
        <BsArrowRight fill="#FB8A03" size="1.5rem" />
      </div>

      <ModalPurchaseDomain
        isOpen={isOpenModalPurchaseDomain}
        onClose={onCloseModalPurchaseDomain}
        domainName={`www.${domain}.com.br`}
      />
    </div>
  );
};
