import { Center } from "@chakra-ui/react";
import { FaLocationArrow, FaTimes } from "react-icons/fa";

export const WebsiteInfo = ({ site }) => {
  return (
    <div className="content main">
      <li className="content__nome">{site.nome}</li>
      <li className="content__email">{site.contato}</li>
      <li className="content__sites">{site.dominio}</li>
      <li className="content__temas">{site.categoria}</li>
      <li className="content__id">{site.id_site}</li>
      <li className="content__acoes">
        <Center as="button" _hover={{ opacity: 0.7 }}>
          <FaTimes fill="#F44336" size="1.5rem" />
        </Center>
        <Center as="button" _hover={{ opacity: 0.7 }}>
          <FaLocationArrow fill="#727272" size="1.5rem" />
        </Center>
      </li>
    </div>
  );
};
