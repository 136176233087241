import { useNavigate } from "react-router-dom";
import { Details } from "./styles";
import { AspectRatio, Spinner } from "@chakra-ui/react";
import { useState } from "react";

export const TutorialDetails = ({ classes, classTutorial }) => {
  const [loading, setLoading] = useState(false);
  const [filteredClass, setFilteredClass] = useState(classTutorial);

  const navigate = useNavigate();

  const handleFilterClasses = (classId) => {
    setLoading(true);

    setTimeout(() => {
      const filterClass = classes.find((data) => data.id === classId);

      setFilteredClass(filterClass);

      setLoading(false);
    }, 2000);
  };

  return (
    <Details>
      {loading ? (
        <Spinner color="#3058a4" size="xl" />
      ) : (
        <div className="video-content">
          <AspectRatio w="100%" ratio={1.8}>
            <iframe
              allowFullScreen
              title={filteredClass.titulo}
              src={filteredClass.video}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            />
          </AspectRatio>

          <h4>{filteredClass.titulo}</h4>
          <div
            className="post__description"
            dangerouslySetInnerHTML={{ __html: filteredClass.descricao }}
          />
        </div>
      )}

      <div className="divider" />

      <div className="more-classes-content">
        <ul className="more-classes">
          {classes.map((data) => (
            <li key={data.id} onClick={() => handleFilterClasses(data.id)}>
              {data.titulo}
            </li>
          ))}
        </ul>

        <button onClick={() => navigate("/dashboard/tutoriais")}>
          Voltar para os tutoriais
        </button>
      </div>
    </Details>
  );
};
