import { apiAuth } from "../../../services/api";
import { useToast } from "@chakra-ui/react";
import { useTemplatesClient } from "../TemplatesContextClient";
import { useContext, createContext } from "react";

const TemplatesContextAdmin = createContext({});

export const useTemplatesAdmin = () => {
  const context = useContext(TemplatesContextAdmin);

  if (!context) {
    throw new Error(
      "useTemplatesAdmin must be used within a TemplateProviderAdmin"
    );
  }

  return context;
};

export const TemplatesProviderAdmin = ({ children }) => {
  const { loadAllTemplates } = useTemplatesClient();

  const toast = useToast();

  // Adiciona novos templates no painel adm
  const addNewTemplate = async (data) => {
    const body = {
      ...data,
      capa_template: data.capa_template[0],
      status: "ativo",
    };
    try {
      await apiAuth
        .post("/casdastrotemplate-admin", body, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((_) => {
          loadAllTemplates();
          toast({
            title: "Sucesso",
            description: "Template cadastrado com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
        })
        .catch((_) => {
          toast({
            title: "Opss...",
            description: "Algo deu errado ao cadastrar template!",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Edição de template no painel adm
  const editTemplate = async (data, template_id) => {
    const body = {
      ...data,
      capa_template: data.capa_template[0],
      id_template: template_id,
      status: "ativo",
    };

    try {
      await apiAuth
        .post(`/editartemplates-admin?id_template=${template_id}`, body, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          toast({
            title: "Sucesso",
            description: "Template editado com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadAllTemplates();
        })
        .catch((_) => {
          toast({
            title: "Opss...",
            description: "Algo deu errado ao editar template!",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Exclusão de templates no painel adm
  const deleteTemplate = async (id_template) => {
    await apiAuth
      .get(`/excluitemplate-admin?id_template=${id_template}`)
      .then((_) => {
        toast({
          title: "Sucesso",
          description: "Template excluído!",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
        loadAllTemplates();
      })
      .catch((_) =>
        toast({
          title: "Erro",
          description: "Algo deu errado ao excluir template!",
          status: "error",
          duration: 2500,
          isClosable: true,
        })
      );
  };

  return (
    <TemplatesContextAdmin.Provider
      value={{ addNewTemplate, editTemplate, deleteTemplate }}
    >
      {children}
    </TemplatesContextAdmin.Provider>
  );
};
