import { apiAuth } from "../../../services/api";
import { useToast } from "@chakra-ui/react";
import { useCategoriesClient } from "../CategoriesContextClient";
import { createContext, useContext } from "react";

const CategoriesContextAdmin = createContext({});

export const useCategoriesAdmin = () => {
  const context = useContext(CategoriesContextAdmin);

  if (!context) {
    throw new Error(
      "useCategoriesAdmin must be used within an CategoriesProviderAdmin"
    );
  }

  return context;
};

export const CategoriesProviderAdmin = ({ children }) => {
  const { loadCategories } = useCategoriesClient();

  const toast = useToast();

  const addNewCategory = async (data) => {
    try {
      await apiAuth
        .post("/cadastrarcategorias_produtos", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((_) => {
          toast({
            title: "Sucesso",
            description: "Categoria cadastrada com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadCategories();
        });
    } catch (err) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao cadastrar categoria!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  const deleteCategory = async (categoryId) => {
    await apiAuth
      .get(`/deletarcategorias_produtos?id=${categoryId}`)
      .then((res) => {
        if (res.data.status !== "error") {
          toast({
            title: "Sucesso",
            description: "Categoria excluída com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadCategories();
        } else {
          toast({
            title: "Erro",
            description: "Algo deu errado ao excluir categoria!",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
        }
      });
  };

  const editCategory = async (data, categoryId) => {
    const body = { ...data, id: categoryId };
    try {
      await apiAuth
        .post(`/editarcategorias_produtos?id=${categoryId}`, body)
        .then((_) => {
          toast({
            title: "Sucesso",
            description: "Categoria editada com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadCategories();
        });
    } catch (_) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao editar categoria!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  return (
    <CategoriesContextAdmin.Provider
      value={{ addNewCategory, editCategory, deleteCategory }}
    >
      {children}
    </CategoriesContextAdmin.Provider>
  );
};
