import * as D from "./styles";

import { IoCloudyNightSharp } from "react-icons/io5";
import { WiDayCloudy, WiDayCloudyHigh } from "react-icons/wi";

// import { Slider } from "./Slider";
import { Container } from "../../components/Reusable/Container";
import { DashNavigation } from "./DashNavigation";
import { BannersGetStarted } from "./BannersGetStarted";
// import { BannersOurFeatures } from "./BannersOurFeatures";

import { useAuth } from "../../contexts/Auth";
import { useEffect, useState } from "react";

import { ModalAddContactContent } from "./ModalAddContactContent";
import { Progress } from "@chakra-ui/react";
import { useCredits } from "../../contexts/Credits";
import { ChecklistDashboard } from "../../components/ChecklistDashboard";
import { useWebsitesClient } from "../../contexts/Websites/WebsitesContextClient";
import { useBacklogs } from "../../contexts/Backlogs";
import { useProductsClient } from "../../contexts/Products/ProductsContextClient";

export const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [saudation, setSaudation] = useState("");
  const [showModalContent, setShowModalContent] = useState(false);

  const { data } = useAuth();
  const { loadBacklogUser } = useBacklogs();
  const { loadWebsitesUser } = useWebsitesClient();
  const { loadProductsConveyor } = useProductsClient();
  const { checkAndRenewCredits } = useCredits();

  useEffect(() => {
    const hour = new Date().getHours();
    if (hour <= 5) return setSaudation("Boa madrugada");
    if (hour > 5 && hour < 12) return setSaudation("Bom dia");
    if (hour >= 12 && hour < 18) return setSaudation("Boa tarde");
    return setSaudation("Boa noite");
  }, []);

  useEffect(() => {
    loadWebsitesUser();
    loadBacklogUser();
    loadProductsConveyor();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      if (data.contato !== "") {
        setLoading(false);
        setShowModalContent(false);

        return;
      } else {
        setShowModalContent(true);
        setLoading(false);
      }
    }, 1000);

    // eslint-disable-next-line
  }, [data]);

  const onPageLoad = async () => {
    await checkAndRenewCredits();
  };

  window.addEventListener("load", onPageLoad);

  return (
    <Container>
      {loading ? (
        <Progress mb="1rem" borderRadius="10px" isIndeterminate />
      ) : null}

      <D.Header>
        <D.TopFlex>
          <div>
            {saudation === "Bom dia" ? (
              <WiDayCloudy size="2.2rem" color="#3058a5" />
            ) : saudation === "Boa tarde" ? (
              <WiDayCloudyHigh size="2.2rem" color="#3058a5" />
            ) : saudation === "Boa noite" ? (
              <IoCloudyNightSharp size="1.5rem" color="#3058a5" />
            ) : (
              <WiDayCloudy size="2.2rem" color="#3058a5" />
            )}
          </div>
          <p>
            {saudation}, {data.nome}
          </p>
        </D.TopFlex>

        <div className="divider" />

        <D.Bottom>
          <p>
            {saudation === "Boa madrugada"
              ? "Continue persistindo e conquiste cada vez mais. Tenha uma madrugada produtiva e cheia de inspiração!"
              : saudation === "Bom dia"
              ? "Sabemos que cada manhã é uma nova oportunidade para alcançar resultados incríveis. Então, que tal dar uma espiada nas suas vendas hoje?"
              : saudation === "Boa tarde"
              ? "Tenha um dia produtivo e repleto de vendas de sucesso!"
              : "Acredite no seu potencial e mantenha o foco em seus objetivos."}
          </p>
        </D.Bottom>
      </D.Header>

      <DashNavigation />

      <BannersGetStarted />

      {data.perfil !== "admin" && <ChecklistDashboard />}

      {/* <BannersOurFeatures /> */}

      {/* <h4>Tutoriais da PlayDrop</h4> */}

      {/* <Slider /> */}

      {showModalContent ? (
        <ModalAddContactContent onClose={() => setShowModalContent(false)} />
      ) : null}
    </Container>
  );
};
