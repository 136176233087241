import { BsBell } from "react-icons/bs";
import { Grid, Heading } from "@chakra-ui/react";

import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import { useBacklogs } from "../../../contexts/Backlogs";
import { MessageCard } from "./MessageCard";

export const ModalBacklog = ({ isOpen, onClose }) => {
  const { backlogDataUser } = useBacklogs();

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="49rem" height="35rem">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BsBell} color="#fff" />
            <ModalHeader.Title title="Mensagem do Administrador" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <Grid
            w="100%"
            h="400px"
            display="flex"
            flexDir="column"
            overflowX="hidden"
            overflowY="auto"
          >
            {backlogDataUser.length > 0 &&
            backlogDataUser?.filter(
              (item) =>
                item.status_pendencias !== "pendente" &&
                item.data_pendencia !== ""
            ).length > 0 ? (
              backlogDataUser.map((item) => (
                <MessageCard key={item.data_pendencia} item={item} />
              ))
            ) : (
              <Heading
                mt="8"
                color="#4f4f4f"
                textAlign="center"
                fontSize="1.25rem"
              >
                Sem notificações no momento
              </Heading>
            )}
          </Grid>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
