import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useAuth } from "../../contexts/Auth";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";

import { Input } from "../Reusable/Input";
import { Button } from "../Button";
import { Spinner } from "../Spinner";
import { FormAuth } from "./styles";

import { MdOutlineMail, MdLockOutline } from "react-icons/md";

import { yupResolver } from "@hookform/resolvers/yup";
import { signInSchema } from "../../schemas/schemas";

export const SignInForm = () => {
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const { signIn } = useAuth();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signInSchema),
  });

  const handleSignIn = (data) => {
    setLoading(true);

    signIn(data)
      .then((_) => setLoading(false))
      .catch((_) => {
        toast({
          title: "Opss...",
          description: "E-mail ou senha inválidos!",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setLoading(false);
      });

    reset();
  };

  return (
    <FormAuth onSubmit={handleSubmit(handleSignIn)}>
      <h2>Área de Login</h2>
      <Input
        type="text"
        label="E-mail"
        error={errors.email}
        icon={MdOutlineMail}
        {...register("email")}
      />
      <Input
        label="Senha"
        type="password"
        error={errors.senha}
        icon={MdLockOutline}
        {...register("senha")}
      />

      <Button formSchema type="submit">
        {loading ? <Spinner /> : "Acessar"}
      </Button>

      <Link to="/recuperar-senha">Esqueci minha senha</Link>
    </FormAuth>
  );
};
