import { Button, Grid, Heading, Text, Input } from "@chakra-ui/react";

import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";

import { BiStoreAlt } from "react-icons/bi";
import { useState } from "react";
import { useProductsAdmin } from "../../../contexts/Products/ProductsContextAdmin";

export const ModalAddVendors = ({ isOpen, onClose, adId, productName }) => {
  const [token, setToken] = useState(false);
  const [loading, setLoading] = useState(false);

  const { addNewVendors } = useProductsAdmin();

  const handleAddNewVendor = (ev, vendorId) => {
    ev.preventDefault();

    addNewVendors(token, vendorId, setLoading);
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BiStoreAlt} color="#fff" />
            <ModalHeader.Title title="Adicionar novos fornecedores" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <Grid display="flex" flexDir="column" gap="2rem" alignItems="center">
            <Heading
              textAlign="center"
              fontSize="1.125rem"
              color="#3058a4"
              fontWeight="500"
            >
              Adicione fornecedores de forma manual para o produto {productName}
            </Heading>

            <Grid w="100%">
              <Text color="#3058a4" fontSize="1.125rem">
                Token
              </Text>
              <Input
                w="100%"
                h="50px"
                border="2px solid #3058a4"
                borderRadius="10px"
                onChange={(ev) => setToken(ev.target.value)}
                placeholder="Cole aqui o token..."
              />
            </Grid>

            <Button
              w="200px"
              h="50px"
              bg="#3058a4"
              color="#fff"
              onClick={(ev) => handleAddNewVendor(ev, adId)}
              isLoading={loading}
              borderRadius="10px"
              _hover={{ opacity: 0.8 }}
            >
              Adicionar fornecedores
            </Button>
          </Grid>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
