import { Flex, Grid, Skeleton } from "@chakra-ui/react";

export const SkeletonProducts = ({ quantityParam }) => {
  const quantity = Array.from({ length: quantityParam });

  return (
    <>
      {quantity.map((_, index) => (
        <Grid key={index}>
          <Skeleton h="14rem" />
          <Skeleton mt="2" mb="2" w="150px" h="1rem" />
          <Skeleton mb="4" w="80px" h="1.25rem" />
          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <Skeleton mb="4" w="7rem" h="2.5rem" />
            <Skeleton mb="4" w="7rem" h="2.5rem" />
          </Flex>
        </Grid>
      ))}
    </>
  );
};
