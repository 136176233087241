import { Title } from "../../../components/Reusable/Title";
import { MainPage } from "../../../components/Reusable/Pagination/MainPage";
import { Container } from "../../../components/Reusable/Container";
import { BiStoreAlt } from "react-icons/bi";
import { CardProduct } from "./CardProduct";
import { useNavigate } from "react-router-dom";
import { SearchContent } from "../SocialMediaAdmin/styles";
import { MdArrowDropDown } from "react-icons/md";
import { SuppliersContent } from "./styles";
import { ModalCrudProduct } from "../../../components/Modal/Products/ModalCrudProduct";
import { useMemo, useState } from "react";
import {
  Grid,
  Input,
  Button,
  Select,
  Heading,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { useProductsAdmin } from "../../../contexts/Products/ProductsContextAdmin";
import { ModalMiningProducts } from "../../../components/Modal/Products/ModalMiningProducts";

let PageSize = 12;

export const SuppliersAdmin = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const { products, loadAllProducts } = useProductsAdmin();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenMiningProducts,
    onOpen: onOpenMiningProducts,
    onClose: onCloseMiningProducts,
  } = useDisclosure();

  useMemo(() => {
    loadAllProducts();

    // eslint-disable-next-line
  }, []);

  const currentTableSuppliersAdmin = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (products !== undefined) {
      return products.slice(firstPageIndex, lastPageIndex);
    }

    return [];
  }, [currentPage, products]);

  return (
    <Container>
      <Title
        headingName="Produtos - Painel Administrativo"
        caption="Produtos com fornecedores
            nacionais e internacionais, com frete grátis."
      >
        <BiStoreAlt size="2rem" fill="#3058A9" />
      </Title>
      <Flex
        flexDir={["column", "column", "row"]}
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          h="50px"
          w="250px"
          mb="1rem"
          bg="#3058A4"
          color="#ffffff"
          fontFamily="Poppins"
          _hover={{ opacity: 0.7 }}
          onClick={() => onOpen()}
        >
          Publicar novo produto
        </Button>

        <Button
          h="50px"
          w="250px"
          mb="1rem"
          bg="#3058A4"
          color="#ffffff"
          fontFamily="Poppins"
          _hover={{ opacity: 0.7 }}
          onClick={() => onOpenMiningProducts()}
        >
          Minerar produtos
        </Button>
      </Flex>
      <SearchContent>
        <div>
          <p>Show</p>
          <Select
            w="5rem"
            h="2rem"
            outline="0"
            color="#505050"
            iconSize="1rem"
            cursor="pointer"
            borderRadius="6px"
            iconColor="#3058A4"
            fontFamily="Poppins"
            fontSize="1.125rem"
            border="1px solid #505050"
            icon={<MdArrowDropDown />}
            _hover={{ borderColor: "#3058A4" }}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </Select>
          <p>entries</p>
        </div>
        <div>
          <p>Search:</p>
          <Input w="270px" color="#505050" />
        </div>
      </SearchContent>
      <SuppliersContent>
        {currentTableSuppliersAdmin.length < 1 ? (
          <Grid
            gap="4"
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <Heading
              w="100%"
              color="#3058a4"
              fontSize="2rem"
              textAlign="center"
            >
              Sem produtos cadastrados
            </Heading>
            <Button
              h="50px"
              w="250px"
              mb="1rem"
              bg="#3058A4"
              color="#ffffff"
              fontFamily="Poppins"
              _hover={{ opacity: 0.7 }}
              onClick={() =>
                navigate("/dashboard/fornecedores-admin/novo-produto")
              }
            >
              Publicar novo produto
            </Button>
          </Grid>
        ) : (
          <>
            <ul className="headers">
              <li className="headers__capa">Capa</li>
              <li className="headers__nome">Nome</li>
              <li className="headers__status">Status</li>
              <li className="headers__categoria">Categoria</li>
              <li className="headers__data">Data de postagem</li>
              <li className="headers__price">Preço R$</li>
              <li className="headers__acoes">Ações</li>
            </ul>
            <ul className="content">
              {currentTableSuppliersAdmin.map((product) => {
                return (
                  <CardProduct
                    key={product.id_fornecedores}
                    product={product}
                  />
                );
              })}
            </ul>
          </>
        )}
      </SuppliersContent>
      {products !== undefined && (
        <MainPage
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={products.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
      <ModalCrudProduct isOpen={isOpen} onClose={onClose} verbType="create" />
      <ModalMiningProducts
        isOpen={isOpenMiningProducts}
        onClose={onCloseMiningProducts}
      />
    </Container>
  );
};
