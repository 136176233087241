import { MediasProviderAdmin } from "./MediasContextAdmin";
import { MediasProviderClient } from "./MediasContextClient";

export const SocialMediaProvider = ({ children }) => {
  return (
    <MediasProviderClient>
      <MediasProviderAdmin>{children}</MediasProviderAdmin>
    </MediasProviderClient>
  );
};
