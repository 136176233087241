import * as B from "./styles";

export const Button = ({
  type,
  onClick = () => {},
  formSchema = false,
  children,
}) => {
  return (
    <B.Container type={type} onClick={onClick} formSchema={formSchema}>
      {children}
    </B.Container>
  );
};
