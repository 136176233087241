import styled from "styled-components";

export const ContentInput = styled.div`
  display: flex;
  align-items: center;

  input {
    width: 21px;
    height: 21px;
    cursor: pointer;
  }

  label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 25.1806px;
    line-height: 41px;
    color: #4f4f4f;
    margin-left: 1rem;
  }
`;

export const ContentInputCancel = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;

  input {
    width: 19px;
    height: 19px;
    cursor: pointer;
  }

  label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.6rem;
    color: #202020;
    margin-left: 0.7rem;
    cursor: pointer;
  }
`;
