import { Title } from "../../../components/Reusable/Title";
import { MainPage } from "../../../components/Reusable/Pagination/MainPage";
import { Container } from "../../../components/Reusable/Container";
import { BiCategory } from "react-icons/bi";
import { CardCategory } from "./CardCategory";
import { SearchContent } from "../SocialMediaAdmin/styles";
import { CategoryContent } from "./styles";
import { MdArrowDropDown } from "react-icons/md";
import { useMemo, useState } from "react";
import { useCategoriesClient } from "../../../contexts/Categories/CategoriesContextClient";
import { Button, Input, Select, useDisclosure } from "@chakra-ui/react";
import { ModalCrudCategory } from "../../../components/Modal/Categories/ModalCrudCategory";

let PageSize = 6;

export const CategoryAdmin = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { categoryData } = useCategoriesClient();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const currentTableCategories = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (categoryData !== undefined) {
      return categoryData.slice(firstPageIndex, lastPageIndex);
    }

    return [];
  }, [currentPage, categoryData]);

  return (
    <>
      <Container>
        <Title headingName="Categorias - Painel Administrativo">
          <BiCategory fill="#3058A4" size="1.5rem" />
        </Title>
        <Button
          h="50px"
          w="250px"
          mt="1rem"
          mb="1rem"
          bg="#3058A4"
          color="#ffffff"
          fontFamily="Poppins"
          _hover={{ opacity: 0.7 }}
          onClick={() => onOpen()}
        >
          Publicar nova categoria
        </Button>
        <SearchContent>
          <div>
            <p>Show</p>
            <Select
              w="5rem"
              h="2rem"
              outline="0"
              color="#505050"
              iconSize="1rem"
              cursor="pointer"
              borderRadius="6px"
              iconColor="#3058A4"
              fontSize="1.125rem"
              fontFamily="Poppins"
              border="1px solid #505050"
              icon={<MdArrowDropDown />}
              _hover={{ borderColor: "#3058A4" }}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </Select>
            <p>entries</p>
          </div>
          <div>
            <p>Search:</p>
            <Input w="270px" color="#505050" />
          </div>
        </SearchContent>
        <CategoryContent>
          <ul className="headers">
            <li className="headers__nome">Nome</li>
            <li className="headers__acoes">Ações</li>
          </ul>
          <ul className="content">
            {currentTableCategories.map((category) => {
              return <CardCategory key={category.id} categoryData={category} />;
            })}
          </ul>
        </CategoryContent>
        {categoryData !== undefined && (
          <MainPage
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={categoryData.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </Container>
      <ModalCrudCategory isOpen={isOpen} onClose={onClose} verbType="create" />
    </>
  );
};
