import { FiArrowUpRight } from "react-icons/fi";
import { Center, Flex, Grid, Text } from "@chakra-ui/react";

export const WebsiteCard = ({ website }) => {
  return (
    <Grid
      p="4"
      w="100%"
      borderRadius="4px"
      boxShadow="0px 3px 8px -2.0137px #3058A1"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Text color="#4f4f4f" fontSize="1.125rem">
          Sua loja:
        </Text>
        <Center cursor="pointer" _hover={{ opacity: 0.7 }}>
          <FiArrowUpRight size="1.5rem" fill="#3058A5" />
        </Center>
      </Flex>
      <Text color="#3058A5" fontSize={["1rem", "1rem", "1.25rem"]}>
        {website.nomesite}
      </Text>
    </Grid>
  );
};
