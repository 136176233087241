import * as PC from "../Products/styles";

import playdropIcon from "../../assets/icons/playdrop-icon.svg";

import { useNavigate } from "react-router-dom";

import { AiFillLike } from "react-icons/ai";
import { FaComment, FaShareAlt } from "react-icons/fa";
import { currencyFormat } from "../../utils/currencyFormat";
import { useProductsClient } from "../../contexts/Products/ProductsContextClient";

import { formatDate } from "../../utils/formatDate";
import { useAuth } from "../../contexts/Auth";
import { WithoutPlanContent } from "./styles";

export const TrendingProductCard = ({ product }) => {
  const navigate = useNavigate();

  const { data } = useAuth();

  const signed = data && Object.values(data).length > 0;

  const scrollToTop = () => {
    return window.scrollTo(0, 0);
  };

  const srcProduct =
    "https://services.playdrop.com.br/api/uploads/imgs/produtos";

  const { addExistingProductToTheConveyor } = useProductsClient();

  const linkProduct = `https://app.playdrop.com.br/dashboard/produtos-em-alta/${product.id_fornecedores}`;

  const isVideo = (filename) => {
    const videoExtensions = ["mp4", "avi", "mov", "mkv"]; // Adicione outras extensões de vídeo se necessário
    const extension = filename.split(".").pop();
    return videoExtensions.includes(extension.toLowerCase());
  };

  const handleLinkClick = () => {
    const url = `/dashboard/fornecedores/${product.id_fornecedores}`;
    window.open(url, "_blank");
  };

  return (
    <PC.Container>
      {signed && data.id_planos === 4 ? (
        <WithoutPlanContent>
          <div>
            <img src={playdropIcon} alt="Ícone da Playdrop" />
            <h4>Gold</h4>
          </div>

          <h5>Disponível no Plano Gold</h5>

          <button onClick={() => navigate("/dashboard/planos")}>
            Assinar plano
          </button>
        </WithoutPlanContent>
      ) : (
        <>
          <figure
            onClick={() => {
              handleLinkClick();
              scrollToTop();
            }}
          >
            {isVideo(product.imagem) ? (
              <video controls muted>
                <source
                  src={`${srcProduct}/${product.imagem}`}
                  type={`video/${product.imagem.split(".").pop()}`}
                />
                Seu navegador não suporta o elemento de vídeo.
              </video>
            ) : (
              <img
                alt={product.produto}
                src={`${srcProduct}/${product.imagem}`}
              />
            )}
          </figure>

          <h4>{product.produto}</h4>

          <div className="price">
            <p>
              Publicado em: <span>{formatDate(product.data)}</span>
            </p>
            <p>
              Custo: <span>{currencyFormat(product.preco)}</span>
            </p>
          </div>

          <div className="statistics">
            <div className="statistics__content">
              <AiFillLike size="1.5rem" fill="#3058a4" />
              <span>
                {product.TotalReactions !== null ? product.TotalReactions : 0}
              </span>
            </div>
            <div className="statistics__content">
              <FaComment size="1.5rem" fill="#3058a4" />
              <span>
                {product.TotalComments !== null ? product.TotalComments : 0}
              </span>
            </div>
            <div className="statistics__content">
              <FaShareAlt size="1.5rem" fill="#3058a4" />
              <span>
                {product.TotalShares !== null ? product.TotalShares : 0}
              </span>
            </div>
          </div>

          <div className="buttons">
            <button
              onClick={() => {
                handleLinkClick();
                scrollToTop();
              }}
            >
              Detalhes
            </button>
            <button
              className="orange"
              onClick={() =>
                addExistingProductToTheConveyor(
                  product.produto,
                  product.imagem,
                  linkProduct
                )
              }
            >
              Adicionar a esteira
            </button>
          </div>
        </>
      )}
    </PC.Container>
  );
};
