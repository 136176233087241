import * as PC from "./styles";

import { useAuth } from "../../contexts/Auth";
import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useProductsClient } from "../../contexts/Products/ProductsContextClient";

import { ModalBlockUser } from "../../components/Modal/Products/ModalBlockUser";
import { ModalConveyorDetails } from "../../components/Modal/Products/ModalConveyorDetails";
import { ModalCrudProductConveyor } from "../../components/Modal/Products/ModalCrudProductConveyor";

import { FaChevronLeft, FaChevronRight, FaEdit, FaTrash } from "react-icons/fa";

export const ProductConveyorCard = ({ product }) => {
  const { data } = useAuth();

  const [showOptions, setShowOptions] = useState(false);

  const { deteleProduct, loadProductsConveyor, loadProductDetails } =
    useProductsClient();

  const {
    isOpen: isOpenProductConveyorDetails,
    onOpen: onOpenProductConveyorDetails,
    onClose: onCloseProductConveyorDetails,
  } = useDisclosure();

  const {
    isOpen: isOpenEditProduct,
    onOpen: onOpenEditProduct,
    onClose: onCloseEditProduct,
  } = useDisclosure();

  const {
    isOpen: isOpenBlockContent,
    onOpen: onOpenBlockContent,
    onClose: onCloseBlockContent,
  } = useDisclosure();

  return (
    <>
      <PC.Card>
        <p
          onClick={() => {
            if (data.id_planos !== 4) {
              onOpenProductConveyorDetails();
              loadProductDetails(product.id);
            } else {
              onOpenBlockContent();
            }
          }}
        >
          {product.content}
        </p>

        <div>
          {!showOptions ? (
            <FaChevronLeft
              size="1.25rem"
              onClick={() => setShowOptions(!showOptions)}
            />
          ) : (
            <FaChevronRight
              size="1.25rem"
              onClick={() => setShowOptions(!showOptions)}
            />
          )}

          {showOptions && (
            <>
              <div className="options">
                <FaTrash
                  size="1.25rem"
                  color="#fb6b03"
                  onClick={() => {
                    deteleProduct(product.id);
                    loadProductsConveyor();
                  }}
                />
                <FaEdit size="1.25rem" onClick={() => onOpenEditProduct()} />
              </div>
            </>
          )}
        </div>
      </PC.Card>

      <ModalConveyorDetails
        isOpen={isOpenProductConveyorDetails}
        onClose={onCloseProductConveyorDetails}
        product={product}
      />

      <ModalCrudProductConveyor
        isOpen={isOpenEditProduct}
        onClose={onCloseEditProduct}
        product={product}
        crudOption="update"
      />

      <ModalBlockUser
        isOpen={isOpenBlockContent}
        onClose={onCloseBlockContent}
        title="Análise e edição disponíveis a partir do Plano Gold"
        text="Tenha acesso completo à esteira de produtos no plano Premium para manter
        a operação da sua empresa organizada. Saia na frente de seus
        concorrentes."
      />
    </>
  );
};
