import { useCredits } from "../../contexts/Credits";
import { WebsiteCard } from "../../components/Modal/Templates/WebsiteCard";
import { useWebsitesClient } from "../../contexts/Websites/WebsitesContextClient";
import { ImportProductContent } from "./styles";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export const ModalImportProductContent = ({ onClose, productId, planId }) => {
  const { credits, discountCoinWallet } = useCredits();

  const filteredCredits =
    credits.length > 0 &&
    credits?.find((item) => item.category === "importar_para_shopify");

  const { websitesUser, loadWebsitesUser } = useWebsitesClient();

  useEffect(() => {
    loadWebsitesUser();

    // eslint-disable-next-line
  }, []);

  return (
    <ImportProductContent>
      {planId === 4 && (
        <h4>
          Você pode importar {filteredCredits && filteredCredits.creditos / 5}{" "}
          produtos para sua loja
        </h4>
      )}

      <div>
        {websitesUser.length > 0 ? (
          websitesUser.map((website) => (
            <WebsiteCard
              key={website.id_site}
              site={website}
              productId={productId}
              onClose={onClose}
              filteredCredits={filteredCredits}
              discountCoinWallet={discountCoinWallet}
              planId={planId}
            />
          ))
        ) : (
          <div className="no-websites">
            <h4>
              Você ainda não possui nenhuma loja cadastrada para importar
              produtos
            </h4>
            <Link to="/dashboard/construtor-de-lojas">Criar loja</Link>
          </div>
        )}
      </div>
    </ImportProductContent>
  );
};
