import { FaShopLock } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { BlockContent } from "./styles";

export const ModalBlockContent = ({ title, text }) => {
  const navigate = useNavigate();

  return (
    <BlockContent>
      <div>
        <FaShopLock fill="#3058a4" size="3rem" />
        <h4>{title}</h4>
      </div>

      <p>{text}</p>

      <button
        className="button-block-content"
        onClick={() => navigate("/dashboard/planos")}
      >
        Alterar o meu plano
      </button>
    </BlockContent>
  );
};
