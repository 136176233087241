import { useState } from "react";

import { AddColumn } from "./styles";

import { Button, Input, useToast } from "@chakra-ui/react";
import { apiAuth } from "../../services/api";
import { useAuth } from "../../contexts/Auth";
import { useProductsClient } from "../../contexts/Products/ProductsContextClient";

export const CrudColumnContent = ({
  onClose,
  nameColumn,
  crudOption,
  esteira_id,
}) => {
  const [loading, setLoading] = useState(false);
  const [columnName, setColumnName] = useState("");

  const toast = useToast();

  const { data } = useAuth();
  const { updateColumn, loadProductsConveyor } = useProductsClient();

  const addNewColumn = async (columnName) => {
    setLoading(true);

    try {
      await apiAuth
        .post(
          `/cadastro-de-esteira?id_login=${data.id}`,
          { esteira_name: columnName },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((_) => {
          loadProductsConveyor();

          setLoading(false);

          toast({
            title: "Sucesso",
            description: "Coluna adicionada!",
            status: "success",
            duration: 1000,
            isClosable: true,
          });

          onClose();
        });
    } catch (error) {
      setLoading(false);

      toast({
        title: "Erro",
        description: "Algo deu errado ao cadastrar coluna!",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleCrudColumn = () => {
    if (crudOption === "add") {
      addNewColumn(columnName);
    } else {
      updateColumn(esteira_id, columnName);
      onClose();
    }
  };

  return (
    <AddColumn>
      <Input
        w="100%"
        h="50px"
        border="2px solid #3058a4"
        onChange={(ev) => setColumnName(ev.target.value)}
        placeholder="Digite aqui"
        defaultValue={crudOption === "update" ? nameColumn : ""}
      />

      <Button isLoading={loading} onClick={() => handleCrudColumn()}>
        {crudOption === "add" ? "Adicionar coluna" : "Atualizar coluna"}
      </Button>
    </AddColumn>
  );
};
