import { PlansProviderAdmin } from "./PlansContextAdmin";
import { PlansProviderClient } from "./PlansContextClient";

export const PlansProvider = ({ children }) => {
  return (
    <PlansProviderClient>
      <PlansProviderAdmin>{children}</PlansProviderAdmin>
    </PlansProviderClient>
  );
};
