import { apiAuth } from "../../../services/api";
import { useToast } from "@chakra-ui/react";
import { useMediasClient } from "../MediasContextClient";
import { createContext, useContext } from "react";

const MediasContextAdmin = createContext({});

export const useMediasAdmin = () => {
  const context = useContext(MediasContextAdmin);

  if (!context) {
    throw new Error("useMediasAdmin must be used within a MediasProviderAdmin");
  }

  return context;
};

export const MediasProviderAdmin = ({ children }) => {
  const { loadMedias } = useMediasClient();

  const toast = useToast();

  // Adiciona nova mídia no painel adm
  const addNewMedia = async (data) => {
    const body = { ...data, imagem: data.imagem[0] };
    try {
      await apiAuth
        .post("/cadastromidias", body, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((_) => {
          toast({
            title: "Sucesso",
            description: "Mídia cadastrada com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadMedias();
        });
    } catch (_) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao adicionar mídia!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  // Edita mídia no painel adm
  const editMedia = async (data, mediaId) => {
    const body = { ...data, imagem: data.imagem[0], id: mediaId };
    try {
      await apiAuth
        .post(`/editarmidias?id=${mediaId}`, body, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          toast({
            title: "Sucesso",
            description: "Mídia editada com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadMedias();
        });
    } catch (_) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao editar mídia!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  // Exclusão de mídias no painel adm
  const deleteMedia = async (mediaId) => {
    try {
      await apiAuth.delete(`/deletarmidias?id=${mediaId}`).then((_) => {
        toast({
          title: "Sucesso",
          description: "Mídia deletada com sucesso!",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
        loadMedias();
      });
    } catch (_) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao excluir mídia!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  return (
    <MediasContextAdmin.Provider
      value={{ addNewMedia, editMedia, deleteMedia }}
    >
      {children}
    </MediasContextAdmin.Provider>
  );
};
