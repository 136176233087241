import styled from "styled-components";

export const Content = styled.div`
  width: 100%;

  .search {
    position: relative;

    svg {
      position: absolute;
      top: 0.7rem;
      left: 0.5rem;

      z-index: 10;

      cursor: pointer;

      color: #3058a4;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .apps {
    margin-top: 2rem;

    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    .content {
      .grid {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
      }
    }
  }
`;

export const AppCardContent = styled.div`
  width: 250px;
  height: 350px;
  padding: 1rem;

  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 5px 4px -2px #e3e6fe;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  figure {
    width: 150px;
    height: 150px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  h4 {
    color: #4f4f4f;
    margin: 0;
  }

  p {
    color: #3058a4;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
  }

  button {
    width: 100%;
    height: 50px;

    color: #fff;
    border-radius: 10px;
    background: linear-gradient(89.97deg, #3058a5 0.44%, #2964d3 99.97%);

    font-size: 1.25rem;
    font-weight: 500;

    &:hover {
      opacity: 0.8;
    }
  }

  &:hover {
    transform: scale(1.1);
    transition: ease-in 0.2s;
  }
`;

export const ContentApp = styled.div`
  width: 100%;

  h4 {
    color: #4f4f4f;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 1rem;
  }

  iframe {
    width: 100%;
    height: 300px;
  }

  button {
    width: 100%;
    height: 50px;
    margin-top: 1rem;
    margin-bottom: 1rem;

    color: #3058a4;
    font-size: 1.25rem;
    font-weight: 500;

    color: #fff;
    border-radius: 10px;
    background: linear-gradient(89.97deg, #3058a5 0.44%, #2964d3 99.97%);

    &:hover {
      transition: ease-in 0.2s;
      opacity: 0.8;
    }
  }
`;
