import styled, { css } from "styled-components";

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;

    color: #4f4f4f;
    font-size: 12px;
    text-align: center;
  }
`;

export const Header = styled.div`
  width: 100%;
  padding: 1rem;
  max-width: 26rem;

  border: 1px solid #eee;
  box-shadow: 0 12px 9px -1.7px #e6eeff;
  border-radius: 10px;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;

  p {
    font-size: 1.125rem;
    font-weight: 600;
  }

  .month-title {
    color: ${({ changeMethod }) => (!changeMethod ? "#4f4f4f" : "#3058a4")};
  }

  .trim-title {
    color: ${({ changeMethod }) => (changeMethod ? "#4f4f4f" : "#3058a4")};
  }

  .change-method-content {
    width: 4.375rem;
    height: 1.875rem;

    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30rem;
    background-color: #dedede;

    cursor: pointer;

    position: relative;

    .month,
    .trim {
      width: 1.875rem;
      height: 1.875rem;

      border-radius: 50%;
      background-color: #26b1ff;

      position: absolute;
    }

    .month {
      right: 0;
    }
    .trim {
      left: 0;
    }
  }
`;

export const PlansContent = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  grid-gap: 2rem;
`;

export const CardPlanContent = styled.div`
  /* height: 38rem; */
  padding: 2rem;
  margin-bottom: 2rem;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;

  border: 1px solid #eee;
  box-shadow: 0 12px 9px -1.7px #e6eeff;
  border-radius: 10px;
  background-color: ${({ bgSchema }) => (bgSchema ? "#3058a4" : "#fff")};

  .title-plan {
    color: ${({ bgSchema }) => (bgSchema ? "#fff" : "#3058a4")};
    font-size: 1.8rem;
    margin-top: 1rem;
  }

  .best-seller {
    width: 100%;
    padding: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.1rem;
    font-weight: 500;

    color: #fff;
    border-radius: 4px;
    background: linear-gradient(89.97deg, #fb8a03 0.44%, #ffb052 99.97%);
  }

  .free-plan {
    margin-top: 2rem;

    position: relative;

    .top {
      top: 0;
      width: 200px;
      height: 2.6rem;
      position: absolute;
      border-radius: 8% 92% 14% 86% / 100% 0% 100% 0%;
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(48, 88, 165, 1) 35%,
        rgba(39, 152, 175, 1) 100%
      );
    }
    .back {
      width: 200px;
      height: 2.6rem;

      color: #3f3f3f;
      background-color: #e0e0e0;

      font-size: 1.25rem;
      font-weight: 500;

      display: flex;
      align-items: center;
      justify-content: center;

      transform: rotate(-6deg);
      border-radius: 8% 92% 14% 86% / 100% 0% 100% 0%;
    }
  }

  .price-gold {
    .old-price {
      margin-bottom: 0.7rem;

      display: flex;
      align-items: center;
      gap: 1rem;

      p {
        color: ${({ bgSchema }) => (bgSchema ? "#fff" : "#4f4f4f")};
        font-size: 1.1rem;
        font-weight: 500;
        text-decoration: line-through;
      }

      div {
        width: 100px;
        height: 35px;

        display: flex;
        align-items: center;
        justify-content: center;

        color: #fff;
        font-size: 1.125rem;
        font-weight: 500;
        border-radius: 4px;
        background-color: #fb8a03;
      }
    }

    .new-price {
      span {
        margin: 0;

        color: ${({ bgSchema }) => (bgSchema ? "#fff" : "#4f4f4f")};
        font-size: 0.8rem;
        font-weight: 500;
      }

      div {
        display: flex;
        align-items: center;

        p {
          color: ${({ bgSchema }) => (bgSchema ? "#fff" : "#4f4f4f")};
          font-size: 2rem;
          font-weight: 600;
        }
      }
    }
  }
`;

export const StyledButton = styled.button`
  height: 50px;
  width: 166px;
  bottom: -1.6rem;
  font-size: 1rem;
  font-weight: 700;
  color: #ffffff;
  position: absolute;
  border-radius: 8.59px;
  cursor: default;

  ${({ activePlan }) =>
    !activePlan &&
    css`
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    `}

  border: 0.85px solid #eeeeee;
  box-shadow: px 12.7792px 9.37143px -1.7039px #e6eeff;
  background: linear-gradient(89.97deg, #fb8a03 0.44%, #ffb052 99.97%);
`;

export const ContentChoosePlan = styled.div`
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h4 {
    color: #4f4f4f;
    font-size: 1.5rem;
    font-weight: 500;
  }
  h5 {
    color: #4f4f4f;
    font-size: 1.1rem;
  }

  ul {
    width: 100%;

    display: flex;
    flex-direction: column;

    gap: 2rem;

    .content-plan {
      position: relative;

      width: 100%;
      padding: 1rem;
      margin-top: 2rem;

      border: 1px solid #e3eeff;
      box-shadow: 0px 8px 5px -2px #3058a4;
      border-radius: 10px;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;

        h6 {
          margin-top: 2rem;

          color: #3f3f3f;
          font-size: 2.1rem;
          font-weight: 500;
        }
        span {
          font-size: 1.125rem;
          color: #4f4f4f;
        }

        p {
          font-size: 3rem;
          font-weight: 600;
          color: #5f5f5f;
        }
      }

      .go-to-checkout {
        width: 100%;

        a {
          width: 100%;

          button {
            width: 100%;
            height: 50px;

            border-radius: 10px;
            background-color: #fb8a03;

            color: #fff;
            font-size: 1.25rem;
            font-weight: 500;

            &:hover {
              cursor: pointer;
              transition: ease-in 0.2s;
              opacity: 0.8;
            }
          }
        }

        .checkout-safe {
          margin-top: 4px;

          color: #3058a4;
          font-size: 0.9rem;
          font-weight: 500;
        }
      }

      .terms {
        color: #4f4f4f;
        text-align: center;

        a {
          color: #3048a4;
        }
      }

      .economic-info {
        position: absolute;
        top: -1rem;

        width: 250px;
        height: 50px;

        color: #fff;
        font-size: 1.125rem;
        font-weight: 500;
        border-radius: 10px;
        background-color: #fb8a03;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
`;
