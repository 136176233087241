import { useBacklogs } from "../../../contexts/Backlogs";
import { useState } from "react";

import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import { ContentInputCancel } from "../styles";
import {
  Text,
  Flex,
  Button,
  Heading,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";

import { HiOutlineTrash } from "react-icons/hi";

export const ModalToUnsubscribe = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);

  const { backlogUpdateAdmin } = useBacklogs();

  const toast = useToast();

  const handlePlanCancel = () => {
    setLoading(true);

    const backLogObject = {
      data_pendencia: new Date(),
      titulo_pendencia: "Solicitação de cancelamento",
      status_pendencias: "pendente",
      pendencias: "Solicitação de cancelamento de assinatura",
    };

    backlogUpdateAdmin(backLogObject).then((_) => {
      setLoading(false);
      toast({
        title: "Sucesso",
        description: "Solicitação de Cancelamento realizada com sucesso!",
        status: "success",
        duration: 2500,
        isClosable: true,
      });
    });
  };

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="32rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={HiOutlineTrash} color="#fff" />
            <ModalHeader.Title title="Cancelamento de Assinatura" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <Heading
            mb="1rem"
            textAlign="center"
            fontFamily="Poppins"
            fontSize="1.8rem"
            fontWeight="600"
            color="#3058A5"
          >
            Qual o motivo do seu cancelamento?
          </Heading>
          <ContentInputCancel>
            <Flex alignItems="center">
              <input type="radio" id="reason-cancel-1" name="reason-cancel" />
              <label htmlFor="reason-cancel-1">Migrei para outra solução</label>
            </Flex>
            <Flex alignItems="center">
              <input type="radio" id="reason-cancel-2" name="reason-cancel" />
              <label htmlFor="reason-cancel-2">
                Não consegui configurar o Checkout
              </label>
            </Flex>
            <Flex alignItems="center">
              <input type="radio" id="reason-cancel-3" name="reason-cancel" />
              <label htmlFor="reason-cancel-3">
                Não tive o suporte necessário
              </label>
            </Flex>
            <Flex alignItems="center">
              <input type="radio" id="reason-cancel-4" name="reason-cancel" />
              <label htmlFor="reason-cancel-4">Achei caro</label>
            </Flex>
            <Flex alignItems="center">
              <input type="radio" id="reason-cancel-5" name="reason-cancel" />
              <label htmlFor="reason-cancel-5">Não gostei da PlayDrop</label>
            </Flex>
            <Flex alignItems="center">
              <input type="radio" id="reason-cancel-6" name="reason-cancel" />
              <label htmlFor="reason-cancel-6">Estava apenas testando</label>
            </Flex>
            <Flex alignItems="center">
              <input type="radio" id="reason-cancel-7" name="reason-cancel" />
              <label htmlFor="reason-cancel-7">Outro motivo</label>
            </Flex>
          </ContentInputCancel>
          <Flex alignItems="center" justifyContent="space-around">
            <Button
              w="120px"
              h="55px"
              color="#3f3f3f"
              fontWeight="500"
              fontSize="1.25rem"
              fontFamily="Poppins"
              border="1px solid #4f4f4f"
              _hover={{ opacity: 0.7 }}
              onClick={() => onClose()}
            >
              Voltar
            </Button>
            <Button
              w="250px"
              h="55px"
              bg="#CC281E"
              color="#ffffff"
              fontWeight="500"
              fontSize="1.25rem"
              fontFamily="Poppins"
              isLoading={loading}
              onClick={() => handlePlanCancel()}
              _hover={{ opacity: 0.7 }}
            >
              Cancelar assinatura
            </Button>
          </Flex>
        </Modal.Body>
        <ModalFooter w="100%">
          <Text
            textAlign="center"
            fontSize="1rem"
            color="#4F4F4F"
            fontWeight="400"
          >
            Assim que a assinatura for cancelada, sua loja será suspensa.
          </Text>
        </ModalFooter>
      </Modal.Content>
    </Modal.Root>
  );
};
