import { FaEdit, FaTimes } from "react-icons/fa";
import { ModalCrudProduct } from "../../../components/Modal/Products/ModalCrudProduct";
import { useProductsAdmin } from "../../../contexts/Products/ProductsContextAdmin";
import { Center, useDisclosure } from "@chakra-ui/react";

export const CardProduct = ({ product }) => {
  const { deleteProduct } = useProductsAdmin();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const srcProduct =
    "https://services.playdrop.com.br/api/uploads/imgs/produtos";

  const isVideo = (filename) => {
    const videoExtensions = ["mp4", "avi", "mov", "mkv"]; // Adicione outras extensões de vídeo se necessário
    const extension = filename.split(".").pop();
    return videoExtensions.includes(extension.toLowerCase());
  };

  return (
    <>
      <div className="main">
        <li className="content__capa">
          {isVideo(product.imagem) ? (
            <video controls muted>
              <source
                src={`${srcProduct}/${product.imagem}`}
                type={`video/${product.imagem.split(".").pop()}`}
              />
              Seu navegador não suporta o elemento de vídeo.
            </video>
          ) : (
            <img
              alt={product.produto}
              src={`${srcProduct}/${product.imagem}`}
            />
          )}
        </li>
        <li className="content__nome">{product.produto}</li>
        <li
          className={`content__status ${
            product.status === "Pendente" ? "orange" : ""
          }`}
        >
          {product.status}
        </li>
        <li className="content__categoria">{product.categoria}</li>
        <li className="content__data">{product.data}</li>
        <li className="content__price">
          {product.preco.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
          })}
        </li>
        <li className="content__acoes">
          <Center
            w="2rem"
            h="2rem"
            mr="6px"
            as="button"
            bg="#3058A4"
            borderRadius="6px"
            onClick={() => onOpen()}
          >
            <FaEdit fill="#ffffff" />
          </Center>
          <Center
            w="2rem"
            h="2rem"
            as="button"
            bg="#FB6B03"
            borderRadius="6px"
            onClick={() => deleteProduct(product.id_fornecedores)}
          >
            <FaTimes fill="#ffffff" />
          </Center>
        </li>
      </div>
      <ModalCrudProduct
        isOpen={isOpen}
        onClose={onClose}
        product={product}
        verbType="update"
      />
    </>
  );
};
