import styled from "styled-components";

export const SitesContent = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;

  li {
    text-align: center;
    height: 50px;
  }

  .main:nth-child(even) {
    background-color: #ffffff;
  }

  .header {
    width: 100%;

    display: flex;
    align-items: center;

    border-bottom: 1.5px solid #505050;

    .header__nome {
      width: 10%;
      margin-right: 6px;
    }
    .header__email {
      width: 30%;
      margin-right: 6px;
    }
    .header__sites {
      width: 20%;
      margin-right: 6px;
    }
    .header__tema {
      width: 15%;
      margin-right: 6px;
    }
    .header__id {
      width: 5%;
      margin-right: 6px;
    }
    .header__acoes {
      width: 20%;
    }
  }

  .content {
    width: 100%;

    display: flex;
    align-items: center;

    border-bottom: 1.5px solid #505050;
    padding-top: 0.4rem;

    .content__nome {
      width: 10%;
      margin-right: 6px;
    }
    .content__email {
      width: 30%;
      margin-right: 6px;
    }
    .content__sites {
      width: 20%;
      margin-right: 6px;
    }
    .content__temas {
      width: 15%;
      margin-right: 6px;
    }
    .content__id {
      width: 5%;
      margin-right: 6px;
    }
    .content__acoes {
      width: 20%;

      display: flex;
      justify-content: space-around;
    }
  }

  @media (max-width: 865px) {
    width: 100%;
    overflow-x: scroll;

    .header {
      width: 820px;
    }
    .content {
      width: 820px;
    }
  }
`;
