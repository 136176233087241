import { Flex, Text } from "@chakra-ui/react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export const FeedbackCard = ({ feedback }) => {
  return (
    <>
      {feedback === "positive" ? (
        <Flex
          w="100%"
          h="47px"
          pl="6"
          mb="2"
          bg="#56A754"
          borderRadius="10px"
          alignItems="center"
        >
          <AiOutlineCheckCircle
            color="#ffffff"
            fontWeight="500"
            size="1.25rem"
          />
          <Text
            ml="4"
            fontSize="1rem"
            fontWeight="600"
            fontFamily="Poppins"
            color="#ffffff"
          >
            Seu perfil está atualizado
          </Text>
        </Flex>
      ) : feedback === "negative" ? (
        <Flex
          w="100%"
          h="47px"
          pl="6"
          mb="2"
          bg="#FFDD86"
          borderRadius="10px"
          alignItems="center"
        >
          <HiOutlineExclamationCircle
            color="#BF4635"
            size="1.25rem"
            fontWeight="500"
          />
          <Text
            ml="4"
            fontSize="1rem"
            fontWeight="600"
            fontFamily="Poppins"
            color="#BF4635"
          >
            Por favor, complete seu cadastro de perfil
          </Text>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};
