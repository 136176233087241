import { ModalHeaderIcon } from "./ModalHeaderIcon";
import { ModalHeaderRoot } from "./ModalHeaderRoot";
import { ModalHeaderClose } from "./ModalHeaderClose";
import { ModalHeaderTitle } from "./ModalHeaderTitle";
import { ModalHeaderTitleContent } from "./ModalHeaderTitleContent";

export const ModalHeader = {
  Root: ModalHeaderRoot,
  TitleContent: ModalHeaderTitleContent,
  Title: ModalHeaderTitle,
  Icon: ModalHeaderIcon,
  Close: ModalHeaderClose,
};
