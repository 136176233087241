import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import { ModalDownloadTheme } from "../../../pages/StoreBuilder/ModalDownloadTheme";

import { FaArrowDown } from "react-icons/fa";

export const ModalAddTemplate = ({
  isOpen,
  onClose,
  themeName,
  themeId,
  descricao,
}) => {
  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="40rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={FaArrowDown} color="#fff" />
            <ModalHeader.Title title="Baixar novo tema" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>
        <Modal.Body>
          <ModalDownloadTheme
            themeName={themeName}
            themeId={themeId}
            descricao={descricao}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
