import { Modal } from "../index";
import { ModalHeader } from "../ModalHeader";
import { CrudColumnContent } from "../../../pages/ProductConveyor/CrudColumnContent";

import { BiStoreAlt } from "react-icons/bi";

export const ModalCrudColumn = ({
  isOpen,
  onClose,
  esteira_id,
  nameColumn,
  crudOption,
}) => {
  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxW="20rem" height="auto">
        <ModalHeader.Root>
          <ModalHeader.TitleContent>
            <ModalHeader.Icon icon={BiStoreAlt} color="#fff" />
            <ModalHeader.Title title="Adicionar nova coluna" />
          </ModalHeader.TitleContent>
          <ModalHeader.Close onClose={onClose} />
        </ModalHeader.Root>

        <Modal.Body>
          <CrudColumnContent
            onClose={onClose}
            nameColumn={nameColumn}
            crudOption={crudOption}
            esteira_id={esteira_id}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
