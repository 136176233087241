export const dataOptions = [
  { title: "Nomes de Marca", subtitle: "como Zara" },
  { title: "Nomes em Português", subtitle: "irá gerar nomes em português" },
  {
    title: "Nomes Simples",
    subtitle: "irá gerar nomes com uma palavra, como: Zara",
  },
  {
    title: "Nomes compostos",
    subtitle: "irá gerar nomes com duas palavras, como: Casas Bahia",
  },
  {
    title: "Nomes em Inglês",
    subtitle: "Irá gerar nomes em inglês como: Fashion",
  },
];
