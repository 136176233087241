import { Title } from "../../../components/Reusable/Title";
import { Container } from "../../../components/Reusable/Container";
import { Box, Center, Grid, Heading, Text } from "@chakra-ui/react";

import { FaCubes } from "react-icons/fa";

export const Courses = () => {
  return (
    <Container>
      <Title
        headingName="Cursos"
        caption="Estamos empolgados em tê-lo(a) por aqui, prontos para embarcar em uma jornada rumo ao sucesso na construção de uma loja virtual. Queremos garantir que vocês tenham todas as ferramentas  necessárias para alcançar o sucesso, por isso, incentivamos vocês a  aproveitarem ao máximo cada aula."
      >
        <FaCubes fill="#3058A4" size="1.5rem" />
      </Title>

      <Box h="2px" mt="8" mb="8" w="100%" bg="#d4d0d0" />

      <Grid w="100%" gap="8">
        <Heading
          fontWeight="600"
          fontSize="1.5rem"
          color="#3058A5"
          textAlign="center"
        >
          Temos uma página de cursos incrível
        </Heading>
        <Text
          fontWeight="500"
          color="#4f4f4f"
          fontSize="1.2rem"
          textAlign="center"
        >
          Através dela você fica por dentro de diversos assuntos relacionados a
          plataforma, ao dropshipping em geral, finanças e muito mais.
        </Text>
        <Center
          as="a"
          h="50px"
          w="300px"
          m="0 auto"
          target="_blank"
          rel="noreferrer"
          fontWeight="500"
          color="#3058A5"
          borderRadius="4px"
          border="2px solid #3058A5"
          href="https://player.astronmembers.com/cadastro/1038"
          _hover={{ color: "#fff", bg: "#3058A5", transition: "ease-in 0.2s" }}
        >
          Ir para página de cursos
        </Center>
      </Grid>
    </Container>
  );
};
