import styled from "styled-components";

export const HeaderUsers = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const ContentUsers = styled.ul`
  display: flex;
  flex-direction: column;

  margin-bottom: 1rem;

  li {
    text-align: center;
    height: 50px;
  }

  .main:nth-child(even) {
    background-color: #ffffff;
  }

  .header {
    width: 100%;

    display: flex;
    align-items: center;

    border-bottom: 1.5px solid #505050;

    .header__nome {
      width: 22.5%;
      margin-right: 5px;
    }
    .header__email {
      width: 22.5%;
      margin-right: 5px;
    }
    .header__plano {
      width: 12%;
      margin-right: 5px;
    }
    .header__status {
      width: 12%;
      margin-right: 5px;
    }
    .header__venc {
      width: 12%;
      margin-right: 5px;
    }
    .header__pagamento {
      width: 7%;
      margin-right: 5px;
    }
    .header__acoes {
      width: 12%;
      margin-right: 5px;
    }
  }

  @media (max-width: 865px) {
    width: 100%;
    overflow-x: scroll;

    .header {
      width: 820px;
    }
  }
`;

export const Users = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  border-bottom: 1.5px solid #505050;
  padding-top: 0.4rem;

  .content__nome {
    width: 22.5%;
    margin-right: 5px;
  }
  .content__email {
    width: 22.5%;
    margin-right: 5px;
  }
  .content__plano {
    width: 12%;
    margin-right: 5px;

    color: #fff;
    background-color: ${(props) =>
      props.planId === 4
        ? "#fb6b03"
        : props.planId === 5
        ? "#3058a4"
        : "#ff0000"};
  }
  .content__status {
    width: 12%;
    margin-right: 5px;
  }
  .content__venc {
    width: 12%;
    margin-right: 5px;
  }
  .content__pagamento {
    width: 7%;
    margin-right: 5px;
  }
  .content__acoes {
    width: 12%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  @media (max-width: 865px) {
    width: 820px;
  }
`;
