import axios from "axios";

export const apiAuth = axios.create({
  baseURL: "https://services.playdrop.com.br/api/",
});

export const apiCredits = axios.create({
  baseURL: "https://services.playdrop.com.br/api/v2/",
});

export const apiRegistroBr = axios.create({
  baseURL: "https://rdap.registro.br/domain",
});

export const apiCep = axios.create({
  baseURL: "https://api.postmon.com.br/v1/cep/",
});
