import { useState, useContext, createContext, useEffect } from "react";
import { useAuth } from "../Auth";
import { apiCredits } from "../../services/api";
import { getUnixTimestamp } from "../../utils/unixFormatDate";

const CreditsContext = createContext({});

export const useCredits = () => {
  const context = useContext(CreditsContext);

  if (!context) {
    throw new Error("useCredits must be used within a CreditsProvider");
  }

  return context;
};

export const CreditsProvider = ({ children }) => {
  const [credits, setCredits] = useState([]);
  const [totalCredits, setTotalCredits] = useState(0);

  const { data } = useAuth();

  const loadCreditsUser = async () => {
    const response = await apiCredits.post(
      "/get-credits/user",
      { id_login: data.id },
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    const credits = response.data;

    if (credits.response.length > 0) {
      const countCredits = credits.response.reduce(
        (acc, item) => acc + item.creditos,
        0
      );

      setTotalCredits(countCredits > 0 ? countCredits : 0);

      localStorage.setItem(
        "@PlayDrop:creditos",
        JSON.stringify(credits.response)
      );

      const storedData = localStorage.getItem("@PlayDrop:creditos");

      if (storedData) {
        setCredits(JSON.parse(storedData));
      }
    } else {
      setCredits([]);
    }
  };

  useEffect(() => {
    loadCreditsUser();

    // eslint-disable-next-line
  }, []);

  const createsCoinWallet = async (category, totalCredits) => {
    try {
      await apiCredits
        .post(
          "/get-credits/create",
          { id_login: data.id, category: category, creditos: totalCredits },
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((_) => {
          loadCreditsUser();
        });
    } catch (error) {
      console.log(error);
    }
  };

  const discountCoinWallet = async (
    creditId,
    discount,
    infoData,
    typeData = ""
  ) => {
    if (typeData !== "") {
      try {
        await apiCredits
          .post(
            "/get-credits/discount",
            {
              id: creditId,
              credit: 1,
              data: `${typeData}-${infoData}`,
            },
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((res) => {
            console.log(res);
            loadCreditsUser();
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await apiCredits
          .post(
            "/get-credits/discount",
            {
              id: creditId,
              credit: 1,
              data: `Produto-${infoData}`,
            },
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((res) => {
            console.log(res);
            loadCreditsUser();
          });
      } catch (error) {}
    }
  };

  const getDaysDifference = (date1, date2) => {
    const dayInMilliseconds = 86400000;

    const diffTime = Math.abs(date2 - date1);

    return Math.ceil(diffTime / dayInMilliseconds);
  };

  const checkAndRenewCredits = async () => {
    const currentDate = new Date();

    const renewalDate = getUnixTimestamp();

    const creditValues = {
      ver_produtos: 50,
      adicionar_esteira: 50,
      importar_para_shopify: 25,
      gerar_copy: 25,
      gerar_nomes: 25,
    };

    const body = credits?.filter((item) => {
      const createdAt = new Date(item.created_at);
      const renewalAt =
        item.renewal_at !== "0000-00-00 00:00:00"
          ? new Date(item.renewal_at * 1000)
          : null;

      const lastDate = renewalAt || createdAt;

      return getDaysDifference(lastDate, currentDate) >= 30;
    });

    console.log(body);

    const newData =
      body.length > 0 &&
      body.map((item) => ({
        id: item.id,
        creditos: creditValues[item.category],
        renewal_at: renewalDate,
      }));

    if (newData.length > 0) {
      try {
        await apiCredits
          .post(
            "/get-credits/renewal",
            { data: JSON.stringify(body) },
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((res) => {
            console.log(res);
            loadCreditsUser();
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  // const renewalCreditsManually = async () => {
  //   const creditValues = {
  //     ver_produtos: 50,
  //     adicionar_esteira: 50,
  //     importar_para_shopify: 25,
  //     gerar_copy: 25,
  //     gerar_nomes: 25,
  //   };

  //   const renewalDate = getUnixTimestamp();

  //   const body = credits.map((item) => ({
  //     id: item.id,
  //     creditos: creditValues[item.category],
  //     renewal_at: renewalDate,
  //   }));

  //   try {
  //     await apiCredits
  //       .post(
  //         "/get-credits/renewal",
  //         { data: JSON.stringify(body) },
  //         { headers: { "Content-Type": "multipart/form-data" } }
  //       )
  //       .then((res) => {
  //         console.log(res);
  //         loadCreditsUser();
  //       });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <CreditsContext.Provider
      value={{
        credits,
        totalCredits,
        loadCreditsUser,
        createsCoinWallet,
        discountCoinWallet,
        checkAndRenewCredits,
      }}
    >
      {children}
    </CreditsContext.Provider>
  );
};
