import { TemplatesProviderAdmin } from "./TemplatesContextAdmin";
import { TemplatesProviderClient } from "./TemplatesContextClient";

export const TemplatesProvider = ({ children }) => {
  return (
    <TemplatesProviderClient>
      <TemplatesProviderAdmin>{children}</TemplatesProviderAdmin>
    </TemplatesProviderClient>
  );
};
