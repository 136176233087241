import styled from "styled-components";

export const ContainerInput = styled.div`
  width: 100%;

  label {
    color: #3058a4;
    font-weight: 500;
  }

  .content-input {
    width: 100%;
    height: 55px;
    margin-top: 10px;

    border: 2px solid #3058a4;
    border-radius: 4px;

    input {
      width: 100%;
      height: 100%;
      padding: 1rem;

      border-radius: 4px;

      outline: none;
    }
  }

  .selections {
    width: 100%;
    margin-top: 10px;

    display: flex;
    align-items: center;
    gap: 1rem;

    li {
      padding: 10px;

      box-shadow: 0px 8px 5px -1px #e6eeff;
      border-radius: 10px;
      background-color: #fff;

      color: #4f4f4f;
      font-size: 1.125rem;
      font-weight: 500;

      cursor: pointer;
    }
  }
`;
