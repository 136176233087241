import { useState, useContext, useCallback, createContext } from "react";

import { apiAuth } from "../../services/api";
import { useAuth } from "../Auth";
import { useToast } from "@chakra-ui/react";

const BacklogsContext = createContext({});

export const useBacklogs = () => {
  const context = useContext(BacklogsContext);

  if (!context) {
    throw new Error("useBacklogs must be used within a BacklogsProvider");
  }

  return context;
};

export const BacklogsProvider = ({ children }) => {
  const [backlogData, setBacklogData] = useState([]);
  const [backlogDataUser, setBacklogDataUser] = useState([]);

  const { data } = useAuth();
  const toast = useToast();

  const loadBacklogs = useCallback(async () => {
    const response = await apiAuth.get("/pendencias-usuario");

    const backlogs = response.data;

    localStorage.setItem("@PlayDrop:pendencias", JSON.stringify(backlogs));

    const storedData = localStorage.getItem("@PlayDrop:pendencias");

    if (storedData) {
      setBacklogData([...JSON.parse(storedData)]);
    }
  }, []);

  const loadBacklogUser = async () => {
    const response = await apiAuth.get(
      `/lista-pendencias-usuario?id=${data.id}`
    );

    const backlogs = response.data;

    localStorage.setItem("@PlayDrop:pendencias-user", JSON.stringify(backlogs));

    const storedData = localStorage.getItem("@PlayDrop:pendencias-user");

    if (storedData) {
      setBacklogDataUser([...JSON.parse(storedData)]);
    }
  };

  // Admin > client
  const backlogUpdate = async (userId, data) => {
    const date = new Date();
    const body = { ...data, id: userId, data_pendencia: date };

    try {
      await apiAuth
        .post(`/update-pendencias?id=${userId}`, body)
        .then((_) => {
          toast({
            title: "Sucesso",
            description: "Envio realizado com sucesso!",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          loadBacklogs();
        })
        .catch((_) => {
          toast({
            title: "Erro",
            description: "Algo deu errado ao enviar notificação de pendência!",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
        });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Algo deu errado ao enviar notificação de pendência!",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  // client > admin
  const backlogUpdateAdmin = async (backlogData) => {
    const body = {
      ...backlogData,
      id: data.id,
    };

    try {
      await apiAuth
        .post(`/update-pendencias-usuario?id=${data.id}`, body)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <BacklogsContext.Provider
      value={{
        backlogData,
        backlogUpdate,
        backlogUpdateAdmin,
        backlogDataUser,
        loadBacklogs,
        loadBacklogUser,
      }}
    >
      {children}
    </BacklogsContext.Provider>
  );
};
