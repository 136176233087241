import { useDisclosure, useToast } from "@chakra-ui/react";
import { useProductsClient } from "../../contexts/Products/ProductsContextClient";
import * as OC from "./styles";

import { FaEdit, FaTimes, FaTrash } from "react-icons/fa";
import { ModalCrudColumn } from "../../components/Modal/Products/ModalCrudColumn";

export const OptionsConveyor = ({
  esteira_id,
  onClose = () => {},
  nameColumn,
  quantityColumns,
}) => {
  const { deteleColumn, loadProductsConveyor } = useProductsClient();

  const toast = useToast();

  const {
    isOpen: isOpenCrudColumn,
    onOpen: onOpenCrudColumn,
    onClose: onCloseCrudColumn,
  } = useDisclosure();

  const handleDeleteColumn = (esteiraId, quantity) => {
    if (quantity === 1) {
      toast({
        title: "Observação",
        description:
          "É importante manter ao menos uma coluna, caso queira remover, adicione uma nova ou edite a mesma",
        status: "warning",
        duration: 6000,
        isClosable: true,
      });
    } else {
      deteleColumn(esteiraId);
      loadProductsConveyor();
      onClose();
    }
  };

  return (
    <OC.Options>
      <div className="delete" onClick={() => onOpenCrudColumn()}>
        <FaEdit size="1.5rem" />
        <p>Atualizar coluna</p>
      </div>

      <div
        className="delete"
        onClick={() => handleDeleteColumn(esteira_id, quantityColumns)}
      >
        <FaTrash size="1.5rem" />
        <p>Exluir coluna</p>
      </div>

      <div className="close" onClick={() => onClose()}>
        <FaTimes size="1.5rem" fill="#fff" />
      </div>

      <ModalCrudColumn
        isOpen={isOpenCrudColumn}
        onClose={onCloseCrudColumn}
        esteira_id={esteira_id}
        nameColumn={nameColumn}
        crudOption="update"
      />
    </OC.Options>
  );
};
