import { Flex, Grid, Skeleton } from "@chakra-ui/react";

export const SkeletonTemplates = ({ quantityParam }) => {
  const quantity = Array.from({ length: quantityParam });

  return (
    <>
      {quantity.map((_, index) => (
        <Grid key={index}>
          <Skeleton h="14rem" />
          <Flex justifyContent="center">
            <Skeleton mt="2" mb="2" w="150px" h="2rem" />
          </Flex>
          <Flex
            w="100%"
            gap="1rem"
            alignItems="center"
            justifyContent="space-around"
          >
            <Skeleton mb="4" w="100%" h="2.5rem" />
            <Skeleton mb="4" w="100%" h="2.5rem" />
          </Flex>
        </Grid>
      ))}
    </>
  );
};
